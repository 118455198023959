import { Box, Typography } from '@mui/material';
import React from 'react';
import LoginMetaMaskButton from '../Login/LoginMetaMaskButton';
import LoginWithEmailButton from '../Login/LoginWithEmailButton';
import { makeStyles } from '@mui/styles';




const useStyles = makeStyles((theme) => ({

    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "50vh",
    },
    button: {

        maxWidth: 400,
        margin: 'auto',
        marginBottom: theme.spacing(2),
    },
    container: {
        marginTop: theme.spacing(9),
        marginBottom: theme.spacing(1),

    }


}));


export default function LoginView() {

    const classes = useStyles()

    return (
        <Box className={classes.root}>
            <div>
                {/* <div className={classes.container}>
                    <Typography variant="subtitle1" >
                        Already play Definin on Mobile?
                    </Typography>
                </div> */}
                {/* <div className={classes.button}>
                    <LoginWithEmailButton />
                </div> */}
                {/* <div className={classes.container}>
                    <Typography variant="subtitle1">
                        Never played DefiNin before
                    </Typography>
                </div> */}
                <div className={classes.button}>
                    <LoginMetaMaskButton />
                </div>
            </div>


        </Box>


    );


}
