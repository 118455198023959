import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { MailOutlined } from '@mui/icons-material';
import React, { Fragment, useRef } from "react";
import { Link } from 'react-router-dom';
import sessionData from '../../sessionData';
import { linkToAccount, linkToForgotPass } from '../../utils/link';
import { apiLogin } from "../../utils/utils";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    field: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        //width: '100%'
    },
    icon: {
        width: 40,
        height: 40,

    },
    loginButton: {
        margin: theme.spacing(2),
    }

}));


export default function LoginWithEmailButton() {

    const [open, setOpen] = React.useState(false)
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setError(null)
    };


    const [error, setError] = React.useState(null)


    const emailRef = useRef()
    const passwordRef = useRef()

    const handleLogin = () => {

        if (!emailRef || !emailRef.current ||
            !passwordRef || !passwordRef.current
        ) return

        const email = emailRef.current
        const password = passwordRef.current


        apiLogin(
            {
                email: email.value,
                password: password.value,

            },
            (data) => {
                sessionData.setSessionData(data.id, data.token, "","")
                window.location = linkToAccount
            },
            setError

        )
    }


    const classes = useStyles()

    return (
        <Fragment>
            <Button variant="contained" color="primary" fullWidth
                startIcon={<MailOutlined className={classes.icon} />}
                onClick={handleOpen}>
                Login with Email &amp; Password
            </Button>


            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
            >
                <Box component="form" autoComplete="true">
                    <DialogTitle id="alert-dialog-title">{"Login with Email"}</DialogTitle>
                    <DialogContent>
                        <Box>
                            <div ><TextField className={classes.field} inputRef={emailRef} placeholder="Email" type="email" fullWidth autoComplete="true" /></div>
                            <div><TextField className={classes.field} inputRef={passwordRef} placeholder="Password" type="password" fullWidth autoComplete="true"  name="password"/></div>
                            <Link to={linkToForgotPass}><Typography>Forgot password?</Typography></Link>
                            <div className={classes.field}><Typography color="error" variant="subtitle2">{error ? error : ""}</Typography> </div>

                        </Box >
                    </DialogContent>
                    <DialogActions>

                        <Button onClick={handleLogin} variant="contained" 
                            color="primary" className={classes.loginButton} fullWidth>
                            Login
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </Fragment>
    );


}
