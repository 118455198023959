import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { NoBackpackSharp, HelpOutlineOutlined } from "@mui/icons-material";
import { Button, Card, CardContent, CardHeader, TextField, Typography, Link, Divider, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { useSnackbar } from "notistack";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { CLAIM_DATA, COLLECT_TO_CLAIM, QUERY_ME } from "../../graphql/User/userQuery";
import { claimMATA, getNINOBalance } from "../../utils/cryptoWallet";
import { getPriceETHFromWei, handleResult } from "../../utils/utils";
import LoadingSpinner from "../Misc/LoadingSpinner";
import { HelpClaimMataDialog } from "../Misc/HelpClaimMataDialog";
import { TokenBalance } from "../views/InventoryView";
import { MIN_NINO_CLAIM } from "../../utils/constants";
import { LoadingButton } from "@mui/lab";


//let theme = createTheme();

// let theme = createTheme({
//     palette: {
//         primary: blue,
//         secondary: pink
//     },
// });

// theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme) => ({
    paper: {
        //height: '100%',
        maxWidth: 400

    },
    avatar: {
        [theme.breakpoints.down('xs')]: {
            padding: 0,
            marginRight: theme.spacing(1),
        },
    },
    root: {
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1.5),
            margin: theme.spacing(0),
        },

    },
    title: {
        whiteSpace: 'nowrap'
    },
    icon: {
        height: 50,
        width: 50,
        [theme.breakpoints.down('xs')]: {
            height: 30,
            width: 30,
        },
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        paddingBottom: theme.spacing(1),
        //marginTop: theme.spacing(1),
        //flexShrink: 0
    },
    field: {
        marginRight: theme.spacing(1),

    },
    error: {
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    helpIcon: {
        marginLeft: theme.spacing(1),
        height: 45
        //verticalAlign: "bottom",
        //height: "20px",
        //color: "black",
        //paddingBottom: "1px",
    },
    headerLink: {
        fontSize: "16px",
        lineHeight: "22px",
        color: "#212B36",
        // textDecoration: "rgba(244,120,32,.6)",
        textDecorationColor: "#212B36"
    }
}));





export default function Claim() {

    const { enqueueSnackbar } = useSnackbar();

    //const history = useHistory()


    const [loadingClaim, setLoadingClaim] = React.useState(false)
    const [balanceNino, setBalanceNino] = React.useState(0)
    const [claimMata, setClaimMata] = React.useState("")
    const [errorMessage, setErrorMessage] = React.useState(null)

    const { loading, error, data } = useQuery(QUERY_ME);

    const [openHelp, setOpenHelp] = useState(false)

    const getClaimData = () => {

        setLoadingClaim(true)

        client.query(
            {
                query: CLAIM_DATA,
                fetchPolicy: 'no-cache'
            })
            .then(result => {
                const { amount, nonce, sign } = result.data.claimData
                console.log(amount)
                claimMATA(amount, nonce, sign, enqueueSnackbar, onClaimComplete,
                    () => {
                        setLoadingClaim(false)
                    }
                )

            })
            .catch(
                error => {
                    setLoadingClaim(false)
                    console.log(error)
                    setErrorMessage(error.toString())

                }
            );

    }

    const [collectToClaimMutation] = useMutation(
        COLLECT_TO_CLAIM,
        {
            onError: (err) => {

                setLoadingClaim(false)
                setErrorMessage(err.toString())
            },
            onCompleted: getClaimData,
            //refetch ở đây chỉ có ý nghĩa khi claim trên chain fail, mà thường là không. Khi ko fail lại đâm ra có pending
            // refetchQueries: [
            //     { query: QUERY_ME }
            // ]
        });

    useEffect(
        () => getNINOBalance().then((balanceData) => {
            const balance = parseFloat(parseFloat(balanceData).toFixed(2))
            //updateBalance(balanceStr+ " "+balanceTicker)
            setBalanceNino(balance)
            console.log("balance NINO " + balance)
        })
        , []);





    const handleChangeClaimMata = (event) => setClaimMata(event.target.value)

    const client = useApolloClient()
    const classes = useStyles()

    if (loading) return <LoadingSpinner />
    //if (loading) return 'Loading...';
    //if (error) return `Error! ${error.message}`;

    let lastClaim, claimable, claimAmount, mataCollect, nextClaim;

    if (data && data.me) {
        ({ lastClaim, claimable, claimAmount, mataCollect, nextClaim } = data.me)

    }


    const nextClaimStr = nextClaim ? "Next claim: " + (new Date(nextClaim))
        .toLocaleString("en-US", { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }) : ""

    const onClaimComplete = () => {
        //history.go(0)
        setLoadingClaim(false)
        client.refetchQueries({
            //include: [QUERY_PETS,QUERY_MY_PETS],
            include: 'all'
        });

    }

    //claimable = true

    const mataCollectEth = getPriceETHFromWei(mataCollect)
    const claimAmountEth = getPriceETHFromWei(claimAmount)


    const collectToClaim = () => {

        setLoadingClaim(true)
        collectToClaimMutation({
            variables: {
                amount: parseFloat(claimMata) * 1000000000000000000,
            }
        })

    }

    // claimAmount = 100
    return (

        <React.Fragment>
            {/* <TokenBalance /> */}
            {/* <div style={{ marginTop: 24 }} /> */}
            {/* <ThemeProvider theme={theme}> */}
            <Card className={classes.paper} >
                <CardHeader classes={{
                    root: classes.root,
                    avatar: classes.avatar,
                    title: classes.title,
                }}
                    avatar={<img src="/images/svg/coin_mata.svg" className={classes.icon} />}
                    titleTypographyProps={{ variant: 'h6' }}
                    title={mataCollectEth}
                    subheader={claimAmount > 0 ? "Pending: " + claimAmountEth + " MATA" : ""}>

                </CardHeader>
                <div style={{ marginLeft: 30 }}>

                </div>

                <CardContent className={classes.center}>
                    <div>
                        {claimAmount > 0 ?
                            <LoadingButton loading={loadingClaim} variant="contained" color="primary" onClick={getClaimData}>Claim Pending</LoadingButton>
                            :
                            claimable ?
                                <Fragment>
                                    <TextField size="small" label="Enter amount of MATA to claim" className={classes.field} variant="outlined"
                                        value={claimMata}
                                        onChange={handleChangeClaimMata}
                                    />
                                    <LoadingButton
                                        loading={loadingClaim}
                                        variant="contained"
                                        color="primary" onClick={collectToClaim}>
                                        Claim
                                    </LoadingButton>

                                </Fragment>
                                :
                                <Typography>{nextClaimStr}</Typography>
                        }
                    </div>
                    <div>
                        <Divider style={{ marginTop: 16, marginBottom: 16 }} />
                        <Typography variant="caption">
                            {/* - Min 10,000 and max 30,000 MATA per withdrawal<br /> */}
                            - It takes 168 hours between 2 withdrawals<br />
                            {/* *We will increace max MATA per withdrawal and decrease time between 2 withdrawals when the game is stable<br /> */}
                        </Typography>
                        <Typography
                            color={balanceNino < MIN_NINO_CLAIM ? 'red' : ""}
                            variant="caption">
                            - You need to have at least {MIN_NINO_CLAIM} NINO in your wallet<br />

                            <Typography variant="caption">
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><Typography variant="caption" ><strong>NINO</strong></Typography></TableCell>
                                            <TableCell><Typography variant="caption"><strong>Maximum MATA you can claim</strong></Typography></TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell><Typography variant="caption">500</Typography></TableCell>
                                            <TableCell><Typography variant="caption">10,000</Typography></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><Typography variant="caption">5,000</Typography></TableCell>
                                            <TableCell><Typography variant="caption">30,000</Typography></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><Typography variant="caption">10,000</Typography></TableCell>
                                            <TableCell><Typography variant="caption">50,000</Typography></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>

                            </Typography>

                        </Typography>

                    </div>

                </CardContent>
                <div className={classes.error}><Typography color="error" variant="subtitle2">{errorMessage}</Typography></div>
                {/* <Link href="#" className={classes.headerLink} onClick={() => setOpenHelp(true)} >
                                    <HelpOutlineOutlined className={classes.helpIcon}
                                        onClick={() => setOpenHelp(true)}>
                                    </HelpOutlineOutlined>
                                </Link> */}


            </Card>
            {/* <HelpClaimMataDialog open={openHelp} onClose={() => setOpenHelp(false)} /> */}
            {/* </ThemeProvider> */}
        </React.Fragment>
    );


}
