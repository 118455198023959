import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    ListItem,
    Paper,
    Typography,
    useTheme
} from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import { Backup, Favorite, RestartAlt } from '@mui/icons-material';
import React, { Fragment, useEffect, useState } from "react";
import {
    BREED_COST_MATA,
    BREED_COST_NINO,
    CLASSES,
    FACTIONS,
    GENERATION_BETA,
    MINT_COST_MATA,
    MINT_COST_NINO,
    PET_AVATAR_SIZE,
    REBORN_COST_MATA,
    REBORN_COST_NINO, TIMES_RESET
} from "../../utils/constants";
import {
    approveNINO,
    approveMATA,
    checkApproveNINO,
    checkApproveMATA,
    getMATABalance,
    getNINOBalance,
    requestAdressAndApproveToken,
    rebornNFT,
    mintGem,
    checkGemApproveNINO,
    checkGemApproveMATA,
    approveGemNINO,
    approveGemMATA
} from "../../utils/cryptoWallet";
import SelectPetBox from "../Pet/SelectPetBox";

import { useSnackbar } from "notistack";
import { typography } from "@mui/system";
import { QUERY_REBORN_PET_DATA } from "../../graphql/User/marketQuery";
import { useApolloClient, useLazyQuery, useQuery } from '@apollo/client';
import LoadingSpinner from "../Misc/LoadingSpinner";
import PetView from "../views/PetView";
import { QUERY_MINT_GEM_DATA } from "../../graphql/User/userQuery";
import sessionData from "../../sessionData";

//const AccountLayout = loadable(() => import( './layouts/AccountLayout'/* webpackChunkName: "account" */))

const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            margin: theme.spacing(2)
        }
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(1),
        width: '50%',
        minHeight: 231,

        [theme.breakpoints.up('sm')]: {
            minWidth: 270,
            minHeight: 284,
        },
    },
    cardBody: {
        height: '100%',
        display: 'block'
    },
    petAvatar: {
        // width: PET_AVATAR_SIZE,
        // height: PET_AVATAR_SIZE,
        margin: "0 auto"
    },
    price: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(1)
    },
    priceBox: {
        marginTop: theme.spacing(1),
        padding: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 0,
    },
    btnApproved: {
        backgroundColor: "white !important",
        border: '1px solid rgba(145, 158, 171, 0.8)',
        color: 'rgb(33, 43, 54) !important'
    },
    btnNotApproved: {
        //cái màu nền  này làm cho cái loading bị ẩn ko nhìn đc
        //backgroundColor: "rgb(0, 171, 85) !important",
        // backgroundColor: "white !important",
        // '&:hover': {
        //     backgroundColor: 'rgb(0, 125, 85) !important'
        // },

    },
    factionIcon: {
        position: 'absolute',
        top: 0,
        left: '88%',
        [theme.breakpoints.up('sm')]: {
            width: 30,
            height: 30
        },


    },
    name: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.93rem'
        },
    }


}));




export default function MintGemBox({ component, ninneko, setLoadingAction, refetchQuery, refetchQueryPet }) {

    const [balanceMATA, updateBalanceMATA] = useState(0);
    const [balanceNINO, updateBalanceNINO] = useState(0);

    const [boolApproveNINO, setBoolApproveNINO] = useState(false)
    const [boolApproveMATA, setBoolApproveMATA] = useState(false)
    const [loadingApproveNINO, setLoadingApproveNINO] = useState(false)
    const [loadingApproveMATA, setLoadingApproveMATA] = useState(false)


    const { enqueueSnackbar } = useSnackbar();
    // const [loading, setLoading] = useState(false)

    const [open, setOpen] = useState(false)
    const handleOpen = () => {
        checkGemApproveNINO(setBoolApproveNINO)
        checkGemApproveMATA(setBoolApproveMATA)
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // console.log(rebornData)

    const theme = useTheme()

    const classes = useStyles()



    useEffect(
        () => {


                getMATABalance().then((balance) => updateBalanceMATA(parseFloat(parseFloat(balance).toFixed(2))))
                getNINOBalance().then((balance) => updateBalanceNINO(parseFloat(parseFloat(balance).toFixed(2))))


            
        }
        , []);


  
    const onError = () => {
        setLoadingApproveNINO(false)
        setLoadingApproveMATA(false)
    }

    const handleApproveNINO = () => {
        setLoadingApproveNINO(true)
        if (!boolApproveMATA) {
            setLoadingApproveMATA(true)
        }
        approveGemNINO(enqueueSnackbar, () => {
            setLoadingApproveNINO(false)
            setLoadingApproveMATA(false)
            setBoolApproveNINO(true)
        }, onError);

    }
    const handleApproveMATA = () => {
        if (!boolApproveNINO) {
            setLoadingApproveNINO(true)
        }
        setLoadingApproveMATA(true)
        approveGemMATA(enqueueSnackbar, () => {
            setLoadingApproveNINO(false)
            setLoadingApproveMATA(false)
            setBoolApproveMATA(true)
        }, onError);
        // if (checkApproveMATA){
        // //     setLoading(false)
        //     setBoolApproveMATA(true)
        // }
    }

    const getMataMintCost = () => {

        return ninneko.quality == 4 ? 12500 : 18000
    }




    const colorTextNino = balanceNINO > MINT_COST_NINO ? "primary" : "red"
    const colorTextMata = balanceMATA > getMataMintCost() ? "primary" : "red"

    const onClaimComplete = () => {
        //history.go(0)
        client.refetchQueries({
            //include: [QUERY_PETS,QUERY_MY_PETS],
            include: 'all'
        });
        setOpen(false)

    }


    const client = useApolloClient()

    const getClaimData = () => {

        client.query(
            {
                query: QUERY_MINT_GEM_DATA,
                variables: { id: ninneko.id },
                fetchPolicy: 'no-cache'
            })
            .then(result => {
                console.log(result.data.userGemMintData)
                const { inGameId, quality, nonce, sign } = result.data.userGemMintData
                mintGem(inGameId, quality, nonce, sign, enqueueSnackbar, onClaimComplete)

            })
            .catch(
                error => {
                    console.log(error)
                    //setErrorMessage(error.toString())

                }
            );

    }



    return (
        <Fragment>

            {
                React.cloneElement(
                    component,
                    { onClick: handleOpen }
                )
            }


            <Dialog

                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.dialog}
            >
                <DialogTitle id="alert-dialog-title">
                    {"Mint NFT Gem"}
                </DialogTitle>

                <DialogContent style={{ paddingBottom: 0 }}>
                    <ListItem style={{ padding: 0 }}>
                        Mint your in-game Gem to NFT
                    </ListItem>
                    <Grid container spacing={1} className={classes.priceBox} alignContent='center' alignItems='center'>
                        <Grid item xs={12} md={2}>
                            <div className={classes.price}>
                                <Typography>Fee:</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <LoadingButton onClick={handleApproveNINO} variant="contained"
                                disabled={boolApproveNINO}
                                loading={loadingApproveNINO}
                                className={boolApproveNINO ? classes.btnApproved : classes.btnNotApproved}
                                // loadingIndicator="Aproving..."
                                style={{ width: '100%', height: '100%' }}>
                                <img src="/images/svg/coin_nino.svg" style={{ width: 30, height: 30, marginRight: 8 }} />
                                {boolApproveNINO &&
                                    <Typography color={colorTextNino}>{balanceNINO + "/" + MINT_COST_NINO}</Typography>
                                }
                                {!boolApproveNINO && "Approve"}
                            </LoadingButton>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <LoadingButton onClick={handleApproveMATA} variant="contained"
                                disabled={boolApproveMATA}
                                loading={loadingApproveMATA}
                                className={boolApproveMATA ? classes.btnApproved : classes.btnNotApproved}
                                // loadingIndicator="Aproving..."
                                style={{ width: '100%', height: '100%' }}>
                                <img src="/images/svg/coin_mata.svg" style={{ width: 30, height: 30, marginRight: 8 }} />
                                {boolApproveMATA &&
                                    <Typography color={colorTextMata}>{balanceMATA + "/" + getMataMintCost()}</Typography>
                                }
                                {!boolApproveMATA && "Approve"}
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </DialogContent>



                <DialogActions style={{ justifyContent: 'center', paddingTop: 0 }}>
                    <Button onClick={getClaimData} variant="contained" color="primary"
                        disabled={loadingApproveNINO && loadingApproveMATA || !boolApproveNINO || !boolApproveMATA}
                        style={{ margin: theme.spacing(2) }} startIcon={<Backup />}>
                        Mint NFT
                    </Button>
                </DialogActions>

            </Dialog>
        </Fragment>
    );


}
