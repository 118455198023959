import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import React, { Fragment } from "react";
import HelpBox from "../Misc/HelpBox";
import GridPet from "./GridPet";

const useStyles = makeStyles((theme) => ({
    paper: {
        //padding: theme.spacing(2)
    },


}));

/* - Class of hand (weapon) determines Ninneko's class <br />
    - If class of hair/eyes/tail/ears/mouth is the same with Ninneko's class, Ninneko will get bonus stats <br />
    - WILD cattribute always gives Ninneko bonus stats, if class of WILD cattribute is the same as Ninneko's, the bonus stats is doubled<br /> 
    - Hand, tail, eyes, hair determines Ninneko's active skill, passive skill 1, 2, 3 respectively*/


export default function BodyPartHelpBox({ component }) {

    return <HelpBox component={component} title="Cattributes"
        content={`- Ninneko’s appearance is determined by cattributes in its genetic code: hand (weapon), hair, eyes, tail, ears and mouth 
            - Genes are either primary (P) or hidden (H1 and H2) based on their location in genome sequence
            `
        }
    />
      



}
