import { Chip, Grid, IconButton, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AccountCircleOutlined, HelpOutlineOutlined, HourglassEmpty, HourglassFull } from '@mui/icons-material';
import React from 'react';
import { Link } from 'react-router-dom';
import { BREED_AGE, CLASSES, FACTIONS, MAX_BREED_COUNT, TIMES_RESET } from '../../../utils/constants';
import { linkToUser } from '../../../utils/link';
import { getAge, getTimeToBreed, getOwnerLink } from '../../../utils/utils';
import { useQuery } from '@apollo/client';
import { QUERY_SERVER_TIME } from '../../../graphql/User/marketQuery';
import HelpBox from '../../Misc/HelpBox';
import RebornHistory from '../RebornHistory';











const useStyles = makeStyles((theme) => ({

    paper: {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(1),

        padding: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2),
        },
    },
    icon: {
        marginRight: theme.spacing(0.5)
    },

    owner: {
        marginTop: theme.spacing(1)
    }



}));




export function PetAbout({ ninneko, timeToBreed }) {





    const classes = useStyles()

    // const petAge = getAge(ninneko.createdAt)

    // const isNewBorn = petAge < BREED_AGE


    // console.log(ninneko)
    const canReset = ninneko.rebornTimes < TIMES_RESET;
    const canBreed = ninneko.breedCount < MAX_BREED_COUNT;
    const FactionIcon = FACTIONS[ninneko.faction - 1].icon
    const ClassIcon = CLASSES[ninneko.class - 1].icon
    return (
        <Paper className={classes.paper} variant="outlined"
        //elevation={4}
        >
            <Grid item container spacing={2}>

                <Grid item xs={4}>
                    <Typography color="textSecondary">faction</Typography>
                    <Grid container>
                        <FactionIcon color="primary" className={classes.icon} />
                        <Typography>
                            {FACTIONS[ninneko.faction - 1].label}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item xs={4} >

                    <Typography color="textSecondary">class</Typography>
                    <Grid container>
                        <ClassIcon color="secondary" className={classes.icon} />
                        <Typography>{CLASSES[ninneko.class - 1].label}</Typography>
                    </Grid>
                </Grid>

                {timeToBreed != null &&
                    <Grid item xs={4}>

                        {
                            //timeToBreed != "0" ? "breedable in" : "breed"
                            timeToBreed != "0" ?
                                <Grid container>
                                    <Typography color="red">
                                        newborn
                                    </Typography>
                                    <HelpBox component={<HelpOutlineOutlined color="action" className={classes.icon} />} title="Newborn Ninneko"
                                        content={`- Newborn Ninneko needs 6 days to become an adult
                                                  - Only adult Ninneko can breed and can be used in Game`
                                        } />

                                </Grid>
                                :
                                <Typography color="textSecondary">breed</Typography>
                        }


                        <Grid container>
                            {/* <FactionIcon color="primary" className={classes.icon} /> */}
                            {
                                canBreed ? <Typography>{timeToBreed != "0" ? timeToBreed : ninneko.breedCount + "/" + MAX_BREED_COUNT}</Typography> : <Typography color={"red"}>{timeToBreed != "0" ? timeToBreed : ninneko.breedCount + "/" + MAX_BREED_COUNT}</Typography>
                            }

                        </Grid>
                    </Grid>
                }

                <Grid item xs={4} className={classes.owner}>
                    <Typography variant="body1" color="textSecondary">{"generation"}&nbsp;</Typography>
                    {ninneko.generation == 1 && <Chip label="alpha" size="small" color="primary" variant="outlined" className={classes.chip} />}
                    {ninneko.generation != 1 && <Chip label="beta" size="small" variant="outlined" className={classes.chip} />}
                </Grid>

                {ninneko.owner?.id > 0 && <Grid item xs={4} className={classes.owner}>
                    <Typography color="textSecondary">owner</Typography>
                    <div>

                        <Link to={linkToUser + "/?" + getOwnerLink(ninneko.owner)} style={{ textDecoration: 'none' }}>
                            <Typography color="textPrimary" noWrap >{ninneko.owner?.name}</Typography>
                        </Link>
                    </div>
                </Grid>}

                <Grid item xs={4} className={classes.owner}>
                    {
                        ninneko.rebornHistory && ninneko.rebornHistory.length === parseInt(ninneko.rebornTimes) ?
                            <RebornHistory
                                rebornHistory={ninneko.rebornHistory}
                                component={
                                    <Typography color="textSecondary" sx={{ textDecoration: 'underline', cursor: 'pointer' }}>reborn</Typography>
                                }
                            />
                            :
                            <Typography color="textSecondary">reborn</Typography>
                    }
                    <Grid container>
                        {
                            canReset ?
                                <Typography>{ninneko.rebornTimes + "/" + TIMES_RESET}</Typography> :
                                <Typography color={"red"}>{ninneko.rebornTimes + "/" + TIMES_RESET}</Typography>
                        }
                    </Grid>
                </Grid>

            </Grid>
        </Paper>
    )

}

