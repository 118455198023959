import { useQuery } from '@apollo/client';
import { Alert, Button, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { Fragment } from 'react';
import { QUERY_EVENT_REF, QUERY_EVENT_REF_CLAIM_DATA, QUERY_ME } from '../../graphql/User/userQuery';
import { claimToken, claimTokenEvent, getMATABalance, getNINOBalance, getWalletAddress, getWalletBalance } from '../../utils/cryptoWallet';
import Token from '../User/Token';

import { copyTextToClipboard, getPriceETHFromWei, updateURLParameter } from '../../../src/utils/utils';

import { useLocation } from "react-router-dom";
import { isNonEmptyArray } from '@apollo/client/utilities';
import sessionData from '../../sessionData';
import Countdown from 'react-countdown';
import { Link } from 'react-router-dom';
import { linkToEventFriendRefFriendList } from '../../utils/link';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
    accountView: {
        margin: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(2),
        },


        // width: '100%'
        //marginBottom: theme.spacing(2),
        //overflow: 'hidden'
        //padding: theme.spacing(2),
        //maxWidth: 'lg'
        //display: 'block'
    },
    widgets: {
        //display: 'flex',
        //margin: theme.spacing(1),
    },
    title: {
        flex: 1,
        margin: theme.spacing(1),
    },
    paper: {
        display: 'block',
        //minWidth: 150,
        //marginRight: theme.spacing(2),

    },
    media: {
        height: 90,
        width: 90,
        margin: 'auto',
        marginTop: '10%'
    },
    number: {
        textAlign: 'center'
    },
    icon: {
        height: 60,
        width: 60,
        [theme.breakpoints.down('xs')]: {
            height: 30,
            width: 30,
        },
    },
    token: {
        //flex: 1,

        //maxWidth: 400
    },
    address: {
        color: 'textSecondary',


    },
    bannerLink: {
        fontSize: "14px",
        lineHeight: "22px",
        color: "rgba(244,120,32,.6)"
    },


}));

function useMyQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function getURLRef() {
    var arrUrl = window.location.href.split("friend")
    var baseUrl = arrUrl[0]
    arrUrl = baseUrl.split("account")
    baseUrl = arrUrl[0]
    // console.log(arrUrl)
    return baseUrl
}

export default function FriendReferenceView() {
    let query = useMyQuery();
    const { enqueueSnackbar } = useSnackbar();

    let dataForApi = { ref: query.get("id") }

    const classes = useStyles()

    const { loading: loadingMe, error: errorMe, data: dataMe } = useQuery(QUERY_ME);
    const { loading: loadingEvent, error: errorEvent, data: dataEvent } = useQuery(QUERY_EVENT_REF);
    const { loading: loadingClaim, error: errorClaim, data: dataClaim } = useQuery(QUERY_EVENT_REF_CLAIM_DATA);

    if (loadingMe || loadingEvent || loadingClaim) return 'Loading...';
    if (errorMe || errorEvent) return `Error!`;

    let email, ethAddr, ninoAvailable, sumDesc, nofBuys;
    let dataClaimData = null

    if (dataMe && dataMe.me) {
        ;
        ({ email, ethAddr } = dataMe.me)
        //TODO check ví server và client xem có lệch nhau ko thì bắt login lại hoặc chuyển lại ví

    }

    if (dataEvent) {
        ;
        ({ sumDesc, nofBuys } = dataEvent.event_Ref)
    }



    //console.log(errorClaim.message)

    dataClaimData = dataClaim ? dataClaim.event_Ref_ClaimData : null



    if (dataClaimData) {
        ninoAvailable = getPriceETHFromWei(dataClaimData.amount)
    }

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <span></span>;
        } else {

            return <span>Event ends in: {days}d {hours}h {minutes}min {seconds}</span>;
        }

    };

    const myURL = updateURLParameter(getURLRef(), "ref", dataMe.me.id)

    const getAccountAccumulated = () => {
        if (!sumDesc) return 0
        return sumDesc.split(": ")[1]
    }

    const claimNINO = () => {
        claimTokenEvent(dataClaimData.id, dataClaimData.amount, dataClaimData.nonce, dataClaimData.sign,enqueueSnackbar)
    }

    return (
        <div className={classes.accountView}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6" style={{ paddingBottom: 5 }}>
                                Invite friends to get rewards
                            </Typography>
                        </Grid>
                        {sessionData.myAddress ?
                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    Share this link with your friends on social media&nbsp; and get &nbsp;
                                    <a style={{ textDecoration: 'none', fontWeight: 500 }} href="https://ninnekodev.github.io/whitepaper/gameplay/invite" target="_blank">rewards</a>
                                </Typography>

                                <Alert severity="info" style={{ marginTop: 5 }}>
                                    {myURL}
                                </Alert>
                                <Button onClick={() => copyTextToClipboard(myURL)}>
                                    Copy Link to Clipboard
                                </Button>
                            </Grid>
                            :
                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    Connect Wallet to get Ref code
                                </Typography>
                            </Grid>
                        }
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                Event: Get NINO when your friends buy Ninneko
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <a href="https://ninneko.substack.com/p/nino-staking-guide" className={classes.bannerLink} target="_blank">
                                Learn More</a>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography color="#3990f8" variant="body2">
                                <Countdown
                                    date={"Sat May 14 2022 21:00:00 GMT+0700"}
                                    renderer={renderer}
                                />
                            </Typography>
                        </Grid>

                        {sessionData.myAddress ?
                            <Fragment>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        {"Account Accumulated: " + getAccountAccumulated()}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        {"Total Ninneko bought by my friends: " + nofBuys}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        <Link to={linkToEventFriendRefFriendList} style={{ textDecoration: 'none' }}>
                                            Show my friend accounts
                                        </Link>
                                    </Typography>
                                </Grid>










                                <Grid item xs={12} style={{ marginTop: 16 }}>
                                    <Typography variant="h6">
                                        Claim NINO
                                    </Typography>
                                </Grid>


                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        {"NINO available to claim: " + (ninoAvailable ? ninoAvailable : "0")}
                                    </Typography>
                                </Grid>



                                <Grid item xs={12}>
                                    {ninoAvailable ?
                                        <Button onClick={claimNINO} variant="contained">
                                            Claim NINO
                                        </Button> :
                                        <Typography color="red">
                                            {errorClaim.message}
                                        </Typography>
                                    }
                                </Grid>


                            </Fragment>
                            :
                            <Grid item xs={12}>
                                <Typography variant="body1">
                                    Connect Wallet to join event
                                </Typography>
                            </Grid>
                        }




                    </Grid>
                </Grid>
            </Grid>
        </div >
    );
}
