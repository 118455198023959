import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from "react";
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import HeaderBar from "../components/Header/HeaderBar";
import ItemView from '../components/views/ItemView';

const useStyles = makeStyles((theme) => ({

}));

export default function ItemLayout() {
    const classes = useStyles()

    return (
        <>
            <HeaderBar />
            <Container maxWidth="lg">
                <ItemView />
            </Container>
        </>

    );

}


