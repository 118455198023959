
import React from 'react';
import { Button, Card, Chip, Container, Divider, FormControlLabel, Grid, Icon, Paper, SvgIcon, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { BREED_AGE, CLASSES, FACTIONS, FireIcon, GuardIcon, PET_AVATAR_SIZE } from '../../utils/constants';
import { getAge } from '../../utils/utils';





const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        //width: 0.85*PET_AVATAR_SIZE ,
        //marginBottom: theme.spacing(10)
    },
    centerGrid: {
        display: 'flex',
        justifyContent: 'center',
    },
    petAvatar: {
        //width: 0.85*PET_AVATAR_SIZE,
        //height: 0.85*PET_AVATAR_SIZE,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)

    },
    textBlock: {
        display: 'block'
    },
    
}));





export default function SimplePetItem({ ninneko }) {

    const classes = useStyles();

    const linkToPet = "/pet/" + ninneko.id

    return (

        <Link to={linkToPet} style={{ textDecoration: 'none' }}>
            <Card className={classes.root} >

                <Grid container style={{ height: 'auto' }}>
                    <Grid item xs={12}>
                        {/* <div id="id" className={classes.textBlock} > */}

                            <div style={{ display: 'block',  marginBottom: 2 }}>
                                <Typography style={{lineHeight: 1}} noWrap>{ninneko.name}</Typography>
                                {/* <Typography variant="caption" color="textSecondary">{"#" + ninneko.id}</Typography> */}
                                
                            </div>


                            {/* <Divider /> */}
                            
                        {/* </div> */}
                    </Grid>
                    <Grid item xs={12} className={classes.centerGrid}>
                        <img src={ninneko.avatarURL} className={classes.petAvatar} />
                    </Grid>
                    

                </Grid>
            </Card>
        </Link>

    );
}