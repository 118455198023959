import { Drawer, InputLabel, MenuItem, Select, Button, Hidden, useMediaQuery, Card, Typography, Grid } from "@mui/material";
import { MHidden } from '../@material-extend';
import { makeStyles } from '@mui/styles';
import React, { Fragment, useEffect, useState, useRef } from "react";
import { CLASSES, FACTIONS, GENERATIONS, MAX_BREED_COUNT, SALE_STATS, DRAWER_WIDTH, LIFE_STAGES, LIFE_STAGE_ADULT } from "../../utils/constants";
import { useMemoryState } from "../../utils/memoryState";
import FilterPanel from "../Misc/FilterPanel";
import GridPet from "../Pet/GridPet";
import SaleStats from "../Stats/SaleStats";
import { useTheme } from "@emotion/react";
import { getPriceUSDFromWei, getPriceUSDFromETH, updateURLParameter, addURLParameter, removeURLParameterByName, removeURLParameterByNameAndValue } from '../../../src/utils/utils';
import { QUERY_MARKET_STATS } from "../../graphql/User/marketQuery";
import { useQuery } from '@apollo/client';
import LoadingSpinner from "../Misc/LoadingSpinner";
import { fontSize } from "@mui/system";

//import {animation} from "../utils/pixiApp"

const useStyles = makeStyles((theme) => ({

    root: {
        display: 'flex',
    },

    toolbar: theme.mixins.toolbar,

    drawerPaper: {
        width: DRAWER_WIDTH,
        padding: theme.spacing(3),
        // overflowX: 'hidden'
        //paddingTop: theme.spacing(2)
        '&::-webkit-scrollbar': {
            width: 0,
        },

    },

    main: {
        //padding: theme.spacing(12),
        //padding: theme.spacing(4),

        paddingTop: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(2),
        },

        paddingBottom: theme.spacing(10),
        flex: 1,
        //display: 'flex',
        //justifyContent: 'center'
        //overflowY: 'auto',
        // overflowX: 'hidden'
    },
    saleFilter: {

    },
    select: {
        padding: 0
    },
    filter: {
        padding: 5,
    },
    filterToolBar: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(5),
            marginRight: theme.spacing(5),
        },
        display: 'flex',
        height: 42,
        justifyContent: 'space-between'
    },
    statistic: {
        marginTop: theme.spacing(9),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(5),
            paddingRight: theme.spacing(5),
        },
        display: "block"
    },
    statisticSelected: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        textAlign: "center",
        borderBottom: "2px solid #00ab55",
        color: "#212B36",
        cursor: "context-menu"
    },
    statisticOption: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        textAlign: "center",
        color: "#8f929c",
        cursor: "pointer",
    },
    statisticInfo: {
        borderTop: "1px solid #eaeefb",
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(9),
            // paddingRight: theme.spacing(5),
        },
    },
    statisticTitle: {
        fontWeight: 700,
        fontSize: 10,
        color: "#6d7079"
    },
    statisticContent: {
        fontWeight: 600,
        fontSize: 28
    },
    banner: {
        display: "flex",
        borderRadius: "10px",
        //background: "linear-gradient(268.58deg,#0c2a54 .69%,#1a3175)",
        //border: "1px solid #000",
        //boxSizing: "border-box",
        //boxShadow: "0 4px 4px rgba(0,0,0,.25)",
        padding: "35px 26px 28px",
        margin: "24px 0 43px",
        flexWrap: "wrap",
        justifyContent: "space-between",
        color: "white",
    }
}));


const getFilterObject = (arrayConfig) => {
    let ret = {}
    arrayConfig.map(
        //item => ret[item.label] = false
        item => ret[item.id] = false
    )
    return ret
}

const getMarketStatsByTime = (time, dataQuery) => {
    if (time == "30d") {
        return dataQuery.last30d
    } else if (time == "7d") {
        return dataQuery.last7d
    } else if (time == "24h") {
        return dataQuery.last24h
    } else {
        return dataQuery.alltime
    }
}


export default function MarketView({ queryParams }) {
    // const refPage = useRef(variable);
    // refPage.current = variable.page

    const theme = useTheme();

    const classes = useStyles()

    const isSM = useMediaQuery(theme.breakpoints.down('sm'));

    const [filterClick, setFilterClick] = useState(false);

    var defaultPage = 1
    var defaultPart = []
    var defaultLegend = 0
    var defaultGen = getFilterObject(GENERATIONS)
    var defaultSort = { id: undefined, price: false, priceSetAt: undefined }
    var defaultSortValue = 2
    var defaultSale = 1
    var defaultFaction = getFilterObject(FACTIONS)
    var defaultClass = getFilterObject(CLASSES)
    var defaultBreed = [0, MAX_BREED_COUNT]
    var defaultAge = getFilterObject(LIFE_STAGES)
    defaultAge[LIFE_STAGE_ADULT] = true

    var paramPage = defaultPage
    var paramPart = defaultPart
    var paramLegend = defaultLegend
    var paramGen = defaultGen
    var paramSort = defaultSort
    var paramSortValue = defaultSortValue
    var paramSale = defaultSale
    var paramFaction = defaultFaction
    var paramClass = defaultClass
    var paramBreed = defaultBreed
    var paramAge = defaultAge

    for (const [key, value] of queryParams) {
        if (key == "page") {
            paramPage = value
        } else if (key == "part") {
            paramPart.push(value)
        } else if (key == "faction") {
            for (let i = 0; i < FACTIONS.length; i++) {
                if (value != FACTIONS[i].id) continue

                paramFaction[i + 1] = true
            }
        } else if (key == "class") {
            for (let i = 0; i < CLASSES.length; i++) {
                if (value != CLASSES[i].id) continue
                paramClass[i + 1] = true
            }
        } else if (key == "legend") {
            paramLegend = value
        } else if (key == "sale") {
            paramSale = value
        } else if (key == "sort") {
            paramSortValue = parseInt(value)

            if (value == 0) paramSort = { id: false, price: undefined, priceSetAt: undefined }
            if (value == 1) paramSort = { id: true, price: undefined, priceSetAt: undefined }
            if (value == 3) paramSort = { id: undefined, price: true, priceSetAt: undefined }
            if (value == 4) paramSort = { id: undefined, price: undefined, priceSetAt: true }
        } else if (key == "gen") {
            for (let i = 0; i < GENERATIONS.length; i++) {
                if (value != GENERATIONS[i].id) continue
                paramGen[i + 1] = true
            }
        } else if (key == "breed") {
            var arr = value.split("-")
            if (arr[0] && arr[1]) {
                paramBreed = [arr[0], arr[1]]
            }
        } else if (key == "age") {
            for (let i = 0; i < LIFE_STAGES.length; i++) {
                if (value != LIFE_STAGES[i].id) continue

                paramAge[i + 1] = true
            }
        }
    }

    // const [page, setPage] = useMemoryState('page', pageId);
    const [page, setPage] = useMemoryState('page', paramPage);

    //FILTER
    const [breedCount, setBreedCount] = useMemoryState('stars', paramBreed)

    const [legendary, setLegendary] = useMemoryState('legendary', paramLegend)

    const [pure, setPure] = useMemoryState('pure', 0)

    const [generations, setGenerations] = useMemoryState('generations', paramGen);

    const [sale, setSale] = useMemoryState('sale', paramSale);

    const [genParts, setGenParts] = useMemoryState('genParts', paramPart);

    const [factions, setFactions] = useMemoryState('factions', paramFaction);

    const [petClasses, setPetClasses] = useMemoryState('petClasses', paramClass);

    const [filterLifeStage, setFilterLifeStage] = useMemoryState('age', paramAge)

    //SORT
    const [sort, setSort] = useMemoryState('sort', paramSort);
    const [sortValue, setSortValue] = useMemoryState('sortValue', paramSortValue);

    const handleAddGenParts = (value) => {
        setPage(1)
        var newGenParts = [...genParts, value]
        if (!genParts.includes(value)) {
            setGenParts(newGenParts);
        }

        var newURL = updateURLParameter(window.location.href, "page", 1)
        newURL = removeURLParameterByName(newURL, "part")

        if (newGenParts.length > 0) {
            // newURL = updateURLParameter(window.location.href, "part", newGenParts[0])

            for (let i = 0; i < newGenParts.length; i++) {
                newURL = addURLParameter(newURL, "part", newGenParts[i])
            }

            window.history.replaceState('', '', newURL)
        }

        window.history.replaceState('', '', newURL)
    };

    const handleRemoveGenParts = (value) => {
        setPage(1)
        var arr = [...genParts]
        var index = arr.indexOf(value)
        if (index !== -1) {
            arr.splice(index, 1)
            setGenParts(arr);
        }

        var newURL = updateURLParameter(window.location.href, "page", 1)
        newURL = removeURLParameterByName(newURL, "part")

        if (arr.length > 0) {
            for (let i = 0; i < arr.length; i++) {
                newURL = addURLParameter(newURL, "part", arr[i])
            }
        }

        window.history.replaceState('', '', newURL)
    };

    const handleChangeFilterLifeStage = (event) => {
        setPage(1)
        var newFilterLifeStage = { ...filterLifeStage, [event.target.name]: event.target.checked }
        setFilterLifeStage(newFilterLifeStage);

        var newURL = updateURLParameter(window.location.href, "page", 1)
        newURL = removeURLParameterByName(newURL, "age")

        for (let i = 0; i < LIFE_STAGES.length; i++) {
            if (newFilterLifeStage[i + 1] == true) {
                newURL = addURLParameter(newURL, "age", LIFE_STAGES[i].id)
            }

        }

        window.history.replaceState('', '', newURL)
    };

    const handleChangeGenerations = (event) => {
        setPage(1)
        var newGenerations = { ...generations, [event.target.name]: event.target.checked }
        setGenerations(newGenerations);

        var newURL = updateURLParameter(window.location.href, "page", 1)
        newURL = removeURLParameterByName(newURL, "gen")

        for (let i = 0; i < GENERATIONS.length; i++) {
            if (newGenerations[i + 1] == true) {
                newURL = addURLParameter(newURL, "gen", GENERATIONS[i].id)
            }

        }

        window.history.replaceState('', '', newURL)
    };

    const handleChangeSale = (event) => {

        setPage(1)
        setSale(event.target.value)
        var newURL = updateURLParameter(window.location.href, "page", 1)
        newURL = updateURLParameter(newURL, "sale", event.target.value)
        window.history.replaceState('', '', newURL)
    };


    const handleChangeSort = (event) => {
        const value = event.target.value
        if (value === 0) setSort({ id: false, price: undefined, priceSetAt: undefined })
        if (value === 1) setSort({ id: true, price: undefined, priceSetAt: undefined })
        if (value === 2) setSort({ id: undefined, price: false, priceSetAt: undefined })
        if (value === 3) setSort({ id: undefined, price: true, priceSetAt: undefined })
        if (value === 4) setSort({ id: undefined, price: undefined, priceSetAt: true })
        setPage(1)
        setSortValue(value)
        var newURL = updateURLParameter(window.location.href, "page", 1)
        newURL = updateURLParameter(newURL, "sort", value)
        window.history.replaceState('', '', newURL)
    };

    const handleChangeFactions = (event) => {
        setPage(1)
        var newFactions = { ...factions, [event.target.name]: event.target.checked }
        setFactions(newFactions);

        var newURL = updateURLParameter(window.location.href, "page", 1)
        newURL = removeURLParameterByName(newURL, "faction")

        for (let i = 0; i < FACTIONS.length; i++) {
            if (newFactions[i + 1] == true) {
                newURL = addURLParameter(newURL, "faction", FACTIONS[i].id)
            }

        }

        window.history.replaceState('', '', newURL)

    };

    const handleChangeClasses = (event) => {
        setPage(1)
        var newClasses = { ...petClasses, [event.target.name]: event.target.checked }
        setPetClasses(newClasses);

        var newURL = updateURLParameter(window.location.href, "page", 1)
        newURL = removeURLParameterByName(newURL, "class")

        for (let i = 0; i < CLASSES.length; i++) {
            if (newClasses[i + 1] == true) {
                newURL = addURLParameter(newURL, "class", CLASSES[i].id)
            }

        }

        window.history.replaceState('', '', newURL)
    };

    const handleChangeLegendary = (value) => {
        setPage(1)
        setLegendary(value)
        var newURL = updateURLParameter(window.location.href, "page", 1)
        newURL = updateURLParameter(newURL, "legend", value)
        window.history.replaceState('', '', newURL)
    };

    const handleChangeBreed = (value) => {
        setPage(1)
        setBreedCount(value)
        var paramVal = value[0] + "-" + value[1]
        var newURL = updateURLParameter(window.location.href, "page", 1)
        newURL = updateURLParameter(newURL, "breed", paramVal)
        window.history.replaceState('', '', newURL)
    };

    const handleClear = () => {

        // setBreedCount([0, MAX_BREED_COUNT])

        // setLegendary(0)

        // setPure(0)

        // setFactions(getFilterObject(FACTIONS))

        // setPetClasses(getFilterObject(CLASSES))

        // setGenerations(getFilterObject(GENERATIONS))

        // setGenParts([])

        // setFilterLifeStage()

        setPage(defaultPage)
        setGenParts(defaultPart)
        setLegendary(defaultLegend)
        setGenerations(defaultGen)
        setSort(defaultSort)
        setSortValue(defaultSortValue)
        setSale(defaultSale)
        setFactions(defaultFaction)
        setPetClasses(defaultClass)
        setBreedCount(defaultBreed)
        setFilterLifeStage(defaultAge)
    }

    //----END FILTER


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        window.history.replaceState('', '', updateURLParameter(window.location.href, "page", newPage))
        // window.history.replaceState('', '', updateURLParameter(window.location.href, "param", "value"));
    };

    //select statistic
    const [statsSelected, setStatsSelected] = useState("24h")

    //query statistic
    const { data } = useQuery(QUERY_MARKET_STATS)

    if (!data || !data.marketStats) return <LoadingSpinner />

    return (

        <Fragment>
            <Drawer
                classes={{ paper: classes.drawerPaper }}
                variant={isSM ? 'temporary' : 'persistent'}
                onClose={() => { setFilterClick(false) }}
                open={!isSM || filterClick}
            >
                <FilterPanel
                    factions={factions}
                    handleChangeFactions={handleChangeFactions}
                    petClasses={petClasses}
                    handleChangeClasses={handleChangeClasses}
                    legendary={legendary}
                    setLegendary={handleChangeLegendary}
                    pure={pure}
                    setPure={setPure}
                    breedCount={breedCount}
                    setBreedCount={handleChangeBreed}
                    generations={generations}
                    handleChangeGenerations={handleChangeGenerations}
                    handleClear={handleClear}
                    genParts={genParts}
                    handleAddGenParts={handleAddGenParts}
                    handleRemoveGenParts={handleRemoveGenParts}
                    filterLifeStage={filterLifeStage}
                    handleChangeFilterLifeStage={handleChangeFilterLifeStage}
                // sale={sale}
                // handleChangeSale={handleChangeSale}
                />
            </Drawer>

            <div className={classes.main}>
                {page == 1 ?
                    <Grid container className={classes.statistic}>
                        <Card>

                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={4} md={1.15} onClick={() => { setStatsSelected("24h") }}>
                                        <Typography className={statsSelected == "24h" ? classes.statisticSelected : classes.statisticOption}>
                                            Last 24h
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} md={1.15} onClick={() => { setStatsSelected("7d") }}>
                                        <Typography className={statsSelected == "7d" ? classes.statisticSelected : classes.statisticOption}>
                                            7 days
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} md={1.15} onClick={() => { setStatsSelected("30d") }}>
                                        <Typography className={statsSelected == "30d" ? classes.statisticSelected : classes.statisticOption}>
                                            30 days
                                        </Typography>
                                    </Grid>
                                    {/* <Grid item xs={4} md={1.15} onClick={() => { setStatsSelected("all") }}>
                                        <Typography className={statsSelected == "all" ? classes.statisticSelected : classes.statisticOption}>
                                            All time
                                        </Typography>
                                    </Grid> */}
                                </Grid>
                            </Grid>

                            <Grid item xs={12} className={classes.statisticInfo}>
                                <Grid container>
                                    <Grid item xs={12} md={4}>
                                        <Grid container style={{ paddingTop: theme.spacing(2), paddingBottom: theme.spacing(1) }}>
                                            <Grid item xs={4} sm={3} md={2}> <img src="/images/svg/pie-chart-svgrepo-com.svg" style={{ height: 50 }} /> </Grid>
                                            <Grid item xs={8} sm={9} md={10}>
                                                <Grid container>
                                                    <Grid item xs={12}><Typography className={classes.statisticTitle}>TOTAL SALE</Typography></Grid>
                                                    <Grid item xs={12}>
                                                        <Typography className={classes.statisticContent}>
                                                            {getMarketStatsByTime(statsSelected, data.marketStats).txCount}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Grid container style={{ paddingTop: theme.spacing(2), paddingBottom: theme.spacing(1) }}>
                                            <Grid item xs={4} sm={3} md={2}> <img src="/images//svg/binance-coin-seeklogo.com.svg" style={{ height: 50 }} /> </Grid>
                                            <Grid item xs={8} sm={9} md={10}>
                                                <Grid container>
                                                    <Grid item xs={12}><Typography className={classes.statisticTitle}>TOTAL VOLUME</Typography></Grid>
                                                    <Grid item xs={12}>
                                                        <Typography className={classes.statisticContent}>
                                                            {((getMarketStatsByTime(statsSelected, data.marketStats).volume / 1e18).toFixed()) + " BNB"}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Grid container style={{ paddingTop: theme.spacing(2), paddingBottom: theme.spacing(1) }}>
                                            <Grid item xs={4} sm={3} md={2}> <img src="/images/svg/nino_black.svg" style={{ height: 50 }} /> </Grid>
                                            <Grid item xs={8} sm={9} md={10}>
                                                <Grid container >
                                                    <Grid item xs={12}><Typography className={classes.statisticTitle}>NINNEKO SOLD</Typography></Grid>
                                                    <Grid item xs={12}>
                                                        <Typography className={classes.statisticContent}>
                                                            {getMarketStatsByTime(statsSelected, data.marketStats).petCount}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    :
                    <div className={classes.toolbar} />
                }
                {/* <SaleStats /> */}
                <div className={classes.filterToolBar}>
                    <Select
                        size='small'
                        value={sale}
                        onChange={handleChangeSale}
                        className={classes.saleFilter}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            getcontentanchorel: null
                        }}
                    >
                        <MenuItem value={2}>All</MenuItem>
                        <MenuItem value={1}>For sale</MenuItem>
                        <MenuItem value={0}>Not for sale</MenuItem>
                    </Select>

                    <Select
                        value={sortValue}
                        size='small'
                        onChange={handleChangeSort}
                        className={classes.saleFilter}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            getcontentanchorel: null
                        }}
                    >
                        <MenuItem value={4}>Latest</MenuItem>
                        <MenuItem value={3}>Highest Price</MenuItem>
                        <MenuItem value={2}>Lowest Price</MenuItem>
                        <MenuItem value={1}>Highest ID</MenuItem>
                        <MenuItem value={0}>Lowest ID</MenuItem>
                    </Select>

                    <Hidden only={['sm', 'md', 'lg', 'xl']}>
                        <Button
                            variant="outlined"
                            className={classes.saleFilter}
                            onClick={() => { setFilterClick(true) }}
                        >
                            FILTER
                        </Button>
                    </Hidden>
                </div>

                <GridPet
                    //forSale={1}
                    //faction={factions[1] ? 1 : 0}
                    breedCount={breedCount}
                    petClasses={petClasses}
                    factions={factions}
                    legendary={legendary}
                    generations={generations}
                    page={page}
                    handleChangePage={handleChangePage}
                    forSale={sale}
                    sort={sort}
                    genParts={genParts}
                    lifeStages={filterLifeStage}
                //stage={null} //chỉ hiện Pet, ko hiện box, pod, egg
                />
                {/* <small style={{display:'flex', alignSelf:'flex-end'}}>This site is protected by reCAPTCHA and the Google 
                    <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                    <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                </small> */}
            </div>

        </Fragment>

    );

}


