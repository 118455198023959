
import React, { Fragment } from 'react';
import { Button, Card, Chip, Container, Divider, FormControlLabel, Grid, Icon, ListItem, ListItemIcon, ListItemText, Paper, SvgIcon, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { BREED_AGE, CLASSES, FACTIONS, FireIcon, GuardIcon, PET_AVATAR_SIZE, PET_BOX_SIZE, PET_STAGE_COTTON_BOX, PET_STAGE_PET } from '../../utils/constants';
import { getAge, getPriceETH, getPriceETHFromWei, getPriceUSD, getPriceUSDFromETH, getPriceUSDFromWei } from '../../utils/utils';
import { LinkOutlined } from '@mui/icons-material';
import { GENERATION_BETA } from '../../utils/constants';
import { useServerTime } from '../../utils/hookUtils';
import { getTimeToBreed } from '../../utils/utils';


const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        height: '100%',
        display: 'block'
    },
    centerGrid: {
        display: 'flex',
        justifyContent: 'center',
    },
    petAvatar: {
        width: PET_AVATAR_SIZE,
        height: PET_AVATAR_SIZE,
        //marginTop: theme.spacing(2),
        //marginBottom: theme.spacing(2)

    },
    petBox: {
        width: PET_AVATAR_SIZE,
        height: PET_AVATAR_SIZE,
        padding: theme.spacing(3)
        //marginTop: theme.spacing(2),
        //marginBottom: theme.spacing(2)

    },
    textBlock: {
        display: 'block'
    },
    priceBlock: {
        display: 'block',
        // alignItems: 'center',
        // justifyContent: 'center',

    },

    factionIcon: {
        position: 'absolute',
        top: 0,
        left: '92%'

    },
    classIcon: {
        position: 'absolute',
        top: -1,
        left: '92%'

    },
    chip: {
        marginRight: theme.spacing(0.5),
        padding: 0
    }

}));





export default function PetItem({ ninneko }) {

    const classes = useStyles();

    const serverTime = useServerTime()

    if (!ninneko || !ninneko.faction) return <div />
    const linkToPet = "/pet/" + ninneko.id

    const FactionIcon = ninneko.faction > 0 ? FACTIONS[ninneko.faction - 1].icon : null
    const ClassIcon = ninneko.class > 0 ? CLASSES[ninneko.class - 1].icon : null

    const isAuction = () => {
        return ninneko.auction && ninneko.auction.startTime.length > 0
    }

    const getCurrentPriceOfAuction = () => {
        var startPrice = parseFloat(ninneko.auction.startPrice)
        var endPrice = parseFloat(ninneko.auction.endPrice)
        var duration = parseFloat(ninneko.auction.duration)
        var startTime = parseFloat(ninneko.auction.startTime)
        var priceStepPerSecond = (endPrice - startPrice) / duration
        var isIncrease = priceStepPerSecond > 0
        var now = new Date()
        var endTime = parseFloat(startTime) + parseFloat(duration)
        var timing = parseInt(now.getTime() / 1000, 10)
        timing = Math.min(timing , endTime)
        var bonusSecond = isIncrease ? 10 : -10
        var sencondsPassed = timing - startTime + bonusSecond

        if (timing - startTime >= duration) {
            return endPrice
        }

        var currentPrice = startPrice + priceStepPerSecond * sencondsPassed

        if (ninneko.id == 6128){
            console.log(priceStepPerSecond/1e18)
        }

        if (isIncrease) {
            currentPrice = Math.max(startPrice, Math.min(currentPrice, endPrice))
        } else {
            currentPrice = Math.max(endPrice, Math.min(currentPrice, startPrice))
        }

        return currentPrice
    }

    // if(ninneko.id == 10360){
    //     console.log(ninneko)
    // }

    //const timeToBreed = ninneko.generation == GENERATION_BETA ? getTimeToBreed(serverTime, ninneko.createdAt) : 0
    const timeToBreed = getTimeToBreed(serverTime, ninneko.createdAt)

    return (

        <Link to={linkToPet} style={{ textDecoration: 'none' }}>
            <Card className={classes.root} >

                <Grid container style={{ height: 'auto' }}>
                    <Grid item xs={12}>
                        <div id="id" className={classes.textBlock} >

                            <div style={{ display: 'block', position: 'relative', marginBottom: 2 }}>
                                <Typography style={{ lineHeight: 1 }} noWrap>{ninneko.name}</Typography>
                                <Typography variant="caption" color="textSecondary">{"#" + ninneko.id}</Typography>
                                {FactionIcon && <FactionIcon className={classes.factionIcon} />}
                            </div>


                            <Divider />
                            <div style={{ marginTop: 8, display: 'flex', position: 'relative' }}>
                                {timeToBreed != "0" ?
                                    <Chip label={"newborn"} size="small" variant="outlined" className={classes.chip} style={{ color: "red" }} />
                                    :
                                    <Chip label={ninneko.breedCount + " breed"} size="small" variant="outlined" className={classes.chip} />
                                }
                                {ninneko.generation == 1 && <Chip label={"alpha"} size="small" variant="outlined" color="primary" className={classes.chip} />}
                                {ninneko.beastCount > 0 && <Chip label={ninneko.beastCount + " WILD"} color="primary" size="small" variant="outlined" className={classes.chip} />}
                                {ClassIcon && <ClassIcon className={classes.classIcon} />}
                            </div>



                        </div>

                    </Grid>
                    <Grid item xs={12} className={classes.centerGrid} >
                        {
                            ninneko.stage == PET_STAGE_PET ?
                                <img src={ninneko.avatarURL} className={classes.petAvatar} alt="Pet" />
                                :
                                <img src={ninneko.stage == PET_STAGE_COTTON_BOX ? "/images/fodder.png" : "/images/fodder.png"}
                                    alt="Cat Box"
                                    className={classes.petBox} />
                        }

                    </Grid>
                    {ninneko.price > 0 &&
                        <Fragment>
                            <>
                                <ListItem style={{ display: 'flex', justifyContent: 'center', padding: 0 }}>
                                    {isAuction() &&
                                        <img src="/images/svg/auction-icon.svg" style={{ width: 24, height: 24, top: 0 , marginRight:3}} />
                                    }
                                    <img src="/images/icon_binance.png" style={{ width: 24, height: 24, top: 0 }} />
                                    <Typography variant="h6" noWrap>&nbsp;{getPriceETHFromWei(isAuction() ? getCurrentPriceOfAuction() : ninneko.price)} BNB</Typography>
                                </ListItem>
                                <div style={{ display: 'flex', justifyContent: 'center', width: "100%" }}>
                                    <Typography variant="body2"  >{getPriceUSDFromWei(isAuction() ? getCurrentPriceOfAuction() : ninneko.price)}</Typography>
                                </div>
                            </>
                        </Fragment>
                    }
                </Grid>
            </Card>
        </Link>

    );
}