export const TBL_SKILL =
{

2: "At round 6, increase 50% attack in 5 round.",
4: "When use basic attack , increase 10% armor in 3 round.",
5: "Increase 50% critical.",
6: "Deal damage [ 200% attack ] to the closest enemy. Increase 20% critical in 3 round, 20% critical damage in 3 round.",
8: "At round 2, increase 70% attack in 3 round.",
10: "When use basic attack , increase 10% attack in 3 round.",
11: "Increase 71% accuracy.",
12: "Deal damage [ 110% attack ], reduce 30% block in 3 round to the closest enemy.",
14: "When someone uses active skill, increase 5 energy .",
16: "When use basic attack , increase 10 speed in 2 round.",
17: "Increase 30% immunity.",
18: "Deal damage [ 100% attack ], silence enemy in 1 round to all enemies.",
20: "When enemies dies, increase 15% critical in 3 round, increase 15% critical damage in 3 round.",
22: "When use basic attack , heal [ 35% attack ].",
23: "Increase 68% armor penetration.",
24: "Deal damage [ 180% attack ] to nearest vertical enemies. Increase 20 energy to 1 ally in the same line.",
26: "When enemies dies, increase 15% attack in 5 round.",
28: "When use basic attack , healing efficiency reduce 10% in 2 round to the closest enemy.",
29: "Increase 49% true damage.",
30: "Deal damage [ 110% attack ] to the closest enemy. Heal [ 70% attack ] to all allies.",
32: "When allie dies, increase 10 energy .",
34: "When use basic attack , deal damage [ 20% attack ] to the closest enemy.",
35: "Increase 85% damage skill .",
36: "Deal damage [ 198% attack ] to 1 enemy with least hp.",
38: "When allie dies, increase 10% armor penetration in 5 round.",
40: "When use basic attack , burn [ 50% attack ] in 1 round to the closest enemy.",
41: "Increase 43% damage skill , 36% accuracy.",
42: "Deal damage [ 110% attack ], reduce 20% accuracy in 3 round to all enemies.",
44: "When hp < 80%, increase 20% armor in 4 round.",
46: "When use basic attack , poison [ 25% attack ] in 3 round to the closest enemy.",
47: "Increase 43% damage skill , 25% critical.",
48: "Deal damage [ 220% attack ] to 2 enemies with highest attack.",
50: "When hp < 80%, increase 95% armor penetration in 2 round.",
52: "When use basic attack , bleed enemy [ 25% attack ] in 2 round to the closest enemy.",
53: "Increase 43% damage skill , 18% critical damage.",
54: "Deal damage [ 50% attack ], bleed enemy [ 65% attack ] in 2 round to all enemies.",
56: "When hp < 50%, increase 90% attack in 3 round.",
58: "When use basic attack , deal damage [ 80% attack ] to 1 enemies with highest energy.",
59: "Increase 43% damage skill , 34% armor penetration.",
60: "Deal damage [ 150% attack ] to 2 enemies with least hp.",

62: "When hp < 50%, heal [ 30% max health ] in 5 round.",


64: "When use basic attack , deal damage [ 80% attack ] to 1 enemies with highest attack.",
65: "Increase 90% damage skill , 30% crit.",
66: "Deal damage [ 150% attack ] to all enemies.",
67: "Deal damage [ 300% attack ] to the closest enemy.",
68: "Deal damage [ 120% attack ], reduce 20% armor penetration in 3 round to enemies in the back line.",
69: "Deal damage [ 220% attack ] to nearest vertical enemies.",
70: "Deal damage [ 150% attack ], reduce 30% attack in 3 round to 1 enemies with highest attack.",
71: "Deal damage [ 200% attack ] to the closest enemy. Increase 34% armor penetration in 3 round.",
72: "Deal damage [ 220% attack ], reduce 60 energy to nearest vertical enemies.",
73: "Deal damage [ 200% attack ], cripple enemy (crippled enemy can not use basic attack) in 2 round to the closest enemy.",
74: "Deal damage [ 50% attack ], burn [ 100% attack ] in 1 round to all enemies.",
75: "When taking damage, deal damage [ 80% attack ] to attacker.",
76: "Deal damage [ 110% attack ] to the closest enemy. Increase 20% block in 3 round to all allies.",
77: "Deal damage [ 350% attack ] to the closest enemy. Increase 20% immunity in 3 round to all allies.",
78: "Deal damage [ 110% attack ] to the closest enemy. Increase 20% accuracy in 3 round to all allies.",
79: "When taking damage, deal damage [ 100% attack if your armor is higher ] to attacker.",
80: "Deal damage [ 180% attack ], petrify enemy in 2 round to nearest vertical enemies.",
81: "Deal damage [ 180% attack ], reduce 20 speed in 2 round to nearest vertical enemies.",
82: "Deal damage [ 50% attack ], poison [ 50% attack ] in 3 round to all enemies.",
83: "Deal damage [ 198% attack ] to 1 enemies with highest attack.",
84: "Deal damage [ 120% attack ], reduce 15% attack in 3 round to enemies in the back line.",
85: "Deal damage [ 150% attack ], reduce 30% armor in 3 round to 1 random enemies with highest amor.",
86: "Deal damage [ 270% attack ] to enemies in the front line.",
87: "Deal damage [ 110% attack ] to the closest enemy. Increase 10 speed in 3 round to all allies.",
88: "Deal damage [ 110% attack ] to the closest enemy. Increase 25 energy to all allies.",
89: "Deal damage [ 180% attack ] to enemies in the back line.",
90: "Deal damage [ 300% attack ], freeze enemy in 2 round to the closest enemy.",
91: "Deal damage [ 110% attack ] to the closest enemy. Heal [ 150% attack ] to ally with the lowest hp.",

93: "When HP < 50%, deals 75% poison damage to all enemies for 2 rounds.",



95: "When taking damage, heal [ 40% attack ].",
96: "Increase 43% damage skill , 25% true damage.",
97: "Deal damage [ 150% attack ], reduce 50 speed in 3 round to 1 random enemies with highest speed.",
98: "Deal damage [ 150% attack ], fear ( reduce 50 energy at end of round) in 1 round to nearest vertical enemies.",
99: "Deal damage [ 200% attack ] to the closest enemy. Increase 35% block in 3 round.",
100: "Deal damage [ 110% attack ], reduce 20% block in 3 round to all enemies.",
101: "Deal damage [ 110% attack ] to the closest enemy. Heal [ 100% attack ] to allies in the front line.",
102: "Deal damage [ 110% attack ], reduce 10% armor in 3 round to all enemies.",
103: "Deal damage [ 150% attack ], reduce 20 energy to 1 enemies with highest energy.",
104: "Deal damage [ 120% attack ], reduce 20% critical in 3 round to enemies in the back line.",
105: "Deal damage [ 120% attack ], stun enemy in 1 round to enemies in the back line.",
106: "Deal damage [ 350% attack ] to the closest enemy. Heal [ 150% attack ].",
107: "Deal damage [ 220% attack ], reduce 20% armor in 3 round to enemies in the front line.",
108: "Deal damage [ 350% attack ], reduce 40% armor in 3 round to the closest enemy.",
109: "Deal damage [ 180% attack ] to nearest vertical enemies. Increase 20 speed in 2 round to 1 ally in the same line.",
115: "Deal damage [ 200% attack ] to the closest enemy. Increase 25% armor in 3 round.",
116: "Deal damage [ 350% attack ], reduce 40% attack in 3 round to 1 enemies with highest attack.",
117: "Deal damage [ 158% attack ], stun enemy in 1 round to enemies in the front line.",
118: "Deal damage [ 110% attack ], reduce 10 speed in 3 round to all enemies.",
119: "Increase 36% accuracy, 25% critical.",
120: "Increase 36% accuracy, 18% critical damage.",
121: "Increase 36% accuracy, 34% armor penetration.",
122: "Increase 36% accuracy, 25% true damage.",
123: "Increase 25% critical, 18% critical damage.",
124: "Increase 18% critical damage, 34% armor penetration.",
125: "Increase 18% critical damage, 25% true damage.",
126: "Increase 25% critical, 25% true damage.",
127: "Increase 40% critical, 60% armor penetration.",
147: "Increase 60% armor penetration, 30% immunity.",
148: "Increase 34% armor penetration, 25% true damage.",
149: "Increase 71% block.",
150: "Increase 30% damage reduction,30% immunity.",
151: "Increase 70% immunity.",
152: "Increase 36% block, 15% damage reduction.",
153: "Increase 15% immunity, 25% true damage.",
154: "Increase 36% block, 15% immunity.",
155: "Increase 15% damage reduction, 15% immunity.",
156: "When taking damage, reduce 15% armor penetration in 2 round to attacker.",
157: "When taking damage, reduce 18% damage skill in 2 round to attacker.",
158: "When taking damage, reduce 15% critical in 2 round to attacker.",
159: "When taking damage, reduce 5 speed in 2 round to attacker.",
160: "When taking damage, reduce 25 energy to attacker.",
161: "When taking damage, reduce 5% accuracy in 2 round to attacker.",
162: "Whenever suffers a critical strike, increase 10 energy .",
163: "Whenever suffers a critical strike, increase 5% crit and 10% crit dame for 2 rounds.",
164: "Whenever suffers a critical strike, reduce 30% critical to attacker.",
165: "When your attack is blocked, increase 15% armor penetration in 2 round.",
166: "When your attack is blocked, increase 20% accuracy in 2 round.",
167: "When allies use active skills, increase 10% true damage in 2 round.",
168: "When blocking an attack, increase 10% attack in 2 round.",
169: "When blocking an attack, increase 10 energy .",
170: "When blocking an attack, heal based on % 35% damage received.",
171: "When critical strike, increase 50% critical damage in 2 round.",
172: "When critical strike, increase 20% true damage in 2 round.",
173: "When critical strike, heal [ 30% attack ].",
174: "Increase 25% critical, 15% immunity.",
189: "When hp < 30%, increase 100% critical in 3 round.",
190: "When you die, revive with [ 50% max health ].",
191: "When you die, stun enemy in 2 round to the closest enemy.",
192: "When you die, deal damage [ 200% attack ] to all enemies.",
193: "When battle starts, energy shield [ 70% max health ] in 2 round.",

194: "when battle starts, stun the front line enemy for 1 round.",


195: "When battle starts, stun enemy in 1 round to the closest enemy.",
196: "Reduce damage from debuff 15% damage from burning, poison, bleeding.",
197: "Reduce 15% critical damage.",
198: "When HP< 50%, has a 50% chance to petrify all enemies for 2 round.",

199: "Increase damage [ 30% attack to freezy enemies ] to enemies.",
200: "Increase damage [ 30% attack to stuned enemies ] to enemies.",
201: "When allies use active skill, increase 10% true damage in 2 round.",
202: "When start rounds increase attack by 10%. (Resonance for 15 rounds max 150%).",
203: "When allies use active skill, heal [ 10% attack ].",
204: "At the beginning of round, increases damage [ 5% attack ] on crowd controlled enemies in 1 round.",
205: "At the beginning of round, increase 10 energy to 1 ally in the same line.",
206: "At the end of each round, heal for 15% HP lost.",
207: "At the end of round, increases damage [ 5% attack ] on crowd controlled enemies in 2 round.",

208: "Deal damage [ 180% attack ] to nearest vertical enemies. Increase 100 energy, 25% critical, 50% critical damage, but reduce 10% armor in 5 round",
209:"Deal damage [ 200% attack ] to 3 random enemies. Increase 35% true damage in 15 round, 35% critical in 4 round.",
210:"Deal damage [ 110% attack ], bleed enemy [ 50% attack ] in 15 round, fear ( reduce 50 energy at end of round) to 5 random enemies.",
211:"Deal damage [ 180% attack ] to enemies in the back line. Increase 50% attack in 4 round.",

212: "Deal damage [ 120% attack ], reduce 25% accuracy in 3 round to enemies in the back line.",
213: "Deal damage [ 50% attack ], bleed enemy [ 65% attack ] in 1 round to all enemies. Increase 15% armor in 3 round to all allies.",
214: "Deal damage [ 110% attack ] to the closest enemy. Heal [ 100% attack ] in 2 round to 1 allies with least hp.",
215: "Deal damage [ 110% attack ] to the closest enemy. Heal [ 70% attack ] in 2 round to allies in the front line.",
216: "Deal damage [ 110% attack ] to the closest enemy. Heal [ 100% attack ] to allies in the back line.",

217:"Deal damage [ 110% attack ], total of 30% chance to stun, petrify, and freeze enemies to all enemies.",


218: "Deal damage [ 120% attack ], reduce 30% damage skill in 3 round to enemies in the back line.",
219:"Deal damage [ 110% attack ], freeze enemy in 2 round to 2 random enemies. Increase 15% armor in 3 round to allies in the front line.",
220: "Deal damage [ 120% attack ], petrify enemy in 1 round to enemies in the back line.",
221:"Deal damage [ 120% attack ], have 60% reduce 50 energy  to 4 random enemies.",


222: "Deal damage [ 110% attack ] to the closest enemy. Increase 15% critical in 3 round to all allies.",
223:"Deal damage [ 150% attack ], reduce 100 speed in 3 round, petrify enemy in 2 round to 3 random enemies with highest speed.",
224:"Deal damage [ 150% attack ], reduce 50% critical in 3 round to 3 random enemies with highest speed. Increase 50% block in 5 round to all allies.",
225: "Deal damage [ 158% attack ], reduce 26% block in 3 round to enemies in the front line.",
226: "Deal damage [ 110% attack ] to the closest enemy. Heal [ 100% attack ] to allies in the back line.",

227: "Deal damage [ 180% attack ] to the 3 random enemies. Increase 10% damage reduction in 3 round for all allies.",

228:"Deal damage [ 200% attack ] to enemies in front line. Create energy shield to block upcoming damage [ 20% max health ] in 2 round to 3 random allies (except myself). ",
229:"Deal damage [ 110% attack ] to 3 enemies with least hp. Reflect 35% damage received in 2 round to 3 allies in the same line.",
230: "Deal damage [ 200% attack ] to the closest enemy. Increase 35% accuracy in 3 round.",
231: "Deal damage [ 200% attack ] to the closest enemy. Increase 25% attack in 3 round.",
232: "Deal damage [ 200% attack ], stun enemy in 2 round to the closest enemy.",
233:"Deal damage [ 500% attack ] to 2 random enemies with highest amor. Reduce 50% damage from burning, poison, bleeding in 3 round to allies in the back line. Increases [Attack] in 15 round for each living enemies.",
234:"Deal damage [ 80% attack ] to all enemies. Increase 40 energy , 30 speed in 3 round to allies in the back line.",
235:"Deal damage [ 150% attack ], have 50% tag Hurt mark to 3 enemes with highest armor. Hurt mark : Deal dame [ 1000% attack ] whenever end turn.",

236:"Deal damage [ 160% attack ] to 4 random enemies. Heal [ 120% attack ] to all allies.",
237:"Deal damage [ 160% attack ], reduce 40% critical damage in 3 round to enemies in the back line. Heal [ 50% attack ] in 5 round to 4 allies with least hp.",


238:"Deal damage [ 180% attack ], tag skull mark to enemies in the front line. Skull mark : This neko increase damage on target with Skull mark",
239:"Deal damege [110% damage], have a 20% stun in 2 rounds to all enemies. 20% increase 100 energy for self .",
240:"Deal damage spread evenly for alive enemies [ 1000% attack ] to all enemies.",
241:"Deal damage [ 158% attack ], reduce 30% damage reduction in 2 round to enemies in the front line. Have 50% chance increase 50 energy for self.",

242: "Deal damage [ 158% attack ], if you have more hp than enemy, deal damage [ 10% max health of Enemy ] to enemies in the front line.",

243: "Deal damage [ 150% attack ], stun enemy in 1 round to nearest vertical enemies.",
244:"Deal damage [ 150% attack ], bleed enemy [ 70% attack ] in 2 round to 3 random enemies. Increase damage [ 100% attack to bleeding enemies ] in 3 round to all allies.",
245: "Deal damage [ 158% attack ], petrify enemy in 1 round to enemies in the front line.",
246:"Deal damage [ 120% attack ], bleed enemy [ 120% attack ] in 15 round to enemies in the back line.",
247:"Deal damage [ 150% attack ], tag curse mark ] to 2 random enemies. Curse mark : Deal damage [ 300% attack ] whenever enemy is critical.",
248: "Deal damage [ 198% attack ] to 1 random enemies with highest speed.",
249: "Deal damage [ 150% attack ], freeze enemy in 2 round, if you have more hp than enemy, deal damage [ 150% attack ] to 1 enemy with highest attack.",
250: "Deal damage [ 150% attack ] to 2 random enemies with highest speed.",
251: "Deal damage [ 120% attack ], reduce 15 energy to 2 enemies with highest energy.",
252:"Deal damage [110% attack ] and Exhausting in 3 turns to all enemies. Exhausted: Take 300% more damage from burning, poison, and bleeding effects. Not cumulative.",
253:"Deal damage [ 180% attack ] to enemies in the front line. Increase 100% critical in 3 round, 100% critical damage in 3 round.",
254: "Deal damage [ 150% attack ], reduce 40% armor penetration in 3 round to 1 enemies with highest attack.",
255:"Deal damage [ 130% attack ], cripple enemy (crippled enemy can not use basic attack) in 1 round, reduce 50% immunity in 3 round to all enemies. Increase healing efficiency 25% in 5 round to 3 random allies.",
256:"Deal damage [ 110% attack ], weakened ( weakened enemies receive extra 50% damage) in 3 round to 4 random enemies.",
257:"Deal damage [ 200% attack ] to 2 enemies with least hp. Increase 15% critical in 15 round, 20% critical damage in 15 round to all allies.",
}