import { useQuery } from '@apollo/client';
import { Alert, AlertTitle, Button, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { QUERY_ME } from '../../graphql/User/userQuery';
import { getMATABalance, getNINOBalance, getWalletAddress, getWalletBalance } from '../../utils/cryptoWallet';
import EmailPassBox from '../User/EmailPassBox';
import Token from '../User/Token';
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    accountView: {
        margin: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(2),
        },


        // width: '100%'
        //marginBottom: theme.spacing(2),
        //overflow: 'hidden'
        //padding: theme.spacing(2),
        //maxWidth: 'lg'
        //display: 'block'
    },
    widgets: {
        //display: 'flex',
        //margin: theme.spacing(1),
    },
    title: {
        flex: 1,
        margin: theme.spacing(1),
        marginBottom: theme.spacing(3),
    },
    paper: {
        display: 'block',
        //minWidth: 150,
        //marginRight: theme.spacing(2),

    },
    media: {
        height: 90,
        width: 90,
        margin: 'auto',
        marginTop: '10%'
    },
    number: {
        textAlign: 'center'
    },
    icon: {
        height: 60,
        width: 60,
        [theme.breakpoints.down('xs')]: {
            height: 30,
            width: 30,
        },
    },
    token: {
        //flex: 1,

        //maxWidth: 400
    },
    info: {
        marginTop: theme.spacing(1),

        //maxWidth: 400
    },
    address: {
        color: 'textSecondary',


    }
}));

function useMyQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function AccountDashboardView() {
    const classes = useStyles()

    let query = useMyQuery();

    const { loading, error, data } = useQuery(QUERY_ME);

    // useEffect(() => {
    //     //muc dich de load info khi user vua vao trang, 1 chay sau 500s cho may nhanh, 1 chay 2000s cho may cham
    //     setTimeout(() => {
    //         let ref = query.get("ref")
    //         if (typeof (Storage) !== "undefined" && data.me.refId == null && ref) {
    //             localStorage.ref = ref
    //         }
    //     }, 500)

    //     // let myInterval = setInterval(() => {
    //     //     getBoxInfo();
    //     // }, 30000)
    //     // return () => {
    //     //     clearInterval(myInterval);
    //     // };
    // }, []);

    if (loading) return 'Loading...';
    if (error) return `Error! ${error.message}`;

    let email, ethAddr;

    if (data && data.me) {
        let ref = query.get("ref")
        //console.log((data.me.refId))
        if (typeof (Storage) !== "undefined") {
            if (localStorage.ref == data.me.id) localStorage.removeItem("ref");

            if (ref && data.me.id != ref && ref != 0) {
                localStorage.ref = ref
            } else if (!localStorage.ref || localStorage.ref == 0){
                localStorage.ref = data.me.refId
            }
        }
        ;
        ({ email, ethAddr  } = data.me)

        if (email){
            //Appsflyer tracking
            window.afSetUpEmail(ethAddr, email)
        }

        //TODO check ví server và client xem có lệch nhau ko thì bắt login lại hoặc chuyển lại ví
    }

    return (
        <div className={classes.accountView}>
            {//chua set email
                !email &&
                <Alert severity="error" style={{marginBottom: 24}}
                    action={
                        <EmailPassBox
                            component={
                                <Button color="inherit" size="small" variant='outlined'>
                                    Set up
                                </Button>}
                        />
                    }>
                    <AlertTitle>Setup Account</AlertTitle>
                    Please setup Account to log into game
                </Alert>
            }

            {//da set email nhung chua activated
                // email == 1 && <Alert severity="warning"
                // >
                //     We have sent an email with confirmation link to your email address.
                //     Can't find this email? You can
                //     <EmailPassBox
                //         component={
                //             <Button color="inherit" size="small" variant='text'
                //                 disabled={typeof (Storage) !== "undefined" && (localStorage.nextTimeSetUpEmail && localStorage.nextTimeSetUpEmail > new Date().getTime())}>
                //                 Set up email
                //             </Button>}
                //     />
                //     again in few minutes.

                // </Alert>
            }

            <Typography variant="h6" className={classes.title} noWrap>
                Wallet:&nbsp;
                <Typography display="inline" component="span" color="textSecondary" noWrap>
                    {getWalletAddress()}
                </Typography>
            </Typography>
            <Grid container spacing={2}>
                < Grid item className={classes.token} xs={12} sm={4}>
                    <Token BalanceIcon={<img src="/images/icon_binance.png" className={classes.icon} />}
                        balanceFunction={getWalletBalance} balanceTicker="BNB" description="Binance coin" />
                </Grid>



                < Grid item className={classes.token} xs={12} sm={4}>
                    <Token BalanceIcon={<img src="/images/svg/coin_mata.svg" className={classes.icon} />}
                        balanceFunction={getMATABalance} balanceTicker="MATA" description="Matatabi Token" />
                </Grid>

                < Grid item className={classes.token} xs={12} sm={4}>
                    <Token BalanceIcon={<img src="/images/svg/coin_nino.svg" className={classes.icon} />}
                        balanceFunction={getNINOBalance} balanceTicker="NINO" description="Ninneko Token" />
                </Grid>

            </Grid>

            <Grid container spacing={2} className={classes.info}>

                < Grid item xs={12} sm={4}>
                    <Alert severity="info">
                        <AlertTitle>Home Page</AlertTitle>
                        <a href="https://ninneko.com" target="_blank" rel="noreferrer"> https://ninneko.com</a>
                    </Alert>

                </Grid>

                < Grid item xs={12} sm={4}>
                    <Alert severity="info">
                        <AlertTitle>Download</AlertTitle>
                        <a href="https://get.ninneko.com" target="_blank" rel="noreferrer"> https://get.ninneko.com</a>
                    </Alert>

                </Grid>

                < Grid item xs={12} sm={4}>
                    <Alert severity="info">
                        <AlertTitle>Whitepaper</AlertTitle>
                        <a href="https://whitepaper.ninneko.com" target="_blank" rel="noreferrer"> https://whitepaper.ninneko.com</a>
                    </Alert>

                </Grid>
            </Grid>



        </div>


    );


}
