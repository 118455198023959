import * as React from 'react';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import { List, ListItem, ListItemButton, ListItemText, MenuItem, Select, TextField, Typography } from "@mui/material";

import { useState } from "react";
import { makeStyles } from "@mui/styles";

import sessionData from '../../sessionData';
import { GEM_NAME } from '../../utils/constants';
import { BootstrapDialog, BootstrapDialogTitle } from '../Misc/BootstrapDialog';
import ConnectWalletBox from '../Login/ConnectWalletBox';
import { ShoppingBag } from '@mui/icons-material';
import { BUNDLE_PRICE_BNB, BUNDLE_PRICE_NINO } from '../views/NftGemBox';

const useStyles = makeStyles((theme) => ({
    selected: {
        //border: "1px solid",
        //borderColor: theme.palette.primaryColor
        background: "rgba(200, 200, 200, 0.4)",
        //background: "#f7f7f8",
        borderRadius: 10
    },
    centerGrid: {
        display: 'flex',
        //alignItems: 'stretch',
        justifyContent: 'center'
    },
}));

export const BuyGemBoxDialog = ({ open, onClose, id, buyBox, loading }) => {



    const classes = useStyles()
    const [numberOfBox, setNumberOfBox] = useState(1);

    const buyBundleByBNB = () => {
        buyBox (id, numberOfBox, true)
    }

    const buyBundleByNINO = () => {
        buyBox (id, numberOfBox, false)
    }



    return (
        <BootstrapDialog
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
                Buy {GEM_NAME[id]}
            </BootstrapDialogTitle>
            <DialogContent dividers>
                <List>
                    <ListItem
                        //key={key}
                        className={classes.centerGrid}
                    >
                        <img src={"/images/gems/5/" + id + ".png"} width="90" />
                    </ListItem>

                </List>

                <Typography>- Gem has 4 random attribues: ATK, DEF, HP, Crit... </Typography>

                <Typography>- 4 gems of the same type makes a set</Typography>

                <Typography>- Set effect is activated if Ninneko equips the whole set</Typography>

                <Typography>- You can buy gems by BNB or NINO</Typography>
                <div className={classes.centerGrid} style={{ marginTop: 16, marginBottom: 24 }}>
                    <Select
                        //style={{ width: 150 }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={numberOfBox}
                        //label="Quantity"
                        size='small'
                        onChange={event => setNumberOfBox(event.target.value)}
                    >
                        <MenuItem value={1}>01 Gem </MenuItem>
                        <MenuItem value={2}>02 Gems </MenuItem>
                        <MenuItem value={5}>05 Gems </MenuItem>
                        <MenuItem value={10}>10 Gems </MenuItem>
                        <MenuItem value={20}>20 Gems </MenuItem>
                    </Select>
                    <Button onClick={buyBundleByBNB} disabled={loading || numberOfBox <= 0} color="primary"
                        variant='contained' style={{ marginLeft: 16 }}
                        autoFocus>
                        {BUNDLE_PRICE_BNB[numberOfBox]} BNB
                    </Button>
                    <Typography style={{marginTop: 8, marginLeft:8}}>or</Typography>
                    <Button onClick={buyBundleByNINO} disabled={loading || numberOfBox <= 0} color="primary"
                        variant='contained' style={{ marginLeft: 16 }}
                        autoFocus>
                        {BUNDLE_PRICE_NINO[numberOfBox]} NINO
                    </Button>
                </div>
            </DialogContent>

        </BootstrapDialog>
    );
}