import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField, Typography, useTheme } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { CalendarToday, SellRounded } from '@mui/icons-material';
import React, { Fragment, useEffect } from "react";
import { getPriceUSDFromETH } from "../../utils/utils";
import { AntTab, AntTabs, TabPanel } from "../Misc/TabPanel";

const useStyles = makeStyles((theme) => ({
    field: {
        //margin: theme.spacing(2),
        width: '20ch'
    },

    price: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: theme.spacing(3)
        //flexShrink: 0ß
    },
    priceUSD: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        //margin: theme.spacing(3)
        //flexShrink: 0ß
    },
    note: {
        marginTop: theme.spacing(5)
    },
    command: {
        display: 'flex',
        padding: 8,
        alignItems: 'center',
        justifyContent: 'flex-end',
    }

}));

function WarnSell({ classes, command }) {

    const theme = useTheme()
    return (
        <>
            <div className={classes.note}>


                <Typography variant="body2" color="textSecondary">
                    - Once the transaction is complete, Ninneko's level will reset to 1, resources used to upgrade ninneko will be refunded to your in-game mailbox
                </Typography>
                <Typography variant="body2" color="textSecondary" style={{ marginTop: theme.spacing(0.5) }}>- Transaction fee is 5%</Typography>
            </div>

            <div className={classes.command}>

                <Button onClick={command} variant="contained" color="primary" style={{ margin: theme.spacing(2) }} startIcon={<SellRounded />}>
                    Confirm
                </Button>

            </div>

        </>
    )
}


function SellPet({ handleClose, sellNinneko }) {

    const theme = useTheme()
    const classes = useStyles()
    const [price, setPrice] = React.useState("")

    const [error, setError] = React.useState("")

    const handleChangePrice = (event) => {
        setPrice(event.target.value)
    }

    const sellPet = () => {

        if (parseFloat(price) < 0.01) {
            setError("Price must be >= 0.01 BNB")
            return
        }
        setError("")
        handleClose()
        sellNinneko(price)
    }


    return (
        <>
            <Typography style={{ marginTop: theme.spacing(1) }}>Enter price to list your ninneko on Market</Typography>

            <div id="price" className={classes.price} >
                <img src="/images/icon_binance.png" style={{ width: 24, height: 24, marginRight: theme.spacing(1) }} />
                <TextField className={classes.field} placeholder="A reasonable price" value={price} onChange={handleChangePrice} type="number" />
                {price && <Typography variant="body2" color="textSecondary">{getPriceUSDFromETH(price)}</Typography>}
            </div>
            <Typography color="secondary">{error}</Typography>

            <WarnSell classes={classes} command={sellPet} />

        </>
    )
}


export default function SellGemBox({ component, sellNinneko, auctionNinneko, isAuction }) {
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        //setError(null)
    };


    const classes = useStyles()

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        // console.log(newValue)
        setValue(newValue);
    };

    useEffect(() => {
        // console.log(isAuction)
        // if (isAuction){
        //     setValue(1)
        // }else{
        //     setValue(0)
        // }
    }, []);

    // console.log(value)

    return (
        <Fragment>

            {
                React.cloneElement(
                    component,
                    { onClick: handleOpen }
                )
            }

            <Dialog

                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Sell Gem"}
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <SellPet handleClose={handleClose} sellNinneko={sellNinneko} />
                </DialogContent>

            </Dialog>
        </Fragment>
    );


}
