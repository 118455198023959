import { Box, Grid} from "@mui/material";
import React, { Fragment } from "react";
import PetSKillBox from "./PetSkillBox";
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({

}));

function Skill({ id, active = false }) {
    //console.log(id)
    return (
        <PetSKillBox id ={id} active={active}
        component={<img src={"/images/skills/Skill_" + id + ".png"} style={{ width: 60, height: 60, borderRadius: 10 }} />} />
    )
}

export default function PetSKillList({ninneko}) {


    return (
        <Fragment>
            <Grid container style={{ marginTop: 24 }}>
                <Grid item xs={3} >
                    <Skill id={ninneko.handD} active={true}/>
                </Grid>

                <Grid item xs={3} >
                    <Skill id={ninneko.hairD} />
                </Grid>

                <Grid item xs={3} >
                    <Skill id={ninneko.tailD} />
                </Grid>

                <Grid item xs={3} >
                    <Skill id={ninneko.eyesD} />
                </Grid>
            </Grid>
        </Fragment>
    )


}
