import { Dialog, DialogContent, DialogTitle, Grid, Slide } from "@mui/material";
import { makeStyles } from '@mui/styles';
import React, { Fragment } from "react";
import LoginMetaMaskButton from "./LoginMetaMaskButton";
import LoginWalletConnectButton from "./LoginWalletConnectButton";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    dialogLoginTitle: {
        display: 'flex',
        justifyContent: 'center'
    },
    dialogLogin: {
        position: 'absolute',
        top: 50,
        left: theme.spacing(3),
        right: theme.spacing(3),
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    groupButtonLogin: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginBottom: 30,
        paddingLeft: 40,
        paddingRight: 40,
    }

}));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

function useMyQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function ConnectWalletBox({ component }) {

    const [open, setOpen] = React.useState(false)

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    let query = useMyQuery();

    const classes = useStyles()

    return (
        <Fragment>
            {
                React.cloneElement(
                    component,
                    { onClick: handleOpen }
                )
            }

            <Dialog
                open={open}
                // fullWidth={true}
                maxWidth='xs'
                classes={{
                    paper: classes.dialogLogin
                }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle className={classes.dialogLoginTitle}>{"Connect to a Wallet"}</DialogTitle>
                <DialogContent>
                    <div className={classes.groupButtonLogin}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}> <LoginMetaMaskButton friendRef={query.get("ref")} /> </Grid>
                            <Grid item xs={12}> <LoginWalletConnectButton friendRef={query.get("ref")} /> </Grid>
                        </Grid>
                    </div>

                </DialogContent>

            </Dialog>
        </Fragment>
    );


}
