import { ApolloProvider } from '@apollo/client';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
//import loadable from '@loadable/component';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Button, Fab } from '@mui/material';
import { SnackbarProvider } from "notistack";
import React, { Suspense, useEffect } from 'react';
import { loadReCaptcha } from 'react-recaptcha-v3';
import { Route, Router, Switch } from "react-router-dom";
import { useMemoryState } from "../src/utils/memoryState";
import { isMobileBrowser } from '../src/utils/utils';
import './App.css';
import { MySnackMessage } from './components/Misc/MySnackMessage';
import StartLayout from './layouts/StartLayout';
import ItemLayout from './layouts/ItemLayout';
import sessionData from './sessionData';
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import { client } from './utils/apollo';
import { NETWORK_VERSION, WALLETCONNECT_PROVIDER } from './utils/constants';
import { getProviderType, getWeb3 } from './utils/cryptoWallet';
import { linkToForgotPass, linkToGemItem, linkToLogOut, linkToNft, linkToPet, linkToStart, linkToUser } from './utils/link';
import history from './utils/myHistory';
import LoadingSpinner from './components/Misc/LoadingSpinner';
import GemLayout from './layouts/GemLayout';
import GemItemLayout from './layouts/GemItemLayout';
import NftItemLayout from './layouts/NftItemLayout';


sessionData.getSessionData()

// add action to all snackbars
const notistackRef = React.createRef();
const onClickDismiss = key => () => {
  notistackRef.current.closeSnackbar(key);
}



const AccountLayout = React.lazy(() => import('./layouts/AccountLayout'/* webpackChunkName: "account" */))
const ForgotPassLayout = React.lazy(() => import('./layouts/ForgotPassLayout'/* webpackChunkName: "forgot" */))
//const LoginLayout = React.lazy(() => import('./layouts/LoginLayout'/* webpackChunkName: "login" */))
const LogoutLayout = React.lazy(() => import('./layouts/LogoutLayout'/* webpackChunkName: "logout" */))
const MarketLayout = React.lazy(() => import('./layouts/MarketLayout'/* webpackChunkName: "market" */))
const PetLayout = React.lazy(() => import('./layouts/PetLayout'/* webpackChunkName: "pet" */))
const ResetPassLayout = React.lazy(() => import('./layouts/ResetPassLayout'/* webpackChunkName: "reset" */))
const TestLayout = React.lazy(() => import('./layouts/TestLayout'/* webpackChunkName: "test" */))
const UserLayout = React.lazy(() => import('./layouts/UserLayout'/* webpackChunkName: "user" */))
const VerifyEmailLayout = React.lazy(() => import('./layouts/VerifyEmailLayout'/* webpackChunkName: "verify" */))
const VerifyEmailInGameLayout = React.lazy(() => import('./layouts/VerifyEmailInGameLayout'/* webpackChunkName: "verify" */))
const PetEditLayout = React.lazy(() => import('./layouts/PetEditLayout'/* webpackChunkName: "petedit" */))
const VoteLayout = React.lazy(() => import('./layouts/VoteLayout'/* webpackChunkName: "petedit" */))

function App() {
  const [userId, setUserId] = useMemoryState('userId', sessionData.userId);

  useEffect(() => { loadReCaptcha('6LdvLeobAAAAAGgaVdDQx-nSXJZ_bdih1G-4h6L7'); }, []);

  useEffect(() => {
    window.addEventListener("load", () => {
      const _web3 = getWeb3()

      if (!_web3) return

      _web3.currentProvider.on('accountsChanged', async () => {
        sessionData.logOut()
        if (getProviderType() == WALLETCONNECT_PROVIDER) {
          await _web3.currentProvider.disconnect()
        }
      });

      _web3.currentProvider.on('networkChanged', async (networkId) => {
        if (networkId !== NETWORK_VERSION) {
          sessionData.logOut()
          if (getProviderType() == WALLETCONNECT_PROVIDER) {
            await _web3.currentProvider.disconnect()
          }
        }
      });

      _web3.currentProvider.on("disconnect", async () => {
        sessionData.logOut()
        if (getProviderType() == WALLETCONNECT_PROVIDER) {
          await _web3.currentProvider.disconnect()
        }
      });
    });

  }, []);

  //genPetFromJSONGen() 


  return (
    <ThemeConfig>
      <GlobalStyles />
      <ApolloProvider client={client}>
        <SnackbarProvider
          ref={notistackRef}
          action={(key) => (
            <Button onClick={onClickDismiss(key)} size="small" color="inherit" variant="outlined">
              Dismiss
            </Button>
          )}
          content={
            (key, message) => {
              if (typeof message === 'string' || message instanceof String) return undefined
              return (<MySnackMessage id={key} message={message} />)
            }}
          maxSnack={3} preventDuplicate>

          <Router history={history}>
            <Suspense fallback={<LoadingSpinner />}>
              <Switch>
                <Route path="/" exact component={MarketLayout} />
                {/* <Route path={linkToLogin}>
                {userId ? <Redirect to="/account" /> : <LoginLayout />}
              </Route> */}
                <Route path="/account" component={AccountLayout} />
                <Route path="/items" component={ItemLayout} />
                {/* <Route path={linkToNft} component={NftItemLayout} /> */}
                <Route path={linkToPet} component={PetLayout} />
                <Route path={linkToGemItem} component={GemItemLayout} />
                <Route path={linkToLogOut} component={LogoutLayout} />
                <Route path={linkToForgotPass} component={ForgotPassLayout} />
                <Route path="/verify/passwd_recovery" component={ResetPassLayout} />
                <Route path="/verify/setup_email" component={VerifyEmailLayout} />
                <Route path="/verify/setup_email_in_game" component={VerifyEmailInGameLayout} />
                <Route path="/verify/new_email" component={VerifyEmailLayout} />
                <Route path={linkToUser} component={UserLayout} />
                <Route path="/test" component={TestLayout} />
                <Route path="/petedit" component={PetEditLayout} />
                <Route path="/vote" component={VoteLayout} />
                <Route path="/gems" component={GemLayout} />
                <Route path={linkToStart} component={StartLayout} />
              </Switch>
            </Suspense>
          </Router>

          <Fab href='https://t.me/ninnekogame' size={isMobileBrowser() ? "small" : "medium"}
            style={{ position: 'fixed', bottom: 20, right: 20, zIndex: 999, color: 'white', backgroundColor: '#0088CC' }}>
            <TelegramIcon />
          </Fab>

        </SnackbarProvider>
      </ApolloProvider>
    </ThemeConfig>
  );
}
console.warn = () => { }

// function iOS() {

//   let platform = navigator?.userAgentData?.platform || navigator?.platform 

// 	return (
// 		[
//                   'iPad Simulator',
// 		  'iPhone Simulator',
// 		  'iPod Simulator',
// 		  'iPad',
// 		  'iPhone',
// 		  'iPod',
// 		].includes(platform) ||
// 		// iPad on iOS 13 detection
// 		(navigator.userAgent.includes('Mac') && 'ontouchend' in document)
// 	);
// }

// document.addEventListener("visibilitychange", function() {
// 	if (document.visibilityState === 'hidden' && iOS()) {
// 		window.localStorage.removeItem('WALLETCONNECT_DEEPLINK_CHOICE');
// 	}
// });

export default App;
