import { Grid, Paper, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { Fragment } from 'react';
import { getPriceETHFromWei } from '../../../utils/utils'
const useStyles = makeStyles((theme) => ({
    paper: {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(1),
        padding: theme.spacing(3),
        // [theme.breakpoints.down('xs')]: {
        //     padding: theme.spacing(1),
        // },
    },

    group: {
        marginBottom: theme.spacing(2),
    },

    cell: {
        // ...{ width: 145 },
        ...theme.typography.caption
    }
}));

function History({ history }) {
    const classes = useStyles()
    return (
        <Fragment>
            <Grid container spacing={2} className={classes.group}>
                <Grid item xs={4} >
                    <Typography variant="caption" color="textSecondary">Buyer</Typography>
                    <Typography variant="subtitle2">{history.buyer.name}</Typography>
                    <Typography variant="body2" noWrap color="textSecondary" className={classes.cell} >{history.buyer.ethAddr}</Typography>
                </Grid>
                <Grid item xs={5} >
                    <Typography variant="caption" color="textSecondary">Seller</Typography>
                    <Typography variant="subtitle2">{history.seller.name}</Typography>
                    <Typography variant="body2" noWrap color="textSecondary" className={classes.cell} >{history.seller.ethAddr}</Typography>
                </Grid>
                <Grid item xs={3} >
                    <Typography variant="caption" color="textSecondary">Price</Typography>
                    <Typography variant="subtitle2">{getPriceETHFromWei(history.price)}</Typography>
                    <Typography variant="body2" noWrap color="textSecondary" className={classes.cell} >{new Date(history.createdAt).toLocaleDateString()}</Typography>
                </Grid>
            </Grid>
            
        </Fragment>

    )
}

export function SaleHistory({ histories }) {
    const classes = useStyles()
    
    return (
        <Fragment>
            <Typography variant="h6">Sale History &nbsp; </Typography>
            <Paper className={classes.paper} variant="outlined">
                {histories.map((e,i) => <History key = {i} history={e} />)}
            </Paper>
        </Fragment>
    )
}

