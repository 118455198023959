
import React, { Fragment } from 'react';
import { Button, Card, Chip, Container, Divider, FormControlLabel, Grid, Icon, List, ListItem, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, SvgIcon, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { BREED_AGE, CLASSES, FACTIONS, FireIcon, GEM_MULTIPLIER, GEM_NAME, GEM_TYPE, GuardIcon, PET_AVATAR_SIZE, PET_BOX_SIZE, PET_STAGE_COTTON_BOX, PET_STAGE_PET } from '../../utils/constants';
import { getAge, getPriceETH, getPriceETHFromWei, getPriceUSD, getPriceUSDFromETH, getPriceUSDFromWei } from '../../utils/utils';
import { Cloud, ContentCopy, ContentCut, ContentPaste, LinkOutlined } from '@mui/icons-material';
import { GENERATION_BETA } from '../../utils/constants';
import { useServerTime } from '../../utils/hookUtils';
import { getTimeToBreed } from '../../utils/utils';
import { linkToGemItem } from '../../utils/link';


const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        height: '100%',
        display: 'block',
        width: 245,
        // marginLeft: 8,
        // marginRight: 8,
        // marginTop: 8

    },
    centerGrid: {
        display: 'flex',
        justifyContent: 'center',
    },
    petAvatar: {
        width: PET_AVATAR_SIZE,
        height: PET_AVATAR_SIZE,
        //marginTop: theme.spacing(2),
        //marginBottom: theme.spacing(2)

    },
    petBox: {
        width: 140,
        height: 140,
        padding: theme.spacing(2)
        //marginTop: theme.spacing(2),
        //marginBottom: theme.spacing(2)

    },
    textBlock: {
        display: 'block'
    },
    priceBlock: {
        display: 'block',
        // alignItems: 'center',
        // justifyContent: 'center',

    },

    factionIcon: {
        position: 'absolute',
        top: 0,
        left: '92%'

    },
    classIcon: {
        position: 'absolute',
        top: -1,
        left: '92%'

    },
    chip: {
        marginRight: theme.spacing(0.5),
        padding: 0
    }

}));


function Bonus({ type, value }) {

    return (
        <ListItem>
            {/* <ListItemIcon>
                <ContentCut fontSize="small" />
            </ListItemIcon> */}
            <ListItemText>
                <Typography variant="body2">
                    {type}
                </Typography>
            </ListItemText>
            <Typography variant="body2" color="text.secondary">
                +{value}
            </Typography>

        </ListItem>
    )
}


export function BonusInfo({ ninneko }) {
    return (
    <div style={{ marginBottom: 16, width: '100%' }}>
        <Bonus type={GEM_TYPE[ninneko.pointType1]} value={(ninneko.pointValue1 * GEM_MULTIPLIER[ninneko.pointType1]).toFixed(1)} />
        <Divider />
        <Bonus type={GEM_TYPE[ninneko.pointType2]} value={(ninneko.pointValue2 * GEM_MULTIPLIER[ninneko.pointType2]).toFixed(1)} />
        <Divider />
        <Bonus type={GEM_TYPE[ninneko.pointType3]} value={(ninneko.pointValue3 * GEM_MULTIPLIER[ninneko.pointType3]).toFixed(1)} />
        <Divider />
        <Bonus type={GEM_TYPE[ninneko.pointType4]} value={(ninneko.pointValue4 * GEM_MULTIPLIER[ninneko.pointType4]).toFixed(1)} />
    </div>
    )

}


export default function GemItem({ ninneko }) {

    //console.log("/images/gems/"+ninneko.setId+".png")

    const classes = useStyles();

    if (!ninneko) return <div />


    //console.log(ninneko)

    const linkToPet = linkToGemItem + "/" + ninneko.id

    const ClassIcon = ninneko.clazz > 0 ? CLASSES[ninneko.clazz - 1].icon : null

    return (

        <Link to={linkToPet} style={{ textDecoration: 'none', margin: 8 }}>
            <Card className={classes.root} >

                <Grid container style={{ height: 'auto' }}>
                    <Grid item xs={12}>
                        <div id="id" className={classes.textBlock} >

                            <div style={{ display: 'block', position: 'relative', marginBottom: 8 }}>
                                {
                                    ninneko.nftId ?
                                        <Typography style={{ lineHeight: 1 }} noWrap>{"#" + ninneko.nftId}</Typography>
                                        :
                                        <Typography style={{ lineHeight: 1 }} color='red' noWrap>Not Minted</Typography>
                                }

                                {ClassIcon && <ClassIcon className={classes.classIcon} />}
                            </div>
                            <Divider />

                        </div>

                    </Grid>
                    <Grid item xs={12} className={classes.centerGrid}>
                        <img src={"/images/gems/" + ninneko.quality + "/" + ninneko.setId + ".png"} alt="Gem" className={classes.petBox} />
                    </Grid>
                    <Grid item xs={12} className={classes.centerGrid}>
                        <Typography variant='h6'>{GEM_NAME[ninneko.setId]}</Typography>
                    </Grid>


                    <BonusInfo ninneko={ninneko}/>


                    {ninneko.price > 0 &&
                        <Fragment>
                            <>
                                <ListItem style={{ display: 'flex', justifyContent: 'center', padding: 0 }}>
                                    <img src="/images/icon_binance.png" style={{ width: 24, height: 24, top: 0 }} />
                                    <Typography variant="h6" noWrap>&nbsp;{getPriceETHFromWei(ninneko.price)} BNB</Typography>
                                </ListItem>
                                <div style={{ display: 'flex', justifyContent: 'center', width: "100%" }}>
                                    <Typography variant="body2"  >{getPriceUSDFromWei(ninneko.price)}</Typography>
                                </div>
                            </>
                        </Fragment>
                    }
                </Grid>
            </Card>
        </Link>

    );
}