import { Box, Typography } from "@mui/material";
import { Diamond, DiamondOutlined, LocalMall, SentimentVeryDissatisfied } from '@mui/icons-material';
import { default as React } from "react";
import { AntTab, AntTabs, TabPanel } from '../Misc/TabPanel';
import { useMemoryState } from "../../utils/memoryState";

export default function InventoryTab({ petComponent, gemComponent,setTab, tab }) {


    //const [value, setValue] = useMemoryState('inventoryTab',0);

    const handleChange = (event, newValue) => {
        //setValue(newValue);
        setTab(newValue)
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <AntTabs
                    value={tab}
                    onChange={handleChange}
                    aria-label="basic tabs example">
                    <AntTab label="Ninnekos" icon={<img src="/images/svg/nino_black.svg" width="32" height="32" />} />
                    <AntTab label="Gems" icon={<Diamond width="32" height="32" />}/>
                </AntTabs>
            </Box>
            <TabPanel value={tab} index={0}>
                {petComponent}
            </TabPanel>
            <TabPanel value={tab} index={1}>
                {/* <div style={{textAlign: 'center', display: 'block', minHeight: 300, marginTop: 48}}>
                    <SentimentVeryDissatisfied style={{ color: 'rgba(0, 0, 0, 0.54)', width: 40, height: 40 }} />
                    <Typography variant="h5" color="textSecondary"> There's nothing here</Typography>
                </div> */}
                {gemComponent}
            </TabPanel>

        </Box>
    );


}
