import { makeStyles } from '@mui/styles';
import React from "react";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import HeaderBar from "../components/Header/HeaderBar";
import GemFilterHandler from '../components/Pet/GemFilterHandler';
import PetFilterHandler from '../components/Pet/PetFilterHandler';
import GemView from '../components/views/GemView';
//import {animation} from "../utils/pixiApp"




const useStyles = makeStyles((theme) => ({

    root: {
        display: 'flex',

    },
}));

function useMyQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}



export default function GemLayout() {
    const classes = useStyles()
    let query = useMyQuery();

    const queryParams = new URLSearchParams(window.location.search)

    let ref = query.get("ref")
    // console.log((data.me.id != ref))
    if (typeof (Storage) !== "undefined") {
        // console.log(localStorage.ref)
        if (ref && ref != 0) {
            localStorage.ref = ref
        }
    }

    // console.log(localStorage.ref)

    return (
        <div className={classes.root}>
            <HeaderBar />
            <GemFilterHandler queryParams={queryParams}>
                <GemView />
            </GemFilterHandler>

        </div>

    );

}


