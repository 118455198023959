import {
    Card,
    Grid,
    ListItem,
    ListItemIcon,
    Paper,
    Typography,
    Alert,
    InputBase,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Tooltip
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { AccountCircleOutlined } from '@mui/icons-material';
import React from 'react';
import { makeStyles } from '@mui/styles';
import { TokenBalance } from './InventoryView';
import { Button } from '@mui/material';
import { useState } from 'react';
import { approveBuyItemByNINO, approveBuyItemByMATA, buyItemByMATA, buyItemByNINO, checkApproveBuyItemNINO, checkApproveBuyItemMATA, getArrayNumberItemBoughtByNINOToday, checkGemApproveNINO, approveGemNINO } from '../../utils/cryptoWallet';
import sessionData from '../../sessionData';
import { useEffect } from 'react';
import { useSnackbar } from "notistack";
import Countdown from 'react-countdown';
import { NftGemBox } from './NftGemBox';
import { approveBuyGemNftBundle, checkApproveBuyGemNftBundleNINO } from '../../utils/nftGem';




const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(3),
        },
        //padding: theme.spacing(2),
        //margin: theme.spacing(2),
        //justifyContent: 'center',
        //flex: 'auto',
        //display: 'flex'
    },
    stakingButtonWrapper: {
        marginTop: "5px",
        boxShadow: "inset 1px 2px 3px rgba(0, 0, 0, 0.075)",
        background: "rgba(244, 244, 244)"
    },
    stakingButton: {
        marginTop: "4px",
        width: "85px"
    }
}));

const TYPE_GREEN_FISH = 1
const TYPE_PURPLE_FISH = 2


const fishItems = [
    {
        id: 1,
        price: 5,
        limit: 10,
        disabled: false,
        type: TYPE_GREEN_FISH
    },
    {
        id: 2,
        price: 13,
        limit: 10,
        disabled: false,
        type: TYPE_GREEN_FISH
    },
    {
        id: 3,
        price: 20,
        limit: 10,
        disabled: false,
        type: TYPE_GREEN_FISH
    },
    {
        id: 4,
        price: 45,
        limit: 10,
        disabled: false,
        type: TYPE_GREEN_FISH
    },
    {
        id: 5,
        price: 90,
        limit: 10,
        disabled: false,
        type: TYPE_GREEN_FISH
    },
    {
        id: 6,
        price: 135,
        limit: 10,
        disabled: false,
        type: TYPE_GREEN_FISH
    },
    {
        id: 7,
        price: 600,
        limit: 10,
        disabled: false,
        type: TYPE_PURPLE_FISH
    },
    {
        id: 8,
        price: 1800,
        limit: 10,
        disabled: false,
        type: TYPE_PURPLE_FISH
    }
]

const shopMataItems = [
    {
        id: 1,
        price: 5000,
        limit: -1,
        desc:'Instantly accquire 72 hours worth of Ninneko EXP',
        disabled: false,
    },
    {
        id: 2,
        price: 2500,
        limit: -1,
        desc:'Used to upgrade Ninneko tier or Guild skill',
        disabled: false,
    },
    {
        id: 3,
        price: 1500,
        limit: -1,
        desc:'Yellow Artifact Special Fragment',
        disabled: false,
    },
    {
        id: 4,
        price: 4000,
        limit: -1,
        desc:'Item equipment',
        disabled: false,
    },
    {
        id: 5,
        price: 4000,
        limit: -1,
        desc:'Item equipment',
        disabled: false,
    },
    {
        id: 6,
        price: 4000,
        limit: -1,
        desc:'Item equipment',
        disabled: false,
    },
    {
        id: 7,
        price: 4000,
        limit: -1,
        desc:'Item equipment',
        disabled: false,
    },
    // {
    //     id: 8,
    //     price: 1000,
    //     limit: -1,
    //desc:'Pick a Yellow Artifact',
    //     disabled: false,
    // },
    {
        id: 9,
        price: 40000,
        limit: -1,
        desc:'Pick a Yellow Artifact',
        disabled: false,
    },
    {
        id: 10,
        price: 30000,
        limit: -1,
        desc:'Use to create a guild',
        disabled: false,
    },
    {
        id: 11,
        price: 8000,
        limit: -1,
        desc:'Reset Neko',
        disabled: false,
    },
  /*  {
        id: 12,
        price: 15000,
        limit: 1,
        desc:'Open to claim: 3.000.000 GreenFish, 3.000 PurpleFish, 1.000.000 Exp acc',
        disabled: false,
    },*/
]


export default function NftItemView() {
    const classes = useStyles()

    //const [walletAddress, setWalletAddress] = useState(null);
    const [approveBuyItemNINO, setApprovedBuyItemNINO] = useState(null)
    

    const { enqueueSnackbar } = useSnackbar();

    const getInfo = async () => {
        //setWalletAddress(sessionData.myAddress);
        
        setApprovedBuyItemNINO( await checkApproveBuyGemNftBundleNINO())
        
    };

    useEffect(() => {
        //muc dich de load info khi user vua vao trang, 1 chay sau 500s cho may nhanh, 1 chay 2000s cho may cham
        setTimeout(() => {
            getInfo();
        }, 500)
        setTimeout(() => {
            getInfo();
        }, 2000)
    }, []);

    const handleApproveBuyItemNINO = () => {
        approveBuyGemNftBundle(enqueueSnackbar, () => { setApprovedBuyItemNINO(true) }, () => { })
    }

    
    


    return (

        <div className={classes.root}>

            <TokenBalance />

            <div style={{ marginTop: 16 }} />

            {!sessionData.isLoggedIn() &&
                <Alert severity="info">
                    Connect your wallet first
                </Alert>
            }

            {sessionData.isLoggedIn() && approveBuyItemNINO == false &&
                <Alert severity="info"
                    action={
                        <Button color="inherit" size="small" variant='outlined' onClick={handleApproveBuyItemNINO}>
                            Approve
                        </Button>
                    }>
                    You need to approve NINO to buy items
                </Alert>
            }

            <div style={{ marginTop: 27 }} />

            <NftGemBox approveBuyItemNINO={approveBuyItemNINO}/>
        </div >
    );


}
