import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField, Typography, useTheme } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { CalendarToday, SellRounded } from '@mui/icons-material';
import React, { Fragment, useEffect } from "react";
import { getPriceUSDFromETH } from "../../utils/utils";
import { AntTab, AntTabs, TabPanel } from "../Misc/TabPanel";
import { BodyParts } from "./Info/BodyParts";

const useStyles = makeStyles((theme) => ({
    field: {
        //margin: theme.spacing(2),
        width: '20ch'
    },

    price: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: theme.spacing(3)
        //flexShrink: 0ß
    },
    priceUSD: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        //margin: theme.spacing(3)
        //flexShrink: 0ß
    },
    note: {
        marginTop: theme.spacing(5)
    },
    command: {
        display: 'flex',
        padding: 8,
        alignItems: 'center',
        justifyContent: 'flex-end',
    }

}));

const OldPet = ({ ninneko }) => {



    return <>
        <img src={ninneko.avatarURL}
            //className={classes.petAvatar} 
            alt="Pet" />
            <BodyParts ninneko={ninneko} />


    </>
}
export default function RebornHistory({ component, rebornHistory }) {
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => {
        console.log("open")
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        //setError(null)
    };


    const classes = useStyles()

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        // console.log(newValue)
        setValue(newValue);
    };

    useEffect(() => {
        // console.log(isAuction)
        // if (isAuction){
        //     setValue(1)
        // }else{
        //     setValue(0)
        // }
    }, []);

    // console.log(value)

     
    return (
      
        <Fragment>

            {
                React.cloneElement(
                    component,
                    { onClick: handleOpen }
                )
            }

            <Dialog

                open={open}
                sx={{ m: 0, p : 0 }} 
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Reborn history"}
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ p: 2 }} >
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <AntTabs
                                value={value}
                                onChange={handleChange}
                                aria-label="basic tabs example">

                                {
                                    rebornHistory.map(
                                        ( item,index) => {
                                            const label = index === 0?"Original":`Reborn ${index}`
                                            return <AntTab label={label} key={index}/>
                                        }
                                    )
                                }


                            </AntTabs>
                        </Box>
                        {
                            rebornHistory.map(
                                (ninneko, index) =>
                                    <TabPanel value={value} index={index} key={index}>
                                        <OldPet ninneko={ninneko} />
                                    </TabPanel>
                            )
                        }

                    </Box>
                </DialogContent>

            </Dialog>
        </Fragment>

    );


}
