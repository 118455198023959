import { Container } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import HeaderBar from "../components/Header/HeaderBar";
import GemItemView from "../components/views/GemItemView";
import PetView from "../components/views/PetView";

export default function GemItemLayout() {


    const location = useLocation();

    const id = location?.pathname?.split("/")[2]

    return (
        <div>
            <HeaderBar />
            
            <Container maxWidth="md">
                
                 <GemItemView id={id}/> 
                
            </Container>


        </div >
    );

}


