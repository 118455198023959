import { PET_GEN_MAP } from "./genMap"

const resBodyScaleEntities = [
    {
        "id": 1,
        "body_part": 4,
        "clazz": 1,
        "attack_scale_origin": 20.625,
        "hp_scale_origin": 157.5,
        "armor_scale_origin": 2.8125,
        "speed_origin": 127.5
    },
    {
        "id": 2,
        "body_part": 4,
        "clazz": 2,
        "attack_scale_origin": 18.75,
        "hp_scale_origin": 180,
        "armor_scale_origin": 3.375,
        "speed_origin": 120
    },
    {
        "id": 3,
        "body_part": 4,
        "clazz": 3,
        "attack_scale_origin": 16.875,
        "hp_scale_origin": 157.5,
        "armor_scale_origin": 2.25,
        "speed_origin": 150
    },
    {
        "id": 4,
        "body_part": 4,
        "clazz": 4,
        "attack_scale_origin": 22.5,
        "hp_scale_origin": 135,
        "armor_scale_origin": 2.25,
        "speed_origin": 135
    },
    {
        "id": 5,
        "body_part": 4,
        "clazz": 5,
        "attack_scale_origin": 22.5,
        "hp_scale_origin": 112.5,
        "armor_scale_origin": 2.25,
        "speed_origin": 142.5
    },
    {
        "id": 6,
        "body_part": 1,
        "clazz": 1,
        "attack_scale_origin": 1.375,
        "hp_scale_origin": 10.5,
        "armor_scale_origin": 0.1875,
        "speed_origin": 8.5
    },
    {
        "id": 7,
        "body_part": 1,
        "clazz": 2,
        "attack_scale_origin": 1.25,
        "hp_scale_origin": 12,
        "armor_scale_origin": 0.225,
        "speed_origin": 8
    },
    {
        "id": 8,
        "body_part": 1,
        "clazz": 3,
        "attack_scale_origin": 1.125,
        "hp_scale_origin": 10.5,
        "armor_scale_origin": 0.15,
        "speed_origin": 10
    },
    {
        "id": 9,
        "body_part": 1,
        "clazz": 4,
        "attack_scale_origin": 1.5,
        "hp_scale_origin": 9,
        "armor_scale_origin": 0.15,
        "speed_origin": 9
    },
    {
        "id": 10,
        "body_part": 1,
        "clazz": 5,
        "attack_scale_origin": 1.5,
        "hp_scale_origin": 7.5,
        "armor_scale_origin": 0.15,
        "speed_origin": 9.5
    },
    {
        "id": 11,
        "body_part": 2,
        "clazz": 1,
        "attack_scale_origin": 1.375,
        "hp_scale_origin": 10.5,
        "armor_scale_origin": 0.1875,
        "speed_origin": 8.5
    },
    {
        "id": 12,
        "body_part": 2,
        "clazz": 2,
        "attack_scale_origin": 1.25,
        "hp_scale_origin": 12,
        "armor_scale_origin": 0.225,
        "speed_origin": 8
    },
    {
        "id": 13,
        "body_part": 2,
        "clazz": 3,
        "attack_scale_origin": 1.125,
        "hp_scale_origin": 10.5,
        "armor_scale_origin": 0.15,
        "speed_origin": 10
    },
    {
        "id": 14,
        "body_part": 2,
        "clazz": 4,
        "attack_scale_origin": 1.5,
        "hp_scale_origin": 9,
        "armor_scale_origin": 0.15,
        "speed_origin": 9
    },
    {
        "id": 15,
        "body_part": 2,
        "clazz": 5,
        "attack_scale_origin": 1.5,
        "hp_scale_origin": 7.5,
        "armor_scale_origin": 0.15,
        "speed_origin": 9.5
    },
    {
        "id": 16,
        "body_part": 3,
        "clazz": 1,
        "attack_scale_origin": 1.375,
        "hp_scale_origin": 10.5,
        "armor_scale_origin": 0.1875,
        "speed_origin": 8.5
    },
    {
        "id": 17,
        "body_part": 3,
        "clazz": 2,
        "attack_scale_origin": 1.25,
        "hp_scale_origin": 12,
        "armor_scale_origin": 0.225,
        "speed_origin": 8
    },
    {
        "id": 18,
        "body_part": 3,
        "clazz": 3,
        "attack_scale_origin": 1.125,
        "hp_scale_origin": 10.5,
        "armor_scale_origin": 0.15,
        "speed_origin": 10
    },
    {
        "id": 19,
        "body_part": 3,
        "clazz": 4,
        "attack_scale_origin": 1.5,
        "hp_scale_origin": 9,
        "armor_scale_origin": 0.15,
        "speed_origin": 9
    },
    {
        "id": 20,
        "body_part": 3,
        "clazz": 5,
        "attack_scale_origin": 1.5,
        "hp_scale_origin": 7.5,
        "armor_scale_origin": 0.15,
        "speed_origin": 9.5
    },
    {
        "id": 21,
        "body_part": 5,
        "clazz": 1,
        "attack_scale_origin": 1.375,
        "hp_scale_origin": 10.5,
        "armor_scale_origin": 0.1875,
        "speed_origin": 8.5
    },
    {
        "id": 22,
        "body_part": 5,
        "clazz": 2,
        "attack_scale_origin": 1.25,
        "hp_scale_origin": 12,
        "armor_scale_origin": 0.225,
        "speed_origin": 8
    },
    {
        "id": 23,
        "body_part": 5,
        "clazz": 3,
        "attack_scale_origin": 1.125,
        "hp_scale_origin": 10.5,
        "armor_scale_origin": 0.15,
        "speed_origin": 10
    },
    {
        "id": 24,
        "body_part": 5,
        "clazz": 4,
        "attack_scale_origin": 1.5,
        "hp_scale_origin": 9,
        "armor_scale_origin": 0.15,
        "speed_origin": 9
    },
    {
        "id": 25,
        "body_part": 5,
        "clazz": 5,
        "attack_scale_origin": 1.5,
        "hp_scale_origin": 7.5,
        "armor_scale_origin": 0.15,
        "speed_origin": 9.5
    },
    {
        "id": 26,
        "body_part": 6,
        "clazz": 1,
        "attack_scale_origin": 1.375,
        "hp_scale_origin": 10.5,
        "armor_scale_origin": 0.1875,
        "speed_origin": 8.5
    },
    {
        "id": 27,
        "body_part": 6,
        "clazz": 2,
        "attack_scale_origin": 1.25,
        "hp_scale_origin": 12,
        "armor_scale_origin": 0.225,
        "speed_origin": 8
    },
    {
        "id": 28,
        "body_part": 6,
        "clazz": 3,
        "attack_scale_origin": 1.125,
        "hp_scale_origin": 10.5,
        "armor_scale_origin": 0.15,
        "speed_origin": 10
    },
    {
        "id": 29,
        "body_part": 6,
        "clazz": 4,
        "attack_scale_origin": 1.5,
        "hp_scale_origin": 9,
        "armor_scale_origin": 0.15,
        "speed_origin": 9
    },
    {
        "id": 30,
        "body_part": 6,
        "clazz": 5,
        "attack_scale_origin": 1.5,
        "hp_scale_origin": 7.5,
        "armor_scale_origin": 0.15,
        "speed_origin": 9.5
    }
]


const resBonusClassEntities = [
    {
        "name": "asssasin",
        "name_id": 5,
        "attack_scale_origin": 2,
        "hp_scale_origin": 0,
        "armor_scale_origin": 1,
        "speed_origin": 1
    },
    {
        "name": "mage",
        "name_id": 1,
        "attack_scale_origin": 3,
        "hp_scale_origin": 1,
        "armor_scale_origin": 0,
        "speed_origin": 0
    },
    {
        "name": "priest",
        "name_id": 3,
        "attack_scale_origin": 0,
        "hp_scale_origin": 1,
        "armor_scale_origin": 0,
        "speed_origin": 3
    },
    {
        "name": "ranger",
        "name_id": 4,
        "attack_scale_origin": 2,
        "hp_scale_origin": 0,
        "armor_scale_origin": 0,
        "speed_origin": 2
    },
    {
        "name": "warrior",
        "name_id": 2,
        "attack_scale_origin": 2,
        "hp_scale_origin": 0,
        "armor_scale_origin": 2,
        "speed_origin": 0
    }
]

class ResBodyScaleEntity {

    constructor() {
        this.bodyPart = 0
        this.clazz = 0
        this.attackScaleOrigin = 0
        this.hpScaleOrigin = 0
        this.armorScaleOrigin = 0
        this.speedOrigin = 0
    }

    getK() {
        return this.bodyPart+"_"+this.clazz
    }

    newInstance(bonusClassEntity, percent, numberRareGenes) {
        let instance = new ResBodyScaleEntity()
        
        instance.bodyPart = this.bodyPart
        instance.clazz = this.clazz

        instance.attackScaleOrigin = this.attackScaleOrigin + this.attackScaleOrigin * bonusClassEntity.attack_scale_origin * percent / 100 + this.attackScaleOrigin * numberRareGenes / 10
        instance.hpScaleOrigin = this.hpScaleOrigin + this.hpScaleOrigin * bonusClassEntity.hp_scale_origin * percent / 100 + this.hpScaleOrigin * numberRareGenes / 10
        instance.armorScaleOrigin = this.armorScaleOrigin + this.armorScaleOrigin * bonusClassEntity.armor_scale_origin * percent / 100
        instance.speedOrigin = this.speedOrigin + this.speedOrigin * bonusClassEntity.speed_origin * percent / 100 + this.speedOrigin * numberRareGenes / 10

        return instance

    }

    add (bodyScale) {
        this.attackScaleOrigin += bodyScale.attack_scale_origin
        this.hpScaleOrigin += bodyScale.hp_scale_origin
        this.armorScaleOrigin += bodyScale.armor_scale_origin
        this.speedOrigin += bodyScale.speed_origin
    }


}

let mBodyScale = []
let mBonusClazz = []
let parts = ['handD', 'eyesD', 'earsD', 'mouthD', 'tailD', 'hairD']
let bonus_parts = ['eyesD', 'earsD', 'mouthD', 'tailD', 'hairD']

init()

//console.log(mBodyScale)

function init() {
    resBodyScaleEntities.forEach(resBodyScaleEntity => mBodyScale[resBodyScaleEntity.body_part+"_"+resBodyScaleEntity.clazz]= resBodyScaleEntity);
    resBonusClassEntities.forEach(resBonusClassEntity => mBonusClazz[resBonusClassEntity.name_id]= resBonusClassEntity);
  
}

function getRawBodyScale(ninneko) {


    let bodyScale = new ResBodyScaleEntity();
    
    parts.forEach(part => {
        if (PET_GEN_MAP[ninneko[part]]) {
            
            const key = PET_GEN_MAP[ninneko[part]].part+"_"+PET_GEN_MAP[ninneko[part]].class


            let rawScale = mBodyScale[key] //ResBodyScaleEntity


            if (rawScale != null) {

                bodyScale.add(rawScale);
            }
        }
    })

    return bodyScale;
}


export function getBodyScale(ninneko) {

    let petGenes = PET_GEN_MAP[ninneko['handD']] //PetGenesEntity
    let bodyScale = getRawBodyScale(ninneko) //ResBodyScaleEntity

    if (bodyScale != null && petGenes != null) {
        let rawClazz = petGenes.class
        let bonus = 0, numberRareGenes = 0;

        bonus_parts.forEach(part => {
            if (PET_GEN_MAP[ninneko[part]]) {

                if (PET_GEN_MAP[ninneko[part]].class == rawClazz) {
                    bonus += PET_GEN_MAP[ninneko[part]].type == 0 ? 1 : 2;
                }
                numberRareGenes += PET_GEN_MAP[ninneko[part]].type == 0 ? 0 : 1;
            }
        })

        if (bonus > 0) bonus += petGenes.type == 0 ? 1 : 2;
        numberRareGenes += petGenes.type == 0 ? 0 : 1;

        return bodyScale.newInstance(mBonusClazz[petGenes.class], bonus, numberRareGenes);
    }
    return null;
}

export function pointLevel1(level = 1, ninneko) {

    const atk =1 * (level + 9) * getBodyScale(ninneko).attackScaleOrigin
    const arm =1 * (level + 9) * getBodyScale(ninneko).armorScaleOrigin
    const hp =1 * (level + 9) * getBodyScale(ninneko).hpScaleOrigin
    const spd = getBodyScale(ninneko).speedOrigin

    return {
        'atk': Math.floor(atk), 'arm': Math.floor(arm), 'hp': Math.floor(hp), 'spd': Math.floor(spd)
    }
}



export function testPoint(){

    const ninneko = {
        earsD: 13,
        eyesD: 196,
        hairD: 162,
        handD: 36,
        mouthD: 136,
        tailD: 154,
    }

    console.log(pointLevel1(1,ninneko))

}