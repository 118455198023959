import { Drawer, InputLabel, MenuItem, Select, Button, Hidden, useMediaQuery, Card, Typography, Grid } from "@mui/material";
import { MHidden } from '../@material-extend';
import { makeStyles } from '@mui/styles';
import React, { Fragment, useEffect, useState, useRef } from "react";
import { CLASSES, FACTIONS, GENERATIONS, MAX_BREED_COUNT, SALE_STATS, DRAWER_WIDTH, LIFE_STAGES, LIFE_STAGE_ADULT } from "../../utils/constants";
import { useMemoryState } from "../../utils/memoryState";
import FilterPanel from "../Misc/FilterPanel";
import GridPet from "../Pet/GridPet";
import SaleStats from "../Stats/SaleStats";
import { useTheme } from "@emotion/react";
import { getPriceUSDFromWei, getPriceUSDFromETH, updateURLParameter, addURLParameter, removeURLParameterByName, removeURLParameterByNameAndValue } from '../../utils/utils';
import { QUERY_MARKET_STATS } from "../../graphql/User/marketQuery";
import { useQuery } from '@apollo/client';
import LoadingSpinner from "../Misc/LoadingSpinner";
import { fontSize } from "@mui/system";
import { PetFilterPanel, PetSortPanel } from "../Pet/PetFilterHandler";
import { useLocation } from 'react-router-dom';

//import {animation} from "../utils/pixiApp"

const useStyles = makeStyles((theme) => ({

    toolbar: theme.mixins.toolbar,

    main: {
        paddingTop: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(2),
        },

        paddingBottom: theme.spacing(10),
        flex: 1,
    },

}));





export default function MarketView2({ handler }) {

    //const location = useLocation()

    const theme = useTheme();

    const classes = useStyles()

    const isSM = useMediaQuery(theme.breakpoints.down('sm'));

    // useEffect(
    //     () => {
    //         if (handler) {

    //             handler.setSale(1)
    //             handler.setFilterLifeStage({1: false, 2: true})
    //             handler.doUpdateChangeSort(2)

    //         }

    //     }
    //     , [location]);


    return (

        <Fragment>

            <PetFilterPanel handler={handler} />

            <div className={classes.main}>
                {
                    handler.page == 1 ?
                        <SaleStats />
                        : <div className={classes.toolbar} />
                }

                <PetSortPanel handler={handler} />

                <GridPet
                    //forSale={1}
                    //faction={factions[1] ? 1 : 0}
                    breedCount={handler.breedCount}
                    petClasses={handler.petClasses}
                    factions={handler.factions}
                    legendary={handler.legendary}
                    generations={handler.generations}
                    page={handler.page}
                    handleChangePage={handler.handleChangePage}
                    forSale={handler.sale}
                    sort={handler.sort}
                    genParts={handler.genParts}
                    lifeStages={handler.filterLifeStage}
                //stage={null} //chỉ hiện Pet, ko hiện box, pod, egg
                />

            </div>

        </Fragment>

    );

}


