export const TBL_AMULET =
[
    {
      "id": "1",
      "name": "Amulet Square",
      "desc": "Hoàng Thạch Công sáng tác cuối đời Tây Hán",
      "type_stone": "1",
      "star": "1",
      "effect1": "[{\"clazz\":0,\"faction\":0,\"point\":[0,55]}]",
      "effect2": "[{\"clazz\":0,\"faction\":0,\"point\":[2,450]}]",
      "effect3": "",
      "upgrade_cost_gold": "10000",
      "upgrade_cost_dust": "36",
      "convert_cost_gold": "500",
      "convert_cost_dust": "2",
      "gem_retain_effect": "10",
      "old_effect1": "55 Công",
      "old_effect2": "450 Máu",
      "old_effect3": ""
    },
    {
      "id": "2",
      "name": "Amulet Square",
      "desc": "Hoàng Thạch Công sáng tác cuối đời Tây Hán",
      "type_stone": "1",
      "star": "2",
      "effect1": "[{\"clazz\":0,\"faction\":0,\"point\":[0,72]}]",
      "effect2": "[{\"clazz\":0,\"faction\":0,\"point\":[2,582]}]",
      "effect3": "",
      "upgrade_cost_gold": "20000",
      "upgrade_cost_dust": "108",
      "convert_cost_gold": "1000",
      "convert_cost_dust": "4",
      "gem_retain_effect": "50",
      "old_effect1": "72 Công",
      "old_effect2": "582 Máu",
      "old_effect3": ""
    },
    {
      "id": "3",
      "name": "Amulet Hexagonal",
      "desc": "Úy Liêu biên soạn thời Chiến Quốc",
      "type_stone": "2",
      "star": "1",
      "effect1": "[{\"clazz\":0,\"faction\":0,\"point\":[0,110]},{\"clazz\":0,\"faction\":0,\"point\":[0,50,6,20]}]",
      "effect2": "[{\"clazz\":0,\"faction\":0,\"point\":[2,880]}]",
      "effect3": "",
      "upgrade_cost_gold": "40000",
      "upgrade_cost_dust": "180",
      "convert_cost_gold": "2000",
      "convert_cost_dust": "10",
      "gem_retain_effect": "100",
      "old_effect1": "110 Công, \n50 Công, 2% Chính xác ",
      "old_effect2": "880 Máu",
      "old_effect3": ""
    },
    {
      "id": "4",
      "name": "Amulet Hexagonal",
      "desc": "Úy Liêu biên soạn thời Chiến Quốc",
      "type_stone": "2",
      "star": "2",
      "effect1": "[{\"clazz\":0,\"faction\":0,\"point\":[0,150]},{\"clazz\":0,\"faction\":0,\"point\":[0,70,6,30]}]",
      "effect2": "[{\"clazz\":0,\"faction\":0,\"point\":[2,1120]}]",
      "effect3": "",
      "upgrade_cost_gold": "80000",
      "upgrade_cost_dust": "360",
      "convert_cost_gold": "4000",
      "convert_cost_dust": "18",
      "gem_retain_effect": "130",
      "old_effect1": "150 Công\n70 Công ,3% Chính xác",
      "old_effect2": "1120 Máu",
      "old_effect3": ""
    },
    {
      "id": "5",
      "name": "Amulet Pentagon",
      "desc": "Tác phẩm của Khương Tử Nha đời Chu",
      "type_stone": "3",
      "star": "1",
      "effect1": "[{\"clazz\":0,\"faction\":0,\"point\":[0,210,100,40]},{\"clazz\":0,\"faction\":0,\"point\":[0,125,6,50]},{\"clazz\":0,\"faction\":0,\"point\":[0,125,10,80]}]",
      "effect2": "[{\"clazz\":0,\"faction\":0,\"point\":[2,1480,102,70]},{\"clazz\":0,\"faction\":0,\"point\":[2,920,7,70]}]",
      "effect3": "[{\"clazz\":0,\"faction\":0,\"point\":[2,920,8,50]}]",
      "upgrade_cost_gold": "160000",
      "upgrade_cost_dust": "540",
      "convert_cost_gold": "8000",
      "convert_cost_dust": "36",
      "gem_retain_effect": "210",
      "old_effect1": "210 Công, 4% Công\n125 Công, 5% Chính xác\n125 Công, 8% Phá giáp",
      "old_effect2": "1480 Máu , 7% Máu\n920 Máu , 7% Chặn",
      "old_effect3": "920 Máu, 5% Chí mạng"
    },
    {
      "id": "6",
      "name": "Amulet Pentagon",
      "desc": "Tác phẩm của Khương Tử Nha đời Chu",
      "type_stone": "3",
      "star": "2",
      "effect1": "[{\"clazz\":0,\"faction\":0,\"point\":[0,270,100,50]},{\"clazz\":0,\"faction\":0,\"point\":[0,155,6,60]},{\"clazz\":0,\"faction\":0,\"point\":[0,155,10,110]}]",
      "effect2": "[{\"clazz\":0,\"faction\":0,\"point\":[2,1960,102,80]},{\"clazz\":0,\"faction\":0,\"point\":[2,1200,7,80]}]",
      "effect3": "[{\"clazz\":0,\"faction\":0,\"point\":[2,1200,8,60]}]",
      "upgrade_cost_gold": "320000",
      "upgrade_cost_dust": "720",
      "convert_cost_gold": "16000",
      "convert_cost_dust": "54",
      "gem_retain_effect": "260",
      "old_effect1": "270 Công, 5% Công\n155 Công, 6% Chính xác\n155 Công, 11% Phá giáp",
      "old_effect2": "1960 Máu, 8% Máu\n1200 Máu, 8% Chặn",
      "old_effect3": "1200 Máu, 6% Chí mạng"
    },
    {
      "id": "7",
      "name": "Amulet Pentagon",
      "desc": "Tác phẩm của Khương Tử Nha đời Chu",
      "type_stone": "3",
      "star": "3",
      "effect1": "[{\"clazz\":0,\"faction\":0,\"point\":[0,330,100,60]},{\"clazz\":0,\"faction\":0,\"point\":[0,185,6,70]},{\"clazz\":0,\"faction\":0,\"point\":[0,185,10,140]}]",
      "effect2": "[{\"clazz\":0,\"faction\":0,\"point\":[2,2440,102,90]},{\"clazz\":0,\"faction\":0,\"point\":[2,1480,7,90]}]",
      "effect3": "[{\"clazz\":0,\"faction\":0,\"point\":[2,1480,8,70]}]",
      "upgrade_cost_gold": "500000",
      "upgrade_cost_dust": "1440",
      "convert_cost_gold": "32000",
      "convert_cost_dust": "72",
      "gem_retain_effect": "320",
      "old_effect1": "330 Công, 6% Công\n185 Công, 7% Chính xác\n185 Công, 14% Phá giáp",
      "old_effect2": "2440 Máu, 9% Máu\n1480 Máu, 9% Chặn",
      "old_effect3": "1480 Máu, 7% Chí mạng"
    },
    {
      "id": "8",
      "name": "Amulet Purple",
      "desc": "Của Tư Mã Nhương Thư đời Xuân Thu",
      "type_stone": "4",
      "star": "1",
      "effect1": "[{\"clazz\":0,\"faction\":0,\"point\":[0,450,100,80]},{\"clazz\":0,\"faction\":0,\"point\":[0,225,6,90]},{\"clazz\":0,\"faction\":0,\"point\":[8,30,9,90]},{\"clazz\":0,\"faction\":0,\"point\":[0,225,10,180]},{\"clazz\":0,\"faction\":0,\"point\":[6,30,5,100]}]",
      "effect2": "[{\"clazz\":0,\"faction\":0,\"point\":[2,3260,102,110]},{\"clazz\":0,\"faction\":0,\"point\":[2,1680,7,100]}]",
      "effect3": "[{\"clazz\":0,\"faction\":0,\"point\":[2,1680,8,90]}]",
      "upgrade_cost_gold": "1200000",
      "upgrade_cost_dust": "1800",
      "convert_cost_gold": "50000",
      "convert_cost_dust": "86",
      "gem_retain_effect": "500",
      "old_effect1": "450 Công, 8% Công\n225 Công, 9% Chính xác\n3% Chí mạng, 9% Sát thương chí mạng\n225 Công, 18% Phá giáp\n3% Chính xác, 10% Sát thương chí mạng",
      "old_effect2": "3260 Máu, 11% Máu\n1680 Máu, 10% Chặn",
      "old_effect3": "1680 Máu, 9% Chí mạng"
    },
    {
      "id": "9",
      "name": "Amulet Purple",
      "desc": "Của Tư Mã Nhương Thư đời Xuân Thu",
      "type_stone": "4",
      "star": "2",
      "effect1": "[{\"clazz\":0,\"faction\":0,\"point\":[0,500,100,95]},{\"clazz\":0,\"faction\":0,\"point\":[0,265,6,100]},{\"clazz\":0,\"faction\":0,\"point\":[8,40,9,140]},{\"clazz\":0,\"faction\":0,\"point\":[0,265,10,210]},{\"clazz\":0,\"faction\":0,\"point\":[6,40,5,115]}]",
      "effect2": "[{\"clazz\":0,\"faction\":0,\"point\":[2,3680,102,130]},{\"clazz\":0,\"faction\":0,\"point\":[2,1980,7,110]}]",
      "effect3": "[{\"clazz\":0,\"faction\":0,\"point\":[2,1980,8,100]}]",
      "upgrade_cost_gold": "1800000",
      "upgrade_cost_dust": "2160",
      "convert_cost_gold": "120000",
      "convert_cost_dust": "108",
      "gem_retain_effect": "700",
      "old_effect1": "500 Công, 9.5% Công\n265 Công, 10% Chính xác\n4% Chí mạng, 14% Sát thương chí mạng\n265 Công, 21% Phá giáp\n4% Chính xác, 11.5% Sát thương chí mạng",
      "old_effect2": "3680 Máu, 13% Máu\n1980 Máu, 11% Chặn",
      "old_effect3": "1980 Máu, 10% Chí mạng"
    },
    {
      "id": "10",
      "name": "Amulet Purple",
      "desc": "Của Tư Mã Nhương Thư đời Xuân Thu",
      "type_stone": "4",
      "star": "3",
      "effect1": "[{\"clazz\":0,\"faction\":0,\"point\":[0,560,100,110]},{\"clazz\":0,\"faction\":0,\"point\":[0,305,6,110]},{\"clazz\":0,\"faction\":0,\"point\":[8,50,9,180]},{\"clazz\":0,\"faction\":0,\"point\":[0,305,10,240]},{\"clazz\":0,\"faction\":0,\"point\":[6,50,5,130]}]",
      "effect2": "[{\"clazz\":0,\"faction\":0,\"point\":[2,4120,102,150]},{\"clazz\":0,\"faction\":0,\"point\":[2,2280,7,120]}]",
      "effect3": "[{\"clazz\":0,\"faction\":0,\"point\":[2,2280,8,110]}]",
      "upgrade_cost_gold": "2500000",
      "upgrade_cost_dust": "4320",
      "convert_cost_gold": "180000",
      "convert_cost_dust": "129",
      "gem_retain_effect": "1000",
      "old_effect1": "560 Công, 11% Công\n305 Công, 11% Chính xác\n5% Chí mạng, 18% Sát thương chí mạng\n305 Công, 24% Phá giáp\n5% Chính xác, 13% Sát thương chí mạng",
      "old_effect2": "4120 Máu, 15% Máu\n2280 Máu, 12% Chặn",
      "old_effect3": "2280 Máu, 11% Chí mạng"
    },
    {
      "id": "11",
      "name": "Amulet Green",
      "desc": "Ngô Khởi soạn thời Chiến Quốc",
      "type_stone": "5",
      "star": "1",
      "effect1": "[{\"clazz\":0,\"faction\":0,\"point\":[0,640,100,135]},{\"clazz\":0,\"faction\":0,\"point\":[8,70,9,240]},{\"clazz\":0,\"faction\":0,\"point\":[0,640,100,100,6,20]},{\"clazz\":0,\"faction\":0,\"point\":[0,360,10,280]},{\"clazz\":0,\"faction\":0,\"point\":[6,70,5,155]},{\"clazz\":0,\"faction\":0,\"point\":[0,360,13,110]}]",
      "effect2": "[{\"clazz\":0,\"faction\":0,\"point\":[2,4820,102,180]},{\"clazz\":0,\"faction\":0,\"point\":[2,2980,7,140]}]",
      "effect3": "[{\"clazz\":0,\"faction\":0,\"point\":[2,2980,8,130]},{\"clazz\":0,\"faction\":0,\"point\":[2,4820,102,140,6,20]},{\"clazz\":0,\"faction\":0,\"point\":[102,80,3,10]}]",
      "upgrade_cost_gold": "6000000",
      "upgrade_cost_dust": "5040",
      "convert_cost_gold": "250000",
      "convert_cost_dust": "259",
      "gem_retain_effect": "1400",
      "old_effect1": "640 Công, 13.5% Công\n7% Chí mạng, 24% Sát thương chí mạng\n640 Công, 10% Công, 2% Chính xác\n360 Công, 28% Phá giáp\n7% Chính xác, 15.5% Sát thương chí mạng\n360 Công, 11% Sát thương chuẩn",
      "old_effect2": "4820 Máu, 18% Máu\n2980 Máu, 14% Chặn",
      "old_effect3": "2980 Máu, 13% Chí mạng\n4820 Máu, 14% Máu, 2% Chính xác\n8% Máu, 10 Tốc độ"
    },
    {
      "id": "12",
      "name": "Amulet Green",
      "desc": "Ngô Khởi soạn thời Chiến Quốc",
      "type_stone": "5",
      "star": "2",
      "effect1": "[{\"clazz\":0,\"faction\":0,\"point\":[0,720,100,150]},{\"clazz\":0,\"faction\":0,\"point\":[8,80,9,260]},{\"clazz\":0,\"faction\":0,\"point\":[0,720,100,110,6,25]},{\"clazz\":0,\"faction\":0,\"point\":[0,420,10,310]},{\"clazz\":0,\"faction\":0,\"point\":[6,80,5,170]},{\"clazz\":0,\"faction\":0,\"point\":[0,420,13,130]}]",
      "effect2": "[{\"clazz\":0,\"faction\":0,\"point\":[2,5420,102,200]},{\"clazz\":0,\"faction\":0,\"point\":[2,3460,7,150]}]",
      "effect3": "[{\"clazz\":0,\"faction\":0,\"point\":[2,3460,8,130]},{\"clazz\":0,\"faction\":0,\"point\":[2,5420,102,160,6,25]},{\"clazz\":0,\"faction\":0,\"point\":[102,90,3,20]}]",
      "upgrade_cost_gold": "9000000",
      "upgrade_cost_dust": "5760",
      "convert_cost_gold": "600000",
      "convert_cost_dust": "302",
      "gem_retain_effect": "1750",
      "old_effect1": "720 Công, 15% Công\n8% Chí mạng, 26% Sát thương chí mạng\n720 Công, 11% Công, 2.5% Chính xác\n420 Công, 31% Phá giáp\n8% Chính xác, 17% Sát thương chí mạng\n420 Công, 13% Sát thương chuẩn",
      "old_effect2": "5420 Máu, 20% Máu\n3460 Máu, 15% Chặn",
      "old_effect3": "3460 Máu, 14% Chí mạng\n5420 Máu, 16% Máu, 2.5% Chính xác\n9% Máu, 20 Tốc độ"
    },
    {
      "id": "13",
      "name": "Amulet Green",
      "desc": "Ngô Khởi soạn thời Chiến Quốc",
      "type_stone": "5",
      "star": "3",
      "effect1": "[{\"clazz\":0,\"faction\":0,\"point\":[0,800,100,165]},{\"clazz\":0,\"faction\":0,\"point\":[8,90,9,300]},{\"clazz\":0,\"faction\":0,\"point\":[0,800,100,125,6,30]},{\"clazz\":0,\"faction\":0,\"point\":[0,480,10,340]},{\"clazz\":0,\"faction\":0,\"point\":[6,90,5,185]},{\"clazz\":0,\"faction\":0,\"point\":[0,480,13,150]}]",
      "effect2": "[{\"clazz\":0,\"faction\":0,\"point\":[2,6120,102,220]},{\"clazz\":0,\"faction\":0,\"point\":[2,3980,7,160]}]",
      "effect3": "[{\"clazz\":0,\"faction\":0,\"point\":[2,3980,8,150]},{\"clazz\":0,\"faction\":0,\"point\":[2,6120,102,180,6,30]},{\"clazz\":0,\"faction\":0,\"point\":[102,100,3,30]}]",
      "upgrade_cost_gold": "12000000",
      "upgrade_cost_dust": "6480",
      "convert_cost_gold": "900000",
      "convert_cost_dust": "345",
      "gem_retain_effect": "2050",
      "old_effect1": "800 Công, 16.5% Công\n9% Chí mạng, 30% Sát thương chí mạng\n800 Công, 12.5% Công, 3% Chính xác\n480 Công, 34% Phá giáp\n9% Chính xác, 18.5% Sát thương chí mạng\n480 Công, 15% Sát thương chuẩn",
      "old_effect2": "6120 Máu, 22% Máu\n3980 Máu, 16% Chặn",
      "old_effect3": "3980 Máu, 15% Chí mạng\n6120 Máu, 18% Máu, 3% Chính xác\n10% Máu, 30 Tốc độ"
    },
    {
      "id": "14",
      "name": "Amulet Green",
      "desc": "Ngô Khởi soạn thời Chiến Quốc",
      "type_stone": "5",
      "star": "4",
      "effect1": "[{\"clazz\":0,\"faction\":0,\"point\":[0,880,100,180]},{\"clazz\":0,\"faction\":0,\"point\":[8,100,9,340]},{\"clazz\":0,\"faction\":0,\"point\":[6,160,0,540]},{\"clazz\":0,\"faction\":0,\"point\":[0,540,10,370]},{\"clazz\":0,\"faction\":0,\"point\":[6,100,5,200]},{\"clazz\":0,\"faction\":0,\"point\":[0,540,13,170]}]",
      "effect2": "[{\"clazz\":0,\"faction\":0,\"point\":[2,6760,102,240]},{\"clazz\":0,\"faction\":0,\"point\":[2,4460,7,170]}]",
      "effect3": "[{\"clazz\":0,\"faction\":0,\"point\":[2,4460,8,160]},{\"clazz\":0,\"faction\":0,\"point\":[2,6760,102,200,6,35]},{\"clazz\":0,\"faction\":0,\"point\":[102,110,3,40]}]",
      "upgrade_cost_gold": "15000000",
      "upgrade_cost_dust": "8640",
      "convert_cost_gold": "1200000",
      "convert_cost_dust": "388",
      "gem_retain_effect": "2500",
      "old_effect1": "880 Công, 18% Công\n10% Chí mạng, 34% Sát thương chí mạng\n16% Chính xác, 540 Công\n540 Công, 37% Phá giáp\n10% Chính xác, 20% Sát thương chí mạng\n540 Công, 17% Sát thương chuẩn",
      "old_effect2": "6760 Máu, 24% Máu\n4460 Máu, 17% Chặn",
      "old_effect3": "4460 Máu, 16% Chí mạng\n6760 Máu, 20%Máu, 3.5% Chính xác\n11% Máu, 40 Tốc độ"
    },
    {
      "id": "15",
      "name": "Amulet Magic",
      "desc": "Kiệt tác của Tôn Vũ thời Xuân Thu",
      "type_stone": "6",
      "star": "1",
      "effect1": "[{\"clazz\":0,\"faction\":0,\"point\":[0,1020,100,205]},{\"clazz\":0,\"faction\":0,\"point\":[8,120,9,400]},{\"clazz\":0,\"faction\":0,\"point\":[6,120,9,240]},{\"clazz\":0,\"faction\":0,\"point\":[0,620,100,70,10,410]},{\"clazz\":0,\"faction\":0,\"point\":[0,590,100,80,6,180]},{\"clazz\":0,\"faction\":0,\"point\":[0,620,102,90,13,200]}]",
      "effect2": "[{\"clazz\":0,\"faction\":0,\"point\":[2,7820,102,270]},{\"clazz\":0,\"faction\":0,\"point\":[102,110,7,180]}]",
      "effect3": "[{\"clazz\":0,\"faction\":0,\"point\":[102,100,8,180]},{\"clazz\":0,\"faction\":0,\"point\":[2,7260,102,220,6,50]},{\"clazz\":0,\"faction\":0,\"point\":[102,130,3,55]}]",
      "upgrade_cost_gold": "18000000",
      "upgrade_cost_dust": "9360",
      "convert_cost_gold": "1500000",
      "convert_cost_dust": "518",
      "gem_retain_effect": "2900",
      "old_effect1": "1020 Công, 20.5% Công\r\n12% Chí mạng, 40% Sát thương chí mạng\r\n12% Chính xác, 24% Sát thương chí mạng\r\n620 Công, 7% Công, 41% Phá giáp\r\n590 Công, 8% Công, 18% Chính xác\r\n620 Công, 9% Máu, 20% Sát thương chuẩn",
      "old_effect2": "7820 Máu, 27% Máu\n11% Máu, 18% Chặn",
      "old_effect3": "10% Máu, 18% Chí mạng\n7260 Máu, 22%Máu, 5% Chính xác\n13% Máu, 55 Tốc độ"
    },
    {
      "id": "16",
      "name": "Amulet Magic",
      "desc": "Kiệt tác của Tôn Vũ thời Xuân Thu",
      "type_stone": "6",
      "star": "2",
      "effect1": "[{\"clazz\":0,\"faction\":0,\"point\":[0,1200,100,220]},{\"clazz\":0,\"faction\":0,\"point\":[8,130,9,440]},{\"clazz\":0,\"faction\":0,\"point\":[6,130,9,260]},{\"clazz\":0,\"faction\":0,\"point\":[0,620,100,90,10,440]},{\"clazz\":0,\"faction\":0,\"point\":[0,620,100,90,6,190]},{\"clazz\":0,\"faction\":0,\"point\":[0,620,102,120,13,220]}]",
      "effect2": "[{\"clazz\":0,\"faction\":0,\"point\":[2,8960,102,290]},{\"clazz\":0,\"faction\":0,\"point\":[102,140,7,190]}]",
      "effect3": "[{\"clazz\":0,\"faction\":0,\"point\":[102,130,8,190]},{\"clazz\":0,\"faction\":0,\"point\":[2,7960,102,240,6,70]},{\"clazz\":0,\"faction\":0,\"point\":[102,145,3,70]}]",
      "upgrade_cost_gold": "21000000",
      "upgrade_cost_dust": "12960",
      "convert_cost_gold": "1800000",
      "convert_cost_dust": "561",
      "gem_retain_effect": "3100",
      "old_effect1": "1200 Công, 22% Công\r\n13% Chí mạng, 44% Sát thương chí mạng\r\n13% Chính xác, 26% Sát thương chí mạng\r\n620 Công, 9% Công, 44% Phá giáp\r\n620 Công, 9% Công, 19% Chính xác\r\n620 Công, 12% Máu, 22% Sát thương chuẩn",
      "old_effect2": "8960 Máu, 29% Máu\n14% Máu, 19% Chặn",
      "old_effect3": "13% Máu, 19% Chí mạng\n7960 Máu, 24%Máu, 7% Chính xác\n14.5% Máu, 70 Tốc độ"
    },
    {
      "id": "17",
      "name": "Amulet Magic",
      "desc": "Kiệt tác của Tôn Vũ thời Xuân Thu",
      "type_stone": "6",
      "star": "3",
      "effect1": "[{\"clazz\":0,\"faction\":0,\"point\":[0,1600,100,250]},{\"clazz\":0,\"faction\":0,\"point\":[8,140,9,480]},{\"clazz\":0,\"faction\":0,\"point\":[6,150,9,300]},{\"clazz\":0,\"faction\":0,\"point\":[0,740,100,120,10,440]},{\"clazz\":0,\"faction\":0,\"point\":[0,740,100,120,6,200]},{\"clazz\":0,\"faction\":0,\"point\":[0,740,102,150,13,260]}]",
      "effect2": "[{\"clazz\":0,\"faction\":0,\"point\":[2,10160,102,310]},{\"clazz\":0,\"faction\":0,\"point\":[102,170,7,200]}]",
      "effect3": "[{\"clazz\":0,\"faction\":0,\"point\":[102,150,8,200]},{\"clazz\":0,\"faction\":0,\"point\":[2,8480,102,250,6,100]},{\"clazz\":0,\"faction\":0,\"point\":[102,160,3,80]}]",
      "upgrade_cost_gold": "24000000",
      "upgrade_cost_dust": "15000",
      "convert_cost_gold": "2100000",
      "convert_cost_dust": "777",
      "gem_retain_effect": "3200",
      "old_effect1": "1600 Công, 25% Công\r\n14% Chí mạng. 48% Sát thương chí mạng\r\n15% Chính xác, 30% Sát thương chí mạng\r\n740 Công, 12% Công, 44% Phá giáp\r\n740 Công, 12% Công, 20% Chính xác\r\n740 Công, 15% Máu, 26% Sát thương chuẩn",
      "old_effect2": "10160 Máu, 31% Máu\n17% Máu, 20% Chặn",
      "old_effect3": "15% Máu, 20% Chí mạng\n8480 Máu, 25%Máu, 10% Chính xác\n16% Máu, 80 Tốc độ"
    },
    {
      "id": "18",
      "name": "Amulet Magic",
      "desc": "Kiệt tác của Tôn Vũ thời Xuân Thu",
      "type_stone": "6",
      "star": "4",
      "effect1": "[{\"clazz\":0,\"faction\":0,\"point\":[0,2000,100,270]},{\"clazz\":0,\"faction\":0,\"point\":[8,150,9,520]},{\"clazz\":0,\"faction\":0,\"point\":[6,170,9,340]},{\"clazz\":0,\"faction\":0,\"point\":[0,860,100,140,10,440]},{\"clazz\":0,\"faction\":0,\"point\":[0,860,100,140,6,210]},{\"clazz\":0,\"faction\":0,\"point\":[0,860,102,180,13,300]}]",
      "effect2": "[{\"clazz\":0,\"faction\":0,\"point\":[2,12000,102,330]},{\"clazz\":0,\"faction\":0,\"point\":[102,200,7,220]}]",
      "effect3": "[{\"clazz\":0,\"faction\":0,\"point\":[102,180,8,200]},{\"clazz\":0,\"faction\":0,\"point\":[2,9040,102,270,6,120]},{\"clazz\":0,\"faction\":0,\"point\":[102,180,3,90]}]",
      "upgrade_cost_gold": "27000000",
      "upgrade_cost_dust": "19000",
      "convert_cost_gold": "2400000",
      "convert_cost_dust": "950",
      "gem_retain_effect": "3500",
      "old_effect1": "2000 Công, 27% Công\r\n15% Chí mạng, 52% Sát thương chí mạng\r\n17% Chính xác, 34% Sát thương chí mạng\r\n860 Công, 14% Công, 44% Phá giáp\r\n860 Công,  14% Công, 21% Chính xác\r\n860 Công, 18% Máu, 30% Sát thương chuẩn",
      "old_effect2": "12000 Máu, 33% Máu\n20% Máu, 22% Chặn",
      "old_effect3": "18% Máu, 20% Chí mạng\n9040 Máu, 27%Máu, 12% Chính xác\n18.0% Máu, 90 Tốc độ\n"
    },
    {
      "id": "19",
      "name": "Amulet Magic",
      "desc": "Kiệt tác của Tôn Vũ thời Xuân Thu",
      "type_stone": "6",
      "star": "5",
      "effect1": "[{\"clazz\":0,\"faction\":0,\"point\":[0,2400,100,290]},{\"clazz\":0,\"faction\":0,\"point\":[8,170,9,520,100,20]},{\"clazz\":0,\"faction\":0,\"point\":[6,200,9,400]},{\"clazz\":0,\"faction\":0,\"point\":[0,980,100,160,10,440]},{\"clazz\":0,\"faction\":0,\"point\":[0,980,100,160,6,210]},{\"clazz\":0,\"faction\":0,\"point\":[0,980,102,200,13,300]}]",
      "effect2": "[{\"clazz\":0,\"faction\":0,\"point\":[2,14000,102,350]},{\"clazz\":0,\"faction\":0,\"point\":[102,230,7,230]}]",
      "effect3": "[{\"clazz\":0,\"faction\":0,\"point\":[102,210,8,200]},{\"clazz\":0,\"faction\":0,\"point\":[2,9510,102,280,6,150]},{\"clazz\":0,\"faction\":0,\"point\":[102,200,3,100]}]",
      "upgrade_cost_gold": "30000000",
      "upgrade_cost_dust": "23000",
      "convert_cost_gold": "3000000",
      "convert_cost_dust": "1166",
      "gem_retain_effect": "3600",
      "old_effect1": "2400 Công, 29% Công\r\n17% Chí mạng, 52% Sát thương chí mạng, 2% Công\r\n20% Chính xác, 40% Sát thương chí mạng\r\n980 Công, 16% Công, 44% Phá giáp\r\n980 Công, 16.0% Công, 21.0% Chính xác\r\n980 Công, 20% Máu, 30% Sát thương chuẩn",
      "old_effect2": "14000 Máu, 35% Máu\n23% Máu, 23% Chặn",
      "old_effect3": "21% Máu, 20% Chí mạng\n9510 Máu, 28%Máu, 15% Chính xác\n20.0% Máu, 100 Tốc độ"
    },
    {
      "id": "20",
      "name": "Amulet Magic",
      "desc": "Kiệt tác của Tôn Vũ thời Xuân Thu",
      "type_stone": "6",
      "star": "6",
      "effect1": "[{\"clazz\":0,\"faction\":0,\"point\":[0,2800,100,310]},{\"clazz\":0,\"faction\":0,\"point\":[8,200,9,520,100,40]},{\"clazz\":0,\"faction\":0,\"point\":[6,200,9,500]},{\"clazz\":0,\"faction\":0,\"point\":[0,1100,100,180,10,440]},{\"clazz\":0,\"faction\":0,\"point\":[0,1100,100,180,6,210]},{\"clazz\":0,\"faction\":0,\"point\":[0,1100,102,220,13,300]}]",
      "effect2": "[{\"clazz\":0,\"faction\":0,\"point\":[2,16000,102,370]},{\"clazz\":0,\"faction\":0,\"point\":[102,260,7,250]}]",
      "effect3": "[{\"clazz\":0,\"faction\":0,\"point\":[102,240,8,200]},{\"clazz\":0,\"faction\":0,\"point\":[2,10260,102,300,6,170]},{\"clazz\":0,\"faction\":0,\"point\":[102,230,3,100]}]",
      "upgrade_cost_gold": "0",
      "upgrade_cost_dust": "0",
      "convert_cost_gold": "3000000",
      "convert_cost_dust": "1425",
      "gem_retain_effect": "-1",
      "old_effect1": "2800 Công, 31% Công\r\n20% Chí mạng, 52% Sát thương chí mạng, 4% Công\r\n20% Chính xác, 50% Sát thương chí mạng\r\n1100 Công, 18% Công, 44% Phá giáp\r\n1100 Công, 18% Công, 21% Chính xác\r\n1100 Công, 22% Máu, 30% Sát thương chuẩn",
      "old_effect2": "16000 Máu, 37% Máu\n26% Máu, 25% Chặn",
      "old_effect3": "24% Máu, 20% Chí mạng\n10260 Máu, 30%Máu, 17% Chính xác\n23% Máu, 100 Tốc độ"
    }
  ]