import { useQuery } from "@apollo/client";
import { Grid } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { useSnackbar } from "notistack";
import React from "react";
import { QUERY_ONCHAIN_ITEMS } from "../../graphql/User/userQuery";
import LoadingSpinner from "../Misc/LoadingSpinner";
import ClaimItem from "./ClaimItem";


//let theme = createTheme();

// let theme = createTheme({
//     palette: {
//         primary: blue,
//         secondary: pink
//     },
// });

// theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme) => ({

}));





export default function ClaimFromGameToChain() {




    const { loading, error, data } = useQuery(QUERY_ONCHAIN_ITEMS);

    const items = data ? data.onchainableMaterials : null

    //console.log(items)

    // const client = useApolloClient()
    // const classes = useStyles()

    if (loading) return <LoadingSpinner />

    // let lastClaim, claimable, claimAmount, mataCollect, nextClaim;

    // if (data && data.me) {
    //     ({ lastClaim, claimable, claimAmount, mataCollect, nextClaim } = data.me)

    // }





    {/* <div style={{ marginTop: 24 }} /> */ }

    return items && items.length > 0 ?
        items.map(
            (item, index) => {
                //if (item.id == 600654) 
                //return //item.number ?
                    return <Grid item key={index}>
                        <ClaimItem item={item} />
                    </Grid>
                    //:
                    //null
            }
        )
        :
        null






}
