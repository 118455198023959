
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { Fragment } from 'react';
import { NETWORK_VERSION, WALLETCONNECT_PROVIDER } from '../../utils/constants';
import { getJWT, updateWeb3Provider, getWeb3 } from '../../utils/cryptoWallet';
import { useSnackbar } from "notistack";
import { isMobileBrowser } from '../../utils/utils';
import sessionData from '../../sessionData';

const useStyles = makeStyles((theme) => ({
    icon: {
        //width: 44,
        height: 36
    },
    caption: {
        marginTop: theme.spacing(1)
    }
}));

export default function LoginWalletConnectButton(friendRef) {

    const { enqueueSnackbar } = useSnackbar();

    let isMobile = isMobileBrowser()

    const classes = useStyles();

    const handleClickConnect = async () => {
        localStorage.removeItem("wallet_address")
        updateWeb3Provider(WALLETCONNECT_PROVIDER)
        try {
            const webb3 = getWeb3()
            const provider = webb3.currentProvider;
            await provider.enable()
            if (provider.chainId != NETWORK_VERSION) {
                enqueueSnackbar("Please change to BSC network", { variant: 'warning' });
                await provider.disconnect()
            } else {
                //console.log("howwwwwwwwwwwww")
                getJWT(friendRef)
            }
            
        } catch {

        }
    }

    return (

        <Fragment>

            {
              
                <Button variant="outlined" color="primary" fullWidth
                    startIcon={<img src="/images/walletconnect.png" className={classes.icon} alt='walletconnect' />}
                    onClick={handleClickConnect}>
                    WalletConnect
                </Button>

            }
        </Fragment >
    );
}




