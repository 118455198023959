import { useTheme } from '@emotion/react';
import { Grid, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useMemoryState } from '../../utils/memoryState';
import GridGem from '../Pet/GridGem';
import GridPet from '../Pet/GridPet';
import { PetFilterPanel, PetSortPanel } from '../Pet/PetFilterHandler';
import InventoryTab from '../User/InventoryTab';
import { BalanceBNB, BalanceMATA, BalanceNINO } from '../Wallet/Balance';


const useStyles = makeStyles((theme) => ({
    root: {

        margin: theme.spacing(2),
        paddingTop: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(2),
        },

        paddingBottom: theme.spacing(10),
        flex: 1,
    },

    token: {
        padding: theme.spacing(1),
        flex: 1,
        height: "100%",
    },

    toolbar: theme.mixins.toolbar,


}));


export function TokenBalance() {
    const classes = useStyles()

    return (
        <Grid container spacing={1}>
            < Grid item xs={6} sm={4}>
                <Paper className={classes.token} variant="outlined">
                    <BalanceBNB />
                </Paper>
            </Grid >
            {/* < Grid item style={{ flex: 1 }}>
                <Paper className={classes.token} variant="outlined">
                    <BalanceNFT />
                </Paper>
            </Grid > */}
            < Grid item xs={6} sm={4}>
                <Paper className={classes.token} variant="outlined">
                    <BalanceMATA />
                </Paper>
            </Grid >
            < Grid item style={{ flex: 1 }}>
                <Paper className={classes.token} variant="outlined">
                    <BalanceNINO />
                </Paper>
            </Grid >
        </Grid>
    )

}



const getFilterObject = (arrayConfig) => {
    let ret = {}
    arrayConfig.map(
        //item => ret[item.label] = false
        item => ret[item.id] = false
    )
    return ret
}

export default function InventoryView2({ handler }) {

    //const location = useLocation()

    const [tab, setTab] = useMemoryState('inventoryTab',0);

    const classes = useStyles()

    const theme = useTheme();

    // useEffect(
    //     () => {
    //         if (handler) {
    //             handler.setSale(2)
    //             handler.setFilterLifeStage({1: true, 2: true})
    //             handler.doUpdateChangeSort(1)
    //         }
    //     }
    //     , [location]);

    return (
        
        <Fragment>
            {tab == 0 && <PetFilterPanel handler={handler} isOnInvetoryPage={true} />}

            <div className={classes.root}>

                <TokenBalance />

                <PetSortPanel handler={handler} />

                {/* <div style={{ marginTop: 10 }} /> */}

                <InventoryTab
                    setTab={setTab}
                    tab={tab}

                    petComponent={
                        <GridPet
                            breedCount={handler.breedCount}
                            petClasses={handler.petClasses}
                            factions={handler.factions}
                            legendary={handler.legendary}
                            generations={handler.generations}
                            page={handler.page}
                            handleChangePage={handler.handleChangePage}
                            forSale={handler.sale}
                            sort={handler.sort}
                            genParts={handler.genParts}
                            lifeStages={handler.filterLifeStage}
                            myPet
                        />
                    }


                    gemComponent={
                        <GridGem
                            breedCount={handler.breedCount}
                            petClasses={handler.petClasses}
                            factions={handler.factions}
                            legendary={handler.legendary}
                            generations={handler.generations}
                            page={handler.page}
                            handleChangePage={handler.handleChangePage}
                            forSale={handler.sale}
                            sort={handler.sort}
                            genParts={handler.genParts}
                            lifeStages={handler.filterLifeStage}
                            myUserGems={true}
                            
                        />
                    }
                />

            </div >
        </Fragment>
    );

}
