import { Grid, Paper, Select, MenuItem, useMediaQuery, Drawer, Hidden, Button, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { Fragment, useState } from 'react';
import GridPet from '../Pet/GridPet';
import InventoryTab from '../User/InventoryTab';
import { BalanceBNB, BalanceMATA, BalanceNINO } from '../Wallet/Balance';
import { addURLParameter, removeURLParameterByName, updateURLParameter } from '../../utils/utils';
import { CLASSES, DRAWER_WIDTH, FACTIONS, GENERATIONS, LIFE_STAGES, LIFE_STAGE_ADULT, MAX_BREED_COUNT } from '../../utils/constants';
import { useMemoryState } from '../../utils/memoryState';
import { useTheme } from '@emotion/react';
import FilterPanel from '../Misc/FilterPanel';
import { accountRoutes } from '../../utils/accountRoutes';
import { NavLink } from 'react-router-dom';
import GridGem from '../Pet/GridGem';


const useStyles = makeStyles((theme) => ({
    root: {
        //padding: theme.spacing(12),
        //padding: theme.spacing(4),

        margin: theme.spacing(2),
        paddingTop: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(2),
        },

        paddingBottom: theme.spacing(10),
        flex: 1,
        // display: 'flex',
        //justifyContent: 'center'
        //overflowY: 'auto',
        // overflowX: 'hidden'
    },
    drawerPaper: {
        width: DRAWER_WIDTH,
        padding: theme.spacing(3),
        // overflowX: 'hidden'
        //paddingTop: theme.spacing(2)
        '&::-webkit-scrollbar': {
            width: 0,
        },
    },
    saleFilter: {

    },
    formControl: {
        margin: theme.spacing(2),
        marginLeft: 0,
        minWidth: 120,
    },
    token: {
        padding: theme.spacing(1),
        flex: 1,
        height: "100%",
    },
    name: {
        margin: theme.spacing(1),
        //padding: theme.spacing(3)
    },
    pet: {
        marginTop: theme.spacing(3)
    },
    page: {
        display: 'flex',
        marginTop: theme.spacing(4)
    },
    filterToolBar: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(5),
            marginRight: theme.spacing(5),
        },
        display: 'flex',
        height: 42,
        justifyContent: 'space-between'
    },
    toolbar: theme.mixins.toolbar,
    icon: {
        color: 'inherit',
    },

    nav: {
        //fontWeight: 900,
        //color: theme.palette.text.secondary,
        color: 'inherit',
        textDecoration: 'none'
    },
    //so anh current route cua react router cung duoc
    activeNav: {
        color: theme.palette.primary.main,
        //backgroundColor: theme.palette.primary.light,

    },
}));


export function TokenBalance() {
    const classes = useStyles()

    return (
        <Grid container spacing={1}>
            < Grid item xs={6} sm={4}>
                <Paper className={classes.token} variant="outlined">
                    <BalanceBNB />
                </Paper>
            </Grid >
            {/* < Grid item style={{ flex: 1 }}>
                <Paper className={classes.token} variant="outlined">
                    <BalanceNFT />
                </Paper>
            </Grid > */}
            < Grid item xs={6} sm={4}>
                <Paper className={classes.token} variant="outlined">
                    <BalanceMATA />
                </Paper>
            </Grid >
            < Grid item style={{ flex: 1 }}>
                <Paper className={classes.token} variant="outlined">
                    <BalanceNINO />
                </Paper>
            </Grid >
        </Grid>
    )

}

function SideMenu({ routes, activeNav = false }) {
    const classes = useStyles()
    return (

        <List style={{ paddingLeft: 0, paddingTop: 0, paddingBottom: 0 }}>
            {
                routes && routes.map((prop, key) => {
                    if (!prop || prop.disabled) return null
                    if (prop.redirect /* || key === 0 */) return null;
                    return (
                        <NavLink
                            to={prop.link}
                            activeClassName={activeNav ? classes.activeNav : classes.nav}
                            className={classes.nav}
                            key={key}>
                            <ListItem button
                                style={{ color: "inherit", paddingLeft: 0 }}
                            >
                                <ListItemIcon style={{ color: "inherit" }}>
                                    <prop.icon className={classes.icon} /></ListItemIcon>
                                <ListItemText primary={prop.sidebarName} />
                            </ListItem>
                        </NavLink>
                    );

                })
            }
        </List>

    )
}

const getFilterObject = (arrayConfig) => {
    let ret = {}
    arrayConfig.map(
        //item => ret[item.label] = false
        item => ret[item.id] = false
    )
    return ret
}

export default function InventoryView() {
    const classes = useStyles()

    const theme = useTheme();

    const queryParams = new URLSearchParams(window.location.search)

    const isSM = useMediaQuery(theme.breakpoints.down('sm'));

    const [filterClick, setFilterClick] = useState(false);

    var defaultPage = 1
    var defaultPart = []
    var defaultLegend = 0
    var defaultGen = getFilterObject(GENERATIONS)
    var defaultSort = { id: undefined, price: false, priceSetAt: undefined }
    var defaultSortValue = 2
    var defaultSale = 2
    var defaultFaction = getFilterObject(FACTIONS)
    var defaultClass = getFilterObject(CLASSES)
    var defaultBreed = [0, MAX_BREED_COUNT]
    var defaultAge = getFilterObject(LIFE_STAGES)
    defaultAge[LIFE_STAGE_ADULT] = true

    var paramPage = defaultPage
    var paramPart = defaultPart
    var paramLegend = defaultLegend
    var paramGen = defaultGen
    var paramSort = defaultSort
    var paramSortValue = defaultSortValue
    var paramSale = defaultSale
    var paramFaction = defaultFaction
    var paramClass = defaultClass
    var paramBreed = defaultBreed
    var paramAge = defaultAge

    for (const [key, value] of queryParams) {
        if (key == "page") {
            paramPage = value
        } else if (key == "part") {
            paramPart.push(value)
        } else if (key == "faction") {
            for (let i = 0; i < FACTIONS.length; i++) {
                if (value != FACTIONS[i].id) continue

                paramFaction[i + 1] = true
            }
        } else if (key == "class") {
            for (let i = 0; i < CLASSES.length; i++) {
                if (value != CLASSES[i].id) continue
                paramClass[i + 1] = true
            }
        } else if (key == "legend") {
            paramLegend = value
        } else if (key == "sale") {
            paramSale = value
        } else if (key == "sort") {
            paramSortValue = parseInt(value)

            if (value == 0) paramSort = { id: false, price: undefined, priceSetAt: undefined }
            if (value == 1) paramSort = { id: true, price: undefined, priceSetAt: undefined }
            if (value == 3) paramSort = { id: undefined, price: true, priceSetAt: undefined }
            if (value == 4) paramSort = { id: undefined, price: undefined, priceSetAt: true }
        } else if (key == "gen") {
            for (let i = 0; i < GENERATIONS.length; i++) {
                if (value != GENERATIONS[i].id) continue
                paramGen[i + 1] = true
            }
        } else if (key == "breed") {
            var arr = value.split("-")
            if (arr[0] && arr[1]) {
                paramBreed = [arr[0], arr[1]]
            }
        } else if (key == "age") {
            for (let i = 0; i < LIFE_STAGES.length; i++) {
                if (value != LIFE_STAGES[i].id) continue

                paramAge[i + 1] = true
            }
        }
    }

    // const [page, setPage] = useMemoryState('page', pageId);
    const [page, setPage] = useMemoryState('page', paramPage);

    //FILTER
    const [breedCount, setBreedCount] = useMemoryState('stars', paramBreed)

    const [legendary, setLegendary] = useMemoryState('legendary', paramLegend)

    const [pure, setPure] = useMemoryState('pure', 0)

    const [generations, setGenerations] = useMemoryState('generations', paramGen);

    const [sale, setSale] = useMemoryState('sale', paramSale);

    const [genParts, setGenParts] = useMemoryState('genParts', paramPart);

    const [factions, setFactions] = useMemoryState('factions', paramFaction);

    const [petClasses, setPetClasses] = useMemoryState('petClasses', paramClass);

    const [filterLifeStage, setFilterLifeStage] = useMemoryState('age', paramAge)

    //SORT
    const [sort, setSort] = useMemoryState('sort', paramSort);
    const [sortValue, setSortValue] = useMemoryState('sortValue', paramSortValue);

    const handleAddGenParts = (value) => {
        setPage(1)
        var newGenParts = [...genParts, value]
        if (!genParts.includes(value)) {
            setGenParts(newGenParts);
        }

        var newURL = updateURLParameter(window.location.href, "page", 1)
        newURL = removeURLParameterByName(newURL, "part")

        if (newGenParts.length > 0) {
            // newURL = updateURLParameter(window.location.href, "part", newGenParts[0])

            for (let i = 0; i < newGenParts.length; i++) {
                newURL = addURLParameter(newURL, "part", newGenParts[i])
            }

            window.history.replaceState('', '', newURL)
        }

        window.history.replaceState('', '', newURL)
    };

    const handleRemoveGenParts = (value) => {
        setPage(1)
        var arr = [...genParts]
        var index = arr.indexOf(value)
        if (index !== -1) {
            arr.splice(index, 1)
            setGenParts(arr);
        }

        var newURL = updateURLParameter(window.location.href, "page", 1)
        newURL = removeURLParameterByName(newURL, "part")

        if (arr.length > 0) {
            for (let i = 0; i < arr.length; i++) {
                newURL = addURLParameter(newURL, "part", arr[i])
            }
        }

        window.history.replaceState('', '', newURL)
    };

    const handleChangeFilterLifeStage = (event) => {
        setPage(1)
        var newFilterLifeStage = { ...filterLifeStage, [event.target.name]: event.target.checked }
        setFilterLifeStage(newFilterLifeStage);

        var newURL = updateURLParameter(window.location.href, "page", 1)
        newURL = removeURLParameterByName(newURL, "age")

        for (let i = 0; i < LIFE_STAGES.length; i++) {
            if (newFilterLifeStage[i + 1] == true) {
                newURL = addURLParameter(newURL, "age", LIFE_STAGES[i].id)
            }

        }

        window.history.replaceState('', '', newURL)
    };

    const handleChangeGenerations = (event) => {
        setPage(1)
        var newGenerations = { ...generations, [event.target.name]: event.target.checked }
        setGenerations(newGenerations);

        var newURL = updateURLParameter(window.location.href, "page", 1)
        newURL = removeURLParameterByName(newURL, "gen")

        for (let i = 0; i < GENERATIONS.length; i++) {
            if (newGenerations[i + 1] == true) {
                newURL = addURLParameter(newURL, "gen", GENERATIONS[i].id)
            }

        }

        window.history.replaceState('', '', newURL)
    };

    const handleChangeSale = (event) => {

        setPage(1)
        setSale(event.target.value)
        var newURL = updateURLParameter(window.location.href, "page", 1)
        newURL = updateURLParameter(newURL, "sale", event.target.value)
        window.history.replaceState('', '', newURL)
    };


    const handleChangeSort = (event) => {
        const value = event.target.value
        if (value === 0) setSort({ id: false, price: undefined, priceSetAt: undefined })
        if (value === 1) setSort({ id: true, price: undefined, priceSetAt: undefined })
        if (value === 2) setSort({ id: undefined, price: false, priceSetAt: undefined })
        if (value === 3) setSort({ id: undefined, price: true, priceSetAt: undefined })
        if (value === 4) setSort({ id: undefined, price: undefined, priceSetAt: true })
        setPage(1)
        setSortValue(value)
        var newURL = updateURLParameter(window.location.href, "page", 1)
        newURL = updateURLParameter(newURL, "sort", value)
        window.history.replaceState('', '', newURL)
    };

    const handleChangeFactions = (event) => {
        setPage(1)
        var newFactions = { ...factions, [event.target.name]: event.target.checked }
        setFactions(newFactions);

        var newURL = updateURLParameter(window.location.href, "page", 1)
        newURL = removeURLParameterByName(newURL, "faction")

        for (let i = 0; i < FACTIONS.length; i++) {
            if (newFactions[i + 1] == true) {
                newURL = addURLParameter(newURL, "faction", FACTIONS[i].id)
            }

        }

        window.history.replaceState('', '', newURL)

    };

    const handleChangeClasses = (event) => {
        setPage(1)
        var newClasses = { ...petClasses, [event.target.name]: event.target.checked }
        setPetClasses(newClasses);

        var newURL = updateURLParameter(window.location.href, "page", 1)
        newURL = removeURLParameterByName(newURL, "class")

        for (let i = 0; i < CLASSES.length; i++) {
            if (newClasses[i + 1] == true) {
                newURL = addURLParameter(newURL, "class", CLASSES[i].id)
            }

        }

        window.history.replaceState('', '', newURL)
    };

    const handleChangeLegendary = (value) => {
        setPage(1)
        setLegendary(value)
        var newURL = updateURLParameter(window.location.href, "page", 1)
        newURL = updateURLParameter(newURL, "legend", value)
        window.history.replaceState('', '', newURL)
    };

    const handleChangeBreed = (value) => {
        setPage(1)
        setBreedCount(value)
        var paramVal = value[0] + "-" + value[1]
        var newURL = updateURLParameter(window.location.href, "page", 1)
        newURL = updateURLParameter(newURL, "breed", paramVal)
        window.history.replaceState('', '', newURL)
    };

    const handleClear = () => {

        // setBreedCount([0, MAX_BREED_COUNT])

        // setLegendary(0)

        // setPure(0)

        // setFactions(getFilterObject(FACTIONS))

        // setPetClasses(getFilterObject(CLASSES))

        // setGenerations(getFilterObject(GENERATIONS))

        // setGenParts([])

        // setFilterLifeStage()

        setPage(defaultPage)
        setGenParts(defaultPart)
        setLegendary(defaultLegend)
        setGenerations(defaultGen)
        setSort(defaultSort)
        setSortValue(defaultSortValue)
        setSale(defaultSale)
        setFactions(defaultFaction)
        setPetClasses(defaultClass)
        setBreedCount(defaultBreed)
        setFilterLifeStage(defaultAge)
    }

    //----END FILTER


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        window.history.replaceState('', '', updateURLParameter(window.location.href, "page", newPage))
        // window.history.replaceState('', '', updateURLParameter(window.location.href, "param", "value"));
    };
    return (
        <Fragment>
            <Drawer
                classes={{ paper: classes.drawerPaper }}
                variant={isSM ? 'temporary' : 'persistent'}
                onClose={() => { setFilterClick(false) }}
                open={!isSM || filterClick}
            >
                {!isSM &&
                    <Fragment>
                        <div className={classes.toolbar} />
                        <SideMenu style routes={accountRoutes()} activeNav />
                    </Fragment>
                }

                <FilterPanel
                    factions={factions}
                    handleChangeFactions={handleChangeFactions}
                    petClasses={petClasses}
                    handleChangeClasses={handleChangeClasses}
                    legendary={legendary}
                    setLegendary={handleChangeLegendary}
                    pure={pure}
                    setPure={setPure}
                    breedCount={breedCount}
                    setBreedCount={handleChangeBreed}
                    generations={generations}
                    handleChangeGenerations={handleChangeGenerations}
                    handleClear={handleClear}
                    genParts={genParts}
                    handleAddGenParts={handleAddGenParts}
                    handleRemoveGenParts={handleRemoveGenParts}
                    filterLifeStage={filterLifeStage}
                    handleChangeFilterLifeStage={handleChangeFilterLifeStage}
                    isOnInvetoryPage={true}
                    isSM={isSM}
                />
            </Drawer>

            <div className={classes.root}>

                <TokenBalance />

                <div className={classes.filterToolBar}>
                    <Select
                        size='small'
                        value={sale}
                        onChange={handleChangeSale}
                        className={classes.saleFilter}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            getcontentanchorel: null
                        }}
                    >
                        <MenuItem value={2}>All</MenuItem>
                        <MenuItem value={1}>For sale</MenuItem>
                        <MenuItem value={0}>Not for sale</MenuItem>
                    </Select>

                    <Select
                        value={sortValue}
                        size='small'
                        onChange={handleChangeSort}
                        className={classes.saleFilter}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            getcontentanchorel: null
                        }}
                    >
                        <MenuItem value={4}>Latest</MenuItem>
                        <MenuItem value={3}>Highest Price</MenuItem>
                        <MenuItem value={2}>Lowest Price</MenuItem>
                        <MenuItem value={1}>Highest ID</MenuItem>
                        <MenuItem value={0}>Lowest ID</MenuItem>
                    </Select>

                    <Hidden only={['sm', 'md', 'lg', 'xl']}>
                        <Button
                            variant="outlined"
                            className={classes.saleFilter}
                            onClick={() => { setFilterClick(true) }}
                        >
                            FILTER
                        </Button>
                    </Hidden>
                </div>

                {/* <div style={{ marginTop: 10 }} /> */}

                <InventoryTab

                    petComponent1={
                        // <GridPet page={page} handleChangePage={handleChangePage} myPet />
                        <GridPet
                            //forSale={1}
                            //faction={factions[1] ? 1 : 0}
                            breedCount={breedCount}
                            petClasses={petClasses}
                            factions={factions}
                            legendary={legendary}
                            generations={generations}
                            page={page}
                            handleChangePage={handleChangePage}
                            forSale={sale}
                            sort={sort}
                            genParts={genParts}
                            lifeStages={filterLifeStage}
                            myPet={true}
                        />
                    }

               
                />

            </div >
        </Fragment>
    );

}
