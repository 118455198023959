import { useMutation } from '@apollo/client';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import React, { Fragment, useRef, useEffect, useState } from "react";
import { QUERY_ME, SETUP_ACCOUNT, SETUP_EMAIL, UPDATE_REF_ID, UPDATE_REF_ID_2 } from "../../graphql/User/userQuery";
import { validateEmail, validateUserName } from "../../utils/utils";
import { ReCaptcha } from 'react-recaptcha-v3'
import { useLocation } from "react-router-dom";

import { updateURLParameter } from '../../../src/utils/utils';


const useStyles = makeStyles((theme) => ({
    field: {
        margin: theme.spacing(2),
        width: "100%",
        [theme.breakpoints.up('sm')]: {

            width: '40ch'
        },
        marginLeft: 0,
    },

}));

function useMyQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function EmailPassBox({ component }) {
    let query = useMyQuery();

    const [open, setOpen] = React.useState(false)
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setError(null)
    };

    const [refID, setRefID] = useState(null)

    useEffect(() => {
        if (typeof (Storage) !== "undefined") {
            setRefID(localStorage.ref)
        }
    }, []);

    const [captchaToken, setCapchaToken] = React.useState(null)
    const [error, setError] = React.useState(null)
    const [setUpEmail] = useMutation(
        SETUP_ACCOUNT,
        {
            onError: setError,
            onCompleted: () => {
                handleClose()
                if (typeof (Storage) !== "undefined") {
                    var targetDate = new Date();
                    targetDate.setMinutes(targetDate.getMinutes() + 2);
                    localStorage.nextTimeSetUpEmail = targetDate.getTime()
                }
            },
            refetchQueries: [
                { query: QUERY_ME }
            ],
            context: {
                headers: {
                    "captcha-token": captchaToken
                }
            }
        }
    );

    const [updateRef] = useMutation(
        UPDATE_REF_ID,
        {
            onError: setError,
            onCompleted: () => { },
            refetchQueries: [
                { query: QUERY_ME }
            ],
            context: {
                headers: {
                    "captcha-token": captchaToken
                }
            }
        }
    );

    const [updateRefForEvent] = useMutation(
        UPDATE_REF_ID_2,
        {
            onError: setError,
            onCompleted: () => { },
            refetchQueries: [
                { query: QUERY_ME }
            ],
            context: {
                headers: {
                    "captcha-token": captchaToken
                }
            }
        }
    );

    const emailRef = useRef()
    const passwordRef = useRef()
    const retypePasswordRef = useRef()
    const userRef = useRef()

    const handleUpdate = () => {

        if (!emailRef || !emailRef.current ||
            !passwordRef || !passwordRef.current ||
            !retypePasswordRef || !retypePasswordRef.current
        ) return

        const email = emailRef.current
        const password = passwordRef.current
        const retypePassword = retypePasswordRef.current
        const friendId = userRef.current.value

        if (!validateUserName(email.value)) {
            setError({ message: "Username should not contain special characters!" })
            return
        }

        if (password.value !== retypePassword.value) {
            setError({ message: "Passwords do not match!" })
            return
        }

        console.log(email.value)

        setUpEmail({
            variables: {
                username: email.value,
                pass: password.value,
            }
        })
            .catch(console.log)

        // console.log(friendId)

        updateRef({
            variables: {
                refId: friendId
            }
        })
            .catch(console.log)

        updateRefForEvent({
            variables: {
                refId: friendId
            }
        })
            .catch(console.log)

        if (typeof (Storage) !== "undefined") {
            localStorage.ref = friendId
        }
    };


    const classes = useStyles()

    return (
        <Fragment>
            {
                React.cloneElement(
                    component,
                    { onClick: handleOpen }
                )
            }
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Setup Account"}</DialogTitle>
                <DialogContent>
                    <Box component="form">
                        <div ><TextField className={classes.field} inputRef={emailRef} label="User name" type="text" /></div>
                        <div><TextField className={classes.field} inputRef={passwordRef} label="Password" type="password" /></div>
                        <div><TextField className={classes.field} inputRef={retypePasswordRef} label="Retype password" type="password" /> </div>
                        <div><TextField className={classes.field} inputRef={userRef} label="Referral ID" defaultValue={refID} /> </div>

                        <div className={classes.field}><Typography color="error" variant="subtitle2">{error ? error.message : ""}</Typography> </div>

                        <Typography className={classes.field} color="textSecondary" variant="body2">This site is protected by reCAPTCHA and the Google
                            <a href="https://policies.google.com/privacy"> Privacy Policy</a> and
                            <a href="https://policies.google.com/terms"> Terms of Service</a> apply.
                        </Typography>

                    </Box >

                </DialogContent>
                <DialogActions>
                    <ReCaptcha
                        sitekey="6LdvLeobAAAAAGgaVdDQx-nSXJZ_bdih1G-4h6L7"
                        action='VerifyEmail'
                        verifyCallback={(capchaToken) => {
                            setCapchaToken(capchaToken)
                            // console.log(`captcha-token: ${capchaToken}`)
                        }}
                    />
                    <Button onClick={handleUpdate} variant="contained" color="primary">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );


}
