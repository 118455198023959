export const db_gen = [{
  "mPart": "ear",
  "genID": "1",
  "mClass": "ranger",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "ear_left",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "23",
          "y": "1",
          "img": "c1_2"
      }, {
          "x": "27",
          "y": "48",
          "img": "colr"
      }, {
          "x": "28",
          "y": "5",
          "img": "wt"
      }, {
          "x": "38",
          "y": "11",
          "img": "c2"
      }, {
          "x": "24",
          "y": "0",
          "img": "line_3"
      }]
  }, {
      "name": "ear_right",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "54",
          "y": "5",
          "img": "c2_4"
      }, {
          "x": "52",
          "y": "9",
          "img": "c1_8"
      }, {
          "x": "52",
          "y": "-31",
          "img": "line_14"
      }, {
          "x": "93",
          "y": "-29",
          "img": "c13"
      }]
  }]
}, {
  "mPart": "hat",
  "genID": "10",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "hat",
      "width": "242",
      "height": "151",
      "lstLayerImage": [{
          "x": "42",
          "y": "0",
          "img": "c2_1"
      }, {
          "x": "28",
          "y": "2",
          "img": "line_6"
      }, {
          "x": "37",
          "y": "4",
          "img": "tocthuong"
      }, {
          "x": "37",
          "y": "4",
          "img": "thuong"
      }, {
          "x": "41",
          "y": "31",
          "img": "c4_1"
      }, {
          "x": "58",
          "y": "52",
          "img": "line_5"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "100",
  "mClass": "warrior",
  "mType": "normal",
  "poseID": "3",
  "lstGroupLayer": [{
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "70",
          "y": "1",
          "img": "line_2"
      }, {
          "x": "74",
          "y": "8",
          "img": "Layer 101 copy"
      }, {
          "x": "181",
          "y": "66",
          "img": "c4"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "101",
  "mClass": "warrior",
  "mType": "normal",
  "poseID": "3",
  "lstGroupLayer": [{
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "85",
          "y": "47",
          "img": "line_2"
      }, {
          "x": "90",
          "y": "50",
          "img": "kien samurai copy"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "102",
  "mClass": "mage",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "weapon_back",
      "width": "428",
      "height": "393",
      "lstLayerImage": [{
          "x": "7",
          "y": "94",
          "img": "line_18"
      }, {
          "x": "50",
          "y": "97",
          "img": "co vua "
      }, {
          "x": "14",
          "y": "140",
          "img": "c4_5"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "103",
  "mClass": "mage",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "weapon_back",
      "width": "428",
      "height": "393",
      "lstLayerImage": [{
          "x": "11",
          "y": "80",
          "img": "line_18"
      }, {
          "x": "15",
          "y": "84",
          "img": "dan "
      }, {
          "x": "60",
          "y": "105",
          "img": "c4_5"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "104",
  "mClass": "mage",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "weapon_back",
      "width": "428",
      "height": "393",
      "lstLayerImage": [{
          "x": "9",
          "y": "114",
          "img": "bon cau "
      }, {
          "x": "6",
          "y": "111",
          "img": "line_18"
      }, {
          "x": "67",
          "y": "197",
          "img": "c4_5"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "105",
  "mClass": "mage",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "weapon_back",
      "width": "428",
      "height": "393",
      "lstLayerImage": [{
          "x": "13",
          "y": "95",
          "img": "phao"
      }, {
          "x": "10",
          "y": "92",
          "img": "line_18"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "106",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "2",
  "lstGroupLayer": [{
      "name": "weapon_right",
      "width": "428",
      "height": "219",
      "lstLayerImage": [{
          "x": "171",
          "y": "46",
          "img": "bao tay "
      }, {
          "x": "168",
          "y": "43",
          "img": "line_16"
      }]
  }, {
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "138",
          "y": "33",
          "img": "bao tay"
      }, {
          "x": "135",
          "y": "30",
          "img": "line_2"
      }, {
          "x": "190",
          "y": "66",
          "img": "c4"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "107",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "weapon_right",
      "width": "428",
      "height": "219",
      "lstLayerImage": [{
          "x": "117",
          "y": "65",
          "img": "line "
      }, {
          "x": "121",
          "y": "69",
          "img": "gay bong chay  "
      }, {
          "x": "313",
          "y": "86",
          "img": "c4 "
      }]
  }, {
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "102",
          "y": "54",
          "img": "line_2"
      }, {
          "x": "106",
          "y": "58",
          "img": "gay bong chay "
      }, {
          "x": "297",
          "y": "76",
          "img": "c4"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "108",
  "mClass": "warrior",
  "mType": "normal",
  "poseID": "3",
  "lstGroupLayer": [{
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "85",
          "y": "64",
          "img": "line_2"
      }, {
          "x": "155",
          "y": "51",
          "img": "c4"
      }, {
          "x": "88",
          "y": "68",
          "img": "handle"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "109",
  "mClass": "warrior",
  "mType": "normal",
  "poseID": "3",
  "lstGroupLayer": [{
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "9",
          "y": "66",
          "img": "line_2"
      }, {
          "x": "22",
          "y": "70",
          "img": "c4"
      }, {
          "x": "14",
          "y": "66",
          "img": "gold"
      }, {
          "x": "96",
          "y": "78",
          "img": "staff"
      }]
  }]
}, {
  "mPart": "tail",
  "genID": "11",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "tail",
      "width": "198",
      "height": "123",
      "lstLayerImage": [{
          "x": "24",
          "y": "31",
          "img": "line_17"
      }, {
          "x": "33",
          "y": "36",
          "img": "c2_7"
      }, {
          "x": "34",
          "y": "53",
          "img": "c1_9"
      }, {
          "x": "34",
          "y": "47",
          "img": "duoidoi"
      }]
  }]
}, {
  "mPart": "ear",
  "genID": "110",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "ear_left",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "4",
          "y": "22",
          "img": "line_3"
      }, {
          "x": "9",
          "y": "26",
          "img": "Layer 136"
      }, {
          "x": "22",
          "y": "26",
          "img": "c1_2"
      }, {
          "x": "22",
          "y": "43",
          "img": "c3"
      }, {
          "x": "4",
          "y": "31",
          "img": "line_2"
      }]
  }, {
      "name": "ear_right",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "53",
          "y": "22",
          "img": "line_14"
      }, {
          "x": "66",
          "y": "26",
          "img": "Layer 136 copy"
      }, {
          "x": "52",
          "y": "26",
          "img": "c1_8"
      }, {
          "x": "82",
          "y": "43",
          "img": "c3_2"
      }, {
          "x": "88",
          "y": "31",
          "img": "line_13"
      }]
  }]
}, {
  "mPart": "ear",
  "genID": "111",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "ear_left",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "0",
          "y": "23",
          "img": "line_2"
      }, {
          "x": "4",
          "y": "27",
          "img": "c1_2"
      }, {
          "x": "20",
          "y": "52",
          "img": "c3"
      }, {
          "x": "4",
          "y": "28",
          "img": "c2"
      }, {
          "x": "4",
          "y": "33",
          "img": "Layer 134"
      }]
  }, {
      "name": "ear_right",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "64",
          "y": "22",
          "img": "line_13"
      }, {
          "x": "68",
          "y": "26",
          "img": "c1_8"
      }, {
          "x": "68",
          "y": "57",
          "img": "c3_2"
      }, {
          "x": "70",
          "y": "26",
          "img": "Layer 138"
      }, {
          "x": "103",
          "y": "41",
          "img": "line_12"
      }]
  }]
}, {
  "mPart": "ear",
  "genID": "112",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "ear_left",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "2",
          "y": "8",
          "img": "line_3"
      }, {
          "x": "7",
          "y": "10",
          "img": "c1_2"
      }, {
          "x": "10",
          "y": "16",
          "img": "c4"
      }, {
          "x": "7",
          "y": "21",
          "img": "c2"
      }, {
          "x": "7",
          "y": "21",
          "img": "c3"
      }, {
          "x": "7",
          "y": "71",
          "img": "Layer 133 copy"
      }, {
          "x": "6",
          "y": "22",
          "img": "line_2"
      }]
  }, {
      "name": "ear_right",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "61",
          "y": "8",
          "img": "line_12"
      }, {
          "x": "64",
          "y": "10",
          "img": "c1_8"
      }, {
          "x": "77",
          "y": "16",
          "img": "c4_4"
      }, {
          "x": "64",
          "y": "21",
          "img": "c2_4"
      }, {
          "x": "64",
          "y": "21",
          "img": "c3_2"
      }, {
          "x": "68",
          "y": "71",
          "img": "Layer 133 copy 2"
      }, {
          "x": "85",
          "y": "22",
          "img": "line_11"
      }]
  }]
}, {
  "mPart": "ear",
  "genID": "113",
  "mClass": "mage",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "ear_left",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "12",
          "y": "8",
          "img": "line_2"
      }, {
          "x": "16",
          "y": "12",
          "img": "c1_2"
      }, {
          "x": "33",
          "y": "46",
          "img": "c3"
      }, {
          "x": "25",
          "y": "23",
          "img": "c2"
      }, {
          "x": "21",
          "y": "13",
          "img": "c4"
      }]
  }, {
      "name": "ear_right",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "63",
          "y": "8",
          "img": "line_10"
      }, {
          "x": "64",
          "y": "12",
          "img": "c1_8"
      }, {
          "x": "64",
          "y": "46",
          "img": "c3_2"
      }, {
          "x": "79",
          "y": "23",
          "img": "c2_4"
      }, {
          "x": "72",
          "y": "13",
          "img": "c4_4"
      }]
  }]
}, {
  "mPart": "ear",
  "genID": "114",
  "mClass": "mage",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "ear_left",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "1",
          "y": "22",
          "img": "line_2"
      }, {
          "x": "5",
          "y": "26",
          "img": "c1_2"
      }, {
          "x": "4",
          "y": "25",
          "img": "c2"
      }, {
          "x": "14",
          "y": "78",
          "img": "c3"
      }, {
          "x": "7",
          "y": "37",
          "img": "c4"
      }]
  }, {
      "name": "ear_right",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "59",
          "y": "22",
          "img": "line_10"
      }, {
          "x": "61",
          "y": "26",
          "img": "c1_8"
      }, {
          "x": "75",
          "y": "25",
          "img": "c2_4"
      }, {
          "x": "67",
          "y": "78",
          "img": "c3_2"
      }, {
          "x": "69",
          "y": "37",
          "img": "c4_4"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "115",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "128",
          "y": "63",
          "img": "line_2"
      }, {
          "x": "161",
          "y": "69",
          "img": "c4"
      }, {
          "x": "131",
          "y": "67",
          "img": "ddcon"
      }]
  }, {
      "name": "weapon_right",
      "width": "428",
      "height": "219",
      "lstLayerImage": [{
          "x": "125",
          "y": "73",
          "img": "line "
      }, {
          "x": "157",
          "y": "78",
          "img": "c4 "
      }, {
          "x": "129",
          "y": "77",
          "img": "con right"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "116",
  "mClass": "mage",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "weapon_back",
      "width": "428",
      "height": "393",
      "lstLayerImage": [{
          "x": "48",
          "y": "95",
          "img": "line_18"
      }, {
          "x": "52",
          "y": "100",
          "img": "mai rua"
      }, {
          "x": "30",
          "y": "203",
          "img": "line_17"
      }, {
          "x": "33",
          "y": "206",
          "img": "snail"
      }, {
          "x": "37",
          "y": "207",
          "img": "c4_1"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "117",
  "mClass": "mage",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "weapon_back",
      "width": "428",
      "height": "393",
      "lstLayerImage": [{
          "x": "4",
          "y": "109",
          "img": "line "
      }, {
          "x": "8",
          "y": "113",
          "img": "wing"
      }, {
          "x": "66",
          "y": "218",
          "img": "c4 "
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "118",
  "mClass": "mage",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "weapon_back",
      "width": "428",
      "height": "393",
      "lstLayerImage": [{
          "x": "47",
          "y": "107",
          "img": "line_17"
      }, {
          "x": "51",
          "y": "77",
          "img": "bee hive "
      }, {
          "x": "74",
          "y": "146",
          "img": "honey"
      }]
  }]
}, {
  "mPart": "tail",
  "genID": "119",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "tail",
      "width": "198",
      "height": "123",
      "lstLayerImage": [{
          "x": "5",
          "y": "3",
          "img": "line_15"
      }, {
          "x": "12",
          "y": "7",
          "img": "Layer 94 copy"
      }, {
          "x": "42",
          "y": "8",
          "img": "c4_5"
      }, {
          "x": "35",
          "y": "12",
          "img": "Layer 95"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "12",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "weapon_right",
      "width": "428",
      "height": "219",
      "lstLayerImage": [{
          "x": "139",
          "y": "57",
          "img": "line_18"
      }, {
          "x": "144",
          "y": "61",
          "img": "Layer 77 copy 3"
      }, {
          "x": "144",
          "y": "69",
          "img": "c4_5"
      }]
  }, {
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "99",
          "y": "61",
          "img": "line_2"
      }, {
          "x": "104",
          "y": "65",
          "img": "Layer 77 copy 2"
      }, {
          "x": "104",
          "y": "73",
          "img": "c4"
      }]
  }]
}, {
  "mPart": "tail",
  "genID": "120",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "tail",
      "width": "198",
      "height": "123",
      "lstLayerImage": [{
          "x": "12",
          "y": "18",
          "img": "line_14"
      }, {
          "x": "17",
          "y": "22",
          "img": "c1_10"
      }, {
          "x": "26",
          "y": "33",
          "img": "c3_4"
      }, {
          "x": "17",
          "y": "22",
          "img": "c2_7"
      }]
  }]
}, {
  "mPart": "tail",
  "genID": "121",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "tail",
      "width": "198",
      "height": "123",
      "lstLayerImage": [{
          "x": "3",
          "y": "27",
          "img": "line_14"
      }, {
          "x": "7",
          "y": "33",
          "img": "c1_10"
      }, {
          "x": "6",
          "y": "43",
          "img": "c3_4"
      }, {
          "x": "7",
          "y": "30",
          "img": "c2_7"
      }, {
          "x": "6",
          "y": "30",
          "img": "vien den"
      }]
  }]
}, {
  "mPart": "tail",
  "genID": "122",
  "mClass": "mage",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "tail",
      "width": "198",
      "height": "123",
      "lstLayerImage": [{
          "x": "21",
          "y": "18",
          "img": "line_15"
      }, {
          "x": "29",
          "y": "23",
          "img": "c1_9"
      }, {
          "x": "38",
          "y": "35",
          "img": "c3_1"
      }, {
          "x": "28",
          "y": "22",
          "img": "c2_5"
      }, {
          "x": "46",
          "y": "37",
          "img": "line_14"
      }]
  }]
}, {
  "mPart": "tail",
  "genID": "123",
  "mClass": "mage",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "tail",
      "width": "198",
      "height": "123",
      "lstLayerImage": [{
          "x": "17",
          "y": "2",
          "img": "line_14"
      }, {
          "x": "22",
          "y": "6",
          "img": "duoi ngua "
      }]
  }]
}, {
  "mPart": "tail",
  "genID": "124",
  "mClass": "warrior",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "tail",
      "width": "198",
      "height": "123",
      "lstLayerImage": [{
          "x": "12",
          "y": "3",
          "img": "line_20"
      }, {
          "x": "17",
          "y": "7",
          "img": "duoi ga"
      }]
  }]
}, {
  "mPart": "tail",
  "genID": "125",
  "mClass": "warrior",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "tail",
      "width": "198",
      "height": "123",
      "lstLayerImage": [{
          "x": "8",
          "y": "10",
          "img": "line_20"
      }, {
          "x": "14",
          "y": "14",
          "img": "than "
      }, {
          "x": "11",
          "y": "56",
          "img": "c3_2"
      }, {
          "x": "16",
          "y": "70",
          "img": "c1_9"
      }, {
          "x": "29",
          "y": "95",
          "img": "trang"
      }, {
          "x": "11",
          "y": "56",
          "img": "c2_4"
      }, {
          "x": "69",
          "y": "72",
          "img": "c4_4"
      }]
  }]
}, {
  "mPart": "tail",
  "genID": "126",
  "mClass": "warrior",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "tail",
      "width": "198",
      "height": "123",
      "lstLayerImage": [{
          "x": "9",
          "y": "15",
          "img": "line_20"
      }, {
          "x": "13",
          "y": "20",
          "img": "c1_9"
      }, {
          "x": "21",
          "y": "35",
          "img": "c3_2"
      }, {
          "x": "13",
          "y": "21",
          "img": "c2_4"
      }, {
          "x": "17",
          "y": "19",
          "img": "dom trang"
      }]
  }]
}, {
  "mPart": "tail",
  "genID": "127",
  "mClass": "mage",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "tail",
      "width": "198",
      "height": "123",
      "lstLayerImage": [{
          "x": "7",
          "y": "3",
          "img": "line_14"
      }, {
          "x": "10",
          "y": "8",
          "img": "la phong"
      }]
  }]
}, {
  "mPart": "mouth",
  "genID": "128",
  "mClass": "mage",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "mouth",
      "width": "41",
      "height": "116",
      "lstLayerImage": [{
          "x": "1",
          "y": "45",
          "img": "line_7"
      }, {
          "x": "3",
          "y": "47",
          "img": "mounth"
      }, {
          "x": "9",
          "y": "50",
          "img": "line_6"
      }, {
          "x": "11",
          "y": "52",
          "img": "teeth "
      }]
  }]
}, {
  "mPart": "mouth",
  "genID": "129",
  "mClass": "mage",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "mouth",
      "width": "41",
      "height": "116",
      "lstLayerImage": [{
          "x": "8",
          "y": "42",
          "img": "line_6"
      }]
  }]
}, {
  "mPart": "ear",
  "genID": "13",
  "mClass": "mage",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "ear_left",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "3",
          "y": "23",
          "img": "c1_2"
      }, {
          "x": "6",
          "y": "23",
          "img": "c2"
      }, {
          "x": "6",
          "y": "46",
          "img": "c3"
      }, {
          "x": "4",
          "y": "29",
          "img": "c4"
      }, {
          "x": "2",
          "y": "19",
          "img": "line_2"
      }]
  }, {
      "name": "ear_right",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "44",
          "y": "23",
          "img": "c1_8"
      }, {
          "x": "70",
          "y": "23",
          "img": "c2_5"
      }, {
          "x": "49",
          "y": "46",
          "img": "c3_2"
      }, {
          "x": "73",
          "y": "29",
          "img": "c4_3"
      }, {
          "x": "43",
          "y": "19",
          "img": "line_14"
      }]
  }]
}, {
  "mPart": "mouth",
  "genID": "130",
  "mClass": "support",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "mouth",
      "width": "41",
      "height": "116",
      "lstLayerImage": [{
          "x": "0",
          "y": "35",
          "img": "mounth"
      }, {
          "x": "-1",
          "y": "34",
          "img": "line_8"
      }, {
          "x": "11",
          "y": "49",
          "img": "nose"
      }]
  }]
}, {
  "mPart": "mouth",
  "genID": "131",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "mouth",
      "width": "41",
      "height": "116",
      "lstLayerImage": [{
          "x": "3",
          "y": "36",
          "img": "Layer 112"
      }, {
          "x": "9",
          "y": "41",
          "img": "line_12"
      }, {
          "x": "10",
          "y": "42",
          "img": "Layer 113"
      }, {
          "x": "9",
          "y": "32",
          "img": "line_11"
      }, {
          "x": "11",
          "y": "34",
          "img": "Layer 115"
      }, {
          "x": "17",
          "y": "41",
          "img": "line_10"
      }, {
          "x": "19",
          "y": "43",
          "img": "Layer 114"
      }, {
          "x": "1",
          "y": "34",
          "img": "line_9"
      }]
  }]
}, {
  "mPart": "mouth",
  "genID": "132",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "mouth",
      "width": "41",
      "height": "116",
      "lstLayerImage": [{
          "x": "4",
          "y": "14",
          "img": "line_9"
      }, {
          "x": "8",
          "y": "17",
          "img": "c4_4"
      }]
  }]
}, {
  "mPart": "mouth",
  "genID": "133",
  "mClass": "mage",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "mouth",
      "width": "41",
      "height": "116",
      "lstLayerImage": [{
          "x": "13",
          "y": "41",
          "img": "line_7"
      }, {
          "x": "15",
          "y": "43",
          "img": "tounge"
      }, {
          "x": "9",
          "y": "52",
          "img": "line_6"
      }]
  }]
}, {
  "mPart": "mouth",
  "genID": "134",
  "mClass": "warrior",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "mouth",
      "width": "41",
      "height": "116",
      "lstLayerImage": [{
          "x": "14",
          "y": "42",
          "img": "line_13"
      }, {
          "x": "1",
          "y": "43",
          "img": "Layer 104"
      }]
  }]
}, {
  "mPart": "mouth",
  "genID": "135",
  "mClass": "warrior",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "mouth",
      "width": "41",
      "height": "116",
      "lstLayerImage": [{
          "x": "1",
          "y": "34",
          "img": "line_13"
      }, {
          "x": "3",
          "y": "36",
          "img": "nose"
      }]
  }]
}, {
  "mPart": "mouth",
  "genID": "136",
  "mClass": "warrior",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "mouth",
      "width": "41",
      "height": "116",
      "lstLayerImage": [{
          "x": "1",
          "y": "35",
          "img": "Layer 103 copy"
      }, {
          "x": "2",
          "y": "37",
          "img": "Layer 103"
      }]
  }]
}, {
  "mPart": "mouth",
  "genID": "137",
  "mClass": "warrior",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "mouth",
      "width": "41",
      "height": "116",
      "lstLayerImage": [{
          "x": "2",
          "y": "38",
          "img": "line_13"
      }]
  }]
}, {
  "mPart": "mouth",
  "genID": "138",
  "mClass": "support",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "mouth",
      "width": "41",
      "height": "116",
      "lstLayerImage": [{
          "x": "1",
          "y": "26",
          "img": "line_9"
      }, {
          "x": "4",
          "y": "29",
          "img": "Layer 1"
      }, {
          "x": "24",
          "y": "51",
          "img": "line_8"
      }, {
          "x": "24",
          "y": "52",
          "img": "Layer 2"
      }]
  }]
}, {
  "mPart": "mouth",
  "genID": "139",
  "mClass": "support",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "mouth",
      "width": "41",
      "height": "116",
      "lstLayerImage": [{
          "x": "2",
          "y": "31",
          "img": "line_8"
      }, {
          "x": "4",
          "y": "41",
          "img": "Layer 1"
      }, {
          "x": "7",
          "y": "34",
          "img": "Layer 2"
      }]
  }]
}, {
  "mPart": "eye",
  "genID": "14",
  "mClass": "mage",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "eye_base",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "83",
          "y": "39",
          "img": "base"
      }]
  }, {
      "name": "eye_cheek",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "36",
          "y": "38",
          "img": "c2_3"
      }, {
          "x": "0",
          "y": "38",
          "img": "line_7"
      }, {
          "x": "6",
          "y": "41",
          "img": "c4_1"
      }]
  }, {
      "name": "eye_dot_left",
      "width": "90",
      "height": "82",
      "lstLayerImage": [{
          "x": "36",
          "y": "34",
          "img": "d_1"
      }]
  }, {
      "name": "eye_dot_right",
      "width": "89",
      "height": "82",
      "lstLayerImage": [{
          "x": "36",
          "y": "34",
          "img": "d"
      }]
  }, {
      "name": "eye_line",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "67",
          "y": "35",
          "img": "c2_2"
      }, {
          "x": "76",
          "y": "39",
          "img": "line_6"
      }]
  }, {
      "name": "eye_mask",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "90",
          "y": "48",
          "img": "line_5"
      }, {
          "x": "92",
          "y": "50",
          "img": "kinh"
      }, {
          "x": "100",
          "y": "73",
          "img": "Layer 96"
      }]
  }]
}, {
  "mPart": "mouth",
  "genID": "140",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "mouth",
      "width": "41",
      "height": "116",
      "lstLayerImage": [{
          "x": "4",
          "y": "33",
          "img": "line_9"
      }]
  }]
}, {
  "mPart": "mouth",
  "genID": "141",
  "mClass": "mage",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "mouth",
      "width": "41",
      "height": "116",
      "lstLayerImage": [{
          "x": "5",
          "y": "33",
          "img": "line_6"
      }, {
          "x": "8",
          "y": "36",
          "img": "mouth_1"
      }]
  }]
}, {
  "mPart": "mouth",
  "genID": "142",
  "mClass": "support",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "mouth",
      "width": "41",
      "height": "116",
      "lstLayerImage": [{
          "x": "-1",
          "y": "34",
          "img": "line_8"
      }, {
          "x": "1",
          "y": "35",
          "img": "Layer 1"
      }]
  }]
}, {
  "mPart": "mouth",
  "genID": "143",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "mouth",
      "width": "41",
      "height": "116",
      "lstLayerImage": [{
          "x": "13",
          "y": "-46",
          "img": "c4_4"
      }, {
          "x": "6",
          "y": "37",
          "img": "line_9"
      }]
  }]
}, {
  "mPart": "mouth",
  "genID": "144",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "mouth",
      "width": "41",
      "height": "116",
      "lstLayerImage": [{
          "x": "13",
          "y": "-46",
          "img": "c4_4"
      }, {
          "x": "6",
          "y": "42",
          "img": "line_9"
      }]
  }]
}, {
  "mPart": "mouth",
  "genID": "145",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "mouth",
      "width": "41",
      "height": "116",
      "lstLayerImage": [{
          "x": "1",
          "y": "36",
          "img": "Layer 113"
      }, {
          "x": "8",
          "y": "31",
          "img": "line_9"
      }]
  }]
}, {
  "mPart": "mouth",
  "genID": "146",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "mouth",
      "width": "41",
      "height": "116",
      "lstLayerImage": [{
          "x": "0",
          "y": "30",
          "img": "line_9"
      }, {
          "x": "1",
          "y": "32",
          "img": "mouth_1"
      }]
  }]
}, {
  "mPart": "tail",
  "genID": "147",
  "mClass": "warrior",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "tail",
      "width": "198",
      "height": "123",
      "lstLayerImage": [{
          "x": "7",
          "y": "7",
          "img": "line_20"
      }, {
          "x": "18",
          "y": "11",
          "img": "Layer 106"
      }, {
          "x": "16",
          "y": "10",
          "img": "Layer 105"
      }]
  }]
}, {
  "mPart": "tail",
  "genID": "148",
  "mClass": "support",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "tail",
      "width": "198",
      "height": "123",
      "lstLayerImage": [{
          "x": "12",
          "y": "16",
          "img": "line_13"
      }, {
          "x": "16",
          "y": "20",
          "img": "duoi soc"
      }]
  }]
}, {
  "mPart": "tail",
  "genID": "149",
  "mClass": "support",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "tail",
      "width": "198",
      "height": "123",
      "lstLayerImage": [{
          "x": "25",
          "y": "5",
          "img": "line_13"
      }, {
          "x": "29",
          "y": "9",
          "img": "duoi "
      }]
  }]
}, {
  "mPart": "mouth",
  "genID": "15",
  "mClass": "mage",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "mouth",
      "width": "41",
      "height": "116",
      "lstLayerImage": [{
          "x": "0",
          "y": "39",
          "img": "line_8"
      }, {
          "x": "1",
          "y": "42",
          "img": "Layer 93"
      }, {
          "x": "10",
          "y": "52",
          "img": "c4_2"
      }]
  }]
}, {
  "mPart": "tail",
  "genID": "150",
  "mClass": "support",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "tail",
      "width": "198",
      "height": "123",
      "lstLayerImage": [{
          "x": "9",
          "y": "11",
          "img": "line_13"
      }, {
          "x": "11",
          "y": "16",
          "img": "ca sau"
      }]
  }]
}, {
  "mPart": "tail",
  "genID": "151",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "tail",
      "width": "198",
      "height": "123",
      "lstLayerImage": [{
          "x": "31",
          "y": "6",
          "img": "line_15"
      }, {
          "x": "35",
          "y": "9",
          "img": "duoi ong "
      }]
  }]
}, {
  "mPart": "tail",
  "genID": "152",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "tail",
      "width": "198",
      "height": "123",
      "lstLayerImage": [{
          "x": "8",
          "y": "18",
          "img": "bong hoa"
      }, {
          "x": "5",
          "y": "15",
          "img": "line_15"
      }]
  }]
}, {
  "mPart": "tail",
  "genID": "153",
  "mClass": "support",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "tail",
      "width": "198",
      "height": "123",
      "lstLayerImage": [{
          "x": "19",
          "y": "10",
          "img": "Layer 108"
      }, {
          "x": "36",
          "y": "26",
          "img": "Layer 108 copy"
      }]
  }]
}, {
  "mPart": "tail",
  "genID": "154",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "tail",
      "width": "198",
      "height": "123",
      "lstLayerImage": [{
          "x": "50",
          "y": "26",
          "img": "line_15"
      }, {
          "x": "54",
          "y": "30",
          "img": "qua thong"
      }]
  }]
}, {
  "mPart": "tail",
  "genID": "155",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "tail",
      "width": "198",
      "height": "123",
      "lstLayerImage": [{
          "x": "48",
          "y": "38",
          "img": "line_15"
      }, {
          "x": "52",
          "y": "42",
          "img": "Layer 95 copy 2"
      }]
  }]
}, {
  "mPart": "hat",
  "genID": "156",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "hat",
      "width": "242",
      "height": "151",
      "lstLayerImage": [{
          "x": "48",
          "y": "4",
          "img": "line_4"
      }, {
          "x": "69",
          "y": "44",
          "img": "c3"
      }, {
          "x": "70",
          "y": "21",
          "img": "c1_3"
      }, {
          "x": "64",
          "y": "22",
          "img": "c2_1"
      }, {
          "x": "48",
          "y": "6",
          "img": "vong kim co "
      }]
  }]
}, {
  "mPart": "hat",
  "genID": "157",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "hat",
      "width": "242",
      "height": "151",
      "lstLayerImage": [{
          "x": "59",
          "y": "6",
          "img": "c2_1"
      }, {
          "x": "35",
          "y": "10",
          "img": "noel"
      }, {
          "x": "33",
          "y": "8",
          "img": "line_4"
      }]
  }]
}, {
  "mPart": "hat",
  "genID": "158",
  "mClass": "mage",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "hat",
      "width": "242",
      "height": "151",
      "lstLayerImage": [{
          "x": "78",
          "y": "14",
          "img": "c2_1"
      }, {
          "x": "77",
          "y": "18",
          "img": "line_3"
      }, {
          "x": "79",
          "y": "18",
          "img": "shit"
      }]
  }]
}, {
  "mPart": "hat",
  "genID": "159",
  "mClass": "mage",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "hat",
      "width": "242",
      "height": "151",
      "lstLayerImage": [{
          "x": "89",
          "y": "5",
          "img": "c2_1"
      }, {
          "x": "49",
          "y": "5",
          "img": "line_3"
      }, {
          "x": "54",
          "y": "7",
          "img": "toc"
      }]
  }]
}, {
  "mPart": "hat",
  "genID": "16",
  "mClass": "mage",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "hat",
      "width": "242",
      "height": "151",
      "lstLayerImage": [{
          "x": "73",
          "y": "1",
          "img": "c2_1"
      }, {
          "x": "83",
          "y": "1",
          "img": "line_4"
      }, {
          "x": "85",
          "y": "2",
          "img": "Layer 91 copy 2"
      }, {
          "x": "105",
          "y": "16",
          "img": "Layer 91"
      }, {
          "x": "111",
          "y": "16",
          "img": "sungs"
      }, {
          "x": "155",
          "y": "56",
          "img": "Layer 90"
      }, {
          "x": "106",
          "y": "15",
          "img": "line_3"
      }]
  }]
}, {
  "mPart": "hat",
  "genID": "160",
  "mClass": "mage",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "hat",
      "width": "242",
      "height": "151",
      "lstLayerImage": [{
          "x": "76",
          "y": "3",
          "img": "c2_1"
      }, {
          "x": "27",
          "y": "4",
          "img": "line_3"
      }, {
          "x": "30",
          "y": "6",
          "img": "Layer 94 copy"
      }]
  }]
}, {
  "mPart": "hat",
  "genID": "161",
  "mClass": "mage",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "hat",
      "width": "242",
      "height": "151",
      "lstLayerImage": [{
          "x": "70",
          "y": "47",
          "img": "line_3"
      }, {
          "x": "74",
          "y": "49",
          "img": "toc"
      }]
  }]
}, {
  "mPart": "hat",
  "genID": "162",
  "mClass": "warrior",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "hat",
      "width": "242",
      "height": "151",
      "lstLayerImage": [{
          "x": "70",
          "y": "14",
          "img": "c2"
      }, {
          "x": "70",
          "y": "20",
          "img": "line_6"
      }, {
          "x": "73",
          "y": "22",
          "img": "y ta"
      }, {
          "x": "111",
          "y": "55",
          "img": "line_5"
      }]
  }]
}, {
  "mPart": "hat",
  "genID": "163",
  "mClass": "warrior",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "hat",
      "width": "242",
      "height": "151",
      "lstLayerImage": [{
          "x": "69",
          "y": "17",
          "img": "c2"
      }, {
          "x": "64",
          "y": "18",
          "img": "line_5"
      }, {
          "x": "67",
          "y": "20",
          "img": "non quan vu"
      }]
  }]
}, {
  "mPart": "hat",
  "genID": "164",
  "mClass": "warrior",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "hat",
      "width": "242",
      "height": "151",
      "lstLayerImage": [{
          "x": "81",
          "y": "21",
          "img": "c2"
      }, {
          "x": "75",
          "y": "28",
          "img": "line_5"
      }, {
          "x": "76",
          "y": "25",
          "img": "non"
      }]
  }]
}, {
  "mPart": "hat",
  "genID": "165",
  "mClass": "support",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "hat",
      "width": "242",
      "height": "151",
      "lstLayerImage": [{
          "x": "46",
          "y": "45",
          "img": "c4"
      }]
  }]
}, {
  "mPart": "hat",
  "genID": "166",
  "mClass": "warrior",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "hat",
      "width": "242",
      "height": "151",
      "lstLayerImage": [{
          "x": "76",
          "y": "18",
          "img": "c2_1"
      }, {
          "x": "57",
          "y": "21",
          "img": "line_3"
      }, {
          "x": "60",
          "y": "22",
          "img": "banh gao"
      }]
  }]
}, {
  "mPart": "hat",
  "genID": "167",
  "mClass": "support",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "hat",
      "width": "242",
      "height": "151",
      "lstLayerImage": [{
          "x": "51",
          "y": "17",
          "img": "line_4"
      }, {
          "x": "80",
          "y": "15",
          "img": "c2_1"
      }, {
          "x": "53",
          "y": "17",
          "img": "kinh"
      }, {
          "x": "75",
          "y": "24",
          "img": "c4"
      }]
  }]
}, {
  "mPart": "hat",
  "genID": "168",
  "mClass": "support",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "hat",
      "width": "242",
      "height": "151",
      "lstLayerImage": [{
          "x": "48",
          "y": "19",
          "img": "non dau bep"
      }, {
          "x": "46",
          "y": "17",
          "img": "line_4"
      }]
  }]
}, {
  "mPart": "hat",
  "genID": "169",
  "mClass": "support",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "hat",
      "width": "242",
      "height": "151",
      "lstLayerImage": [{
          "x": "72",
          "y": "3",
          "img": "c2_1"
      }, {
          "x": "49",
          "y": "5",
          "img": "line_4"
      }, {
          "x": "49",
          "y": "6",
          "img": "non"
      }]
  }]
}, {
  "mPart": "tail",
  "genID": "17",
  "mClass": "mage",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "tail",
      "width": "198",
      "height": "123",
      "lstLayerImage": [{
          "x": "35",
          "y": "20",
          "img": "line_15"
      }, {
          "x": "39",
          "y": "24",
          "img": "duoi2"
      }, {
          "x": "68",
          "y": "33",
          "img": "duoi3"
      }, {
          "x": "68",
          "y": "34",
          "img": "duoi"
      }, {
          "x": "71",
          "y": "33",
          "img": "Layer 94 copy 8"
      }, {
          "x": "42",
          "y": "24",
          "img": "c4_4"
      }]
  }]
}, {
  "mPart": "hat",
  "genID": "170",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "hat",
      "width": "242",
      "height": "151",
      "lstLayerImage": [{
          "x": "98",
          "y": "36",
          "img": "c2_1"
      }, {
          "x": "58",
          "y": "41",
          "img": "line_4"
      }, {
          "x": "62",
          "y": "41",
          "img": "chong chong "
      }]
  }]
}, {
  "mPart": "hat",
  "genID": "171",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "hat",
      "width": "242",
      "height": "151",
      "lstLayerImage": [{
          "x": "55",
          "y": "6",
          "img": "c2_1"
      }, {
          "x": "41",
          "y": "8",
          "img": "line_3"
      }, {
          "x": "45",
          "y": "10",
          "img": "to chim"
      }, {
          "x": "76",
          "y": "35",
          "img": "c4"
      }]
  }]
}, {
  "mPart": "hat",
  "genID": "172",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "hat",
      "width": "242",
      "height": "151",
      "lstLayerImage": [{
          "x": "64",
          "y": "9",
          "img": "c2_1"
      }, {
          "x": "44",
          "y": "15",
          "img": "line_4"
      }, {
          "x": "46",
          "y": "16",
          "img": "hamberger"
      }]
  }]
}, {
  "mPart": "hat",
  "genID": "173",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "hat",
      "width": "242",
      "height": "151",
      "lstLayerImage": [{
          "x": "57",
          "y": "11",
          "img": "line_4"
      }, {
          "x": "59",
          "y": "14",
          "img": "mu coi"
      }]
  }]
}, {
  "mPart": "tail",
  "genID": "174",
  "mClass": "mage",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "tail",
      "width": "198",
      "height": "123",
      "lstLayerImage": [{
          "x": "34",
          "y": "28",
          "img": "line_14"
      }, {
          "x": "36",
          "y": "31",
          "img": "Layer 94 copy"
      }]
  }]
}, {
  "mPart": "ear",
  "genID": "175",
  "mClass": "mage",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "ear_left",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "22",
          "y": "29",
          "img": "line_2"
      }, {
          "x": "26",
          "y": "33",
          "img": "c1_2"
      }, {
          "x": "25",
          "y": "33",
          "img": "c3"
      }, {
          "x": "36",
          "y": "53",
          "img": "Layer 108"
      }]
  }, {
      "name": "ear_right",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "58",
          "y": "28",
          "img": "line_11"
      }, {
          "x": "62",
          "y": "32",
          "img": "c1_9"
      }, {
          "x": "63",
          "y": "32",
          "img": "c3_2"
      }, {
          "x": "70",
          "y": "52",
          "img": "Layer 108_1"
      }]
  }]
}, {
  "mPart": "ear",
  "genID": "176",
  "mClass": "mage",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "ear_left",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "21",
          "y": "17",
          "img": "line_4"
      }, {
          "x": "25",
          "y": "19",
          "img": "c1_2"
      }, {
          "x": "25",
          "y": "69",
          "img": "white"
      }, {
          "x": "31",
          "y": "35",
          "img": "red"
      }, {
          "x": "12",
          "y": "29",
          "img": "line_3"
      }, {
          "x": "15",
          "y": "32",
          "img": "Layer 110"
      }, {
          "x": "9",
          "y": "61",
          "img": "line_2"
      }, {
          "x": "12",
          "y": "64",
          "img": "Layer 110 copy"
      }]
  }, {
      "name": "ear_right",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "49",
          "y": "14",
          "img": "line_13"
      }, {
          "x": "51",
          "y": "16",
          "img": "c1_9"
      }, {
          "x": "51",
          "y": "66",
          "img": "white_1"
      }, {
          "x": "71",
          "y": "32",
          "img": "red_1"
      }, {
          "x": "103",
          "y": "33",
          "img": "line "
      }, {
          "x": "106",
          "y": "36",
          "img": "Layer 110 copy 2"
      }]
  }]
}, {
  "mPart": "ear",
  "genID": "177",
  "mClass": "warrior",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "ear_left",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "23",
          "y": "29",
          "img": "line_3"
      }, {
          "x": "27",
          "y": "33",
          "img": "c1_2"
      }, {
          "x": "27",
          "y": "54",
          "img": "c4_1"
      }, {
          "x": "52",
          "y": "56",
          "img": "c2"
      }]
  }, {
      "name": "ear_right",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "60",
          "y": "25",
          "img": "line "
      }, {
          "x": "62",
          "y": "29",
          "img": "c1_8"
      }, {
          "x": "85",
          "y": "49",
          "img": "c4 "
      }, {
          "x": "62",
          "y": "52",
          "img": "c2 "
      }]
  }]
}, {
  "mPart": "ear",
  "genID": "178",
  "mClass": "warrior",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "ear_left",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "21",
          "y": "21",
          "img": "line_4"
      }, {
          "x": "25",
          "y": "23",
          "img": "c1_2"
      }, {
          "x": "30",
          "y": "27",
          "img": "c2"
      }, {
          "x": "25",
          "y": "24",
          "img": "c4_1"
      }, {
          "x": "35",
          "y": "36",
          "img": "red"
      }, {
          "x": "19",
          "y": "7",
          "img": "line_3"
      }, {
          "x": "23",
          "y": "11",
          "img": "Layer 94"
      }]
  }, {
      "name": "ear_right",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "51",
          "y": "13",
          "img": "line_17"
      }, {
          "x": "55",
          "y": "17",
          "img": "c1_8"
      }, {
          "x": "58",
          "y": "19",
          "img": "c2_4"
      }, {
          "x": "70",
          "y": "18",
          "img": "c4_5"
      }, {
          "x": "64",
          "y": "28",
          "img": "red_1"
      }, {
          "x": "89",
          "y": "0",
          "img": "line_16"
      }, {
          "x": "93",
          "y": "4",
          "img": "Layer 94_1"
      }]
  }]
}, {
  "mPart": "ear",
  "genID": "179",
  "mClass": "warrior",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "ear_left",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "19",
          "y": "20",
          "img": "line_4"
      }, {
          "x": "23",
          "y": "24",
          "img": "c1_2"
      }, {
          "x": "23",
          "y": "25",
          "img": "c4_2"
      }, {
          "x": "22",
          "y": "40",
          "img": "c4_1"
      }, {
          "x": "35",
          "y": "66",
          "img": "Layer 92"
      }, {
          "x": "31",
          "y": "32",
          "img": "Layer 91"
      }, {
          "x": "17",
          "y": "7",
          "img": "line_3"
      }, {
          "x": "19",
          "y": "8",
          "img": "Layer 95"
      }]
  }, {
      "name": "ear_right",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "50",
          "y": "12",
          "img": "line_17"
      }, {
          "x": "53",
          "y": "16",
          "img": "c1_8"
      }, {
          "x": "87",
          "y": "17",
          "img": "c4_7"
      }, {
          "x": "74",
          "y": "32",
          "img": "c4_6"
      }, {
          "x": "51",
          "y": "60",
          "img": "Layer 92_1"
      }, {
          "x": "59",
          "y": "24",
          "img": "Layer 91_1"
      }, {
          "x": "81",
          "y": "0",
          "img": "line_16"
      }, {
          "x": "83",
          "y": "1",
          "img": "Layer 95_1"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "18",
  "mClass": "mage",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "weapon_back",
      "width": "428",
      "height": "393",
      "lstLayerImage": [{
          "x": "12",
          "y": "101",
          "img": "line_18"
      }, {
          "x": "16",
          "y": "105",
          "img": "wing"
      }, {
          "x": "44",
          "y": "146",
          "img": "Layer 97"
      }]
  }]
}, {
  "mPart": "ear",
  "genID": "180",
  "mClass": "warrior",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "ear_left",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "3",
          "y": "31",
          "img": "c1_2"
      }, {
          "x": "6",
          "y": "30",
          "img": "c2"
      }, {
          "x": "6",
          "y": "52",
          "img": "c3"
      }, {
          "x": "4",
          "y": "36",
          "img": "c4_1"
      }, {
          "x": "2",
          "y": "27",
          "img": "line_4"
      }, {
          "x": "16",
          "y": "3",
          "img": "line_3"
      }, {
          "x": "19",
          "y": "6",
          "img": "moon"
      }]
  }, {
      "name": "ear_right",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "43",
          "y": "24",
          "img": "c1_9"
      }, {
          "x": "69",
          "y": "24",
          "img": "c2_5"
      }, {
          "x": "44",
          "y": "46",
          "img": "c3 "
      }, {
          "x": "72",
          "y": "30",
          "img": "c4_6"
      }, {
          "x": "40",
          "y": "20",
          "img": "line "
      }, {
          "x": "74",
          "y": "6",
          "img": "line_17"
      }, {
          "x": "77",
          "y": "9",
          "img": "moon copy"
      }]
  }]
}, {
  "mPart": "ear",
  "genID": "181",
  "mClass": "warrior",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "ear_left",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "9",
          "y": "8",
          "img": "line_4"
      }, {
          "x": "12",
          "y": "11",
          "img": "c1_2"
      }, {
          "x": "22",
          "y": "11",
          "img": "c2"
      }, {
          "x": "18",
          "y": "22",
          "img": "c4"
      }, {
          "x": "47",
          "y": "25",
          "img": "line_3"
      }]
  }, {
      "name": "ear_right",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "75",
          "y": "16",
          "img": "line "
      }, {
          "x": "74",
          "y": "20",
          "img": "c1_9"
      }, {
          "x": "93",
          "y": "19",
          "img": "c2_5"
      }, {
          "x": "92",
          "y": "52",
          "img": "c4_5"
      }, {
          "x": "83",
          "y": "32",
          "img": "line_15"
      }]
  }]
}, {
  "mPart": "ear",
  "genID": "182",
  "mClass": "warrior",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "ear_left",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "21",
          "y": "20",
          "img": "line_3"
      }, {
          "x": "25",
          "y": "24",
          "img": "c1_2"
      }, {
          "x": "25",
          "y": "27",
          "img": "c4"
      }, {
          "x": "57",
          "y": "56",
          "img": "c2"
      }, {
          "x": "29",
          "y": "33",
          "img": "red"
      }]
  }, {
      "name": "ear_right",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "58",
          "y": "17",
          "img": "line "
      }, {
          "x": "62",
          "y": "21",
          "img": "c1 "
      }, {
          "x": "73",
          "y": "23",
          "img": "c4 "
      }, {
          "x": "62",
          "y": "54",
          "img": "c2 "
      }]
  }]
}, {
  "mPart": "ear",
  "genID": "183",
  "mClass": "warrior",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "ear_left",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "13",
          "y": "4",
          "img": "line_3"
      }, {
          "x": "16",
          "y": "5",
          "img": "c1_2"
      }, {
          "x": "16",
          "y": "8",
          "img": "c4"
      }, {
          "x": "17",
          "y": "55",
          "img": "Layer 2"
      }, {
          "x": "26",
          "y": "21",
          "img": "Layer 1"
      }]
  }, {
      "name": "ear_right",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "63",
          "y": "7",
          "img": "line "
      }, {
          "x": "59",
          "y": "8",
          "img": "c1 "
      }, {
          "x": "99",
          "y": "11",
          "img": "c4 "
      }, {
          "x": "64",
          "y": "58",
          "img": "Layer 2 "
      }, {
          "x": "69",
          "y": "24",
          "img": "Layer 1_1"
      }]
  }]
}, {
  "mPart": "ear",
  "genID": "184",
  "mClass": "support",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "ear_left",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "17",
          "y": "13",
          "img": "line_5"
      }, {
          "x": "20",
          "y": "15",
          "img": "c1_2"
      }, {
          "x": "30",
          "y": "45",
          "img": "c2"
      }, {
          "x": "26",
          "y": "19",
          "img": "Layer 5"
      }, {
          "x": "32",
          "y": "61",
          "img": "c4"
      }, {
          "x": "31",
          "y": "58",
          "img": "line_4"
      }, {
          "x": "26",
          "y": "6",
          "img": "line_3"
      }, {
          "x": "28",
          "y": "8",
          "img": "Layer 4"
      }]
  }, {
      "name": "ear_right",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "60",
          "y": "15",
          "img": "line _2"
      }, {
          "x": "48",
          "y": "17",
          "img": "c1 "
      }, {
          "x": "67",
          "y": "47",
          "img": "c2 "
      }, {
          "x": "77",
          "y": "21",
          "img": "Layer "
      }, {
          "x": "93",
          "y": "60",
          "img": "line _1"
      }, {
          "x": "78",
          "y": "63",
          "img": "c4_5"
      }, {
          "x": "88",
          "y": "7",
          "img": "line "
      }, {
          "x": "90",
          "y": "11",
          "img": "Layer 4 "
      }]
  }]
}, {
  "mPart": "ear",
  "genID": "185",
  "mClass": "warrior",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "ear_left",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "14",
          "y": "23",
          "img": "line_4"
      }, {
          "x": "17",
          "y": "26",
          "img": "red"
      }, {
          "x": "13",
          "y": "21",
          "img": "line_3"
      }, {
          "x": "16",
          "y": "24",
          "img": "c1_2"
      }]
  }, {
      "name": "ear_right",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "65",
          "y": "22",
          "img": "line _1"
      }, {
          "x": "66",
          "y": "25",
          "img": "red "
      }, {
          "x": "62",
          "y": "20",
          "img": "line "
      }, {
          "x": "62",
          "y": "23",
          "img": "c1 "
      }]
  }]
}, {
  "mPart": "ear",
  "genID": "186",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "ear_left",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "6",
          "y": "18",
          "img": "line_3"
      }, {
          "x": "10",
          "y": "22",
          "img": "c1_2"
      }, {
          "x": "10",
          "y": "63",
          "img": "c2"
      }, {
          "x": "16",
          "y": "36",
          "img": "c4"
      }]
  }, {
      "name": "ear_right",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "49",
          "y": "15",
          "img": "line "
      }, {
          "x": "52",
          "y": "19",
          "img": "c1 "
      }, {
          "x": "53",
          "y": "59",
          "img": "c2 "
      }, {
          "x": "58",
          "y": "32",
          "img": "c4 "
      }]
  }]
}, {
  "mPart": "ear",
  "genID": "187",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "ear_left",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "11",
          "y": "23",
          "img": "line_3"
      }, {
          "x": "15",
          "y": "27",
          "img": "c1_2"
      }, {
          "x": "18",
          "y": "29",
          "img": "c4_1"
      }, {
          "x": "18",
          "y": "47",
          "img": "c4"
      }, {
          "x": "15",
          "y": "29",
          "img": "c2"
      }]
  }, {
      "name": "ear_right",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "43",
          "y": "18",
          "img": "line_14"
      }, {
          "x": "47",
          "y": "22",
          "img": "c1 "
      }, {
          "x": "57",
          "y": "26",
          "img": "c4 _1"
      }, {
          "x": "59",
          "y": "43",
          "img": "c4 "
      }, {
          "x": "47",
          "y": "26",
          "img": "c2 "
      }]
  }]
}, {
  "mPart": "ear",
  "genID": "188",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "ear_left",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "30",
          "y": "20",
          "img": "line_3"
      }, {
          "x": "34",
          "y": "24",
          "img": "c1_2"
      }, {
          "x": "73",
          "y": "62",
          "img": "c2"
      }, {
          "x": "37",
          "y": "23",
          "img": "c4"
      }, {
          "x": "40",
          "y": "32",
          "img": "red"
      }]
  }, {
      "name": "ear_right",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "53",
          "y": "9",
          "img": "line "
      }, {
          "x": "52",
          "y": "13",
          "img": "c1 "
      }, {
          "x": "63",
          "y": "60",
          "img": "c2 "
      }, {
          "x": "84",
          "y": "12",
          "img": "c4 "
      }, {
          "x": "77",
          "y": "25",
          "img": "red "
      }]
  }]
}, {
  "mPart": "eye",
  "genID": "189",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "eye_base",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "88",
          "y": "40",
          "img": "Layer 90"
      }]
  }, {
      "name": "eye_cheek",
      "width": "272",
      "height": "237",
      "lstLayerImage": []
  }, {
      "name": "eye_dot_left",
      "width": "90",
      "height": "82",
      "lstLayerImage": [{
          "x": "35",
          "y": "28",
          "img": "c4_2"
      }]
  }, {
      "name": "eye_dot_right",
      "width": "89",
      "height": "82",
      "lstLayerImage": [{
          "x": "37",
          "y": "29",
          "img": "c4 "
      }]
  }, {
      "name": "eye_line",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "85",
          "y": "54",
          "img": "c1 "
      }, {
          "x": "85",
          "y": "53",
          "img": "c2 "
      }, {
          "x": "91",
          "y": "65",
          "img": "c3 "
      }, {
          "x": "84",
          "y": "37",
          "img": "line_6"
      }]
  }, {
      "name": "eye_mask",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "29",
          "y": "27",
          "img": "c2_2"
      }, {
          "x": "23",
          "y": "31",
          "img": "line_5"
      }, {
          "x": "27",
          "y": "33",
          "img": "Ellipse 1 copy"
      }]
  }]
}, {
  "mPart": "ear",
  "genID": "19",
  "mClass": "support",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "ear_left",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "23",
          "y": "15",
          "img": "line_3"
      }, {
          "x": "27",
          "y": "17",
          "img": "c1_2"
      }, {
          "x": "27",
          "y": "16",
          "img": "c2"
      }, {
          "x": "27",
          "y": "16",
          "img": "tai2"
      }]
  }, {
      "name": "ear_right",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "46",
          "y": "15",
          "img": "line_12"
      }, {
          "x": "45",
          "y": "17",
          "img": "c1_8"
      }, {
          "x": "45",
          "y": "16",
          "img": "c2_3"
      }, {
          "x": "45",
          "y": "16",
          "img": "tai2 copy"
      }]
  }]
}, {
  "mPart": "eye",
  "genID": "190",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "eye_base",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "88",
          "y": "40",
          "img": "Layer 90"
      }]
  }, {
      "name": "eye_cheek",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "4",
          "y": "-2",
          "img": "line_7"
      }, {
          "x": "11",
          "y": "1",
          "img": "c1_3"
      }, {
          "x": "11",
          "y": "9",
          "img": "c2_4"
      }, {
          "x": "18",
          "y": "21",
          "img": "c3"
      }]
  }, {
      "name": "eye_dot_left",
      "width": "90",
      "height": "82",
      "lstLayerImage": [{
          "x": "33",
          "y": "25",
          "img": "c4_3"
      }]
  }, {
      "name": "eye_dot_right",
      "width": "89",
      "height": "82",
      "lstLayerImage": [{
          "x": "34",
          "y": "26",
          "img": "c4_2"
      }]
  }, {
      "name": "eye_line",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "75",
          "y": "33",
          "img": "c2_3"
      }, {
          "x": "86",
          "y": "37",
          "img": "line_6"
      }]
  }, {
      "name": "eye_mask",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "77",
          "y": "42",
          "img": "c2_2"
      }, {
          "x": "70",
          "y": "24",
          "img": "Layer 96"
      }, {
          "x": "41",
          "y": "2",
          "img": "line_5"
      }, {
          "x": "43",
          "y": "4",
          "img": "kinh copy"
      }]
  }]
}, {
  "mPart": "eye",
  "genID": "191",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "eye_base",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "88",
          "y": "40",
          "img": "Layer 90"
      }]
  }, {
      "name": "eye_cheek",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "24",
          "y": "33",
          "img": "line_7"
      }, {
          "x": "28",
          "y": "37",
          "img": "c1_3"
      }, {
          "x": "36",
          "y": "37",
          "img": "c4_3"
      }, {
          "x": "29",
          "y": "37",
          "img": "c2_2"
      }]
  }, {
      "name": "eye_dot_left",
      "width": "90",
      "height": "82",
      "lstLayerImage": [{
          "x": "35",
          "y": "28",
          "img": "c4_2"
      }]
  }, {
      "name": "eye_dot_right",
      "width": "89",
      "height": "82",
      "lstLayerImage": [{
          "x": "37",
          "y": "29",
          "img": "c4 "
      }]
  }, {
      "name": "eye_line",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "84",
          "y": "37",
          "img": "line_6"
      }]
  }, {
      "name": "eye_mask",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "62",
          "y": "45",
          "img": "line_5"
      }, {
          "x": "154",
          "y": "124",
          "img": "Layer 106"
      }, {
          "x": "66",
          "y": "48",
          "img": "Layer 105"
      }]
  }]
}, {
  "mPart": "eye",
  "genID": "192",
  "mClass": "mage",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "eye_base",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "88",
          "y": "40",
          "img": "Layer 90"
      }]
  }, {
      "name": "eye_cheek",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "6",
          "y": "27",
          "img": "line copy"
      }, {
          "x": "10",
          "y": "31",
          "img": "c1 "
      }, {
          "x": "9",
          "y": "30",
          "img": "c2 "
      }, {
          "x": "12",
          "y": "43",
          "img": "c3 "
      }, {
          "x": "22",
          "y": "44",
          "img": "line_5"
      }]
  }, {
      "name": "eye_dot_left",
      "width": "90",
      "height": "82",
      "lstLayerImage": [{
          "x": "33",
          "y": "25",
          "img": "c4_3"
      }]
  }, {
      "name": "eye_dot_right",
      "width": "89",
      "height": "82",
      "lstLayerImage": [{
          "x": "34",
          "y": "26",
          "img": "c4_2"
      }]
  }, {
      "name": "eye_line",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "75",
          "y": "33",
          "img": "c2_2"
      }, {
          "x": "74",
          "y": "36",
          "img": "line_4"
      }]
  }, {
      "name": "eye_mask",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "31",
          "y": "54",
          "img": "c4_1"
      }, {
          "x": "100",
          "y": "60",
          "img": "Ellipse 1 copy"
      }]
  }]
}, {
  "mPart": "eye",
  "genID": "193",
  "mClass": "mage",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "eye_base",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "88",
          "y": "40",
          "img": "Layer 90"
      }]
  }, {
      "name": "eye_cheek",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "40",
          "y": "50",
          "img": "c2_2"
      }]
  }, {
      "name": "eye_dot_left",
      "width": "90",
      "height": "82",
      "lstLayerImage": [{
          "x": "33",
          "y": "25",
          "img": "c4_2"
      }]
  }, {
      "name": "eye_dot_right",
      "width": "89",
      "height": "82",
      "lstLayerImage": [{
          "x": "34",
          "y": "26",
          "img": "c4_1"
      }]
  }, {
      "name": "eye_line",
      "width": "227",
      "height": "138",
      "lstLayerImage": []
  }, {
      "name": "eye_mask",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "35",
          "y": "51",
          "img": "Layer 92"
      }, {
          "x": "26",
          "y": "51",
          "img": "line_4"
      }, {
          "x": "28",
          "y": "53",
          "img": "Layer 93 copy 3"
      }]
  }]
}, {
  "mPart": "eye",
  "genID": "194",
  "mClass": "mage",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "eye_base",
      "width": "227",
      "height": "138",
      "lstLayerImage": []
  }, {
      "name": "eye_cheek",
      "width": "272",
      "height": "237",
      "lstLayerImage": []
  }, {
      "name": "eye_dot_left",
      "width": "90",
      "height": "82",
      "lstLayerImage": [{
          "x": "33",
          "y": "25",
          "img": "c4_3"
      }]
  }, {
      "name": "eye_dot_right",
      "width": "89",
      "height": "82",
      "lstLayerImage": [{
          "x": "34",
          "y": "26",
          "img": "c4_2"
      }]
  }, {
      "name": "eye_line",
      "width": "227",
      "height": "138",
      "lstLayerImage": []
  }, {
      "name": "eye_mask",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "30",
          "y": "56",
          "img": "line_4"
      }, {
          "x": "32",
          "y": "57",
          "img": "khan"
      }, {
          "x": "174",
          "y": "87",
          "img": "c4_1"
      }]
  }]
}, {
  "mPart": "eye",
  "genID": "195",
  "mClass": "mage",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "eye_base",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "88",
          "y": "40",
          "img": "Layer 90"
      }]
  }, {
      "name": "eye_cheek",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "45",
          "y": "36",
          "img": "c2_3"
      }, {
          "x": "41",
          "y": "34",
          "img": "c1 _1"
      }, {
          "x": "51",
          "y": "37",
          "img": "c2_2"
      }, {
          "x": "43",
          "y": "51",
          "img": "c3 _1"
      }, {
          "x": "41",
          "y": "34",
          "img": "line_6"
      }]
  }, {
      "name": "eye_dot_left",
      "width": "90",
      "height": "82",
      "lstLayerImage": [{
          "x": "35",
          "y": "28",
          "img": "c4_2"
      }]
  }, {
      "name": "eye_dot_right",
      "width": "89",
      "height": "82",
      "lstLayerImage": [{
          "x": "37",
          "y": "29",
          "img": "c4 "
      }]
  }, {
      "name": "eye_line",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "57",
          "y": "30",
          "img": "bong"
      }, {
          "x": "85",
          "y": "54",
          "img": "c1 "
      }, {
          "x": "85",
          "y": "53",
          "img": "c2 "
      }, {
          "x": "91",
          "y": "65",
          "img": "c3 "
      }, {
          "x": "84",
          "y": "37",
          "img": "line_5"
      }]
  }, {
      "name": "eye_mask",
      "width": "272",
      "height": "237",
      "lstLayerImage": []
  }]
}, {
  "mPart": "eye",
  "genID": "196",
  "mClass": "warrior",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "eye_base",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "88",
          "y": "40",
          "img": "Layer 90"
      }]
  }, {
      "name": "eye_cheek",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "14",
          "y": "24",
          "img": "line_6"
      }, {
          "x": "18",
          "y": "28",
          "img": "c1 "
      }, {
          "x": "19",
          "y": "29",
          "img": "c2_3"
      }, {
          "x": "21",
          "y": "43",
          "img": "c3"
      }]
  }, {
      "name": "eye_dot_left",
      "width": "90",
      "height": "82",
      "lstLayerImage": [{
          "x": "32",
          "y": "25",
          "img": "c4_3"
      }]
  }, {
      "name": "eye_dot_right",
      "width": "89",
      "height": "82",
      "lstLayerImage": [{
          "x": "34",
          "y": "26",
          "img": "c4_2"
      }]
  }, {
      "name": "eye_line",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "63",
          "y": "31",
          "img": "c2_2"
      }, {
          "x": "86",
          "y": "37",
          "img": "line_5"
      }, {
          "x": "86",
          "y": "12",
          "img": "Layer 96"
      }]
  }, {
      "name": "eye_mask",
      "width": "272",
      "height": "237",
      "lstLayerImage": []
  }]
}, {
  "mPart": "eye",
  "genID": "197",
  "mClass": "warrior",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "eye_base",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "88",
          "y": "40",
          "img": "Layer 90"
      }]
  }, {
      "name": "eye_cheek",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "54",
          "y": "41",
          "img": "c2_3"
      }, {
          "x": "29",
          "y": "39",
          "img": "line_6"
      }, {
          "x": "33",
          "y": "43",
          "img": "Layer 97 copy 2"
      }]
  }, {
      "name": "eye_dot_left",
      "width": "90",
      "height": "82",
      "lstLayerImage": [{
          "x": "32",
          "y": "25",
          "img": "c4_3"
      }]
  }, {
      "name": "eye_dot_right",
      "width": "89",
      "height": "82",
      "lstLayerImage": [{
          "x": "34",
          "y": "26",
          "img": "c4_2"
      }]
  }, {
      "name": "eye_line",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "49",
          "y": "31",
          "img": "c2_2"
      }, {
          "x": "74",
          "y": "29",
          "img": "line_5"
      }, {
          "x": "76",
          "y": "32",
          "img": "Layer 96 copy 3"
      }]
  }, {
      "name": "eye_mask",
      "width": "272",
      "height": "237",
      "lstLayerImage": []
  }]
}, {
  "mPart": "eye",
  "genID": "198",
  "mClass": "warrior",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "eye_base",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "69",
          "y": "37",
          "img": "Layer 90"
      }]
  }, {
      "name": "eye_cheek",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "10",
          "y": "16",
          "img": "line "
      }, {
          "x": "14",
          "y": "20",
          "img": "c1 "
      }, {
          "x": "16",
          "y": "20",
          "img": "c2 "
      }, {
          "x": "18",
          "y": "31",
          "img": "c3 "
      }]
  }, {
      "name": "eye_dot_left",
      "width": "90",
      "height": "82",
      "lstLayerImage": [{
          "x": "33",
          "y": "25",
          "img": "mat trai"
      }]
  }, {
      "name": "eye_dot_right",
      "width": "89",
      "height": "82",
      "lstLayerImage": [{
          "x": "34",
          "y": "26",
          "img": "c4_3"
      }]
  }, {
      "name": "eye_line",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "43",
          "y": "20",
          "img": "c2_3"
      }, {
          "x": "56",
          "y": "25",
          "img": "line_5"
      }, {
          "x": "59",
          "y": "60",
          "img": "Layer 96"
      }]
  }, {
      "name": "eye_mask",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "163",
          "y": "121",
          "img": "c2_2"
      }, {
          "x": "166",
          "y": "125",
          "img": "c4_2"
      }]
  }]
}, {
  "mPart": "eye",
  "genID": "199",
  "mClass": "warrior",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "eye_base",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "72",
          "y": "36",
          "img": "Layer 90"
      }]
  }, {
      "name": "eye_cheek",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "21",
          "y": "38",
          "img": "line_5"
      }, {
          "x": "25",
          "y": "42",
          "img": "c1 "
      }, {
          "x": "24",
          "y": "43",
          "img": "c2 "
      }, {
          "x": "40",
          "y": "54",
          "img": "c3"
      }]
  }, {
      "name": "eye_dot_left",
      "width": "90",
      "height": "82",
      "lstLayerImage": [{
          "x": "27",
          "y": "34",
          "img": "mat"
      }]
  }, {
      "name": "eye_dot_right",
      "width": "89",
      "height": "82",
      "lstLayerImage": [{
          "x": "30",
          "y": "34",
          "img": "c4 "
      }]
  }, {
      "name": "eye_line",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "47",
          "y": "30",
          "img": "c2_3"
      }, {
          "x": "70",
          "y": "34",
          "img": "line_4"
      }, {
          "x": "45",
          "y": "32",
          "img": "Layer 92"
      }]
  }, {
      "name": "eye_mask",
      "width": "272",
      "height": "237",
      "lstLayerImage": []
  }]
}, {
  "mPart": "eye",
  "genID": "2",
  "mClass": "ranger",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "eye_base",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "88",
          "y": "40",
          "img": "Layer 90"
      }]
  }, {
      "name": "eye_cheek",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "37",
          "y": "47",
          "img": "Layer 92"
      }, {
          "x": "35",
          "y": "46",
          "img": "line_7"
      }]
  }, {
      "name": "eye_dot_left",
      "width": "90",
      "height": "82",
      "lstLayerImage": [{
          "x": "33",
          "y": "25",
          "img": "c4_1"
      }]
  }, {
      "name": "eye_dot_right",
      "width": "89",
      "height": "82",
      "lstLayerImage": [{
          "x": "34",
          "y": "26",
          "img": "c4"
      }]
  }, {
      "name": "eye_line",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "75",
          "y": "33",
          "img": "c2_2"
      }, {
          "x": "74",
          "y": "36",
          "img": "line_6"
      }]
  }, {
      "name": "eye_mask",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "154",
          "y": "115",
          "img": "c2_1"
      }, {
          "x": "156",
          "y": "114",
          "img": "line_5"
      }]
  }]
}, {
  "mPart": "eye",
  "genID": "20",
  "mClass": "support",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "eye_base",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "88",
          "y": "40",
          "img": "Layer 90"
      }]
  }, {
      "name": "eye_cheek",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "50",
          "y": "59",
          "img": "c4_3"
      }, {
          "x": "36",
          "y": "83",
          "img": "w"
      }]
  }, {
      "name": "eye_dot_left",
      "width": "90",
      "height": "82",
      "lstLayerImage": [{
          "x": "34",
          "y": "24",
          "img": "c4_2"
      }]
  }, {
      "name": "eye_dot_right",
      "width": "89",
      "height": "82",
      "lstLayerImage": [{
          "x": "37",
          "y": "25",
          "img": "c4_1"
      }]
  }, {
      "name": "eye_line",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "79",
          "y": "36",
          "img": "c3"
      }, {
          "x": "73",
          "y": "39",
          "img": "Layer 97"
      }]
  }, {
      "name": "eye_mask",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "144",
          "y": "117",
          "img": "line_5"
      }, {
          "x": "148",
          "y": "121",
          "img": "c4"
      }]
  }]
}, {
  "mPart": "eye",
  "genID": "200",
  "mClass": "support",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "eye_base",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "65",
          "y": "44",
          "img": "Layer 95 copy"
      }]
  }, {
      "name": "eye_cheek",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "41",
          "y": "37",
          "img": "Layer 105"
      }]
  }, {
      "name": "eye_dot_left",
      "width": "90",
      "height": "82",
      "lstLayerImage": [{
          "x": "34",
          "y": "40",
          "img": "Layer 96"
      }]
  }, {
      "name": "eye_dot_right",
      "width": "89",
      "height": "82",
      "lstLayerImage": [{
          "x": "39",
          "y": "42",
          "img": "Layer 96 copy"
      }]
  }, {
      "name": "eye_line",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "65",
          "y": "45",
          "img": "Layer 102"
      }]
  }, {
      "name": "eye_mask",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "36",
          "y": "54",
          "img": "Layer 104"
      }, {
          "x": "28",
          "y": "54",
          "img": "line_4"
      }, {
          "x": "33",
          "y": "57",
          "img": "Layer 103 copy"
      }]
  }]
}, {
  "mPart": "eye",
  "genID": "201",
  "mClass": "support",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "eye_base",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "76",
          "y": "42",
          "img": "Layer 90"
      }]
  }, {
      "name": "eye_cheek",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "10",
          "y": "32",
          "img": "line_6"
      }, {
          "x": "14",
          "y": "36",
          "img": "c1_3"
      }, {
          "x": "14",
          "y": "37",
          "img": "c2_1"
      }, {
          "x": "20",
          "y": "55",
          "img": "line_5"
      }]
  }, {
      "name": "eye_dot_left",
      "width": "90",
      "height": "82",
      "lstLayerImage": [{
          "x": "32",
          "y": "30",
          "img": "Layer 96"
      }, {
          "x": "29",
          "y": "29",
          "img": "Ellipse 1 copy 2"
      }]
  }, {
      "name": "eye_dot_right",
      "width": "89",
      "height": "82",
      "lstLayerImage": [{
          "x": "37",
          "y": "28",
          "img": "Ellipse 1 copy 3"
      }]
  }, {
      "name": "eye_line",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "62",
          "y": "45",
          "img": "bong"
      }, {
          "x": "78",
          "y": "45",
          "img": "Layer 97"
      }, {
          "x": "76",
          "y": "42",
          "img": "line "
      }, {
          "x": "67",
          "y": "41",
          "img": "Layer 98"
      }, {
          "x": "60",
          "y": "81",
          "img": "Layer 97 copy"
      }]
  }, {
      "name": "eye_mask",
      "width": "272",
      "height": "237",
      "lstLayerImage": []
  }]
}, {
  "mPart": "eye",
  "genID": "202",
  "mClass": "support",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "eye_base",
      "width": "227",
      "height": "138",
      "lstLayerImage": []
  }, {
      "name": "eye_cheek",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "10",
          "y": "32",
          "img": "line_7"
      }, {
          "x": "14",
          "y": "36",
          "img": "c1_3"
      }, {
          "x": "14",
          "y": "37",
          "img": "c2_2"
      }, {
          "x": "20",
          "y": "55",
          "img": "line_6"
      }]
  }, {
      "name": "eye_dot_left",
      "width": "90",
      "height": "82",
      "lstLayerImage": []
  }, {
      "name": "eye_dot_right",
      "width": "89",
      "height": "82",
      "lstLayerImage": []
  }, {
      "name": "eye_line",
      "width": "227",
      "height": "138",
      "lstLayerImage": []
  }, {
      "name": "eye_mask",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "40",
          "y": "50",
          "img": "c2_1"
      }, {
          "x": "25",
          "y": "55",
          "img": "line_5"
      }, {
          "x": "27",
          "y": "57",
          "img": "Rectangle 1 copy 7"
      }]
  }]
}, {
  "mPart": "eye",
  "genID": "203",
  "mClass": "support",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "eye_base",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "83",
          "y": "49",
          "img": "Layer 95 copy"
      }]
  }, {
      "name": "eye_cheek",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "14",
          "y": "26",
          "img": "line_6"
      }, {
          "x": "18",
          "y": "30",
          "img": "c1 "
      }, {
          "x": "20",
          "y": "30",
          "img": "c2_2"
      }]
  }, {
      "name": "eye_dot_left",
      "width": "90",
      "height": "82",
      "lstLayerImage": [{
          "x": "34",
          "y": "41",
          "img": "Layer 96"
      }]
  }, {
      "name": "eye_dot_right",
      "width": "89",
      "height": "82",
      "lstLayerImage": [{
          "x": "35",
          "y": "42",
          "img": "Layer 96 copy"
      }]
  }, {
      "name": "eye_line",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "63",
          "y": "39",
          "img": "Layer 97 copy 3"
      }, {
          "x": "65",
          "y": "41",
          "img": "line_5"
      }, {
          "x": "69",
          "y": "43",
          "img": "Layer 97 copy 2"
      }, {
          "x": "73",
          "y": "67",
          "img": "line_4"
      }]
  }, {
      "name": "eye_mask",
      "width": "272",
      "height": "237",
      "lstLayerImage": []
  }]
}, {
  "mPart": "eye",
  "genID": "204",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "eye_base",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "79",
          "y": "49",
          "img": "Layer 95 copy 2"
      }]
  }, {
      "name": "eye_cheek",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "18",
          "y": "14",
          "img": "line_4"
      }, {
          "x": "24",
          "y": "21",
          "img": "c1_3"
      }, {
          "x": "26",
          "y": "22",
          "img": "c2_2"
      }, {
          "x": "40",
          "y": "50",
          "img": "c3"
      }, {
          "x": "24",
          "y": "22",
          "img": "Layer 128"
      }]
  }, {
      "name": "eye_dot_left",
      "width": "90",
      "height": "82",
      "lstLayerImage": [{
          "x": "31",
          "y": "34",
          "img": "Layer 118"
      }]
  }, {
      "name": "eye_dot_right",
      "width": "89",
      "height": "82",
      "lstLayerImage": [{
          "x": "31",
          "y": "34",
          "img": "Layer 118 copy 2"
      }]
  }, {
      "name": "eye_line",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "79",
          "y": "57",
          "img": "Layer 125"
      }, {
          "x": "63",
          "y": "45",
          "img": "Layer 127"
      }, {
          "x": "62",
          "y": "48",
          "img": "Layer 124"
      }, {
          "x": "71",
          "y": "81",
          "img": "Layer 126"
      }]
  }, {
      "name": "eye_mask",
      "width": "272",
      "height": "237",
      "lstLayerImage": []
  }]
}, {
  "mPart": "eye",
  "genID": "205",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "eye_base",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "70",
          "y": "39",
          "img": "Layer 95 copy 2"
      }]
  }, {
      "name": "eye_cheek",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "44",
          "y": "38",
          "img": "]"
      }]
  }, {
      "name": "eye_dot_left",
      "width": "90",
      "height": "82",
      "lstLayerImage": [{
          "x": "31",
          "y": "28",
          "img": "Layer 118"
      }]
  }, {
      "name": "eye_dot_right",
      "width": "89",
      "height": "82",
      "lstLayerImage": [{
          "x": "32",
          "y": "31",
          "img": "Layer 118 copy"
      }]
  }, {
      "name": "eye_line",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "69",
          "y": "47",
          "img": "Layer 119"
      }]
  }, {
      "name": "eye_mask",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "37",
          "y": "45",
          "img": "Layer 116"
      }, {
          "x": "26",
          "y": "47",
          "img": "line_4"
      }, {
          "x": "29",
          "y": "49",
          "img": "Layer 115"
      }]
  }]
}, {
  "mPart": "eye",
  "genID": "206",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "eye_base",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "78",
          "y": "56",
          "img": "Layer 95 copy 2"
      }]
  }, {
      "name": "eye_cheek",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "14",
          "y": "26",
          "img": "line_4"
      }, {
          "x": "18",
          "y": "30",
          "img": "c1 copy"
      }, {
          "x": "20",
          "y": "30",
          "img": "c2_2"
      }]
  }, {
      "name": "eye_dot_left",
      "width": "90",
      "height": "82",
      "lstLayerImage": [{
          "x": "36",
          "y": "40",
          "img": "Ellipse 1"
      }]
  }, {
      "name": "eye_dot_right",
      "width": "89",
      "height": "82",
      "lstLayerImage": [{
          "x": "36",
          "y": "40",
          "img": "Ellipse 1 copy"
      }]
  }, {
      "name": "eye_line",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "64",
          "y": "47",
          "img": "Layer 101"
      }, {
          "x": "74",
          "y": "56",
          "img": "Layer 100 copy"
      }, {
          "x": "65",
          "y": "56",
          "img": "Layer 99 copy"
      }, {
          "x": "63",
          "y": "51",
          "img": "c4_1"
      }]
  }, {
      "name": "eye_mask",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "55",
          "y": "39",
          "img": "Layer 100"
      }]
  }]
}, {
  "mPart": "eye",
  "genID": "207",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "eye_base",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "70",
          "y": "37",
          "img": "Layer 95 copy 2"
      }]
  }, {
      "name": "eye_cheek",
      "width": "272",
      "height": "237",
      "lstLayerImage": []
  }, {
      "name": "eye_dot_left",
      "width": "90",
      "height": "82",
      "lstLayerImage": [{
          "x": "34",
          "y": "32",
          "img": "Layer 109"
      }]
  }, {
      "name": "eye_dot_right",
      "width": "89",
      "height": "82",
      "lstLayerImage": [{
          "x": "38",
          "y": "30",
          "img": "Layer 109 copy"
      }]
  }, {
      "name": "eye_line",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "70",
          "y": "50",
          "img": "Layer 108"
      }]
  }, {
      "name": "eye_mask",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "34",
          "y": "54",
          "img": "line_4"
      }, {
          "x": "35",
          "y": "56",
          "img": "Layer 107 copy"
      }]
  }]
}, {
  "mPart": "mouth",
  "genID": "21",
  "mClass": "support",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "mouth",
      "width": "41",
      "height": "116",
      "lstLayerImage": [{
          "x": "11",
          "y": "39",
          "img": "c2_1"
      }, {
          "x": "7",
          "y": "45",
          "img": "line_6"
      }]
  }]
}, {
  "mPart": "hat",
  "genID": "22",
  "mClass": "support",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "hat",
      "width": "242",
      "height": "151",
      "lstLayerImage": [{
          "x": "40",
          "y": "2",
          "img": "line_4"
      }, {
          "x": "45",
          "y": "3",
          "img": "toc"
      }]
  }]
}, {
  "mPart": "tail",
  "genID": "23",
  "mClass": "support",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "tail",
      "width": "198",
      "height": "123",
      "lstLayerImage": [{
          "x": "58",
          "y": "26",
          "img": "line_13"
      }, {
          "x": "62",
          "y": "30",
          "img": "c2_4"
      }, {
          "x": "62",
          "y": "40",
          "img": "c1_9"
      }, {
          "x": "64",
          "y": "58",
          "img": "Layer 99"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "24",
  "mClass": "support",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "weapon_back",
      "width": "428",
      "height": "393",
      "lstLayerImage": [{
          "x": "44",
          "y": "107",
          "img": "line_16"
      }, {
          "x": "48",
          "y": "111",
          "img": "gio"
      }, {
          "x": "48",
          "y": "209",
          "img": "c4_5"
      }, {
          "x": "55",
          "y": "222",
          "img": "Layer 100"
      }]
  }, {
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "107",
          "y": "24",
          "img": "line_2"
      }, {
          "x": "111",
          "y": "28",
          "img": "melon"
      }]
  }]
}, {
  "mPart": "ear",
  "genID": "25",
  "mClass": "warrior",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "ear_left",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "3",
          "y": "23",
          "img": "c1_2"
      }, {
          "x": "6",
          "y": "23",
          "img": "c2"
      }, {
          "x": "6",
          "y": "46",
          "img": "c3"
      }, {
          "x": "4",
          "y": "33",
          "img": "c4_1"
      }, {
          "x": "2",
          "y": "19",
          "img": "line_3"
      }]
  }, {
      "name": "ear_right",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "44",
          "y": "23",
          "img": "c1_8"
      }, {
          "x": "70",
          "y": "23",
          "img": "c2_5"
      }, {
          "x": "49",
          "y": "46",
          "img": "c3_2"
      }, {
          "x": "100",
          "y": "33",
          "img": "c4_5"
      }, {
          "x": "43",
          "y": "19",
          "img": "line_15"
      }]
  }]
}, {
  "mPart": "eye",
  "genID": "26",
  "mClass": "warrior",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "eye_base",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "78",
          "y": "43",
          "img": "Layer 80"
      }]
  }, {
      "name": "eye_cheek",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "40",
          "y": "64",
          "img": "c2_2"
      }, {
          "x": "41",
          "y": "62",
          "img": "Layer 82"
      }]
  }, {
      "name": "eye_dot_left",
      "width": "90",
      "height": "82",
      "lstLayerImage": [{
          "x": "34",
          "y": "29",
          "img": "line_8"
      }, {
          "x": "38",
          "y": "33",
          "img": "c4_3"
      }]
  }, {
      "name": "eye_dot_right",
      "width": "89",
      "height": "82",
      "lstLayerImage": [{
          "x": "34",
          "y": "29",
          "img": "line_7"
      }, {
          "x": "38",
          "y": "33",
          "img": "c4_2"
      }]
  }, {
      "name": "eye_line",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "68",
          "y": "40",
          "img": "c2_1"
      }, {
          "x": "74",
          "y": "44",
          "img": "line_6"
      }]
  }, {
      "name": "eye_mask",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "31",
          "y": "53",
          "img": "line_5"
      }, {
          "x": "35",
          "y": "55",
          "img": "Layer 81"
      }]
  }]
}, {
  "mPart": "mouth",
  "genID": "27",
  "mClass": "warrior",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "mouth",
      "width": "41",
      "height": "116",
      "lstLayerImage": [{
          "x": "1",
          "y": "45",
          "img": "c2_3"
      }, {
          "x": "1",
          "y": "42",
          "img": "line_9"
      }]
  }]
}, {
  "mPart": "hat",
  "genID": "28",
  "mClass": "warrior",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "hat",
      "width": "242",
      "height": "151",
      "lstLayerImage": [{
          "x": "55",
          "y": "6",
          "img": "line_4"
      }, {
          "x": "63",
          "y": "8",
          "img": "yo"
      }]
  }]
}, {
  "mPart": "tail",
  "genID": "29",
  "mClass": "warrior",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "tail",
      "width": "198",
      "height": "123",
      "lstLayerImage": [{
          "x": "60",
          "y": "30",
          "img": "line_16"
      }, {
          "x": "64",
          "y": "35",
          "img": "c1_9"
      }, {
          "x": "64",
          "y": "34",
          "img": "c2_6"
      }, {
          "x": "68",
          "y": "58",
          "img": "c3_3"
      }]
  }]
}, {
  "mPart": "mouth",
  "genID": "3",
  "mClass": "ranger",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "mouth",
      "width": "41",
      "height": "116",
      "lstLayerImage": [{
          "x": "4",
          "y": "42",
          "img": "Layer 91"
      }, {
          "x": "2",
          "y": "40",
          "img": "line_8"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "30",
  "mClass": "warrior",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "102",
          "y": "50",
          "img": "line_2"
      }, {
          "x": "107",
          "y": "53",
          "img": "Layer 87"
      }, {
          "x": "107",
          "y": "66",
          "img": "c4"
      }]
  }]
}, {
  "mPart": "ear",
  "genID": "31",
  "mClass": "ranger",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "ear_left",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "24",
          "y": "0",
          "img": "c1_2"
      }, {
          "x": "36",
          "y": "30",
          "img": "c2"
      }, {
          "x": "44",
          "y": "28",
          "img": "tai"
      }, {
          "x": "23",
          "y": "0",
          "img": "line_3"
      }]
  }, {
      "name": "ear_right",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "54",
          "y": "0",
          "img": "c1_8"
      }, {
          "x": "72",
          "y": "30",
          "img": "c2_6"
      }, {
          "x": "55",
          "y": "28",
          "img": "c4_3"
      }, {
          "x": "53",
          "y": "0",
          "img": "line_13"
      }]
  }]
}, {
  "mPart": "eye",
  "genID": "32",
  "mClass": "ranger",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "eye_base",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "77",
          "y": "37",
          "img": "Layer 111"
      }]
  }, {
      "name": "eye_cheek",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "11",
          "y": "35",
          "img": "line_6"
      }, {
          "x": "19",
          "y": "40",
          "img": "c2_4"
      }, {
          "x": "37",
          "y": "48",
          "img": "Layer 114"
      }]
  }, {
      "name": "eye_dot_left",
      "width": "90",
      "height": "82",
      "lstLayerImage": [{
          "x": "34",
          "y": "30",
          "img": "c4_1"
      }]
  }, {
      "name": "eye_dot_right",
      "width": "89",
      "height": "82",
      "lstLayerImage": [{
          "x": "34",
          "y": "30",
          "img": "c4"
      }]
  }, {
      "name": "eye_line",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "69",
          "y": "34",
          "img": "c2_3"
      }, {
          "x": "76",
          "y": "37",
          "img": "line_5"
      }]
  }, {
      "name": "eye_mask",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "111",
          "y": "122",
          "img": "c2_2"
      }]
  }]
}, {
  "mPart": "mouth",
  "genID": "33",
  "mClass": "ranger",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "mouth",
      "width": "41",
      "height": "116",
      "lstLayerImage": [{
          "x": "9",
          "y": "15",
          "img": "line_7"
      }, {
          "x": "11",
          "y": "18",
          "img": "Layer 126"
      }]
  }]
}, {
  "mPart": "hat",
  "genID": "34",
  "mClass": "ranger",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "hat",
      "width": "242",
      "height": "151",
      "lstLayerImage": [{
          "x": "49",
          "y": "12",
          "img": "c2_1"
      }, {
          "x": "42",
          "y": "15",
          "img": "line_4"
      }, {
          "x": "46",
          "y": "17",
          "img": "toc"
      }]
  }]
}, {
  "mPart": "tail",
  "genID": "35",
  "mClass": "ranger",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "tail",
      "width": "198",
      "height": "123",
      "lstLayerImage": [{
          "x": "55",
          "y": "26",
          "img": "line_14"
      }, {
          "x": "59",
          "y": "30",
          "img": "Layer 123 copy 2"
      }, {
          "x": "59",
          "y": "31",
          "img": "Layer 124"
      }, {
          "x": "59",
          "y": "51",
          "img": "duoili"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "36",
  "mClass": "ranger",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "weapon_back",
      "width": "428",
      "height": "393",
      "lstLayerImage": [{
          "x": "20",
          "y": "105",
          "img": "line_17"
      }, {
          "x": "24",
          "y": "109",
          "img": "quat"
      }, {
          "x": "71",
          "y": "175",
          "img": "c4_4"
      }]
  }, {
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "77",
          "y": "4",
          "img": "line_2"
      }, {
          "x": "81",
          "y": "8",
          "img": "tenthuong"
      }]
  }]
}, {
  "mPart": "ear",
  "genID": "37",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "ear_left",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "3",
          "y": "6",
          "img": "line_4"
      }, {
          "x": "7",
          "y": "6",
          "img": "c1_2"
      }, {
          "x": "20",
          "y": "64",
          "img": "c3"
      }, {
          "x": "26",
          "y": "22",
          "img": "Vector Smart Object"
      }, {
          "x": "6",
          "y": "5",
          "img": "c2"
      }]
  }, {
      "name": "ear_right",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "46",
          "y": "6",
          "img": "line_15"
      }, {
          "x": "46",
          "y": "6",
          "img": "c1_9"
      }, {
          "x": "48",
          "y": "64",
          "img": "c3_3"
      }, {
          "x": "59",
          "y": "22",
          "img": "Vector Smart Object copy"
      }, {
          "x": "79",
          "y": "5",
          "img": "c2_6"
      }]
  }]
}, {
  "mPart": "eye",
  "genID": "38",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "eye_base",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "46",
          "y": "24",
          "img": "c2_2"
      }, {
          "x": "87",
          "y": "42",
          "img": "Layer 116"
      }]
  }, {
      "name": "eye_cheek",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "16",
          "y": "28",
          "img": "line_8"
      }, {
          "x": "224",
          "y": "47",
          "img": "line_7"
      }, {
          "x": "223",
          "y": "46",
          "img": "c2_4"
      }, {
          "x": "222",
          "y": "50",
          "img": "c3_1"
      }, {
          "x": "23",
          "y": "33",
          "img": "c2_3"
      }, {
          "x": "23",
          "y": "39",
          "img": "c1_3"
      }]
  }, {
      "name": "eye_dot_left",
      "width": "90",
      "height": "82",
      "lstLayerImage": [{
          "x": "34",
          "y": "30",
          "img": "c4_2"
      }]
  }, {
      "name": "eye_dot_right",
      "width": "89",
      "height": "82",
      "lstLayerImage": [{
          "x": "34",
          "y": "31",
          "img": "c4_1"
      }]
  }, {
      "name": "eye_line",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "81",
          "y": "39",
          "img": "Layer 116 copy"
      }]
  }, {
      "name": "eye_mask",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "81",
          "y": "55",
          "img": "line_6"
      }, {
          "x": "83",
          "y": "58",
          "img": "mask"
      }]
  }]
}, {
  "mPart": "mouth",
  "genID": "39",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "mouth",
      "width": "41",
      "height": "116",
      "lstLayerImage": [{
          "x": "4",
          "y": "28",
          "img": "Layer 118"
      }, {
          "x": "15",
          "y": "41",
          "img": "line_9"
      }]
  }]
}, {
  "mPart": "hat",
  "genID": "4",
  "mClass": "ranger",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "hat",
      "width": "242",
      "height": "151",
      "lstLayerImage": [{
          "x": "11",
          "y": "8",
          "img": "tpc"
      }, {
          "x": "3",
          "y": "5",
          "img": "line_4"
      }]
  }]
}, {
  "mPart": "hat",
  "genID": "40",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "hat",
      "width": "242",
      "height": "151",
      "lstLayerImage": [{
          "x": "85",
          "y": "0",
          "img": "c2_1"
      }, {
          "x": "82",
          "y": "6",
          "img": "line_5"
      }, {
          "x": "87",
          "y": "8",
          "img": "Layer 114"
      }]
  }]
}, {
  "mPart": "tail",
  "genID": "41",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "tail",
      "width": "198",
      "height": "123",
      "lstLayerImage": [{
          "x": "74",
          "y": "42",
          "img": "chup"
      }, {
          "x": "34",
          "y": "44",
          "img": "c4_4"
      }, {
          "x": "34",
          "y": "38",
          "img": "line_16"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "42",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "weapon_right",
      "width": "428",
      "height": "219",
      "lstLayerImage": [{
          "x": "145",
          "y": "58",
          "img": "line_18"
      }, {
          "x": "149",
          "y": "62",
          "img": "kiem1 copy 2"
      }, {
          "x": "197",
          "y": "49",
          "img": "line_17"
      }, {
          "x": "149",
          "y": "53",
          "img": "c4_5"
      }]
  }, {
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "100",
          "y": "63",
          "img": "line_3"
      }, {
          "x": "104",
          "y": "67",
          "img": "kiem1 copy"
      }, {
          "x": "152",
          "y": "54",
          "img": "line_2"
      }, {
          "x": "103",
          "y": "58",
          "img": "c4"
      }]
  }]
}, {
  "mPart": "ear",
  "genID": "43",
  "mClass": "mage",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "ear_left",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "18",
          "y": "7",
          "img": "line_3"
      }, {
          "x": "22",
          "y": "11",
          "img": "c1_2"
      }, {
          "x": "30",
          "y": "53",
          "img": "c3"
      }, {
          "x": "32",
          "y": "20",
          "img": "Layer 101"
      }, {
          "x": "29",
          "y": "69",
          "img": "line_2"
      }]
  }, {
      "name": "ear_right",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "64",
          "y": "7",
          "img": "line_13"
      }, {
          "x": "64",
          "y": "11",
          "img": "c1_9"
      }, {
          "x": "76",
          "y": "53",
          "img": "c3_2"
      }, {
          "x": "86",
          "y": "20",
          "img": "Layer 101 copy"
      }, {
          "x": "87",
          "y": "69",
          "img": "line_12"
      }]
  }]
}, {
  "mPart": "eye",
  "genID": "44",
  "mClass": "mage",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "eye_base",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "83",
          "y": "39",
          "img": "base"
      }]
  }, {
      "name": "eye_cheek",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "36",
          "y": "42",
          "img": "Layer 102 copy"
      }]
  }, {
      "name": "eye_dot_left",
      "width": "90",
      "height": "82",
      "lstLayerImage": [{
          "x": "36",
          "y": "33",
          "img": "d_1"
      }, {
          "x": "36",
          "y": "33",
          "img": "d copy"
      }]
  }, {
      "name": "eye_dot_right",
      "width": "89",
      "height": "82",
      "lstLayerImage": [{
          "x": "39",
          "y": "33",
          "img": "d"
      }]
  }, {
      "name": "eye_line",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "67",
          "y": "35",
          "img": "c2"
      }, {
          "x": "72",
          "y": "39",
          "img": "line_5"
      }]
  }, {
      "name": "eye_mask",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "81",
          "y": "38",
          "img": "c4"
      }]
  }]
}, {
  "mPart": "mouth",
  "genID": "45",
  "mClass": "mage",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "mouth",
      "width": "41",
      "height": "116",
      "lstLayerImage": [{
          "x": "1",
          "y": "47",
          "img": "line_6"
      }, {
          "x": "4",
          "y": "49",
          "img": "Layer 102"
      }]
  }]
}, {
  "mPart": "hat",
  "genID": "46",
  "mClass": "mage",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "hat",
      "width": "242",
      "height": "151",
      "lstLayerImage": [{
          "x": "62",
          "y": "25",
          "img": "line_4"
      }, {
          "x": "66",
          "y": "17",
          "img": "c1_3"
      }, {
          "x": "79",
          "y": "42",
          "img": "chop"
      }]
  }]
}, {
  "mPart": "tail",
  "genID": "47",
  "mClass": "mage",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "tail",
      "width": "198",
      "height": "123",
      "lstLayerImage": [{
          "x": "30",
          "y": "15",
          "img": "line_14"
      }, {
          "x": "34",
          "y": "19",
          "img": "duoi"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "48",
  "mClass": "mage",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "weapon_back",
      "width": "428",
      "height": "393",
      "lstLayerImage": [{
          "x": "27",
          "y": "80",
          "img": "line_17"
      }, {
          "x": "31",
          "y": "84",
          "img": "back"
      }]
  }]
}, {
  "mPart": "ear",
  "genID": "49",
  "mClass": "support",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "ear_left",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "16",
          "y": "4",
          "img": "line_3"
      }, {
          "x": "20",
          "y": "6",
          "img": "c1_2"
      }, {
          "x": "20",
          "y": "12",
          "img": "c2"
      }, {
          "x": "27",
          "y": "18",
          "img": "loi"
      }]
  }, {
      "name": "ear_right",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "65",
          "y": "4",
          "img": "line copy"
      }, {
          "x": "64",
          "y": "6",
          "img": "c1 copy"
      }, {
          "x": "101",
          "y": "12",
          "img": "c2 copy"
      }, {
          "x": "77",
          "y": "18",
          "img": "loi copy"
      }]
  }]
}, {
  "mPart": "tail",
  "genID": "5",
  "mClass": "ranger",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "tail",
      "width": "198",
      "height": "123",
      "lstLayerImage": [{
          "x": "42",
          "y": "30",
          "img": "line_16"
      }, {
          "x": "48",
          "y": "33",
          "img": "c1_9"
      }, {
          "x": "48",
          "y": "33",
          "img": "c2_5"
      }, {
          "x": "44",
          "y": "29",
          "img": "line_15"
      }]
  }]
}, {
  "mPart": "eye",
  "genID": "50",
  "mClass": "support",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "eye_base",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "80",
          "y": "31",
          "img": "c2_2"
      }, {
          "x": "83",
          "y": "36",
          "img": "base"
      }]
  }, {
      "name": "eye_cheek",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "18",
          "y": "22",
          "img": "line_7"
      }, {
          "x": "22",
          "y": "26",
          "img": "c1_3"
      }, {
          "x": "22",
          "y": "25",
          "img": "c2_3"
      }]
  }, {
      "name": "eye_dot_left",
      "width": "90",
      "height": "82",
      "lstLayerImage": [{
          "x": "35",
          "y": "29",
          "img": "c4_2"
      }]
  }, {
      "name": "eye_dot_right",
      "width": "89",
      "height": "82",
      "lstLayerImage": [{
          "x": "36",
          "y": "29",
          "img": "c4_1"
      }]
  }, {
      "name": "eye_line",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "83",
          "y": "36",
          "img": "line_6"
      }]
  }, {
      "name": "eye_mask",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "160",
          "y": "120",
          "img": "c2_1"
      }, {
          "x": "163",
          "y": "123",
          "img": "line_5"
      }, {
          "x": "165",
          "y": "125",
          "img": "c4"
      }]
  }]
}, {
  "mPart": "mouth",
  "genID": "51",
  "mClass": "support",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "mouth",
      "width": "41",
      "height": "116",
      "lstLayerImage": [{
          "x": "2",
          "y": "34",
          "img": "mom"
      }, {
          "x": "1",
          "y": "32",
          "img": "line_8"
      }]
  }]
}, {
  "mPart": "hat",
  "genID": "52",
  "mClass": "support",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "hat",
      "width": "242",
      "height": "151",
      "lstLayerImage": [{
          "x": "70",
          "y": "18",
          "img": "shad"
      }, {
          "x": "62",
          "y": "22",
          "img": "shushi"
      }, {
          "x": "61",
          "y": "20",
          "img": "line_4"
      }]
  }]
}, {
  "mPart": "tail",
  "genID": "53",
  "mClass": "support",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "tail",
      "width": "198",
      "height": "123",
      "lstLayerImage": [{
          "x": "31",
          "y": "32",
          "img": "line_14"
      }, {
          "x": "35",
          "y": "36",
          "img": "cao"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "54",
  "mClass": "support",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "weapon_back",
      "width": "428",
      "height": "393",
      "lstLayerImage": [{
          "x": "32",
          "y": "100",
          "img": "line_17"
      }, {
          "x": "36",
          "y": "104",
          "img": "backshushi"
      }]
  }, {
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "112",
          "y": "36",
          "img": "line_2"
      }, {
          "x": "116",
          "y": "40",
          "img": "kem"
      }]
  }]
}, {
  "mPart": "ear",
  "genID": "55",
  "mClass": "warrior",
  "mType": "legend",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "ear_left",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "18",
          "y": "0",
          "img": "line_4"
      }, {
          "x": "24",
          "y": "3",
          "img": "c1_2"
      }, {
          "x": "35",
          "y": "28",
          "img": "Layer 91"
      }, {
          "x": "40",
          "y": "34",
          "img": "c3"
      }, {
          "x": "29",
          "y": "20",
          "img": "den"
      }, {
          "x": "39",
          "y": "33",
          "img": "line_3"
      }]
  }, {
      "name": "ear_right",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "58",
          "y": "0",
          "img": "line_19"
      }, {
          "x": "58",
          "y": "3",
          "img": "den_1"
      }]
  }]
}, {
  "mPart": "eye",
  "genID": "56",
  "mClass": "warrior",
  "mType": "legend",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "eye_base",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "77",
          "y": "39",
          "img": "Layer 80"
      }]
  }, {
      "name": "eye_cheek",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "11",
          "y": "42",
          "img": "line_12"
      }, {
          "x": "16",
          "y": "47",
          "img": "c2_2"
      }, {
          "x": "230",
          "y": "51",
          "img": "c1_3"
      }, {
          "x": "16",
          "y": "47",
          "img": "tifg"
      }]
  }, {
      "name": "eye_dot_left",
      "width": "90",
      "height": "82",
      "lstLayerImage": [{
          "x": "25",
          "y": "33",
          "img": "c4_3"
      }]
  }, {
      "name": "eye_dot_right",
      "width": "89",
      "height": "82",
      "lstLayerImage": [{
          "x": "31",
          "y": "33",
          "img": "c4_2"
      }]
  }, {
      "name": "eye_line",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "64",
          "y": "31",
          "img": "c2_1"
      }, {
          "x": "66",
          "y": "38",
          "img": "line_11"
      }]
  }, {
      "name": "eye_mask",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "53",
          "y": "51",
          "img": "shad"
      }, {
          "x": "87",
          "y": "54",
          "img": "line_10"
      }, {
          "x": "81",
          "y": "79",
          "img": "line_9"
      }, {
          "x": "89",
          "y": "56",
          "img": "Layer 94 copy"
      }, {
          "x": "83",
          "y": "81",
          "img": "c4_1"
      }, {
          "x": "68",
          "y": "113",
          "img": "line_8"
      }, {
          "x": "70",
          "y": "115",
          "img": "Layer 100"
      }, {
          "x": "47",
          "y": "68",
          "img": "line_7"
      }, {
          "x": "49",
          "y": "70",
          "img": "Layer 98"
      }, {
          "x": "53",
          "y": "82",
          "img": "Layer 98 copy 2"
      }, {
          "x": "56",
          "y": "85",
          "img": "Layer 98 copy"
      }, {
          "x": "58",
          "y": "87",
          "img": "Layer 98 copy 3"
      }]
  }]
}, {
  "mPart": "mouth",
  "genID": "57",
  "mClass": "warrior",
  "mType": "legend",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "mouth",
      "width": "41",
      "height": "116",
      "lstLayerImage": [{
          "x": "10",
          "y": "35",
          "img": "m"
      }, {
          "x": "9",
          "y": "34",
          "img": "line_13"
      }]
  }]
}, {
  "mPart": "hat",
  "genID": "58",
  "mClass": "warrior",
  "mType": "legend",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "hat",
      "width": "242",
      "height": "151",
      "lstLayerImage": [{
          "x": "61",
          "y": "3",
          "img": "c2"
      }, {
          "x": "55",
          "y": "33",
          "img": "line_6"
      }, {
          "x": "58",
          "y": "36",
          "img": "duck"
      }, {
          "x": "57",
          "y": "6",
          "img": "line_5"
      }, {
          "x": "61",
          "y": "8",
          "img": "Layer 88"
      }]
  }]
}, {
  "mPart": "tail",
  "genID": "59",
  "mClass": "warrior",
  "mType": "legend",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "tail",
      "width": "198",
      "height": "123",
      "lstLayerImage": [{
          "x": "49",
          "y": "30",
          "img": "line_20"
      }, {
          "x": "53",
          "y": "56",
          "img": "c4_5"
      }, {
          "x": "58",
          "y": "34",
          "img": "dinorsaur"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "6",
  "mClass": "ranger",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "weapon_back",
      "width": "428",
      "height": "393",
      "lstLayerImage": [{
          "x": "38",
          "y": "136",
          "img": "cuon"
      }, {
          "x": "36",
          "y": "132",
          "img": "line_19"
      }]
  }, {
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "83",
          "y": "2",
          "img": "line_2"
      }, {
          "x": "89",
          "y": "7",
          "img": "tieu"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "60",
  "mClass": "warrior",
  "mType": "legend",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "94",
          "y": "13",
          "img": "line_2"
      }, {
          "x": "98",
          "y": "17",
          "img": "fury"
      }, {
          "x": "236",
          "y": "44",
          "img": "Layer 101"
      }, {
          "x": "98",
          "y": "73",
          "img": "c4"
      }]
  }]
}, {
  "mPart": "ear",
  "genID": "61",
  "mClass": "ranger",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "ear_left",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "2",
          "y": "29",
          "img": "line_4"
      }, {
          "x": "6",
          "y": "33",
          "img": "c2"
      }, {
          "x": "50",
          "y": "33",
          "img": "c1_2"
      }, {
          "x": "11",
          "y": "39",
          "img": "c3"
      }, {
          "x": "23",
          "y": "43",
          "img": "line_3"
      }]
  }, {
      "name": "ear_right",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "45",
          "y": "27",
          "img": "line_15"
      }, {
          "x": "45",
          "y": "31",
          "img": "c2_5"
      }, {
          "x": "45",
          "y": "31",
          "img": "c1_8"
      }, {
          "x": "60",
          "y": "37",
          "img": "c3_2"
      }, {
          "x": "70",
          "y": "41",
          "img": "line_14"
      }]
  }]
}, {
  "mPart": "eye",
  "genID": "62",
  "mClass": "ranger",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "eye_base",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "88",
          "y": "47",
          "img": "Layer 90"
      }]
  }, {
      "name": "eye_cheek",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "37",
          "y": "47",
          "img": "c2_3"
      }]
  }, {
      "name": "eye_dot_left",
      "width": "90",
      "height": "82",
      "lstLayerImage": [{
          "x": "37",
          "y": "34",
          "img": "c4_2"
      }]
  }, {
      "name": "eye_dot_right",
      "width": "89",
      "height": "82",
      "lstLayerImage": [{
          "x": "38",
          "y": "34",
          "img": "c4_1"
      }]
  }, {
      "name": "eye_line",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "76",
          "y": "64",
          "img": "c2_2"
      }, {
          "x": "79",
          "y": "63",
          "img": "line_7"
      }]
  }, {
      "name": "eye_mask",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "88",
          "y": "37",
          "img": "line_6"
      }, {
          "x": "90",
          "y": "39",
          "img": "c4"
      }]
  }]
}, {
  "mPart": "mouth",
  "genID": "63",
  "mClass": "ranger",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "mouth",
      "width": "41",
      "height": "116",
      "lstLayerImage": [{
          "x": "5",
          "y": "39",
          "img": "Layer 95"
      }, {
          "x": "6",
          "y": "45",
          "img": "line_8"
      }, {
          "x": "8",
          "y": "47",
          "img": "mpo"
      }]
  }]
}, {
  "mPart": "hat",
  "genID": "64",
  "mClass": "ranger",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "hat",
      "width": "242",
      "height": "151",
      "lstLayerImage": [{
          "x": "55",
          "y": "12",
          "img": "c2_1"
      }, {
          "x": "25",
          "y": "14",
          "img": "line_5"
      }, {
          "x": "29",
          "y": "16",
          "img": "Layer 94"
      }]
  }]
}, {
  "mPart": "tail",
  "genID": "65",
  "mClass": "ranger",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "tail",
      "width": "198",
      "height": "123",
      "lstLayerImage": [{
          "x": "30",
          "y": "29",
          "img": "line_16"
      }, {
          "x": "34",
          "y": "33",
          "img": "Layer 97"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "66",
  "mClass": "ranger",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "weapon_back",
      "width": "428",
      "height": "393",
      "lstLayerImage": [{
          "x": "34",
          "y": "102",
          "img": "line_20"
      }, {
          "x": "42",
          "y": "108",
          "img": "egg"
      }, {
          "x": "38",
          "y": "106",
          "img": "c4_4"
      }, {
          "x": "58",
          "y": "151",
          "img": "Layer 98"
      }, {
          "x": "38",
          "y": "158",
          "img": "line_19"
      }, {
          "x": "40",
          "y": "160",
          "img": "Vector Smart Object"
      }]
  }, {
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "107",
          "y": "23",
          "img": "line_2"
      }, {
          "x": "111",
          "y": "27",
          "img": "bonemeat"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "67",
  "mClass": "ranger",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "weapon_back",
      "width": "428",
      "height": "393",
      "lstLayerImage": [{
          "x": "21",
          "y": "110",
          "img": "hanh"
      }, {
          "x": "17",
          "y": "107",
          "img": "line_19"
      }]
  }, {
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "132",
          "y": "29",
          "img": "chao "
      }, {
          "x": "75",
          "y": "48",
          "img": "c4_1"
      }, {
          "x": "118",
          "y": "37",
          "img": "c4"
      }, {
          "x": "200",
          "y": "52",
          "img": "trung"
      }, {
          "x": "64",
          "y": "25",
          "img": "line_2"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "68",
  "mClass": "ranger",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "weapon_back",
      "width": "428",
      "height": "393",
      "lstLayerImage": [{
          "x": "0",
          "y": "116",
          "img": "line_21"
      }, {
          "x": "4",
          "y": "120",
          "img": "Layer 106"
      }, {
          "x": "19",
          "y": "274",
          "img": "line_20"
      }, {
          "x": "23",
          "y": "276",
          "img": "star"
      }]
  }, {
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "106",
          "y": "24",
          "img": "line_3"
      }, {
          "x": "110",
          "y": "28",
          "img": "Layer 102"
      }, {
          "x": "198",
          "y": "63",
          "img": "line_2"
      }, {
          "x": "200",
          "y": "65",
          "img": "c4"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "69",
  "mClass": "ranger",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "weapon_back",
      "width": "428",
      "height": "393",
      "lstLayerImage": [{
          "x": "1",
          "y": "94",
          "img": "line_19"
      }, {
          "x": "5",
          "y": "98",
          "img": "Group 1 copy"
      }]
  }, {
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "101",
          "y": "46",
          "img": "line_2"
      }, {
          "x": "105",
          "y": "50",
          "img": "Layer 111 copy"
      }]
  }]
}, {
  "mPart": "ear",
  "genID": "7",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "ear_left",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "31",
          "y": "21",
          "img": "c1_2"
      }, {
          "x": "37",
          "y": "22",
          "img": "c2"
      }, {
          "x": "42",
          "y": "60",
          "img": "c3"
      }, {
          "x": "28",
          "y": "11",
          "img": "line_4"
      }, {
          "x": "10",
          "y": "14",
          "img": "line_3"
      }, {
          "x": "15",
          "y": "18",
          "img": "Layer 102"
      }]
  }, {
      "name": "ear_right",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "92",
          "y": "13",
          "img": "line_16"
      }, {
          "x": "82",
          "y": "20",
          "img": "Layer 68 copy"
      }, {
          "x": "47",
          "y": "18",
          "img": "c1_8"
      }, {
          "x": "43",
          "y": "11",
          "img": "line_15"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "70",
  "mClass": "ranger",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "weapon_back",
      "width": "428",
      "height": "393",
      "lstLayerImage": [{
          "x": "26",
          "y": "84",
          "img": "vali"
      }, {
          "x": "63",
          "y": "97",
          "img": "c4_4"
      }, {
          "x": "22",
          "y": "80",
          "img": "line_19"
      }]
  }, {
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "116",
          "y": "7",
          "img": "cup"
      }, {
          "x": "112",
          "y": "3",
          "img": "line_2"
      }, {
          "x": "175",
          "y": "20",
          "img": "c4"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "71",
  "mClass": "ranger",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "weapon_back",
      "width": "428",
      "height": "393",
      "lstLayerImage": [{
          "x": "25",
          "y": "98",
          "img": "line_19"
      }, {
          "x": "29",
          "y": "102",
          "img": "Layer 102"
      }]
  }, {
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "72",
          "y": "18",
          "img": "line_2"
      }, {
          "x": "76",
          "y": "22",
          "img": "Layer 95"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "72",
  "mClass": "ranger",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "weapon_back",
      "width": "428",
      "height": "393",
      "lstLayerImage": [{
          "x": "12",
          "y": "91",
          "img": "cup"
      }, {
          "x": "9",
          "y": "88",
          "img": "line_19"
      }, {
          "x": "22",
          "y": "155",
          "img": "c4_4"
      }]
  }, {
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "73",
          "y": "27",
          "img": "bong bau duc"
      }, {
          "x": "70",
          "y": "24",
          "img": "line_2"
      }, {
          "x": "124",
          "y": "33",
          "img": "c4"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "73",
  "mClass": "ranger",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "weapon_back",
      "width": "428",
      "height": "393",
      "lstLayerImage": [{
          "x": "2",
          "y": "128",
          "img": "line_19"
      }, {
          "x": "6",
          "y": "132",
          "img": "Layer 94 copy"
      }]
  }, {
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "109",
          "y": "39",
          "img": "line_2"
      }, {
          "x": "113",
          "y": "43",
          "img": "Layer 101 copy"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "74",
  "mClass": "ranger",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "weapon_back",
      "width": "428",
      "height": "393",
      "lstLayerImage": [{
          "x": "13",
          "y": "87",
          "img": "go"
      }, {
          "x": "10",
          "y": "84",
          "img": "line_19"
      }]
  }, {
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "90",
          "y": "6",
          "img": "tieu"
      }, {
          "x": "87",
          "y": "3",
          "img": "line_2"
      }, {
          "x": "104",
          "y": "25",
          "img": "c4"
      }]
  }]
}, {
  "mPart": "hat",
  "genID": "75",
  "mClass": "ranger",
  "mType": "normal",
  "poseID": "2",
  "lstGroupLayer": [{
      "name": "hat",
      "width": "242",
      "height": "151",
      "lstLayerImage": [{
          "x": "31",
          "y": "8",
          "img": "line_4"
      }, {
          "x": "35",
          "y": "10",
          "img": "mu"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "76",
  "mClass": "ranger",
  "mType": "normal",
  "poseID": "2",
  "lstGroupLayer": [{
      "name": "weapon_back",
      "width": "428",
      "height": "393",
      "lstLayerImage": [{
          "x": "24",
          "y": "88",
          "img": "line_19"
      }, {
          "x": "28",
          "y": "92",
          "img": "Layer 93"
      }, {
          "x": "33",
          "y": "290",
          "img": "c4_4"
      }]
  }, {
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "110",
          "y": "2",
          "img": "line_2"
      }, {
          "x": "114",
          "y": "6",
          "img": "sung"
      }, {
          "x": "210",
          "y": "31",
          "img": "c4"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "77",
  "mClass": "ranger",
  "mType": "normal",
  "poseID": "3",
  "lstGroupLayer": [{
      "name": "weapon_back",
      "width": "428",
      "height": "393",
      "lstLayerImage": [{
          "x": "78",
          "y": "129",
          "img": "can co "
      }, {
          "x": "10",
          "y": "148",
          "img": "c4_3"
      }, {
          "x": "4",
          "y": "126",
          "img": "line_19"
      }]
  }, {
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "85",
          "y": "23",
          "img": "sung"
      }, {
          "x": "83",
          "y": "21",
          "img": "line_2"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "78",
  "mClass": "ranger",
  "mType": "normal",
  "poseID": "3",
  "lstGroupLayer": [{
      "name": "weapon_back",
      "width": "428",
      "height": "393",
      "lstLayerImage": [{
          "x": "33",
          "y": "82",
          "img": "radio"
      }, {
          "x": "9",
          "y": "152",
          "img": "c4_3"
      }, {
          "x": "6",
          "y": "79",
          "img": "line_19"
      }]
  }, {
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "49",
          "y": "32",
          "img": "sung"
      }, {
          "x": "52",
          "y": "78",
          "img": "Layer 95"
      }, {
          "x": "46",
          "y": "30",
          "img": "line_2"
      }]
  }]
}, {
  "mPart": "hat",
  "genID": "79",
  "mClass": "ranger",
  "mType": "normal",
  "poseID": "4",
  "lstGroupLayer": [{
      "name": "hat",
      "width": "242",
      "height": "151",
      "lstLayerImage": [{
          "x": "62",
          "y": "2",
          "img": "c2_1"
      }, {
          "x": "24",
          "y": "1",
          "img": "line_5"
      }, {
          "x": "28",
          "y": "5",
          "img": "Layer 96"
      }, {
          "x": "98",
          "y": "51",
          "img": "c4"
      }, {
          "x": "38",
          "y": "54",
          "img": "line_4"
      }]
  }]
}, {
  "mPart": "eye",
  "genID": "8",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "eye_base",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "81",
          "y": "41",
          "img": "Layer 90"
      }]
  }, {
      "name": "eye_cheek",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "35",
          "y": "29",
          "img": "c2_4"
      }]
  }, {
      "name": "eye_dot_left",
      "width": "90",
      "height": "82",
      "lstLayerImage": [{
          "x": "34",
          "y": "30",
          "img": "c4_3"
      }]
  }, {
      "name": "eye_dot_right",
      "width": "89",
      "height": "82",
      "lstLayerImage": [{
          "x": "34",
          "y": "30",
          "img": "c4_2"
      }]
  }, {
      "name": "eye_line",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "70",
          "y": "38",
          "img": "c2_3"
      }, {
          "x": "74",
          "y": "40",
          "img": "line_8"
      }]
  }, {
      "name": "eye_mask",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "121",
          "y": "131",
          "img": "c2_2"
      }, {
          "x": "160",
          "y": "116",
          "img": "line_7"
      }, {
          "x": "162",
          "y": "118",
          "img": "ngoc"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "80",
  "mClass": "ranger",
  "mType": "normal",
  "poseID": "4",
  "lstGroupLayer": [{
      "name": "weapon_back",
      "width": "428",
      "height": "393",
      "lstLayerImage": [{
          "x": "6",
          "y": "115",
          "img": "line_21"
      }, {
          "x": "10",
          "y": "119",
          "img": "Layer 95"
      }, {
          "x": "29",
          "y": "260",
          "img": "line_20"
      }, {
          "x": "31",
          "y": "260",
          "img": "Layer 107 copy"
      }]
  }, {
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "22",
          "y": "42",
          "img": "line_2"
      }, {
          "x": "26",
          "y": "46",
          "img": "bow"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "81",
  "mClass": "support",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "weapon_back",
      "width": "428",
      "height": "393",
      "lstLayerImage": [{
          "x": "53",
          "y": "92",
          "img": "line_17"
      }, {
          "x": "57",
          "y": "96",
          "img": "giayVS"
      }]
  }, {
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "71",
          "y": "14",
          "img": "line_2"
      }, {
          "x": "75",
          "y": "18",
          "img": "hut"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "82",
  "mClass": "ranger",
  "mType": "normal",
  "poseID": "3",
  "lstGroupLayer": [{
      "name": "weapon_back",
      "width": "428",
      "height": "393",
      "lstLayerImage": [{
          "x": "10",
          "y": "89",
          "img": "Shape 1"
      }, {
          "x": "5",
          "y": "86",
          "img": "line_19"
      }]
  }, {
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "45",
          "y": "42",
          "img": "awm"
      }, {
          "x": "150",
          "y": "98",
          "img": "c4"
      }, {
          "x": "42",
          "y": "39",
          "img": "line_2"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "83",
  "mClass": "support",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "weapon_back",
      "width": "428",
      "height": "393",
      "lstLayerImage": [{
          "x": "21",
          "y": "88",
          "img": "banh xe"
      }, {
          "x": "12",
          "y": "126",
          "img": "c4 "
      }, {
          "x": "9",
          "y": "85",
          "img": "line_19"
      }]
  }, {
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "84",
          "y": "41",
          "img": "co le"
      }, {
          "x": "81",
          "y": "38",
          "img": "line_2"
      }, {
          "x": "215",
          "y": "67",
          "img": "c4"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "84",
  "mClass": "warrior",
  "mType": "normal",
  "poseID": "2",
  "lstGroupLayer": [{
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "10",
          "y": "45",
          "img": "line_3"
      }, {
          "x": "14",
          "y": "49",
          "img": "Layer 106 copy 3"
      }, {
          "x": "237",
          "y": "73",
          "img": "line_2"
      }, {
          "x": "240",
          "y": "75",
          "img": "Layer 102"
      }, {
          "x": "243",
          "y": "78",
          "img": "c4"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "85",
  "mClass": "warrior",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "119",
          "y": "22",
          "img": "Layer 103"
      }, {
          "x": "229",
          "y": "57",
          "img": "c4_1"
      }, {
          "x": "116",
          "y": "20",
          "img": "line_3"
      }, {
          "x": "127",
          "y": "69",
          "img": "line_2"
      }, {
          "x": "129",
          "y": "73",
          "img": "c4"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "86",
  "mClass": "support",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "weapon_back",
      "width": "428",
      "height": "393",
      "lstLayerImage": [{
          "x": "34",
          "y": "102",
          "img": "line_17"
      }, {
          "x": "38",
          "y": "106",
          "img": "balo"
      }]
  }, {
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "75",
          "y": "66",
          "img": "line_2"
      }, {
          "x": "79",
          "y": "73",
          "img": "dao"
      }, {
          "x": "112",
          "y": "70",
          "img": "c4"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "87",
  "mClass": "support",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "weapon_back",
      "width": "428",
      "height": "393",
      "lstLayerImage": [{
          "x": "48",
          "y": "74",
          "img": "line_17"
      }, {
          "x": "52",
          "y": "78",
          "img": "shield"
      }]
  }, {
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "86",
          "y": "47",
          "img": "line_2"
      }, {
          "x": "90",
          "y": "51",
          "img": "sword "
      }, {
          "x": "172",
          "y": "72",
          "img": "c4"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "88",
  "mClass": "support",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "weapon_back",
      "width": "428",
      "height": "393",
      "lstLayerImage": [{
          "x": "11",
          "y": "89",
          "img": "balo"
      }, {
          "x": "5",
          "y": "86",
          "img": "line_19"
      }, {
          "x": "54",
          "y": "194",
          "img": "c4_3"
      }]
  }, {
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "124",
          "y": "56",
          "img": "golf"
      }, {
          "x": "121",
          "y": "53",
          "img": "line_2"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "89",
  "mClass": "support",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "weapon_back",
      "width": "428",
      "height": "393",
      "lstLayerImage": [{
          "x": "18",
          "y": "82",
          "img": "hom vang "
      }, {
          "x": "15",
          "y": "79",
          "img": "line_19"
      }]
  }, {
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "166",
          "y": "45",
          "img": "tay cam"
      }, {
          "x": "153",
          "y": "47",
          "img": "Layer 93"
      }, {
          "x": "149",
          "y": "42",
          "img": "line_2"
      }]
  }]
}, {
  "mPart": "mouth",
  "genID": "9",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "mouth",
      "width": "41",
      "height": "116",
      "lstLayerImage": [{
          "x": "5",
          "y": "38",
          "img": "c2_5"
      }, {
          "x": "6",
          "y": "50",
          "img": "Layer 109"
      }, {
          "x": "1",
          "y": "46",
          "img": "line_9"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "90",
  "mClass": "warrior",
  "mType": "normal",
  "poseID": "2",
  "lstGroupLayer": [{
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "85",
          "y": "42",
          "img": "choi "
      }, {
          "x": "82",
          "y": "39",
          "img": "line_2"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "91",
  "mClass": "warrior",
  "mType": "normal",
  "poseID": "2",
  "lstGroupLayer": [{
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "91",
          "y": "74",
          "img": "luoi hai "
      }, {
          "x": "88",
          "y": "71",
          "img": "line_2"
      }]
  }]
}, {
  "mPart": "ear",
  "genID": "92",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "ear_left",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "9",
          "y": "0",
          "img": "line_3"
      }, {
          "x": "13",
          "y": "4",
          "img": "c1_2"
      }, {
          "x": "13",
          "y": "10",
          "img": "c3"
      }, {
          "x": "19",
          "y": "25",
          "img": "Layer 106"
      }, {
          "x": "20",
          "y": "26",
          "img": "line_2"
      }]
  }, {
      "name": "ear_right",
      "width": "142",
      "height": "145",
      "lstLayerImage": [{
          "x": "52",
          "y": "0",
          "img": "line_17"
      }, {
          "x": "52",
          "y": "4",
          "img": "c1_8"
      }, {
          "x": "68",
          "y": "10",
          "img": "c3_2"
      }, {
          "x": "71",
          "y": "25",
          "img": "Layer 106 copy"
      }, {
          "x": "72",
          "y": "26",
          "img": "line_16"
      }]
  }]
}, {
  "mPart": "eye",
  "genID": "93",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "eye_base",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "77",
          "y": "39",
          "img": "Layer 80"
      }]
  }, {
      "name": "eye_cheek",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "11",
          "y": "35",
          "img": "line_9"
      }, {
          "x": "15",
          "y": "39",
          "img": "c4_4"
      }, {
          "x": "29",
          "y": "39",
          "img": "Layer 120"
      }, {
          "x": "227",
          "y": "41",
          "img": "line_8"
      }, {
          "x": "229",
          "y": "45",
          "img": "c4_3"
      }, {
          "x": "229",
          "y": "44",
          "img": "Layer 120 copy"
      }]
  }, {
      "name": "eye_dot_left",
      "width": "90",
      "height": "82",
      "lstLayerImage": [{
          "x": "25",
          "y": "33",
          "img": "c4_2"
      }]
  }, {
      "name": "eye_dot_right",
      "width": "89",
      "height": "82",
      "lstLayerImage": [{
          "x": "31",
          "y": "33",
          "img": "c4_1"
      }]
  }, {
      "name": "eye_line",
      "width": "227",
      "height": "138",
      "lstLayerImage": [{
          "x": "64",
          "y": "31",
          "img": "c2"
      }, {
          "x": "66",
          "y": "38",
          "img": "line_7"
      }]
  }, {
      "name": "eye_mask",
      "width": "272",
      "height": "237",
      "lstLayerImage": [{
          "x": "64",
          "y": "8",
          "img": "line_6"
      }, {
          "x": "66",
          "y": "10",
          "img": "Layer 104"
      }, {
          "x": "126",
          "y": "30",
          "img": "line_5"
      }]
  }]
}, {
  "mPart": "mouth",
  "genID": "94",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "mouth",
      "width": "41",
      "height": "116",
      "lstLayerImage": [{
          "x": "5",
          "y": "35",
          "img": "c2_1"
      }, {
          "x": "8",
          "y": "38",
          "img": "Layer 122"
      }, {
          "x": "4",
          "y": "44",
          "img": "line_10"
      }, {
          "x": "17",
          "y": "48",
          "img": "Layer 123"
      }]
  }]
}, {
  "mPart": "hat",
  "genID": "95",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "hat",
      "width": "242",
      "height": "151",
      "lstLayerImage": [{
          "x": "71",
          "y": "14",
          "img": "Layer 115"
      }, {
          "x": "21",
          "y": "18",
          "img": "line_4"
      }, {
          "x": "25",
          "y": "20",
          "img": "Layer 110 copy"
      }, {
          "x": "69",
          "y": "20",
          "img": "c4"
      }]
  }]
}, {
  "mPart": "tail",
  "genID": "96",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "tail",
      "width": "198",
      "height": "123",
      "lstLayerImage": [{
          "x": "14",
          "y": "28",
          "img": "line_19"
      }, {
          "x": "18",
          "y": "32",
          "img": "Layer 130"
      }, {
          "x": "37",
          "y": "34",
          "img": "line_18"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "97",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "weapon_right",
      "width": "428",
      "height": "219",
      "lstLayerImage": [{
          "x": "126",
          "y": "35",
          "img": "line "
      }, {
          "x": "129",
          "y": "38",
          "img": "right"
      }]
  }, {
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "77",
          "y": "37",
          "img": "line_2"
      }, {
          "x": "82",
          "y": "40",
          "img": "left"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "98",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "weapon_right",
      "width": "428",
      "height": "219",
      "lstLayerImage": [{
          "x": "135",
          "y": "48",
          "img": "line_16"
      }, {
          "x": "139",
          "y": "52",
          "img": "bienbao giao thong copy"
      }, {
          "x": "163",
          "y": "76",
          "img": "c4_5"
      }]
  }, {
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "77",
          "y": "23",
          "img": "line_2"
      }, {
          "x": "81",
          "y": "27",
          "img": "bienbao giao thong"
      }, {
          "x": "103",
          "y": "73",
          "img": "c4"
      }]
  }]
}, {
  "mPart": "weapon",
  "genID": "99",
  "mClass": "assassin",
  "mType": "normal",
  "poseID": "1",
  "lstGroupLayer": [{
      "name": "weapon_right",
      "width": "428",
      "height": "219",
      "lstLayerImage": [{
          "x": "147",
          "y": "68",
          "img": "line_16"
      }, {
          "x": "151",
          "y": "73",
          "img": "ban chai "
      }, {
          "x": "320",
          "y": "124",
          "img": "c4_5"
      }]
  }, {
      "name": "weapon_left",
      "width": "427",
      "height": "219",
      "lstLayerImage": [{
          "x": "83",
          "y": "60",
          "img": "line_2"
      }, {
          "x": "87",
          "y": "63",
          "img": "ban chai"
      }, {
          "x": "256",
          "y": "113",
          "img": "c4"
      }]
  }]
}]