import { useQuery } from "@apollo/client";
import { Button, Dialog, DialogContent, DialogTitle, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { minHeight } from "@mui/system";
import React, { Fragment, useRef } from "react";
import { QUERY_PET } from "../../graphql/User/marketQuery";
import sessionData from "../../sessionData";
import { PET_STAGE_PET } from "../../utils/constants";
import { approveMATA } from "../../utils/cryptoWallet";
import { useServerTime } from "../../utils/hookUtils";
import LoadingSpinner from "../Misc/LoadingSpinner";
import GridPet from "./GridPet";
import SelectPetItem from "./SelectPetItem";

const useStyles = makeStyles((theme) => ({
    paper: {
        maxWidth: 1000,
        maxHeight: 'calc(100% - 128px)',
        minHeight: 450
    },
    filterToolBar: {
        display: 'flex',
        //marginTop: theme.spacing(2)
        //height: 42,
        //justifyContent: 'flex-end',
        //marginTop: theme.spacing(2),
    },
    field: {
        //margin: theme.spacing(2),
        marginLeft: 0,
        // width: '100%',
        //display: 'flex',
        flex: 1,
        //maxWidth: 400,
        // width: '30ch',
        // [theme.breakpoints.up('sm')]: {
        //     width: '40ch'
        // },

    },


}));

function SearchPet({ id,onSelect,parent1,serverTime }) {

    const variables = {
        id: id
    }
    const { loading, error, data } = useQuery(QUERY_PET, { variables })

    if (loading) return <LoadingSpinner />

    const ninneko = data.pet
    let isMypet = false
    if (ninneko.owner) {
        isMypet = (ninneko.owner.id == sessionData.userId)

    }

    if (!isMypet) return <Typography>You do not own Ninneko #{id}</Typography>

    return (
        <SelectPetItem ninneko={ninneko} serverTime={serverTime}
            onSelect={onSelect} parent1={parent1} />
    )


}

export default function SelectPetBox({ parent1, component, onSelect }) {

    const [open, setOpen] = React.useState(false)

    //SORT
    const [sort, setSort] = React.useState({ id: true, price: undefined });
    const [sortValue, setSortValue] = React.useState(1);
    const [searchId, setSearchid] = React.useState("")


    const serverTime = useServerTime()

    const handleOpen = () => {
        // approveMATA()
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const classes = useStyles()

    const [page, setPage] = React.useState(1);

    const searchRef = useRef()


    const handleChangeSearchId = () => {
        
        //console.log(searchRef)
        if (searchRef && searchRef.current) setSearchid(searchRef.current.value)

      //  console.log(searchId)
    };

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const handleSelect = (ninneko) => {
        setOpen(false)
        onSelect(ninneko)
    }

    const handleChangeSort = (event) => {
        const value = event.target.value

        if (value === 0) setSort({ id: false, price: undefined })
        if (value === 1) setSort({ id: true, price: undefined })
        if (value === 2) setSort({ id: undefined, price: false })
        if (value === 3) setSort({ id: undefined, price: true })
        setPage(1)
        setSortValue(value)
    };

    //console.log(searchId)

    return (
        <Fragment>

            {
                React.cloneElement(
                    component,
                    { onClick: handleOpen }
                )
            }

            <Dialog
                classes={{
                    paper: classes.paper
                }}

                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Select Ninneko"}
                    <div className={classes.filterToolBar}>

                        <Grid container>
                            <Grid item sm={6} xs={12} style={{ marginTop: 16 }}>
                                <div style={{ display: 'flex' }}>

                                    <TextField size="small" label="or Enter ID"
                                        className={classes.field}
                                        variant="outlined"
                                        inputRef={searchRef} 
                                        //value={searchId}
                                        //onChange={handleSearchId}
                                    />
                                    <Button
                                        //size="small"
                                        style={{ marginLeft: 8 }}
                                        //className={classes.button} 
                                        variant="contained" color="primary"
                                        onClick={handleChangeSearchId}
                                    >
                                        Search
                                    </Button>
                                </div>

                            </Grid>
                            <Grid sm={6} item xs={12} style={{ marginTop: 16, display: 'flex' }}>
                                <Select
                                    style={{ marginLeft: 'auto' }}
                                    value={sortValue}
                                    size='small'
                                    onChange={handleChangeSort}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        //getcontentanchorel: null
                                    }}>

                                    {/* <MenuItem value={4}>Latest</MenuItem> */}
                                    {/* <MenuItem value={3}>Highest Price</MenuItem> */}
                                    {/* <MenuItem value={2}>Lowest Price</MenuItem> */}
                                    <MenuItem value={1}>Highest ID</MenuItem>
                                    <MenuItem value={0}>Lowest ID</MenuItem>
                                </Select>

                            </Grid>
                        </Grid>

                    </div>
                </DialogTitle>
                <DialogContent>

                    {
                        searchId ?
                            <SearchPet id={searchId} serverTime={serverTime}
                                onSelect={handleSelect} parent1={parent1} />
                            :
                            <GridPet page={page}
                                handleChangePage={handleChangePage} onSelect={handleSelect}
                                limit={9} isSelect myPet parent1={parent1}
                                stage={PET_STAGE_PET} //sinh đẻ chỉ hiện Pet ko hiện Box
                                //stage={1}
                                sort={sort}
                                serverTime={serverTime} />
                    }


                </DialogContent>

            </Dialog>
        </Fragment>
    );


}
