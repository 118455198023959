import { useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Fragment } from 'react';
import { QUERY_GEMS, QUERY_MY_GEMS } from '../../graphql/User/marketQuery';
import { PETS_PER_PAGE } from '../../utils/constants';
import LoadingSpinner from '../Misc/LoadingSpinner';
import MyPagination from '../Misc/MyPagination';
import GemItem from './GemItem';

const useStyles = makeStyles((theme) => ({
    grid: {
        padding: theme.spacing(2),
        display: 'flex',
        //alignItems: 'stretch',
        justifyContent: 'center'
    },
    page: {
        display: 'flex',
        marginTop: theme.spacing(4)
    },


}));


export default function GridGem({
    myUserGems = false,

    sort = {},
    petClasses = [],
    factions = [],
    attributes = [],
    forSale = null,
    page,
    handleChangePage,
    ownerId,
    stage = null,
    limit = PETS_PER_PAGE,
    genParts = [],
}) {

    let variables = {
        page: (page - 1)
    }

    

    variables.sortID = sort.id
    variables.sortPrice = sort.price
    variables.priceSetAt = sort.priceSetAt

    variables.limit = limit
    
    let classArray = []

    for (const [id, value] of Object.entries(petClasses)) {
        if (value) classArray.push(id)
    }

    if (classArray.length > 0) variables.clazz = classArray

    let factionArray = []

    for (const [id, value] of Object.entries(factions)) {
        if (value) factionArray.push(id)
    }

    if (factionArray.length > 0) variables.setId = factionArray


    
    let attributeArray = genParts
    
    if (attributeArray.length > 0) variables.pointType = attributeArray

    //console.log(variables.pointType)
    if (ownerId) {
        variables.ownerId = ownerId
    }


    //console.log(ownerId)

    variables.forSale = forSale == 2 ? null : forSale

    const query = myUserGems ? QUERY_MY_GEMS : QUERY_GEMS

    const classes = useStyles();

    const { loading, error, data } = useQuery(
        query,
        {
            variables
        }
    )

    if (loading || !data) return <LoadingSpinner />

    if (error) return `Error! ${error.message}`;

    //console.log(data)

    const ninnekos = myUserGems ? data.myUserGems : data.userGems

    let pageCount = ninnekos.length > limit ? page + 1 : page
     
    return (

        <Fragment>
            <Grid container className={classes.grid} spacing={2}>
                {
                    ninnekos && ninnekos.map((ninneko, key) =>
                          
                            <GemItem ninneko={ninneko} key={key} />
                    )
                }

            </Grid>

            <div className={classes.page} >
                <MyPagination page={page} onChange={handleChangePage} count={pageCount} />
            </div>

        </Fragment>
    );
}
