import { useMutation, useQuery } from '@apollo/client';
import {
    CardGiftcard,
    EditOutlined,
    Favorite, LocalFireDepartment, OpenInNew, RemoveCircleOutlined,
    RestartAlt, SellRounded,
    ShoppingBag
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, ListItem, Slider, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import { useSnackbar } from 'notistack';
import React, { Fragment, useEffect, useState } from 'react';
import { QUERY_PET } from "../../../graphql/User/marketQuery";
import { OPEN_CAT_BOX } from '../../../graphql/User/userQuery';
import sessionData from '../../../sessionData';
import { MAX_BREED_COUNT, PET_STAGE_PET, TIMES_RESET } from '../../../utils/constants';
import {
    auctionNFT,
    bidNFT,
    breedNFT,
    buyNFT,
    cancelAuctionNFT,
    cancelSaleNFT,
    giftNFTToAddress,
    putNFTOnSale
} from '../../../utils/cryptoWallet';
import {
    getPriceETHFromWei, getPriceUSDFromETH, getPriceUSDFromWei
} from '../../../utils/utils';
import BurnBox from '../BurnBox';
//import ConnectWalletBox from '../../Login/ConnectWalletBox';
//import BreedPetBox from '../BreedPetBox';
//import GiftPetBox from '../GiftPetBox';
//import RebornBox from '../RebornBox';
//import SellPetBox2 from '../SellPetBox2';

const BreedPetBox = React.lazy(() => import('../BreedPetBox'))
const RebornBox = React.lazy(() => import('../RebornBox'))
const SellPetBox2 = React.lazy(() => import('../SellPetBox2'))
const GiftPetBox = React.lazy(() => import('../GiftPetBox'))
const ConnectWalletBox = React.lazy(() => import('../../Login/ConnectWalletBox'))


const OPEN_REBORN_PET=true

const useStyles = makeStyles((theme) => ({

    command: {
        marginBottom: theme.spacing(4),
        flex: 'auto'
    },
    commandButton: {
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    priceBlock: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',


    },
    buyBlock: {
        marginLeft: theme.spacing(2),
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        },
    },
    buyButton: {
        marginLeft: theme.spacing(1),
    },
    editButton: {
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(1),
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
        }
    },


}));

const StyledSlider = styled(Slider)({
    '& .MuiSlider-markLabel[data-index="0"]': {
        transform: "translateX(0%)"
    },
    '& .MuiSlider-markLabel[data-index="1"]': {
        transform: "translateX(-100%)"
    }
});

const DECIMAL = 1e18


function BuyNow({ classes, onClick, loading }) {
    return (

        sessionData.userId ?
            <LoadingButton loading={loading} onClick={onClick} variant="contained" color="primary"
                className={classes.buyButton} startIcon={<ShoppingBag />}> Buy now</LoadingButton>
            :
            <ConnectWalletBox component={<Button variant="contained" color="primary" className={classes.buyButton}
                startIcon={<ShoppingBag />}> Buy now</Button>} />

    )
}


export function PetCommand({
    ninneko,
    isMyPet,
    refetchQuery,
    refetchQueryPet,
    timeToBreed,
    loadingAction,
    setLoadingAction
}) {
    const { enqueueSnackbar } = useSnackbar();
    const [cacheIsMyPet, setCacheIsMyPet] = useState(null)
    const [cacheIsForSale, setCacheIsForSale] = useState(null)
    const [cachePriceSell, setCachePriceSell] = useState(null)
    const [cacheAuction, setCacheAuction] = useState(null)
    const [timing, setTiming] = useState(null)
    const [isWaitingForInfo, setIsWaitingForInfo] = useState(true)
    const variables = {
        id: ninneko.id
    }
    const { loading, error, data } = useQuery(QUERY_PET, { variables })
    const [isChainCompletedButWaitForCache, setIsChainCompletedButWaitForCache] = useState(false);
    const [openCatBox] = useMutation(
        OPEN_CAT_BOX,
        {
            //onError: onError,
            //onCompleted: onUpdateCompleted,
            refetchQueries: refetchQuery

        });

    let dataPet;
    if (data && data.pet) {
        dataPet = data.pet
    }
    //console.log(dataPet)
    const onError = () => {
        setLoadingAction(false)
    }

    const breedNinneko = (id1, id2) => {
        setLoadingAction(true)
        breedNFT(id1, id2, enqueueSnackbar, () => {
            setLoadingAction(false)
            refetchQueryPet()
        }, onError)
        setCacheIsMyPet(null)
        setCacheIsForSale(null)
    }

    const isAuction = () => {
        // return false
        return cacheAuction != null

    }

    const stopSaleNinneko = () => {
        setLoadingAction(true)

        if (isAuction()) {
            cancelAuctionNFT(ninneko.id, enqueueSnackbar, () => {
                setCacheIsForSale(false)
                setCacheAuction(null)
                setCacheIsMyPet(true)
                setLoadingAction(false)
            }, onError)
        } else {
            cancelSaleNFT(ninneko.id, enqueueSnackbar, () => {
                setCacheIsForSale(false)
                setCacheIsMyPet(true)
                setLoadingAction(false)
            }, onError)
        }
    }

    const buyNinneko = () => {
        setLoadingAction(true)
        buyNFT(ninneko.id, ninneko.price, enqueueSnackbar, () => {
            setCacheIsMyPet(true)
            setCacheIsForSale(false)
            setLoadingAction(false)

            //Appsflyer tracking
            var priceBNB = getPriceETHFromWei(cachePriceSell == null ? ninneko.price : cachePriceSell)
            var usdPrice = parseFloat((parseFloat(priceBNB) * sessionData.exchangeRate).toFixed(2))
            window.afBuyNFT(ninneko.id, usdPrice)
        }, onError)
    }

    const bidNinneko = () => {
        setLoadingAction(true)
        bidNFT(ninneko.id, getCurrentPriceOfAuction(), enqueueSnackbar, () => {
            setCacheIsMyPet(true)
            setCacheIsForSale(false)
            setLoadingAction(false)
            setCacheAuction(null)

            //Appsflyer tracking
            var priceBNB = getCurrentPriceOfAuction()
            var usdPrice = parseFloat((parseFloat(priceBNB) * sessionData.exchangeRate).toFixed(2))
            window.afBuyNFT(ninneko.id, usdPrice)
        }, onError)
    }

    const sellNinneko = (price) => {
        setLoadingAction(true)
        putNFTOnSale(ninneko.id, price, enqueueSnackbar, () => {
            setCacheIsForSale(true)
            setCacheIsMyPet(true)
            setCachePriceSell(price * 1e18)
            setLoadingAction(false)
        }, onError)
    }

    // console.log(timing)
    // console.log(cacheAuction.duration)

    //console.log(ninneko.beastCount)

    const getCurrentPriceOfAuction = () => {
        var priceStepPerSecond = (cacheAuction.endPrice - cacheAuction.startPrice) / cacheAuction.duration
        var isIncrease = priceStepPerSecond > 0
        var bonusSecond = isIncrease ? 10 : -10
        var sencondsPassed = timing - cacheAuction.startTime + bonusSecond

        if (timing - cacheAuction.startTime >= cacheAuction.duration) {
            return cacheAuction.endPrice
        }

        var currentPrice = cacheAuction.startPrice + priceStepPerSecond * sencondsPassed

        if (isIncrease) {
            currentPrice = Math.max(cacheAuction.startPrice, Math.min(currentPrice, cacheAuction.endPrice))
        } else {
            currentPrice = Math.max(cacheAuction.endPrice, Math.min(currentPrice, cacheAuction.startPrice))
        }

        return currentPrice
    }

    const getNumberHourPassed = () => {
        return ((timing - cacheAuction.startTime) / 3600).toFixed(1)
    }

    const auctionNinneko = (startPrice, endPrice, duration) => {
        setLoadingAction(true)
        auctionNFT(ninneko.id, startPrice, endPrice, duration, enqueueSnackbar, () => {
            var endTime = parseInt(new Date().getTime() / 1000 + duration * 60 * 60, 10)
            setCacheIsForSale(true)
            setCacheIsMyPet(true)
            setCacheAuction({
                "startPrice": parseFloat(startPrice),
                "endPrice": parseFloat(endPrice),
                "startTime": parseInt(new Date().getTime() / 1000, 10),
                "endTime": endTime,
                "duration": duration * 60 * 60,
            })
            setLoadingAction(false)


            const myInterval = setInterval(() => {
                var now = new Date()
                var time = parseInt(now.getTime() / 1000, 10)
                if (time > endTime) {
                    setTiming(endTime)
                    clearInterval(myInterval)
                } else {
                    setTiming(time)
                }
            }, 1000)
        }, onError)
    }

    const doOpenCatBox = () => {
        openCatBox({
            variables: {
                petId: ninneko.id,
            }
        })
    }

    const giftNinneko = (petId, address) => {
        setLoadingAction(true)
        giftNFTToAddress(petId, address, enqueueSnackbar, () => {
            setCacheIsMyPet(false)
            setCacheIsForSale(null)
            setLoadingAction(false)
        }, onError)
    }

    const classes = useStyles()

    useEffect(() => {
        var endTime = parseFloat(ninneko.auction.startTime) + parseFloat(ninneko.auction.duration)
        if (ninneko.auction.startTime.length > 0) {
            setCacheAuction({
                "startPrice": parseFloat(ninneko.auction.startPrice) / DECIMAL,
                "endPrice": parseFloat(ninneko.auction.endPrice) / DECIMAL,
                "startTime": parseInt(ninneko.auction.startTime),
                "endTime": endTime,
                "duration": parseFloat(ninneko.auction.duration),
            })
        }

        const myInterval = setInterval(() => {
            if (ninneko.auction.startTime.length <= 0) {
                setTiming(false)
                return
            }
            var now = new Date()
            var time = parseInt(now.getTime() / 1000, 10)
            if (time > endTime) {
                setTiming(endTime)
                clearInterval(myInterval)
            } else {
                setTiming(time)
            }

        }, 1000)

        return () => {
            // console.log("---------999---------")
            setTiming(null)
            clearInterval(myInterval); //This is important
        }
    }, []);

    // console.log(cacheAuction)
    // console.log(ninneko)
    const srtMsg="This Pet Reached The Limit of "+TIMES_RESET+" reborn."
    const ButtonReset=()=>{
        if (OPEN_REBORN_PET){
            if (ninneko.rebornTimes>=TIMES_RESET){
                return (
                    <LoadingButton loading={loadingAction}
                                   variant='outlined'
                                   color='primary'
                                   startIcon={<RestartAlt/>}
                                   disabled={ninneko.rebornTimes>= TIMES_RESET}
                                   className={classes.commandButton}>
                        Reborn
                    </LoadingButton>

                )
            }
            else {
                return(
                    <RebornBox
                        setLoadingAction={setLoadingAction}
                        refetchQuery={refetchQuery}
                        refetchQueryPet={refetchQueryPet}
                        ninneko={ninneko}
                        component={
                            <LoadingButton loading={loadingAction} variant='outlined' color='primary'
                                           startIcon={<RestartAlt/>} className={classes.commandButton}>
                                Reborn
                            </LoadingButton>
                        }
                    />
                )
            }
        }
        else {
            return (

                <RebornBox
                    setLoadingAction={setLoadingAction}
                    refetchQuery={refetchQuery}
                    refetchQueryPet={refetchQueryPet}
                    ninneko={ninneko}
                    component={
                        <LoadingButton loading={loadingAction} variant='outlined' color='primary'
                            startIcon={<RestartAlt />} className={classes.commandButton}>
                            Reborn
                        </LoadingButton>
                    }
                />
            )
        }
    }

    return (
        <Fragment>
            {timing != null &&
                <>
                    <div className={classes.command}>
                        {((cacheIsMyPet != null && cacheIsMyPet == true) || (cacheIsMyPet == null && isMyPet))
                            && ((cacheIsForSale != null && cacheIsForSale == false) || (cacheIsForSale == null && ninneko.forSale == 0))
                            && (!isAuction())
                            &&
                            <Fragment>
                                {
                                    ninneko.stage != PET_STAGE_PET &&
                                    <Button disableElevation variant="contained" color="primary"
                                        onClick={doOpenCatBox}
                                        className={classes.commandButton} startIcon={<OpenInNew />}>
                                        Open
                                    </Button>
                                }
                                <SellPetBox2
                                    sellNinneko={sellNinneko}
                                    auctionNinneko={auctionNinneko}
                                    petId={ninneko.id}
                                    isAuction={isAuction()}
                                    component={
                                        <LoadingButton loading={loadingAction} disableElevation variant="contained"
                                            color="primary"
                                            className={classes.commandButton} startIcon={<SellRounded />}>
                                            Sell
                                        </LoadingButton>
                                    }
                                />
                                {ninneko.stage == PET_STAGE_PET
                                    && timeToBreed != null && timeToBreed == "0"
                                    && ninneko.breedCount < MAX_BREED_COUNT
                                    && <BreedPetBox
                                        breedNinneko={breedNinneko}
                                        ninneko={ninneko}
                                        component={
                                            <LoadingButton loading={loadingAction} disableElevation variant="contained"
                                                color="primary"
                                                className={classes.commandButton} startIcon={<Favorite />}>
                                                Breed
                                            </LoadingButton>
                                        }
                                    />}

                                <GiftPetBox
                                    giftNinneko={giftNinneko}
                                    petId={ninneko.id}
                                    component={
                                        <LoadingButton loading={loadingAction} variant="outlined" color="primary"
                                            className={classes.commandButton} startIcon={<CardGiftcard />}>
                                            Gift
                                        </LoadingButton>
                                    }
                                />

                                <ButtonReset />

                                <BurnBox
                                    setLoadingAction={setLoadingAction}
                                    refetchQuery={refetchQuery}
                                    refetchQueryPet={refetchQueryPet}
                                    ninneko={ninneko}
                                    component={
                                        <LoadingButton loading={loadingAction} variant='outlined' color='primary'
                                            startIcon={<LocalFireDepartment />} className={classes.commandButton}>
                                            Burn
                                        </LoadingButton>
                                    }
                                />



                            </Fragment>}

                        {((cacheIsForSale != null && cacheIsForSale == true) || (cacheIsForSale == null && ninneko.forSale == 1) || isAuction()) &&
                            <Fragment>
                                <div id="price" className={classes.priceBlock}>
                                    <div>
                                        <ListItem style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            padding: 0,
                                            maxWidth: 300
                                        }}>
                                            {/* <Typography variant="h5" noWrap>&nbsp;{getPriceETHFromWei(cachePriceSell == null ? ninneko.price : cachePriceSell)} BNB</Typography> */}
                                            <>
                                                <img src="/images/icon_binance.png"
                                                    style={{ width: 24, height: 24, top: 0 }} />
                                                {isAuction() ?
                                                    <Typography variant="h5"
                                                        noWrap>&nbsp;{getCurrentPriceOfAuction().toFixed(2)} BNB</Typography>
                                                    :
                                                    <Typography variant="h5"
                                                        noWrap>&nbsp;{getPriceETHFromWei(cachePriceSell == null ? ninneko.price : cachePriceSell)} BNB</Typography>
                                                }
                                            </>
                                        </ListItem>
                                        <div style={{ display: 'flex', justifyContent: 'center', width: "100%" }}>
                                            {/* <Typography variant="body1"  >{getPriceUSDFromWei(cachePriceSell == null ? ninneko.price : cachePriceSell)}</Typography> */}
                                            <>
                                                {isAuction() ?
                                                    <Typography
                                                        variant="body1">{getPriceUSDFromETH(getCurrentPriceOfAuction())}</Typography>
                                                    :
                                                    <Typography
                                                        variant="body1">{getPriceUSDFromWei(cachePriceSell == null ? ninneko.price : cachePriceSell)}</Typography>
                                                }
                                            </>
                                        </div>
                                    </div>
                                    {
                                        <div className={classes.buyBlock}>
                                            {((cacheIsMyPet != null && cacheIsMyPet == true) || (cacheIsMyPet == null && isMyPet)) ?
                                                <Fragment>
                                                    {/* {!isStopSale && */}
                                                    <LoadingButton loading={loadingAction} onClick={stopSaleNinneko}
                                                        disableElevation variant="contained"
                                                        color="secondary"
                                                        startIcon={<RemoveCircleOutlined />}>Stop
                                                        Sale</LoadingButton>
                                                    <SellPetBox2
                                                        sellNinneko={sellNinneko}
                                                        auctionNinneko={auctionNinneko}
                                                        isAuction={isAuction()}
                                                        petId={ninneko.id}
                                                        component={
                                                            <LoadingButton loading={loadingAction} disableElevation
                                                                variant="contained" color="primary"
                                                                className={classes.editButton}
                                                                startIcon={<EditOutlined />}>
                                                                Edit Price
                                                            </LoadingButton>
                                                        } />
                                                </Fragment>
                                                :
                                                <BuyNow classes={classes}
                                                    onClick={isAuction() ? bidNinneko : buyNinneko}
                                                    loading={loadingAction} />
                                                // <BuyNow classes={classes} onClick={buyNinneko} loading={loadingAction} />
                                            }
                                        </div>
                                    }

                                </div>

                                {isAuction() &&
                                    <>
                                        <StyledSlider
                                            value={timing}
                                            style={{ marginTop: 8, marginBottom: 0 }}
                                            aria-label="Default"
                                            min={cacheAuction.startTime}
                                            max={cacheAuction.startTime + cacheAuction.duration}
                                            valueLabelFormat={"Time passed: " + getNumberHourPassed() + " hours / " + (cacheAuction.duration / 3600).toFixed(1) + " hours"}
                                            // disabled={true}
                                            marks={[
                                                {
                                                    label: cacheAuction.startPrice + " BNB",
                                                    value: cacheAuction.startTime
                                                },
                                                {
                                                    label: cacheAuction.endPrice + " BNB",
                                                    value: cacheAuction.startTime + cacheAuction.duration
                                                },
                                            ]}
                                            aria-labelledby="discrete-slider-always"
                                            valueLabelDisplay="auto" />
                                    </>
                                }
                            </Fragment>}
                    </div>
                </>
            }
        </Fragment>
    )


}