import { useQuery } from '@apollo/client';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Fragment } from 'react';
import { QUERY_MY_PETS, QUERY_PETS } from '../../graphql/User/marketQuery';
import { LIFE_STAGES, MAX_BREED_COUNT, PETS_PER_PAGE } from '../../utils/constants';
import LoadingSpinner from '../Misc/LoadingSpinner';
import MyPagination from '../Misc/MyPagination';
import PetItem from './PetItem';
import SelectPetItem from './SelectPetItem';
import { PET_PART_BY_NAME } from '../../utils/genMap';

const useStyles = makeStyles((theme) => ({
    grid: {
        padding: theme.spacing(2),
        display: 'flex',
        //alignItems: 'stretch',
        justifyContent: 'center'
    },
    page: {
        display: 'flex',
        marginTop: theme.spacing(4)
    },


}));


function GridPetItem({ ninneko }) {

    // console.log(ninneko)

    return (
        <Grid item style={{ width: 260 }}>
            <PetItem ninneko={ninneko} />
        </Grid>
    )

}

const PART_HAND = 4
const PART_TAIL = 6
const PART_EYES = 1
const PART_HAIR = 3
const PART_EARS = 5
const PART_MOUTH = 2

export default function GridPet({
    isSelect = false,
    myPet = false,
    onSelect,
    parent1,
    serverTime = null,

    //SQL var
    sort = {},
    breedCount = [],
    factions = [],
    petClasses = [],
    legendary,//beastCount
    generations = [],
    forSale = null,
    page,
    handleChangePage,
    ownerId,
    stage = null,
    limit = PETS_PER_PAGE,
    genParts = [],
    lifeStages = null
}) {

    
    //console.log("adsfdsafdsa")

    let variables = {

        page: (page - 1)
    }

    if (lifeStages) {
        let lifeStageArray = []

        for (const [id, value] of Object.entries(lifeStages)) {
            if (value) lifeStageArray.push(id)
        }

        if (lifeStageArray.length > 0) {
            if (lifeStageArray.length >= LIFE_STAGES.length) {
                variables.lifeStage = null
            } else {
                variables.lifeStage = lifeStageArray[0]
            }
        }
    } else {
        variables.lifeStage = null
    }

    if (stage != null) variables.stage = stage

    variables.sortID = sort.id
    variables.sortPrice = sort.price
    variables.priceSetAt = sort.priceSetAt

    let eyeParts = []
    let earParts = []
    let mouthParts = []
    let hairParts = []
    let handParts = []
    let tailParts = []

    for (let i = 0; i < genParts.length; i++) {
        let part = PET_PART_BY_NAME[genParts[i]].part
        let genId = PET_PART_BY_NAME[genParts[i]].id
        if (part == PART_EARS) {
            earParts.push(genId)
        } else if (part == PART_EYES) {
            eyeParts.push(genId)
        } else if (part == PART_HAND) {
            handParts.push(genId)
        } else if (part == PART_HAIR) {
            hairParts.push(genId)
        } else if (part == PART_MOUTH) {
            mouthParts.push(genId)
        } else if (part == PART_TAIL) {
            tailParts.push(genId)
        }
    }



    if (eyeParts.length > 0) variables.eyesD = eyeParts
    if (earParts.length > 0) variables.earsD = earParts
    if (mouthParts.length > 0) variables.mouthD = mouthParts
    if (hairParts.length > 0) variables.hairD = hairParts
    if (handParts.length > 0) variables.handD = handParts
    if (tailParts.length > 0) variables.tailD = tailParts

    variables.limit = limit

    if (legendary) variables.beastCount = legendary

    let factionArray = []

    for (const [id, value] of Object.entries(factions)) {
        if (value) factionArray.push(id)
    }

    if (factionArray.length > 0) variables.faction = factionArray

    let classArray = []

    for (const [id, value] of Object.entries(petClasses)) {
        if (value) classArray.push(id)
    }

    if (classArray.length > 0) variables.class = classArray


    let generationArray = []

    for (const [id, value] of Object.entries(generations)) {
        if (value) generationArray.push(id)
    }

    if (generationArray.length > 0) variables.generation = generationArray

    if (breedCount.length != 0 && breedCount.length < MAX_BREED_COUNT) { //full 10 thi = ko co filter
        let breedArray = []
        for (let i = breedCount[0]; i <= breedCount[breedCount.length - 1]; i++) {
            breedArray.push(i);
        }
        variables.breedCount = breedArray
    }

    if (ownerId) {
        variables.ownerId = ownerId
    }
    
    // let saleValue = null
    // if (forSale) {
    //     const sale = forSale['1']
    //     const notSale = forSale['2']

    //     //tick cả 2
    //     if (sale && notSale) saleValue = null
    //     //bỏ tick cả 2
    //     if (!sale && !notSale) saleValue = null

    //     if (sale && !notSale) saleValue = 1

    //     if (!sale && notSale) saleValue = 0

    // }

    variables.forSale = forSale == 2 ? null : forSale

    const query = myPet ? QUERY_MY_PETS : QUERY_PETS

    //console.log(variables)

    const classes = useStyles();

    const { loading, error, data } = useQuery(
        query,
        {
            variables
        }
    )

//     console.log(query)

//     console.log(variables)

//    // console.log(ownerId)

    //console.log(data.myPets )

    if (loading || !data) return <LoadingSpinner />

    if (error) return `Error! ${error.message}`;

    const condition = myPet? data.myPets == null || data.myPets.length < 1 : data.pets == null || data.pets.length < 1

    

    if ( condition && (genParts && genParts.length > 0)) {
        return (
            <Grid container className={classes.grid} spacing={2} style={{ paddingTop: 30 }}>
                <Typography style={{ fontSize: 20 }}>There is no Ninneko matches your filter</Typography>
            </Grid>
        )
    }

    const ninnekos = myPet ? data.myPets : data.pets



   

    let pageCount = ninnekos.length > limit ? page + 1 : page
    // console.log("pageCount: " + pageCount)
    // console.log("page: " + page)

    return (

        <Fragment>
            <Grid container className={classes.grid} spacing={2}>
                {
                    ninnekos && ninnekos.map((ninneko, key) =>
                        isSelect ?
                            <SelectPetItem ninneko={ninneko} key={key} serverTime={serverTime}
                                onSelect={onSelect} parent1={parent1} /> :
                            <GridPetItem ninneko={ninneko} key={key} />
                    )
                }

                {/* {ninnekos && ninnekos.filter((ninneko) => ninneko.auction == null || ninneko.auction.startTime.length == 0).map(
                    (ninneko, key) =>
                    isSelect ?
                        <SelectPetItem ninneko={ninneko} key={key} serverTime={serverTime}
                            onSelect={onSelect} parent1={parent1} /> :
                        <GridPetItem ninneko={ninneko} key={key} />
                )} */}
            </Grid>

            <div className={classes.page} >
                <MyPagination page={page} onChange={handleChangePage} count={pageCount} />
            </div>

        </Fragment>
    );
}
