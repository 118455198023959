//Home
//export const pathToHome = "/";

import { TEST_NET } from "./constants";

export const BASE_API = TEST_NET? "https://api-test.ninneko.com":"https://api.ninneko.com"

export const GRAPH_API = TEST_NET? "https://api-test.ninneko.com/graphql":"https://api.ninneko.com/graphql"



export const linkToHome = "/";

export const linkToStart = "/start";

export const linkToItems = "/items";

export const linkToGem = "/gems";

export const linkToNft = "/nft";

export const linkToGemItem = "/gemItem";

export const linkToAccount = "/account/dashboard";

export const linkToVote = "/vote";

export const linkToInventory = "/account/inventory";

export const linkToClaim = "/account/claim";


export const linkToSettings = "/account/settings";

export const linkToActivites = "/account/activities";

//export const linkToLogin = "/login";

export const linkToLogOut = "/logout";

export const linkToUser = "/user";

export const linkToPet = "/pet";

export const linkToForgotPass = "/forgot";

export const linkToFriendRef = "/account/friendRef";

export const linkToEventFriendRefFriendList = "/account/friendList";

export const API_RANDOM_STRING = BASE_API + "/rest/misc/randomString"
export const API_LOGIN = BASE_API + "/rest/user/login"
//export const API_REGISTER = BASE_API + "/rest/user/register"
export const API_REGISTER = BASE_API + "/rest/user/register/sign/personal"

export const API_FORGOT_PASSWORD = BASE_API + "/rest/password/forgot"

export const API_RESET_PASSWORD = BASE_API + "/rest/password/reset"

