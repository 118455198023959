import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { NoBackpackSharp, HelpOutlineOutlined } from "@mui/icons-material";
import { Button, Card, CardContent, CardHeader, TextField, Typography, Link, Divider, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { useSnackbar } from "notistack";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { CLAIM_DATA, COLLECT_TO_CLAIM, INIT_CLAIM_ITEM, QUERY_CLAIM_ITEM_DATA, QUERY_ME, QUERY_ONCHAIN_ITEMS } from "../../graphql/User/userQuery";
import { claimItem, claimMATA, getNINOBalance } from "../../utils/cryptoWallet";
import { getPriceETHFromWei, handleResult } from "../../utils/utils";
import LoadingSpinner from "../Misc/LoadingSpinner";
import { HelpClaimMataDialog } from "../Misc/HelpClaimMataDialog";
import { TokenBalance } from "../views/InventoryView";


//let theme = createTheme();

// let theme = createTheme({
//     palette: {
//         primary: blue,
//         secondary: pink
//     },
// });

// theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme) => ({
    paper: {
        //height: '100%',
        maxWidth: 400,



    },
    avatar: {
        [theme.breakpoints.down('xs')]: {
            padding: 0,
            marginRight: theme.spacing(1),
        },
    },
    root: {
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1.5),
            margin: theme.spacing(0),
        },

    },
    title: {
        whiteSpace: 'nowrap'
    },

    icon: {
        height: 50,
        width: 50,
        // [theme.breakpoints.down('sm')]: {
        //     height: 50,
        //     width: 50,
        // },
    },
    error: {
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    field: {
        marginRight: theme.spacing(1),

    },
    center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        paddingBottom: theme.spacing(1),
        //marginTop: theme.spacing(1),
        //flexShrink: 0
    },

}));


const CLAIM_ITEMS = {
    600654: {
        name: "Sapphire Box",
        image: "600654.png"
    },
    600658: {
        name: "Cotton Box",
        image: "600658.png"
    },
    600662: {
        name: "White Cotton",//2 wild
        image: "600662.png"
    },
    600663: {
        name: "Gold Cotton", //3 wild
        image: "600663.png"
    },
    600664: {
        name: "Green Ruby", // Gen moi wild
        image: "600664.png"
    },
    600665: {
        name: "Grey Ruby", // Gen moi wild + 1 wild khac
        image: "600665.png"
    },
    600666: {
        name: "Layer Cake", // Gen moi wild + 1 wild khac
        image: "600666.png"
    },
    600667: {
        name: "Layer Cake VIP", // Gen moi wild + 1 wild khac
        image: "600667.png"
    },
    600673: {
        name: "Rocket 1 Wild", // Gen moi wild + 1 wild khac
        image: "600673.png"
    },
    600674: {
        name: "Rocket 2 Wild", // Gen moi wild + 1 wild khac
        image: "600674.png"
    },
    600681: {
        name: "Legendary 1 wild", // Gen moi wild + 1 wild khac
        image: "600681.png"
    },
    600682: {
        name: "Legendary 2 wild", // Gen moi wild + 1 wild khac
        image: "600682.png"
    },
    600676: {
        name: "Poseidon 1 wild", // Gen moi wild + 1 wild khac
        image: "600676.png"
    },
    600677: {
        name: "Poseidon 2 wild", // Gen moi wild + 1 wild khac
        image: "600677.png"
    },
    600684: {
        name: "Cross 1 wild", // Gen moi wild + 1 wild khac
        image: "600684.png"
    },
    600685: {
        name: "Cross 2 wild", // Gen moi wild + 1 wild khac
        image: "600685.png"
    },

    600687: {
        name: "2Edge & H.Glass 1 wild", // Gen moi wild + 1 wild khac
        image: "600687.png"
    },

    600688: {
        name: "2Edge & H.Glass 2 wild", // Gen moi wild + 1 wild khac
        image: "600688.png"
    },


    600691: {
        name: "Knives 1 wild", // Gen moi wild + 1 wild khac
        image: "600691.png"
    },

    600692: {
        name: "Knives 2 wild", // Gen moi wild + 1 wild khac
        image: "600692.png"
    }

}


export default function ClaimItem({ item }) {

    //console.log(item)

   

    const [claimAmount, setClaimAmount] = React.useState("")

    const handleChangeClaimAmount = (event) => setClaimAmount(event.target.value)


    const classes = useStyles()

    const image = `/images/claim_items/${CLAIM_ITEMS[item.id]?.image}`

    const { enqueueSnackbar } = useSnackbar();

    const [errorMessage, setErrorMessage] = React.useState(null)



    const client = useApolloClient()

  


    const getClaimData = () => {

        client.query(
            {
                query: QUERY_CLAIM_ITEM_DATA,
                variables: { materialId: item.id },
                fetchPolicy: 'no-cache'
            })
            .then(result => {
                console.log(result.data.claimMaterialData)
                const { id, amount, nonce, sign } = result.data.claimMaterialData
                claimItem(id, amount, nonce, sign, enqueueSnackbar, onClaimComplete)

            })
            .catch(
                error => {
                    console.log(error)
                    setErrorMessage(error.toString())

                }
            );

    }

    const [initClaimMutation] = useMutation(
        INIT_CLAIM_ITEM,
        {
            onError: (err) => setErrorMessage(err.toString()),
            onCompleted: getClaimData,
            // refetchQueries: [
            //     { query: QUERY_ONCHAIN_ITEMS }
            // ]
        });

    const onClaimComplete = () => {
        //history.go(0)
        client.refetchQueries({
            //include: [QUERY_PETS,QUERY_MY_PETS],
            include: 'all'
        });

    }



    const handleClaim = () => {
        initClaimMutation({
            variables: {
                materialId: item.id,
                amount: claimAmount
            }
        })
    }

    if (!CLAIM_ITEMS[item.id]) return null

    // claimAmount = 100
    return (

        <React.Fragment>
            {/* <TokenBalance /> */}

            {/* <ThemeProvider theme={theme}> */}


            <Card className={classes.paper} >
                <CardHeader classes={{
                    root: classes.root,
                    avatar: classes.avatar,
                    title: classes.title,
                }}
                    avatar={<img src={image} className={classes.icon} alt="" />}
                    titleTypographyProps={{ variant: 'h6' }}
                    title={item.number + " " + CLAIM_ITEMS[item.id]?.name}
                    subheader={item.pendingNumber > 0 ? "Pending: " + item.pendingNumber : ""}

                >

                </CardHeader>
                <div style={{ marginLeft: 30 }}>
                </div>
                <CardContent className={classes.center}>
                    <div >
                        {item.state === 1 &&
                            <div>
                                <TextField size="small" label="Enter amount of items" className={classes.field} variant="outlined"
                                    value={claimAmount}
                                    onChange={handleChangeClaimAmount}
                                />
                                <Button variant="contained" color="primary" onClick={handleClaim}>Claim</Button>

                            </div>}
                        {
                            item.state === 2 &&
                            <Button variant="contained" color="primary" onClick={getClaimData}>Claim Pending</Button>
                        }
                    </div>
                    <div>
                        <Divider style={{ marginTop: 16, marginBottom: 16 }} />
                        <Typography variant="caption">
                            - It takes 6 hours between 2 claims<br />
                            - You can claim all boxes at once to avoid waiting
                        </Typography>
                    </div>
                </CardContent>
            </Card>
            <div className={classes.error}><Typography color="error" variant="subtitle2">{errorMessage}</Typography></div>
        </React.Fragment>
    );


}
