import { Drawer, InputLabel, MenuItem, Select, Button, Hidden, useMediaQuery, Card, Typography, Grid } from "@mui/material";
import { MHidden } from '../@material-extend';
import { makeStyles } from '@mui/styles';
import React, { Fragment, useEffect, useState, useRef } from "react";
import { CLASSES, FACTIONS, GENERATIONS, MAX_BREED_COUNT, SALE_STATS, DRAWER_WIDTH, LIFE_STAGES, LIFE_STAGE_ADULT } from "../../utils/constants";
import { useMemoryState } from "../../utils/memoryState";
import FilterPanel from "../Misc/FilterPanel";
import GridPet from "../Pet/GridPet";
import SaleStats from "../Stats/SaleStats";
import { useTheme } from "@emotion/react";
import { getPriceUSDFromWei, getPriceUSDFromETH, updateURLParameter, addURLParameter, removeURLParameterByName, removeURLParameterByNameAndValue } from '../../utils/utils';
import { QUERY_MARKET_STATS } from "../../graphql/User/marketQuery";
import { useQuery } from '@apollo/client';
import LoadingSpinner from "../Misc/LoadingSpinner";
import { fontSize } from "@mui/system";
import { PetFilterPanel, PetSortPanel } from "../Pet/PetFilterHandler";
import { useLocation } from 'react-router-dom';
import GridGem from "../Pet/GridGem";
import { GemFilterPanel } from "../Pet/GemFilterHandler";

//import {animation} from "../utils/pixiApp"

const useStyles = makeStyles((theme) => ({

    toolbar: theme.mixins.toolbar,

    main: {
        paddingTop: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(2),
        },

        paddingBottom: theme.spacing(10),
        flex: 1,
    },

}));





export default function GemView({ handler }) {

    const classes = useStyles()
    return (

        <Fragment>

            <GemFilterPanel handler={handler} />

            <div className={classes.main}>
                <div className={classes.toolbar} />


                <PetSortPanel handler={handler} />

                <GridGem
                    breedCount={handler.breedCount}
                    petClasses={handler.petClasses}
                    factions={handler.factions}
                    attributes={handler.attributes}
                    legendary={handler.legendary}
                    generations={handler.generations}
                    page={handler.page}
                    handleChangePage={handler.handleChangePage}
                    forSale={handler.sale}
                    sort={handler.sort}
                    genParts={handler.genParts}
                    lifeStages={handler.filterLifeStage}
                />

            </div>

        </Fragment>

    );

}


