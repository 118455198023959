import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { AppConsumer } from '@inlet/react-pixi';
//import loadable from '@loadable/component';
import { Button, Divider, Grid, ListItem, ListItemText, Paper, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ArrowBack, ConstructionOutlined, TryRounded } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { QUERY_PET, PET_HISTORY, QUERY_SERVER_TIME, QUERY_GEM, GEM_HISTORY } from '../../graphql/User/marketQuery';
import { UPDATE_PET_NAME } from '../../graphql/User/userQuery';
import sessionData from '../../sessionData';
import LoadingSpinner from '../Misc/LoadingSpinner';
import { BodyParts } from '../Pet/Info/BodyParts';
import { PetAbout } from '../Pet/Info/PetAbout';
import { PetCommand } from '../Pet/Info/PetCommand';
import { PetStats } from '../Pet/Info/PetStats';
import { SaleHistory } from '../Pet/Info/SaleHistory';
//import PetAnim, { STAGE_WIDTH } from '../Pet/PetAnim';
import PetSKillList from '../Pet/PetSkillList';
import SimplePetItem from '../Pet/SimplePetItem';
import { getTimeToBreed } from '../../utils/utils';
import { useServerTime } from '../../utils/hookUtils';
import { GEM_EFFECT, GEM_MULTIPLIER, GEM_NAME, GEM_TYPE, GENERATION_BETA, PET_STAGE_COTTON_BOX, PET_STAGE_PET, PET_STAGE_SPECIAL_BOX, STAGE_WIDTH, TEST_NET } from '../../utils/constants';
import { linkToHome } from '../../utils/link';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import PetAmulet from '../Pet/PetAmulet';
import PetBigImage from '../Pet/PetBigImage';
import { GemAbout } from '../Gem/GemAbout';
import { GemCommand } from '../Gem/GemCommand';
import { BonusInfo } from '../Pet/GemItem';




const useStyles = makeStyles((theme) => ({
    root: {
        justifyContent: 'center',
    },

    petBox: {
        alignSelf: 'flex-start',
        paddingTop: theme.spacing(3),
        maxWidth: 344,

        [theme.breakpoints.up('md')]: {
            position: 'sticky',
            top: 80,
            //marginRight: theme.spacing(3)
        },
    },
    stats: {
        marginTop: theme.spacing(4),
        flex: 'auto',
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(3)
        },

    },
    paper: {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(1),

        padding: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1),
        },
    },
    nonPaper: {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(1),
    },
    name: {
        marginTop: theme.spacing(1),
        //marginBottom: theme.spacing(1),
        //textAlign: 'center'
        //padding: theme.spacing(3)

    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 26,
        height: 26
    },
    centerGrid: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        //flexShrink: 0
    },
    generation: {
        display: 'flex',
        marginBottom: theme.spacing(2)
    },
    chip: {
        marginRight: theme.spacing(1)
    },
    catBox: {
        width: 260,
        height: 260,
        padding: theme.spacing(2),
        paddingTop: 0
    }

}));



function GemBox({ ninneko, isMyPet }) {

    const history = useHistory()

    const goBack = () => {
        history.goBack()
    }
    const classes = useStyles()
    return (
        <Grid container>
            <Grid item xs={12}>
                {/* <Link to={linkToHome} style={{textDecoration: 'none'}}> */}
                <Button startIcon={<ArrowBack />} onClick={goBack} variant="outlined" >Back</Button>
                {/* </Link> */}

                {
                    ninneko.nftId ?
                        <Typography className={classes.name} noWrap>{"#" + ninneko.nftId}</Typography>
                        :
                        <Typography className={classes.name} noWrap color='red'>Not Minted</Typography>
                }

            </Grid>
            <Grid item xs={12} style={{ width: STAGE_WIDTH, marginTop: 24 }} className={classes.centerGrid}>
                <img src={"/images/gems/" + ninneko.quality + "/" + ninneko.setId + ".png"} width={200} height={200} />
            </Grid>
            <Grid item xs={12} style={{ marginTop: 24 }} className={classes.centerGrid}>
                <Typography><strong>Set Effect:</strong> {GEM_EFFECT[ninneko.setId]}</Typography>
            </Grid>



        </Grid>
    )
}


// const PetCommand = loadable(() => import( '../Pet/Info/PetCommand'/* webpackChunkName: "PetCommand" */))

function Bonus({ type, value }) {

    return (
        <ListItem>
            <ListItemText>
                <Typography>
                    {type}
                </Typography>
            </ListItemText>
            <Typography color="text.secondary">
                +{value}
            </Typography>

        </ListItem>
    )
}

function GemHistory({ id }) {

    const variables = {
        id: id
    }
    const { loading, error, data } = useQuery(GEM_HISTORY, { variables })


    if (loading) return <LoadingSpinner />

    //console.log(data)
    const histories = data.userGemSaleHistory

    return (
        histories.length > 0 ?
            <SaleHistory histories={histories} />
            :
            <></>
    )

}


export default function GemItemView({ id }) {
    const client = useApolloClient()
    const classes = useStyles()
    const variables = {
        id: id
    }
    const { loading, error, data, refetch } = useQuery(QUERY_GEM, { variables })
    //const historyQuery = useQuery(GEM_HISTORY, { variables })

    const serverTime = useServerTime()

    const [loadingAction, setLoadingAction] = useState(false)

    //const [timeToBreed, setTimeToBreed] = React.useState(null)



    //----------------------------------------------------------------//

    if (loading) return <LoadingSpinner />
    if (error) {
        setLoadingAction(false)
        return `Error! ${error.message}`;
    }

    const refetchQuery = async () => {
        refetch(variables)
        await client.refetchQueries({
            //include: [QUERY_PETS,QUERY_MY_PETS],
            include: 'all'
        });
        setLoadingAction(false)
        // console.log("----refetchQuerry-------")
    }
    const refetchQueryPet = async () => {
        console.log("refetchQueryPet")
        refetch(variables)
        await client.refetchQueries({
            include: [QUERY_PET],
            // include: 'all'
        });
        setLoadingAction(false)

        // console.log("----refetchQuerryRebornData-------")
    }
    //console.log(data)
    const ninneko = data.userGem
    // console.log(ninneko)
    let isMypet = false
    if (ninneko.owner) {
        isMypet = (ninneko.owner.id == sessionData.userId)
    }

    //console.log("ninneko.createdAt " + ninneko.createdAt)

    //const timeToBreed = ninneko.generation == GENERATION_BETA?getTimeToBreed(serverTime, ninneko.createdAt):0
    const timeToBreed = getTimeToBreed(serverTime, ninneko.createdAt)


    // if (historyQuery.loading) return <LoadingSpinner />
    // if (historyQuery.error) return `Error! ${historyQuery.error.message}`;




    return (

        <Grid container className={classes.root} >
            <Grid item className={classes.petBox} >
                <GemBox ninneko={ninneko} isMyPet={isMypet} id={ninneko.id} />
            </Grid>
            <Grid item className={classes.stats} xs={12} sm={6}>
                <GemCommand ninneko={ninneko} isMyPet={isMypet} refetchQuery={refetchQuery} refetchQueryReborn={refetchQueryPet} timeToBreed={timeToBreed}
                    loadingAction={loadingAction} setLoadingAction={setLoadingAction}
                />

                <Typography variant="h6">{GEM_NAME[ninneko.setId]} &nbsp; </Typography>
                <GemAbout ninneko={ninneko} />



                <Typography variant="h6">Attributes &nbsp; </Typography>
                <Paper variant="outlined" style={{ marginTop: 16, marginBottom: 16 }}>
                    <BonusInfo ninneko={ninneko}/>
                </Paper>

                <GemHistory id={ninneko.nftId} />

            </Grid>



        </Grid >


    );


}
