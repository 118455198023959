import { Grid, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { Fragment } from 'react';
import { BASE_STATS, BONUS_FROM_CLASS_OF_PART, BONUS_FROM_PART, BONUS_STATS, BONUS_STATS_CONST, GEN_TYPE_WILD } from '../../../utils/constants';
import { PET_GEN_MAP } from '../../../utils/genMap';
import { getBodyScale, pointLevel1 } from '../../../utils/petStatsUtil';


const ArmIcon = '/images/svg/arm.svg';
const AtkIcon = '/images/svg/atk.svg';
const HpIcon = '/images/svg/hp.svg';
const SpdIcon = '/images/svg/spd.svg';










const useStyles = makeStyles((theme) => ({

    paper: {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(1),

        padding: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1),
        },
    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 26,
        height: 26
    },



}));




export function getNinnekoStats(ninneko) {

    //let stats = {}

    //let points = ['atk', 'hp', 'arm', 'spd']

    // let bonusPoint = getBonusFromPart(ninneko)

    // points.forEach(point => {
    //     let base = BASE_STATS[ninneko.class][point]
    //     let value = base + base *bonusPoint[point]*BONUS_STATS_CONST

    //     stats[point] = Math.floor(value)
    // })
    // return (stats)
    return pointLevel1(1,ninneko)

}


function getBonusFromPart(ninneko) {

    //lưu điểm bonus vào các chỉ số
    let retBonus = { 'hp': 0, 'atk': 0, 'arm': 0, 'spd': 0 }
    let parts = ['handD', 'eyesD', 'earsD', 'mouthD', 'tailD', 'hairD']

    //Duyệt qua các part của PET
    parts.forEach(part => {
        //class của GEN
        let genClass = PET_GEN_MAP[ninneko[part]].class
        //type của GEN - có WILD không
        let genType = PET_GEN_MAP[ninneko[part]].type

        //lấy bonus theo bảng BONUS, tùy vào CLASS mà sẽ có bonus khác nhau
        let bonusOfClass = BONUS_FROM_CLASS_OF_PART[genClass]
        //duyệt qua bảng bonus, để lấy các chỉ số - point
        for (let point in retBonus){
            if (bonusOfClass[point]) {//trong bảng bonus có bonus ứng với point này
                let bonusValue = bonusOfClass[point]
                //WILD thì x2 bonus
                if (genType == GEN_TYPE_WILD){
                    bonusValue += bonusValue 
                }
                retBonus[point] += bonusValue

            }
        }

    });

    return retBonus

       

}



export function PetStats({ ninneko }) {

    const classes = useStyles()

    const stats = getNinnekoStats(ninneko)
    
    return (
        <Fragment>
            <Typography variant="h6">Base stats</Typography>
            <Paper className={classes.paper} /*elevation={4}*/ variant="outlined">
                <Grid container spacing={2}>
                    <Grid item xs={3} >
                        <Typography color="textSecondary">health</Typography>
                        <div style={{ display: 'inline-flex' }}>
                            <img src={HpIcon} className={classes.icon} />
                            <Typography>{stats['hp']}</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={3} >
                        <Typography color="textSecondary">attack</Typography>
                        <div style={{ display: 'inline-flex' }}>
                            <img src={AtkIcon} className={classes.icon} />
                            <Typography>{stats['atk']}</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={3} >
                        <Typography color="textSecondary">armor</Typography>
                        <div style={{ display: 'inline-flex' }}>
                            <img src={ArmIcon} className={classes.icon} />
                            <Typography>{stats['arm']}</Typography>
                        </div>
                    </Grid>

                    <Grid item xs={3} >
                        <Typography color="textSecondary">speed</Typography>
                        <div style={{ display: 'inline-flex' }}>
                            <img src={SpdIcon} className={classes.icon} />
                            <Typography>{stats['spd']}</Typography>
                        </div>
                    </Grid>

                </Grid>
            </Paper>
        </Fragment>
    )
}

