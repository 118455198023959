import { Drawer, InputLabel, MenuItem, Select, Button, Hidden, useMediaQuery, Card, Typography, Grid } from "@mui/material";
import { MHidden } from '../@material-extend';
import { makeStyles } from '@mui/styles';
import React, { Fragment, useEffect, useState, useRef } from "react";
import { CLASSES, FACTIONS, GENERATIONS, MAX_BREED_COUNT, SALE_STATS, DRAWER_WIDTH, LIFE_STAGES, LIFE_STAGE_ADULT, GEM_NAME, GEM_TYPE_NAME } from "../../utils/constants";
import { useMemoryState } from "../../utils/memoryState";
import FilterPanel from "../Misc/FilterPanel";
import GridPet from "./GridPet";
import SaleStats from "../Stats/SaleStats";
import { useTheme } from "@emotion/react";
import { getPriceUSDFromWei, getPriceUSDFromETH, updateURLParameter, addURLParameter, removeURLParameterByName, removeURLParameterByNameAndValue } from '../../utils/utils';
import { QUERY_MARKET_STATS } from "../../graphql/User/marketQuery";
import { useQuery } from '@apollo/client';
import LoadingSpinner from "../Misc/LoadingSpinner";
import { fontSize } from "@mui/system";
import SideMenu from "../SideBar/SideMenu";
import { accountRoutes } from "../../utils/accountRoutes";
import FilterPanelOfGem from "../Misc/FilterPanelOfGem";

//import {animation} from "../utils/pixiApp"

const useStyles = makeStyles((theme) => ({

    root: {
        display: 'flex',
    },

    toolbar: theme.mixins.toolbar,

    drawerPaper: {
        width: DRAWER_WIDTH,
        padding: theme.spacing(3),
        // overflowX: 'hidden'
        //paddingTop: theme.spacing(2)
        '&::-webkit-scrollbar': {
            width: 0,
        },

    },

    main: {
        //padding: theme.spacing(12),
        //padding: theme.spacing(4),

        paddingTop: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(2),
        },

        paddingBottom: theme.spacing(10),
        flex: 1,
        //display: 'flex',
        //justifyContent: 'center'
        //overflowY: 'auto',
        // overflowX: 'hidden'
    },
    saleFilter: {

    },
    select: {
        padding: 0
    },
    filter: {
        padding: 5,
    },
    filterToolBar: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(5),
            marginRight: theme.spacing(5),
        },
        display: 'flex',
        height: 42,
        justifyContent: 'space-between'
    },


}));


const getFilterObject = (arrayConfig) => {
    let ret = {}
    arrayConfig.map(
        //item => ret[item.label] = false
        item => ret[item.id] = false
    )
    return ret
}




export const GemFilterPanel = ({ handler, isOnInvetoryPage = false }) => {
    const classes = useStyles()
    //console.log(handler.filterClick)
    //console.log(handler.isSM)

    //console.log(handler.handleAddGenParts)
    return (

        <Drawer
            classes={{ paper: classes.drawerPaper }}
            variant={handler.isSM ? 'temporary' : 'persistent'}
            onClose={() => { handler.setFilterClick(false) }}
            open={!handler.isSM || handler.filterClick}
        >
            {!handler.isSM && isOnInvetoryPage &&
                < Fragment >
                    <div className={classes.toolbar} />
                    <SideMenu routes={accountRoutes()} activeNav customStyle={{ paddingLeft: 0, paddingTop: 0, paddingBottom: 0 }} />
                </Fragment>
            }

            <FilterPanelOfGem
                factions={handler.factions}
                
                handleChangeFactions={handler.handleChangeFactions}

                attributes={handler.attributes}
                handleChangeAttributes={handler.handleChangeAttributes}
                petClasses={handler.petClasses}
                handleChangeClasses={handler.handleChangeClasses}
                legendary={handler.legendary}
                setLegendary={handler.handleChangeLegendary}
                pure={handler.pure}
                setPure={handler.setPure}
                breedCount={handler.breedCount}
                setBreedCount={handler.handleChangeBreed}
                generations={handler.generations}
                handleChangeGenerations={handler.handleChangeGenerations}
                handleClear={handler.handleClear}
                genParts={handler.genParts}
                handleAddGenParts={handler.handleAddGenParts}
                handleRemoveGenParts={handler.handleRemoveGenParts}
                filterLifeStage={handler.filterLifeStage}
                handleChangeFilterLifeStage={handler.handleChangeFilterLifeStage}
                isOnInvetoryPage={isOnInvetoryPage}
                isSM={handler.isSM}
            // sale={sale}
            // handleChangeSale={handleChangeSale}
            />
        </Drawer >
    )
}

export const PetSortPanel = ({ handler }) => {

    const classes = useStyles()

    return (
        <div className={classes.filterToolBar}>
            <Select
                size='small'
                value={handler.sale}
                onChange={handler.handleChangeSale}
                className={classes.saleFilter}
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    getcontentanchorel: null
                }}
            >
                <MenuItem value={2}>All</MenuItem>
                <MenuItem value={1}>For sale</MenuItem>
                <MenuItem value={0}>Not for sale</MenuItem>
            </Select>

            <Select
                value={handler.sortValue}
                size='small'
                onChange={handler.handleChangeSort}
                className={classes.saleFilter}
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    getcontentanchorel: null
                }}
            >
                <MenuItem value={4}>Latest</MenuItem>
                <MenuItem value={3}>Highest Price</MenuItem>
                <MenuItem value={2}>Lowest Price</MenuItem>
                <MenuItem value={1}>Highest ID</MenuItem>
                <MenuItem value={0}>Lowest ID</MenuItem>
            </Select>

            <Hidden only={['sm', 'md', 'lg', 'xl']}>
                <Button
                    variant="outlined"
                    className={classes.saleFilter}
                    onClick={() => {
                        //console.log(handler.setFilterClick)
                        handler.setFilterClick(true)
                    }}
                >
                    FILTER
                </Button>
            </Hidden>
        </div>
    )
}

export default function GemFilterHandler({ children, queryParams, inventory = "" }) {
    // const refPage = useRef(variable);
    // refPage.current = variable.page

    const theme = useTheme();

    const isSM = useMediaQuery(theme.breakpoints.down('sm'));



    const [filterClick, setFilterClick] = useState(false);

    var defaultPage = 1
    var defaultPart = []
    var defaultLegend = 0
    var defaultGen = getFilterObject(GENERATIONS)
    
    var defaultSort = { id: undefined, price: false, priceSetAt: undefined }
    var defaultSortInventory = { id: true, price: undefined, priceSetAt: undefined }
    

    var defaultSortValue = 2
    var defaultSortValueInventory = 1


    var defaultSale = 1
    var defaultSaleInventory = 2


    var defaultFaction = {}

    Object.keys(GEM_NAME).map(index => defaultFaction[index] = false)

    var defaultAttribute = {}
    Object.keys(GEM_TYPE_NAME).map(index => defaultAttribute[index] = false)

    var defaultClass = getFilterObject(CLASSES)
    var defaultBreed = [0, MAX_BREED_COUNT]
    var defaultAge = getFilterObject(LIFE_STAGES)
    defaultAge[LIFE_STAGE_ADULT] = true

    var paramPage = defaultPage
    var paramPart = defaultPart
    var paramLegend = defaultLegend
    var paramGen = defaultGen
    var paramSort = defaultSort



    var paramSortValue = defaultSortValue
    var paramSale = defaultSale
    var paramFaction = defaultFaction
    var paramClass = defaultClass
    var paramBreed = defaultBreed
    var paramAge = defaultAge
    var paramAttribute = defaultAttribute

    for (const [key, value] of queryParams) {


        if (key == "page") {
            paramPage = value
        } else if (key == "part") {
            paramPart.push(parseInt(value))

            //console.log(paramPart)
        } else if (key == "faction") {
            for (let i = 0; i < FACTIONS.length; i++) {
                if (value != FACTIONS[i].id) continue

                paramFaction[i + 1] = true
            }
        
        } 
        
        else if (key == "attribute") {
            for (let i = 0; i < GEM_TYPE_NAME.length; i++) {
                if (value != GEM_TYPE_NAME[i].id) continue

                paramAttribute[i + 1] = true
            }
        
        } 
        else if (key == "class") {
            for (let i = 0; i < CLASSES.length; i++) {
                if (value != CLASSES[i].id) continue
                paramClass[i + 1] = true
            }
        } else if (key == "legend") {
            paramLegend = value
        } else if (key == "sale") {
            paramSale = value
        } else if (key == "sort") {
            paramSortValue = parseInt(value)

            if (value == 0) paramSort = { id: false, price: undefined, priceSetAt: undefined }
            if (value == 1) paramSort = { id: true, price: undefined, priceSetAt: undefined }
            if (value == 3) paramSort = { id: undefined, price: true, priceSetAt: undefined }
            if (value == 4) paramSort = { id: undefined, price: undefined, priceSetAt: true }
        } else if (key == "gen") {
            for (let i = 0; i < GENERATIONS.length; i++) {
                if (value != GENERATIONS[i].id) continue
                paramGen[i + 1] = true
            }
        } else if (key == "breed") {
            var arr = value.split("-")
            if (arr[0] && arr[1]) {
                paramBreed = [arr[0], arr[1]]
            }
        } else if (key == "age") {
            for (let i = 0; i < LIFE_STAGES.length; i++) {
                if (value != LIFE_STAGES[i].id) continue

                paramAge[i + 1] = true
            }
        }
    }

    // const [page, setPage] = useMemoryState('page', pageId);
    const [page, setPage] = useMemoryState("gem" + inventory + 'page', paramPage);

    //FILTER
    const [breedCount, setBreedCount] = useMemoryState("gem" + inventory + 'stars', paramBreed)

    const [legendary, setLegendary] = useMemoryState("gem" + inventory + 'legendary', paramLegend)

    const [pure, setPure] = useMemoryState("gem" + inventory + 'pure', 0)

    const [generations, setGenerations] = useMemoryState("gem" + inventory + 'generations', paramGen);



    const [genParts, setGenParts] = useMemoryState("gem" + inventory + 'genParts', paramPart);

    const [factions, setFactions] = useMemoryState("gem" + inventory + 'factions', paramFaction);

    const [attributes, setAttributes] = useMemoryState("gem" + inventory + 'attributes', paramAttribute);

    const [petClasses, setPetClasses] = useMemoryState("gem" + inventory + 'petClasses', paramClass);

    //SORT
    const [sort, setSort] = useMemoryState("gem" + inventory + 'sort',inventory?defaultSortInventory:paramSort);


    //const [filterLifeStage, setFilterLifeStage] = useMemoryState(inventory+'age', paramAge)
    const [filterLifeStage, setFilterLifeStage] = useMemoryState("gem" + inventory + 'age', inventory ? { 1: true, 2: true } : paramAge)

    //const [sortValue, setSortValue] = useMemoryState(inventory+'sortValue', paramSortValue);
    const [sortValue, setSortValue] = useMemoryState("gem" + inventory + 'sortValue', inventory ? defaultSortValueInventory : paramSortValue);

    //const [sale, setSale] = useMemoryState(inventory+'sale', paramSale);
    const [sale, setSale] = useMemoryState("gem" + inventory + 'sale', inventory ? defaultSaleInventory : paramSale);





    const handleAddGenParts = (value) => {

        value = parseInt(value)

        //console.log(genParts)
        //console.log(value)

        if (genParts && genParts.length >= 4) return

        if (genParts.includes(value)) {
            return
        } 

        setPage(1)
        var newGenParts = [...genParts, value]


        // for (const [id, name] of Object.entries(genParts)) {
        //     if (id == value) return
        // }
        // setGenParts(newGenParts);

        

        setGenParts(newGenParts);

        var newURL = updateURLParameter(window.location.href, "page", 1)
        newURL = removeURLParameterByName(newURL, "part")

        if (newGenParts.length > 0) {
            // newURL = updateURLParameter(window.location.href, "part", newGenParts[0])

            for (let i = 0; i < newGenParts.length; i++) {
                newURL = addURLParameter(newURL, "part", newGenParts[i])
            }

            window.history.replaceState('', '', newURL)
        }

        window.history.replaceState('', '', newURL)
    };

    const handleRemoveGenParts = (value) => {
        setPage(1)
        var arr = [...genParts]
        var index = arr.indexOf(value)
        if (index !== -1) {
            arr.splice(index, 1)
            setGenParts(arr);
        }

        var newURL = updateURLParameter(window.location.href, "page", 1)
        newURL = removeURLParameterByName(newURL, "part")

        if (arr.length > 0) {
            for (let i = 0; i < arr.length; i++) {
                newURL = addURLParameter(newURL, "part", arr[i])
            }
        }

        window.history.replaceState('', '', newURL)
    };

    const handleChangeFilterLifeStage = (event) => {
        setPage(1)
        var newFilterLifeStage = { ...filterLifeStage, [event.target.name]: event.target.checked }
        console.log(newFilterLifeStage)
        setFilterLifeStage(newFilterLifeStage);

        var newURL = updateURLParameter(window.location.href, "page", 1)
        newURL = removeURLParameterByName(newURL, "age")

        for (let i = 0; i < LIFE_STAGES.length; i++) {
            if (newFilterLifeStage[i + 1] == true) {
                newURL = addURLParameter(newURL, "age", LIFE_STAGES[i].id)
            }

        }

        window.history.replaceState('', '', newURL)
    };

    const handleChangeGenerations = (event) => {
        setPage(1)
        var newGenerations = { ...generations, [event.target.name]: event.target.checked }
        setGenerations(newGenerations);

        var newURL = updateURLParameter(window.location.href, "page", 1)
        newURL = removeURLParameterByName(newURL, "gen")

        for (let i = 0; i < GENERATIONS.length; i++) {
            if (newGenerations[i + 1] == true) {
                newURL = addURLParameter(newURL, "gen", GENERATIONS[i].id)
            }

        }

        window.history.replaceState('', '', newURL)
    };

    const handleChangeSale = (event) => {

        setPage(1)
        setSale(event.target.value)
        var newURL = updateURLParameter(window.location.href, "page", 1)
        newURL = updateURLParameter(newURL, "sale", event.target.value)
        window.history.replaceState('', '', newURL)
    };


    const handleChangeSort = (event) => {

        const value = event.target.value
        doUpdateChangeSort(value)

    };

    const doUpdateChangeSort = (value) => {
        if (value === 0) setSort({ id: false, price: undefined, priceSetAt: undefined })
        if (value === 1) setSort({ id: true, price: undefined, priceSetAt: undefined })
        if (value === 2) setSort({ id: undefined, price: false, priceSetAt: undefined })
        if (value === 3) setSort({ id: undefined, price: true, priceSetAt: undefined })
        if (value === 4) setSort({ id: undefined, price: undefined, priceSetAt: true })
        setPage(1)
        setSortValue(value)
        var newURL = updateURLParameter(window.location.href, "page", 1)
        newURL = updateURLParameter(newURL, "sort", value)
        window.history.replaceState('', '', newURL)
    }

    const handleChangeFactions = (event) => {
        setPage(1)
        var newFactions = { ...factions, [event.target.name]: event.target.checked }
        setFactions(newFactions);

        var newURL = updateURLParameter(window.location.href, "page", 1)
        newURL = removeURLParameterByName(newURL, "faction")

        for (let i = 0; i < FACTIONS.length; i++) {
            if (newFactions[i + 1] == true) {
                newURL = addURLParameter(newURL, "faction", FACTIONS[i].id)
            }

        }

        window.history.replaceState('', '', newURL)

    };

    const handleChangeAttributes = (event) => {
        setPage(1)
        var newAttributes = { ...attributes, [event.target.name]: event.target.checked }
        setAttributes(newAttributes);

        var newURL = updateURLParameter(window.location.href, "page", 1)
        newURL = removeURLParameterByName(newURL, "attribute")

        for (let i = 0; i < GEM_TYPE_NAME.length; i++) {
            if (newAttributes[i + 1] == true) {
                newURL = addURLParameter(newURL, "attribute", GEM_TYPE_NAME[i].id)
            }

        }

        window.history.replaceState('', '', newURL)

    };

    const handleChangeClasses = (event) => {
        setPage(1)
        var newClasses = { ...petClasses, [event.target.name]: event.target.checked }
        setPetClasses(newClasses);

        var newURL = updateURLParameter(window.location.href, "page", 1)
        newURL = removeURLParameterByName(newURL, "class")

        for (let i = 0; i < CLASSES.length; i++) {
            if (newClasses[i + 1] == true) {
                newURL = addURLParameter(newURL, "class", CLASSES[i].id)
            }

        }

        window.history.replaceState('', '', newURL)
    };

    const handleChangeLegendary = (value) => {
        setPage(1)
        setLegendary(value)
        var newURL = updateURLParameter(window.location.href, "page", 1)
        newURL = updateURLParameter(newURL, "legend", value)
        window.history.replaceState('', '', newURL)
    };

    const handleChangeBreed = (value) => {
        setPage(1)
        setBreedCount(value)
        var paramVal = value[0] + "-" + value[1]
        var newURL = updateURLParameter(window.location.href, "page", 1)
        newURL = updateURLParameter(newURL, "breed", paramVal)
        window.history.replaceState('', '', newURL)
    };

    const handleClear = () => {






        // setBreedCount([0, MAX_BREED_COUNT])

        // setLegendary(0)

        // setPure(0)

        // setFactions(getFilterObject(FACTIONS))

        // setPetClasses(getFilterObject(CLASSES))

        // setGenerations(getFilterObject(GENERATIONS))

        // setGenParts([])

        // setFilterLifeStage()

        setPage(defaultPage)
        setGenParts(defaultPart)
        setLegendary(defaultLegend)
        setGenerations(defaultGen)
        setSort(defaultSort)



        setFactions(defaultFaction)
        setPetClasses(defaultClass)
        setBreedCount(defaultBreed)



        setFilterLifeStage(inventory ? { 1: true, 2: true } : defaultAge)
        setSortValue(inventory ? defaultSortValueInventory : defaultSortValue)
        setSale(inventory ? defaultSaleInventory : defaultSale)
    }

    //----END FILTER


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        window.history.replaceState('', '', updateURLParameter(window.location.href, "page", newPage))
        // window.history.replaceState('', '', updateURLParameter(window.location.href, "param", "value"));
    };

    //select statistic
    const [statsSelected, setStatsSelected] = useState("24h")

    //query statistic
    const { data } = useQuery(QUERY_MARKET_STATS)

    if (!data || !data.marketStats) return <LoadingSpinner />

    return (

        React.cloneElement(children, {
            handler: {
                factions: factions,
                handleChangeFactions: handleChangeFactions,
                attributes: attributes,
                handleChangeAttributes:handleChangeAttributes,
                petClasses: petClasses,
                handleChangeClasses: handleChangeClasses,
                legendary: legendary,
                handleChangeLegendary: handleChangeLegendary,
                pure: pure,
                setPure: setPure,
                breedCount: breedCount,
                handleChangeBreed: handleChangeBreed,
                generations: generations,
                handleChangeGenerations: handleChangeGenerations,
                handleClear: handleClear,
                genParts: genParts,
                handleAddGenParts: handleAddGenParts,
                handleRemoveGenParts: handleRemoveGenParts,
                filterLifeStage: filterLifeStage,
                handleChangeFilterLifeStage: handleChangeFilterLifeStage,
                page: page,
                handleChangePage: handleChangePage,
                sale: sale,
                forSale: sale,
                sort: sort,
                lifeStages: filterLifeStage,
                handleChangeSale: handleChangeSale,
                sortValue: sortValue,
                handleChangeSort: handleChangeSort,
                setFilterClick: setFilterClick,
                setSale: setSale,
                setFilterLifeStage: setFilterLifeStage,
                doUpdateChangeSort: doUpdateChangeSort,
                filterClick: filterClick,
                isSM: isSM

            }
        }
        ));

}


