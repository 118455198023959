
import React, { useState } from 'react';
import { Button, Card, Container, Grid, Paper, Typography, Alert } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { QUERY_MARKET_STATS } from '../../graphql/User/marketQuery';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../Misc/LoadingSpinner';
import { useTheme } from '@emotion/react';


const useStyles = makeStyles((theme) => ({
    statistic: {
        marginTop: theme.spacing(9),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(5),
            paddingRight: theme.spacing(5),
        },
        display: "block"
    },
    statisticSelected: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        textAlign: "center",
        borderBottom: "2px solid #00ab55",
        color: "#212B36",
        cursor: "context-menu"
    },
    statisticOption: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        textAlign: "center",
        color: "#8f929c",
        cursor: "pointer",
    },
    statisticInfo: {
        borderTop: "1px solid #eaeefb",
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(9),
            // paddingRight: theme.spacing(5),
        },
    },
    statisticTitle: {
        fontWeight: 700,
        fontSize: 10,
        color: "#6d7079"
    },
    statisticContent: {
        fontWeight: 600,
        fontSize: 28
    },
}));


const getMarketStatsByTime = (time, dataQuery) => {
    if (time == "30d") {
        return dataQuery.last30d
    } else if (time == "7d") {
        return dataQuery.last7d
    } else if (time == "24h") {
        return dataQuery.last24h
    } else {
        return dataQuery.alltime
    }
}


export default function SaleStats() {

    const theme = useTheme();

    const classes = useStyles();
    //select statistic
    const [statsSelected, setStatsSelected] = useState("30d")

    //query statistic
    const { data } = useQuery(QUERY_MARKET_STATS)

    if (!data || !data.marketStats) return <LoadingSpinner />


    return (
            <Grid container className={classes.statistic}>
                <Card>

                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={4}  onClick={() => { setStatsSelected("24h") }}>
                                <Typography className={statsSelected == "24h" ? classes.statisticSelected : classes.statisticOption}>
                                    Last 24h
                                </Typography>
                            </Grid>
                            <Grid item xs={4}  onClick={() => { setStatsSelected("7d") }}>
                                <Typography className={statsSelected == "7d" ? classes.statisticSelected : classes.statisticOption}>
                                    7 days
                                </Typography>
                            </Grid>
                            <Grid item xs={4}  onClick={() => { setStatsSelected("30d") }}>
                                <Typography className={statsSelected == "30d" ? classes.statisticSelected : classes.statisticOption}>
                                    30 days
                                </Typography>
                            </Grid>
                            {/* <Grid item xs={4} md={1.15} onClick={() => { setStatsSelected("all") }}>
                                <Typography className={statsSelected == "all" ? classes.statisticSelected : classes.statisticOption}>
                                    All time
                                </Typography>
                            </Grid> */}
                        </Grid>
                    </Grid>

                    <Grid item xs={12} className={classes.statisticInfo}>
                        <Grid container>
                            <Grid item xs={12} md={4}>
                                <Grid container style={{ paddingTop: theme.spacing(2), paddingBottom: theme.spacing(1) }}>
                                    <Grid item xs={4} sm={3} md={2}> <img src="/images/svg/pie-chart-svgrepo-com.svg" style={{ height: 50 }} /> </Grid>
                                    <Grid item xs={8} sm={9} md={10}>
                                        <Grid container>
                                            <Grid item xs={12}><Typography className={classes.statisticTitle}>TOTAL SALE</Typography></Grid>
                                            <Grid item xs={12}>
                                                <Typography className={classes.statisticContent}>
                                                    {getMarketStatsByTime(statsSelected, data.marketStats).txCount}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Grid container style={{ paddingTop: theme.spacing(2), paddingBottom: theme.spacing(1) }}>
                                    <Grid item xs={4} sm={3} md={2}> <img src="/images//svg/binance-coin-seeklogo.com.svg" style={{ height: 50 }} /> </Grid>
                                    <Grid item xs={8} sm={9} md={10}>
                                        <Grid container>
                                            <Grid item xs={12}><Typography className={classes.statisticTitle}>TOTAL VOLUME</Typography></Grid>
                                            <Grid item xs={12}>
                                                <Typography className={classes.statisticContent}>
                                                    {((getMarketStatsByTime(statsSelected, data.marketStats).volume / 1e18).toFixed()) + " BNB"}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Grid container style={{ paddingTop: theme.spacing(2), paddingBottom: theme.spacing(1) }}>
                                    <Grid item xs={4} sm={3} md={2}> <img src="/images/svg/nino_black.svg" style={{ height: 50 }} /> </Grid>
                                    <Grid item xs={8} sm={9} md={10}>
                                        <Grid container >
                                            <Grid item xs={12}><Typography className={classes.statisticTitle}>NINNEKO SOLD</Typography></Grid>
                                            <Grid item xs={12}>
                                                <Typography className={classes.statisticContent}>
                                                    {getMarketStatsByTime(statsSelected, data.marketStats).petCount}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>

    );
}