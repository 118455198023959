
import { Sprite, Stage, useApp } from '@inlet/react-pixi';
import { makeStyles } from '@mui/styles';
import * as PIXI from 'pixi.js';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { getImage, offSetX, offSetY, pose_by_class, pose_data } from '../../utils/gen';
import { PET_GEN_MAP } from '../../utils/genMap';
import Spine from '../../utils/Spine';
import LoadingSpinner from '../Misc/LoadingSpinner';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        //alignItems: 'flex-center'
    },


}));


export const STAGE_WIDTH = 830 

export const STAGE_HEIGHT = 830

const mixes = [
    {
        from: "K_Idling",
        to: "K_Run",
        duration: 0.2
    },
    {
        from: "K_Run",
        to: "K_Idling",
        duration: 0.2
    },

];

const anims = [
    "Idling2",
    //"K_Run",
    //"K_Attack1",
    //"S_Idling",
    //"S_Run",
]



export default function PetBigImage({ ninneko }) {


    const [spineData, setSpineData] = useState(null);
    const [animationState, setAnimationState] = useState();

    let animIndex = 0


    // const app = useApp();
    // app.renderer.plugins.interaction.autoPreventDefault = false;
    // app.renderer.view.style.touchAction = 'auto';

    //const [app, setApp] = React.useState()
    const setUpPixiApp = (app) => {
        app.renderer.plugins.interaction.autoPreventDefault = false;
        app.renderer.view.style.touchAction = 'auto';
    }


    useEffect(() => {
        const initSpine = async () => {


            await import("pixi-spine");
            const loader = new PIXI.Loader();

            const petClass = ninneko.class
            const pose = PET_GEN_MAP[ninneko.handD].pose

            //console.log(`ninneko.handD `+ninneko.handD)

            //console.log(`/spine/${petClass}/Body_${petClass}_${pose}.json`)



            loader
                .add("spineboy", `/spine/${petClass}/Body_${petClass}_${pose}.json`)
                .add("weapon_back", "/gen/" + getImage("weapon_back", ninneko))
                .add("arm_2", "/gen/" + getImage("arm_2", ninneko))
                .add("hand_2", "/gen/" + getImage("hand_2", ninneko))
                .add("weapon_right", "/gen/" + getImage("weapon_right", ninneko))
                .add("tail", "/gen/" + getImage("tail", ninneko))
                .add("ear_right", "/gen/" + getImage("ear_right", ninneko))
                .add("leg_2", "/gen/" + getImage("leg_2", ninneko))
                .add("hip", "/gen/" + getImage("hip", ninneko))
                .add("leg_1", "/gen/" + getImage("leg_1", ninneko))
                .add("base_skin", "/gen/" + getImage("base_skin", ninneko))
                .add("shirt", "/gen/" + getImage("shirt", ninneko))
                .add("head", "/gen/" + getImage("head", ninneko))
                .add("mouth", "/gen/" + getImage("mouth", ninneko))
                .add("eye_cheek", "/gen/" + getImage("eye_cheek", ninneko))
                .add("eye_base", "/gen/" + getImage("eye_base", ninneko))
                .add("eye_dot_left", "/gen/" + getImage("eye_dot_left", ninneko))
                .add("eye_dot_right", "/gen/" + getImage("eye_dot_right", ninneko))
                .add("eye_line", "/gen/" + getImage("eye_line", ninneko))
                .add("eye_mask", "/gen/" + getImage("eye_mask", ninneko))
                .add("hat", "/gen/" + getImage("hat", ninneko))
                .add("ear_left", "/gen/" + getImage("ear_left", ninneko))
                .add("weapon_left", "/gen/" + getImage("weapon_left", ninneko))
                .add("arm_1", "/gen/" + getImage("arm_1", ninneko))
                .add("hand_1", "/gen/" + getImage("hand_1", ninneko))

                .load((loader, res) => {

                    let ret = {
                        spineData: res?.spineboy?.spineData,
                        loader: loader,
                        petClass: ninneko.class
                    }
                    setSpineData(ret);
                });
        };
        initSpine();
        return () => setSpineData(null)
    }, [ninneko.id]);

    // const stateRef = useCallback((state) => {
    //     if (state) {
    //         state.state.setAnimation(0, anims[animIndex], true);
    //         //state.addAnimation(0, "K_Run", true, 0);
    //     }
    //     setAnimationState(state);
    // }, []);

    // const jump = useCallback(() => {
    //     if (animationState) {

    //         animIndex++
    //         if (animIndex >= anims.length) animIndex = 0
    //         animationState.state.setAnimation(0, anims[animIndex], true);
    //     }
    // }, [animationState]);


    // console.log(ninneko.class)

    if (!spineData) return <LoadingSpinner />
    return (
      

            <Stage width={STAGE_WIDTH} height={STAGE_HEIGHT}
                onMount={setUpPixiApp}
                //onClick={jump}
                options={{
                    backgroundAlpha: 0,
                    // backgroundColor: 0

                }}>
                

                {
                    pose_by_class(ninneko.handD).map(em => {

                        const image = "/gen/" + getImage(em.slot, ninneko)

                        // console.log(em.slot +" image "+image)

                        //console.log(`.add("${em.slot}","/gen/" + getImage("${em.slot}",ninneko))`)

                        // let ret = 
                        // `let ${em.slot} = loader.resources["${em.slot}"]?.texture 
                        // if (${em.slot}) spine.hackTextureBySlotName("${em.slot}", ${em.slot},${em.slot}.orig) \n`

                        // console.log(ret)

                        return <Sprite image={image} x={parseInt(em.x) + offSetX+200} y={-parseInt(em.y) + offSetY+200}
                            anchor={(0.5, 0.5)}
                            angle={-em.angle}
                            //scale={1, 1} 
                            key={em.slot} />
                    }
                    )}


            </Stage>

       
    );


}

