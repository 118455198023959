import { CLASSES, FACTIONS } from "./constants";

const ATTACK = 0;
const ARMOR = 1;
const HP = 2;
const SPEED = 3;
const ANGER = 4;

// Point percent
const SKILL_DAMAGE = 5;
const PRECISION = 6;
const BLOCK = 7;
const CRIT = 8;
const CRIT_DAMAGE = 9;
const ARMOR_BREAK = 10;
const CONTROL_IMMUNE = 11;
const REDUCE_DAMAGE = 12;
const HOLY_DAMAGE = 13;


const SPEC_ADD_DAMAGE_AGAINST_ASSASIN = 35;
const SPEC_ADD_DAMAGE_AGAINST_MAGE = 36;
const SPEC_ADD_DAMAGE_AGAINST_PRIEST = 37;
const SPEC_ADD_DAMAGE_AGAINST_RANGER = 38;
const SPEC_ADD_DAMAGE_AGAINST_WARRIOR = 39;
const SPEC_IMMUNE_SILENCE = 40;
const SPEC_IMMUNE_STONE = 41;
const SPEC_IMMUNE_STUN = 42;
const SPEC_IMMUNE_FREEZE = 43;
const SPEC_ADD_DAMAGE_AGAINST_BURN_FOE = 44;
const SPEC_ADD_DAMAGE_AGAINST_POISON_FOE = 45;
const SPEC_ADD_DAMAGE_AGAINST_BLOOD_FOE = 46;
const SPEC_ADD_DAMAGE_AGAINST_FREEZE_FOE = 47;
const SPEC_ADD_DAMAGE_AGAINST_STONE_FOE = 48;
const SPEC_ADD_DAMAGE_AGAINST_STUN_FOE = 49;
const SPEC_MORE_HEAL = 52;

const CLASS_MAGE = 1;
const CLASS_WARRIOR = 2;
const CLASS_SUPPORT = 3;
const CLASS_RANGER = 4;
const CLASS_ASSASSIN = 5;


function GetSpecAddMoreDamageOnHeroClass(heroClazz) {
    return "damage to cat" + " " + CLASSES[heroClazz].label;
}




export function getPointDesc(points) {

    
    let strAttributePoint = "";
    for (let i = 0; i < points.length ; i += 2) {
        
        let index = points[i];
        let value = points[i + 1];
        switch (index) {
            case ATTACK:
                strAttributePoint += value + " " + "Attack";
                break;
            case ARMOR:
                strAttributePoint += value + " " + "Armor";
                break;
            case HP:
                strAttributePoint += value + " " + "HP";
                break;
            case 100:
                strAttributePoint += (parseFloat(value) / 10) + "% " + "Attack";
                break;
            case 101:
                strAttributePoint += (parseFloat(value) / 10) + "% " + "Armor";
                break;
            case 102:
                strAttributePoint += (parseFloat(value) / 10) + "% " + "HP";
                break;
            case 103:
                strAttributePoint += (parseFloat(value) / 10) + "% " + "Speed";
                break;
            case 104:
                strAttributePoint += (parseFloat(value) / 10) + "% " + "Energy";
                break;
            case 105:
                strAttributePoint += (parseFloat(value) / 10) + "% " + "Skill damage";
                break;
            case 106:
                strAttributePoint += (parseFloat(value) / 10) + "% " + "Accuracy";
                break;
            case 107:
                strAttributePoint += (parseFloat(value) / 10) + "% " + "Block";
                break;
            case 108:
                strAttributePoint += (parseFloat(value) / 10) + "% " + "Crit";
                break;
            case 109:
                strAttributePoint += (parseFloat(value) / 10) + "% " + "Crit damage";
                break;
            case 110:
                strAttributePoint += (parseFloat(value) / 10) + "% " + "Armor break";
                break;
            case 111:
                strAttributePoint += (parseFloat(value) / 10) + "% " + "Immunity";
                break;
            case 112:
                strAttributePoint += (parseFloat(value) / 10) + "% " + "Skill damage";
                break;
            case 113:
                strAttributePoint += (parseFloat(value) / 10) + "% " + "True damage";
                break;
            case SPEED:
                strAttributePoint += value + " " + "Speed";
                break;
            case ANGER:
                strAttributePoint += value + " " + "Energy";
                break;
            case SKILL_DAMAGE:
                strAttributePoint += (parseFloat(value) / 10) + "% " + "Skill damage";
                break;
            case PRECISION:
                strAttributePoint += (parseFloat(value) / 10) + "% " + "Accuracy";
                break;
            case BLOCK:
                strAttributePoint += (parseFloat(value) / 10) + "% " + "Block";
                break;
            case CRIT:
                strAttributePoint += (parseFloat(value) / 10) + "% " + "Crit";
                break;
            case CRIT_DAMAGE:
                strAttributePoint += (parseFloat(value) / 10) + "% " + "Crit damage";
                break;
            case ARMOR_BREAK:
                strAttributePoint += (parseFloat(value) / 10) + "% " + "Armor break";
                break;
            case CONTROL_IMMUNE:
                strAttributePoint += (parseFloat(value) / 10) + "% " + "Immunity";
                break;
            case REDUCE_DAMAGE:
                strAttributePoint += (parseFloat(value) / 10) + "% " + "Damage reduction";
                break;
            case HOLY_DAMAGE:
                strAttributePoint += (parseFloat(value) / 10) + "% " + "True damage";
                break;
            case SPEC_ADD_DAMAGE_AGAINST_ASSASIN:
                strAttributePoint += (parseFloat(value) / 10) + "% " + GetSpecAddMoreDamageOnHeroClass(CLASS_ASSASSIN);
                // I2.Loc.ScriptLocalization.SPEC_ADD_DAMAGE_AGAINST_ASSASIN;
                break;
            case SPEC_ADD_DAMAGE_AGAINST_MAGE:
                strAttributePoint += (parseFloat(value) / 10) + "% " + GetSpecAddMoreDamageOnHeroClass(CLASS_MAGE);
                //I2.Loc.ScriptLocalization.SPEC_ADD_DAMAGE_AGAINST_MAGE;
                break;
            case SPEC_ADD_DAMAGE_AGAINST_WARRIOR:
                strAttributePoint += (parseFloat(value) / 10) + "% " + GetSpecAddMoreDamageOnHeroClass(CLASS_WARRIOR);
                //I2.Loc.ScriptLocalization.SPEC_ADD_DAMAGE_AGAINST_WARRIOR;
                break;
            case SPEC_ADD_DAMAGE_AGAINST_PRIEST:
                strAttributePoint += (parseFloat(value) / 10) + "% " + GetSpecAddMoreDamageOnHeroClass(CLASS_SUPPORT);
                //I2.Loc.ScriptLocalization.SPEC_ADD_DAMAGE_AGAINST_PRIEST;
                break;
            case SPEC_ADD_DAMAGE_AGAINST_RANGER:
                strAttributePoint += (parseFloat(value) / 10) + "% " + GetSpecAddMoreDamageOnHeroClass(CLASS_RANGER);
                //I2.Loc.ScriptLocalization.SPEC_ADD_DAMAGE_AGAINST_RANGER;
                break;
            case SPEC_IMMUNE_SILENCE:
                strAttributePoint += (parseFloat(value) / 10) + "% immune to silence";
                break;
            case SPEC_IMMUNE_STONE:
                strAttributePoint += (parseFloat(value) / 10) + "% immune to stone";
                break;
            case SPEC_IMMUNE_STUN:
                strAttributePoint += (parseFloat(value) / 10) + "% immune to stune";
                break;
            case SPEC_IMMUNE_FREEZE:
                strAttributePoint += (parseFloat(value) / 10) + "% immune to freeze";
                break;
            case SPEC_ADD_DAMAGE_AGAINST_BURN_FOE:
                strAttributePoint += (parseFloat(value) / 10) + "% damage against burnt enemies";
                break;
            case SPEC_ADD_DAMAGE_AGAINST_POISON_FOE:
                strAttributePoint += (parseFloat(value) / 10) + "% damage against poisoned enemies";
                break;
            case SPEC_ADD_DAMAGE_AGAINST_BLOOD_FOE:
                strAttributePoint += (parseFloat(value) / 10) + "% damage against bleeding enemies";
                break;
            case SPEC_ADD_DAMAGE_AGAINST_FREEZE_FOE:
                strAttributePoint += (parseFloat(value) / 10) + "% damage against frozen enemies";
                break;
            case SPEC_ADD_DAMAGE_AGAINST_STONE_FOE:
                strAttributePoint += (parseFloat(value) / 10) + "% damage against stoned enemies";
                break;
            case SPEC_ADD_DAMAGE_AGAINST_STUN_FOE:
                strAttributePoint += (parseFloat(value) / 10) + "% damage against stunned enemies";
                break;
            case SPEC_MORE_HEAL:
                strAttributePoint += (parseFloat(value) / 10) + "% healing effect";
                break;
            default:
                strAttributePoint += value + "not defined";
                break;
        }
        //
        if (i + 2 < points.length) {
            strAttributePoint += ", ";
        }
    }

    //console.log(strAttributePoint)
    return strAttributePoint
}






