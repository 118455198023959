import { useQuery } from '@apollo/client';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
    Timeline, TimelineConnector,
    TimelineContent, TimelineDot, TimelineItem,
    TimelineSeparator
} from '@mui/lab';
import {
    Collapse, Grid,
    List,
    ListItemButton, ListItemText, Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { MY_ACTIVITIES } from '../../graphql/User/userQuery';



const useStyles = makeStyles((theme) => ({
    autoView: {
        margin: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(2),
        },
    },
    
    
    
    button: {
        width: 90
    },
    oppositeContent: {
        // TODO: adjust this value accordingly
        flex: 30
    }

}));

const TimelineActivity = ({ activity }) => {
    const classes = useStyles()
    return (
        <TimelineItem>
            <TimelineSeparator >
                <TimelineDot variant="outlined" />
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent className={classes.oppositeContent}>
                <Typography variant="h6">{new Date(activity.createdAt).toLocaleString()}</Typography>
                <Typography variant="body2">{activity.message}</Typography>
            </TimelineContent>
        </TimelineItem>
    )
}

const makeActivityData = (activities) => {
    const now = new Date()
    const y = now.getFullYear();
    const m = now.getMonth();
    const d = now.getDate();
    const today = new Date(y, m, d, 0, 0, 0);
    const day = now.getDay()
    const fw = new Date(today - day + 1);
    const thisWeek = new Date(fw.getFullYear(), fw.getMonth(), fw.getDate(), 0, 0, 0);
    const thisMonth = new Date(y, m, 1, 0, 0, 0);
    var todayLst = activities.filter(r => {
        const at = Date.parse(r.createdAt)
        return at >= today
    });
    var thisWeekLst = activities.filter(r => {
        const at = Date.parse(r.createdAt)
        return at >= thisWeek && at < today
    });
    var thisMonthLst = activities.filter(r => {
        const at = Date.parse(r.createdAt)
        return at >= thisMonth && at < thisWeek
    });
    var ret = {
        today: todayLst,
        week: thisWeekLst,
        month: thisMonthLst
    }
    //console.log(ret)
    return ret
}


export default function ActivitiesView() {
    
    const classes = useStyles()
    const [todayActivity, setTodayActivity] = React.useState(null)
    const [weekActivity, setWeekActivity] = React.useState(null)
    const [monthActivity, setMonthActivity] = React.useState(null)

    const [todayOpen, setTodayOpen] = React.useState(true)
    const [weekOpen, setWeekOpen] = React.useState(false)
    const [monthOpen, setMonthOpen] = React.useState(false)
   

    const onActivityCompleted = (retsult) => {
        const myActivities = makeActivityData(retsult.myActivities)
        setTodayActivity(myActivities.today)
        setWeekActivity(myActivities.week)
        setMonthActivity(myActivities.month)
    }


    useQuery(MY_ACTIVITIES, {
        onCompleted: onActivityCompleted
    });


    return (
        <div className={classes.autoView}>
            <Grid container spacing={2} >

                <Grid item xs={12} sm={12} md={6}>
                    <Typography variant="h6">My Activities</Typography>
                    <ListItemButton onClick={() => { setTodayOpen(!todayOpen); setWeekOpen(false); setMonthOpen(false) }}>
                        <ListItemText primary="Today" />
                        {todayOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={todayOpen} timeout="auto" unmountOnExit>
                        <List componet="div" disablePadding>
                            <Timeline>
                                {
                                    todayActivity ? todayActivity.map((e,index) => <TimelineActivity activity={e} key={index} />) : null
                                }
                            </Timeline>
                        </List>
                    </Collapse>

                    
                    
                    <ListItemButton onClick={() => { setWeekOpen(!weekOpen); setTodayOpen(false); setMonthOpen(false) }}>
                        <ListItemText primary="This Week" />
                        {weekOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={weekOpen} timeout="auto" unmountOnExit>
                        <List componet="div" disablePadding>
                            <Timeline>
                                {
                                    weekActivity ? weekActivity.map((e,index) => <TimelineActivity activity={e}  key={index}/>) : null
                                }
                            </Timeline>
                        </List>
                    </Collapse>


                    
                    <ListItemButton onClick={() => { setMonthOpen(!monthOpen); setTodayOpen(false); setWeekOpen(false) }}>
                        <ListItemText primary="This Month" />
                        {monthOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={monthOpen} timeout="auto" unmountOnExit>
                        <List componet="div" disablePadding>
                            <Timeline>
                                {
                                    monthActivity ? monthActivity.map((e,index) => <TimelineActivity activity={e}  key={index}/>) : null
                                }
                            </Timeline>
                        </List>
                    </Collapse>
                </Grid>
            </Grid>
        </div >

    );
}
