import { Card, CardHeader } from "@mui/material";
import { createTheme,responsiveFontSizes } from '@mui/material/styles';
import { makeStyles,ThemeProvider } from '@mui/styles';

import React, { useEffect, useState } from "react";



let theme = createTheme();
theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme) => ({
    paper: {
        height: '100%',
        paddingBottom: theme.spacing(2),
        boxShadow: 'none'
    },
    avatar: {
        [theme.breakpoints.down('xs')]: {
            padding: 0,
            marginRight: theme.spacing(1),
        },
    },
    root: {
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1.5),
            margin: theme.spacing(0),
        },
    },
    title: {
        whiteSpace: 'nowrap'
    }

}));


export default function Token({ BalanceIcon, balanceFunction, balanceTicker, description }) {

    const [balance, updateBalance] = useState("");

    useEffect(() => {
        balanceFunction()?.then((balanceData) => {
            const balanceStr = parseFloat(parseFloat(balanceData).toFixed(2))
            updateBalance(balanceStr + " " + balanceTicker)
            //console.log("balance BNB " + balanceStr)
        })

        return () => {}
    }
        , []);

    const classes = useStyles()

    return (
        // <ThemeProvider theme={theme}>
            <Card 
            className={classes.paper} 
            variant="outlined">
                <CardHeader
                    classes={{
                        root: classes.root,
                        avatar: classes.avatar,
                        title: classes.title
                    }}
                    avatar={BalanceIcon}
                    titleTypographyProps={{ variant: 'h6' }}
                    title={balance}
                subheader={description} />
            </Card>
        // </ThemeProvider>
    );


}
