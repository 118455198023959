import { Box, DialogActions, DialogContent, Divider, Grid, ListItem, ListItemIcon, ListItemText, Typography, useTheme } from "@mui/material";
import React, { Fragment } from "react";
import PetSKillBox from "./PetSkillBox";
import { makeStyles } from '@mui/styles';
import { Dialog } from "@mui/material";
import { getPointDesc } from "../../utils/pointModel";
import { TBL_AMULET } from "../../db/tblAmulet";


const useStyles = makeStyles((theme) => ({
    amulet: {
        position: 'relative'
    },
    stars: {
        position: 'absolute',
        left: 0,
        top: -3
    }

}));

function AmuletFull({type_stone, star, handleOpen}){
    const classes = useStyles()
    const imageURL = "/images/amulet/hero_stone_" + type_stone + ".png"
    const starURL = "/images/stars/star_" + star + "_small.png"

    
    return (
        <div className={classes.amulet}>
                <img src={imageURL}
                    style={{ marginLeft: 3}}
                    onClick={handleOpen?handleOpen:null}
                    alt=""
                />
                <img src={starURL} className={classes.stars}
                    alt=""
                />
            </div>
    )
}

function AmuletBox({ id, effectName, index }) {

    const [open, setOpen] = React.useState(false)
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        //setError(null)
    };
    const theme = useTheme()
    // console.log(id)
    const amulet = TBL_AMULET[id - 1]
    // console.log(amulet)

    // console.log(effectName)
    const effect = JSON.parse(amulet["effect" + (effectName + 1)])
    // console.log(effect)
    const points = (effect[index].point)
    const type_stone = amulet.type_stone
    const star = amulet.star
    const name = amulet.name

    return (
        <Fragment>
            <AmuletFull type_stone={type_stone} star={star} handleOpen={handleOpen}/>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {/* <DialogTitle id="alert-dialog-title">  */}
                <ListItem>
                    <ListItemIcon>
                        <AmuletFull type_stone={type_stone} star={star} />
                    </ListItemIcon>

                    <ListItemText>
                        <Typography variant="h6">

                            {name}
                        </Typography>
                    </ListItemText>
                </ListItem>
                {/* </DialogTitle> */}
                <Divider />
                <DialogContent>
                    <Typography style={{ marginTop: theme.spacing(1) }}>
                        {getPointDesc(points)}
                    </Typography>
                </DialogContent>
                <DialogActions />
            </Dialog>
        </Fragment>
    );


}

//Test ID 37
export default function PetAmulet({ ninneko }) {

    const { id, effect, index } = ninneko.treasure
    //console.log(ninneko.treasure)

    if (id < 1) return null

    return (
        <Fragment>
            <Grid container style={{ marginTop: 24 }}>
                <Grid item xs={4} >
                    <AmuletBox id={id} effectName={effect} index={index} />
                </Grid>
            </Grid>
        </Fragment>
    )


}
