import gql from 'graphql-tag';



export const PET_FIELDS = gql`
  fragment PetFields on Pet {
            id,
            createdAt,
            forSale,
            price,
            name,
            breedCount,
            beastCount,
            generation,
            faction,
            class,
            parent1 {id},
            parent2 {id},
            avatarURL,
            stage,
            auction {startPrice,startTime,endPrice,duration},
  }
`;



export const QUERY_PETS = gql`
    ${PET_FIELDS}
    query pets($stage : Int, $page : Int,$faction: [Int], $ownerId: Int, $limit: Int ,
        $class: [Int],$beastCount: Int,$breedCount: [Int], $generation: [Int], 
        $forSale: Int, $sortID: Boolean, $sortPrice: Boolean, $priceSetAt: Boolean,
        $eyesD: [Int],$mouthD: [Int],$hairD: [Int],$handD: [Int],$tailD: [Int], $earsD: [Int], $lifeStage: Int)  {
        pets(
          filter: {
            stage: $stage ,
            faction: $faction, 
            class: $class,
            beastCount: $beastCount,
            breedCount: $breedCount, 
            generation: $generation,
            forSale: $forSale,
            ownerId: $ownerId,
            eyesD: $eyesD,
            mouthD: $mouthD,
            hairD: $hairD,
            handD: $handD,
            tailD: $tailD,
            earsD: $earsD,
            lifeStage: $lifeStage
          },
          sort: {
            id: $sortID,
            price: $sortPrice,
            priceSetAt: $priceSetAt
          }, page: $page, limit: $limit)
          {
            ...PetFields
          }
      }`;

export const QUERY_MY_PETS = gql`
    ${PET_FIELDS}

  query myPets($stage : Int,$forSale: Int, $page : Int,$faction: [Int], $ownerId: Int, $limit: Int ,
    $class: [Int],$beastCount: Int,$breedCount: [Int], $generation: [Int], 
    $sortID: Boolean, $sortPrice: Boolean, $priceSetAt: Boolean,
    $eyesD: [Int],$mouthD: [Int],$hairD: [Int],$handD: [Int],$tailD: [Int], $earsD: [Int], $lifeStage: Int)  {
      myPets(
        filter: {
          stage: $stage ,
          faction: $faction, 
          class: $class,
          beastCount: $beastCount,
          breedCount: $breedCount, 
          generation: $generation,
          forSale: $forSale,
          ownerId: $ownerId,
          eyesD: $eyesD,
          mouthD: $mouthD,
          hairD: $hairD,
          handD: $handD,
          tailD: $tailD,
          earsD: $earsD,
          lifeStage: $lifeStage
        }, 
        sort: {
          id: $sortID,
          price: $sortPrice,
          priceSetAt: $priceSetAt
        }, 
        page: $page, limit: $limit) {

        ...PetFields
      }
    }`;

export const EXCHANGE_RATES = gql`
  query exchangeRates  {
    exchangeRates {
        bnb
      }
    }`;

export const QUERY_PET = gql`
    query pet( $id: Int!)  {
        pet(id: $id) {
          id,
          createdAt,
          forSale,
          price,
          name,
          class,
          beastCount,
          breedCount,
          generation,
          faction,
          factionColor,

          owner {id, name, ethAddr},

          parent1 {id, name, avatarURL},
          parent2 {id, name, avatarURL},
          
          clothing,
          stage,
          eyesD,
          eyesR,
          eyesR1,
          mouthD,
          mouthR,
          mouthR1,
          hairD,
          hairR,
          hairR1,
          handD,
          handR,
          handR1,
          earsD,
          earsR,
          earsR1,
          tailD,
          tailR,
          tailR1,
          avatarURL,
          
          auction{startPrice,endPrice,startTime,duration}
          
          rebornTimes

          treasure {id, effect,index}

          rebornHistory {
             eyesD
             eyesR
             eyesR1
             mouthD
             mouthR
             mouthR1
             hairD
             hairR
             hairR1
             handD
             handR
             handR1
             earsD
             earsR
             earsR1
             tailD
             tailR
             tailR1
             avatarURL
         }
        }
      }`;


export const VALIDATE_BREED = gql`
query ($petId1: Int!, $petId2: Int! ){
  validateBreed (petId1: $petId1, petId2: $petId2) {
      code
      message
    }
  }`;


export const TEST_MIX_GEN = gql`
query ($petId1: Int!, $petId2: Int! ){
  testMixGenes (petId1: $petId1, petId2: $petId2) {
    faction
    factionColor
    class
    clothing
    eyesD
    eyesR
    eyesR1
    mouthD
    mouthR
    mouthR1
    hairD
    hairR
    hairR1
    handD
    handR
    handR1
    earsD
    earsR
    earsR1
    tailD
    tailR
    tailR1
    }
  }`;


//     {
//   saleHistory(id: 24) {
//     createdAt
//     price
//     buyer {
//       id
//       name
//       ethAddr
//     }
//     seller {
//       id
//       name
//       ethAddr
//     }
//   }
// }

export const PET_HISTORY = gql`
query saleHistory ($id: Int!){
  saleHistory(id: $id) {
    createdAt
    price
    buyer {
      id
      name
      ethAddr
    }
    seller {
      id
      name
      ethAddr
    }
  }
}`;

export const QUERY_SERVER_TIME = gql`
query serverTime {
  serverTime {
    code
    message
}
}`;

export const QUERY_MARKET_STATS = gql`
query marketStats {
    marketStats{
    last24h {
      txCount
      petCount
      volume
    }
    last7d {
      txCount
      petCount
      volume
    }
    last30d {
      txCount
      petCount
      volume
    }
    alltime {
      txCount
      petCount
      volume
    }
  }
}`;

export const QUERY_REBORN_PET_DATA = gql`
query rebornData ($petId: Int!){
  rebornData(petId: $petId) {
    petId
    gene
    nonce
    sign
  }
}`;


export const QUERY_GEMS = gql`
  query userGems($forSale: Int,$clazz: [Int],$setId: [Int], $pointType: [Int], $page: Int, $sortID: Boolean, $sortPrice: Boolean, $priceSetAt: Boolean, $limit: Int, $ownerId: Int) {
    userGems(
      filter: {forSale: $forSale, clazz: $clazz, setId: $setId, pointType: $pointType, ownerId:$ownerId}, 
      sort: { id: $sortID, price: $sortPrice, priceSetAt: $priceSetAt }, page: $page, limit: $limit) {
    id
    nftId
    setId
    clazz
    quality
    pointType1
    pointValue1
    pointType2
    pointValue2
    pointType3
    pointValue3
    pointType4
    pointValue4
    createdAt
    updatedAt
    forSale
    price
  }
}`;

export const QUERY_MY_GEMS = gql`
  query myUserGems($forSale: Int,$clazz: [Int],$setId: [Int] , $pointType: [Int], $page: Int,  $sortID: Boolean, $sortPrice: Boolean, $priceSetAt: Boolean, $limit: Int ) {
    myUserGems(filter: {forSale: $forSale, clazz: $clazz,pointType: $pointType, setId: $setId}, sort: { id: $sortID, price: $sortPrice, priceSetAt: $priceSetAt }, page: $page, limit: $limit) {
      id
      nftId
      setId
      clazz
      quality
      pointType1
      pointValue1
      pointType2
      pointValue2
      pointType3
      pointValue3
      pointType4
      pointValue4
      createdAt
      updatedAt
      forSale
      price
  }
}`;

export const QUERY_GEM = gql`
query userGem($id: Int!) {
  userGem(id: $id) {
    id
    owner {
      id
      name
      ethAddr
    }
    nftId     
    setId
    clazz
    quality
    pointType1
    pointValue1
    pointType2
    pointValue2
    pointType3
    pointValue3
    pointType4
    pointValue4
    createdAt
    updatedAt
    forSale
    price
  }
}`;


export const GEM_HISTORY = gql`
query userGemSaleHistory ($id: Int!){
  userGemSaleHistory(nftId: $id) {
    createdAt
    price
    buyer {
      id
      name
      ethAddr
    }
    seller {
      id
      name
      ethAddr
    }
  }
}`;