import { useQuery } from '@apollo/client';
import { useTheme } from '@emotion/react';
import MenuIcon from '@mui/icons-material/Menu';
import {
    AppBar, Button, IconButton, Toolbar, useMediaQuery
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { EXCHANGE_RATES } from '../../graphql/User/marketQuery';
import sessionData from '../../sessionData';
import { accountRoutes, mainRoutes } from '../../utils/accountRoutes';
import { NETWORK_VERSION } from '../../utils/constants';
import { getNetworkVersion, initWallet, switchToBSCNetwork } from '../../utils/cryptoWallet';
import { linkToHome } from '../../utils/link';
import { MHidden } from '../@material-extend';
//import ConnectWalletBox from '../Login/ConnectWalletBox';
//import MobileSidebar from '../SideBar/MobileSidebar';
//import UserInfo from './UserInfo';
import history from '../../utils/myHistory';
import { StorefrontOutlined } from '@mui/icons-material';

const ConnectWalletBox = React.lazy(() => import('../Login/ConnectWalletBox'))
const MobileSidebar = React.lazy(() => import('../SideBar/MobileSidebar'))
const UserInfo = React.lazy(() => import('./UserInfo'))



//export const APP_BAR_HEIGHT = 52;

//const useStyles = makeStyles((theme) => console.log(theme.breakpoints.down('xs')))

const useStyles = makeStyles((theme) => ({
    appBar: {
        backdropFilter: 'blur(6px)',
        WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
        backgroundColor: alpha(theme.palette.background.default, 0.52),
        zIndex: theme.zIndex.drawer + 100,
    },
    menuButton: {
        //color: 0,
        //marginRight: theme.spacing(2),
        marginLeft: 'auto',
        //float: 'right',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    nav: {
        display: 'flex',
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },

    },

    leftNav: {
        flexGrow: 1,
        display: 'inline-flex',
    },
    rightNav: {
        //marginRight: 50,
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    navLink: {
        marginRight: theme.spacing(3),
        color: "inherit",
        display: "inline-flex",
        textDecoration: 'none'

    },
    icon: {
        width: 20,
        height: 20
    },
    logo: {
        textDecoration: 'none',
        marginRight: theme.spacing(3),
        display: 'flex',
        justifyContent: 'center'
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,


}));

export function ToolBarPlaceHolder() {
    const classes = useStyles();
    return <div className={classes.toolbar} />

}


export const Logo = ({ classes }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));


    return (

        matches ?
            <>
                <a className={classes.logo} href="https://ninneko.com" target="_blank" rel="noreferrer" style={{ marginRight: 0}} >
                    <img src="/images/svg/coin_nino.svg" alt="Ninneko" style={{ width: 42, height: 42 }} />
                </a>
                <Link to={linkToHome} className={classes.navLink} style={{ marginLeft: 8, marginTop: 4 }}>
                    <Button startIcon={<StorefrontOutlined className={classes.icon} style={{ marginBottom: 4, marginRight: -4 }} />}>Ninnekos</Button>
                </Link>
            </>
            :
            <a className={classes.logo} href="https://ninneko.com" target="_blank" rel="noreferrer">
                <img src="/images/lg_169x47.png" alt="Ninneko" style={{ width: 169, height: 47 }} />
            </a>

    )

}



export default function HeaderBar({ inLoginPage = false }) {


    const { data } = useQuery(EXCHANGE_RATES)

    //phải cho init wallet vào đây, vì theo nguyên lý bubbling của JS thì useEffect của child sẽ đc gọi trước
    //nếu để ở App.js thì sẽ bị gọi sau 1 số components dùng đến ví, dẫn đến ví chưa có mà đã gọi
    useEffect(() => {
        //console.log("Init wallet")
        initWallet()
        if (sessionData.userId) {
            if (getNetworkVersion() != NETWORK_VERSION) {
                switchToBSCNetwork(history)
            }
        }
    }, []);

    if (data) {
        sessionData.exchangeRate = parseFloat(data.exchangeRates.bnb)
    }

    const classes = useStyles();
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    // let ret = ""
    // for (let i =1; i<101;i++ ){
    //     ret += ""+i+","
    // }
    // console.log(ret)


    return (
        <Fragment>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <Logo classes={classes} />
                    <div className={classes.nav}>
                        <div className={classes.leftNav}>
                            {
                                mainRoutes().map((prop, key) =>
                                    !prop.disabled && <Link to={prop.link} className={classes.navLink} key={key}>
                                        <Button startIcon={<prop.icon className={classes.icon} />}>{prop.sidebarName}</Button>
                                    </Link>
                                )
                            }
                        </div>
                        {
                            !inLoginPage && < div className={classes.rightNav}>
                                {
                                    sessionData.isLoggedIn() ?
                                        <UserInfo />
                                        :
                                        <MHidden width='smDown'>

                                            <ConnectWalletBox
                                                component={<Button
                                                    //className={classes.menuButton}
                                                    // size="medium"
                                                    variant="contained"
                                                    color="primary">
                                                    Connect Wallet
                                                </Button>} />
                                        </MHidden>
                                }
                            </div>
                        }
                    </div>
                    {
                        //Sau này nếu có nhiều route mà ko cần connect vẫn vào được, thì hiện cả nút connect lẫn menu
                        //thu cái logo lại, bỏ chữ ninneko đi
                        sessionData.isLoggedIn() ?
                            <IconButton
                                color="default"
                                aria-label="open drawer"
                                edge="end"
                                onClick={handleDrawerToggle}
                                className={classes.menuButton}
                            >
                                <MenuIcon />
                            </IconButton>
                            :
                            <ConnectWalletBox
                                component={<Button
                                    className={classes.menuButton}
                                    // size="small"
                                    variant="contained"
                                    color="primary">
                                    Connect Wallet
                                </Button>} />
                    }

                </Toolbar>
            </AppBar >
            <div className={classes.toolbar} />



            {/* Sidbar tren man hinh mobile */}
            <MobileSidebar

                mobileOpen={mobileOpen}
                handleDrawerToggle={handleDrawerToggle} />
        </Fragment>
    )
}




