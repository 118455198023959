import { Button, Checkbox, Drawer, FormControlLabel, Grid, Slider, Typography, TextField, Autocomplete, ListItem, Chip, Box, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ClearIcon from '@mui/icons-material/Clear';

import { Fragment, useState } from 'react';

import {
    CLASSES, DRAWER_WIDTH, FACTIONS, FACTIONs, GENERATIONS, MAX_BREED_COUNT, SALE_STATS, MySvgClass1, MySvgClass2, MySvgClass3, MySvgClass4, MySvgClass5
    , LIFE_STAGES,
    GEM_NAME,
    GEM_EFFECT,
    GEM_TYPE_NAME,
    LightningIcon,
    GEM_TYPE_SEARCH
} from '../../utils/constants';

import { PET_PARTS, PET_PART_BY_NAME, PET_GEN_MAP } from '../../utils/genMap';

import { Part, Tail, Eyes, Ears, Hand, Hair, Mouth } from '../Pet/Info/BodyParts';
import { updateURLParameter } from '../../utils/utils';

const MARKS_6 = [
    {
        value: 0,
        label: 'Any'
    },
    {
        value: 1,
        label: '1'
    },
    {
        value: 2,
        label: '2'
    },
    {
        value: 3,
        label: '3'
    },
    {
        value: 4,
        label: '4'
    },
    {
        value: 5,
        label: '5'
    },
    {
        value: 6,
        label: '6'
    },
]

const PARTS = {
    "4": { part: 4, name: "hand", icon: Hand },
    "6": { part: 6, name: "tail", icon: Tail },
    "1": { part: 1, name: "eyes", icon: Eyes },
    "3": { part: 3, name: "hair", icon: Hair },
    "5": { part: 5, name: "ears", icon: Ears },
    "2": { part: 2, name: "mouth", icon: Mouth }
}

const SRC_CLASSES = [MySvgClass4, MySvgClass1, MySvgClass5, MySvgClass2, MySvgClass3]

const useStyles = makeStyles((theme) => ({

    drawerPaper: {
        width: DRAWER_WIDTH,
        padding: theme.spacing(3),
        // overflowX: 'hidden'
        //paddingTop: theme.spacing(2)
    },
    toolbar: theme.mixins.toolbar,
    filterHead: {
        display: 'flex'
    },
    icon: {
        marginRight: theme.spacing(0.5),
        '-webkit-backface-visibility': 'hidden',
        '-webkit-transform': 'translateZ(0)', /* Chrome, Safari, Opera */
        'transform': 'translateZ(0)',
    },
    checkBox: {
        //marginRight: 0,//theme.spacing(0.5)
        padding: 0,
        //margin: 0

    },
    checkBoxForm: {
        padding: theme.spacing(1),


    },

    checkBoxFormSmall: {

    },
    filterTitle: {
        marginTop: theme.spacing(3)
    },
    rail: {
        height: 2,
        opacity: 0.5,
        backgroundColor: '#bfbfbf',
    },
    mark: {
        backgroundColor: '#bfbfbf',
        height: 6,
        width: 1,
        marginTop: -2,
    },
    numberFilterPart: {
        backgroundColor: '#00AB55',
        height: 22,
        width: 22,
        borderRadius: "50%",
        marginLeft: "auto",
        color: '#fff'
    },
    partBox: {
        display: "inline-flex",
        // marginLeft: theme.spacing(1)
    },
    iconClass: {
        marginRight: 5,
        width: 28,
        height: 28,
    },
    part: {
        width: 28,
        height: 28,
    },
    clearIcon: {
        marginLeft: "auto",
        marginRight: "auto",
        fontSize: 20,
        width: 20,
        height: 20,
        color: "#6a7582",
    },
    clearButton: {
        margin: 3,
        float: "right",
        width: 26,
        height: 26,
        borderRadius: "50%",
        "&:hover": {
            backgroundColor: "#f1f1f1"
        },
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    textSearch: {
        fontSize: 10
    }
}));

function FilterCheckBox({ classes, value, handleChangeState, icon, label, id, small = false }) {



    const handleChange = event => handleChangeState(event)
    const tag = icon ? { icon } : null //lam the nay moi dat tag duoc

    return (

        <Grid item xs={6}>
            <FormControlLabel
                className={small ? classes.checkBoxFormSmall : classes.checkBoxForm}
                //className={classes.checkBoxForm}
                control={
                    <Fragment>
                        <Checkbox classes={{ root: classes.checkBox }} color="primary"
                            name={"" + id} checked={value} onChange={handleChange} />
                        {tag ? <tag.icon className={classes.icon} /> : <div className={classes.icon} />}
                        {/* <Typography variant="body2">{label}</Typography> */}
                    </Fragment>
                }
                label={<Typography variant={small ? "body2" : "body1"}>{label}</Typography>}
            />
        </Grid>
    )
}


function ItemSearch({ iconSrc, classSrc, partName, handleRemoveGenParts }) {
    const classes = useStyles()
    //console.log(partName)
    return (
        <Fragment>
            <Grid container>
                <Grid item xs={8}>
                    <div style={{ display: 'inline-flex' }}>
                        {GEM_TYPE_NAME[partName]}
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div className={classes.clearButton} onClick={() => {
                        handleRemoveGenParts(partName)
                    }}>
                        <ClearIcon className={classes.clearIcon}></ClearIcon>
                    </div>
                </Grid>
            </Grid>
        </Fragment>
    )
}






export default function FilterPanelOfGem({
    factions,
    handleChangeFactions,
    attributes,
    handleChangeAttributes,
    petClasses,
    handleChangeClasses,
    legendary,
    setLegendary,
    pure,
    setPure,
    breedCount,
    setBreedCount,
    generations,
    handleChangeGenerations,
    handleClear,
    genParts,
    handleAddGenParts,
    handleRemoveGenParts,
    filterLifeStage,
    handleChangeFilterLifeStage,
    isOnInvetoryPage = false,
    isSM = false
    //sale,
    // handleChangeSale
}) {



    const classes = useStyles()

    let genPartArray = []

    for (const [key, value] of Object.entries(genParts)) {
        if (value) genPartArray.push(value)


    }

    //console.log(genPartArray)


    return (

        <Fragment>
            {isOnInvetoryPage && !isSM ?
                // <div style={{ marginTop: 16 }} />
                <div />
                :
                <div className={classes.toolbar} />
            }

            <Grid container style={isOnInvetoryPage && !isSM ? { borderTop: "1px solid rgb(226, 226, 226)", paddingTop: 16 } : {}}>
                <Grid item xs={12} className={classes.filterHead}>
                    <Typography variant="h6">Filter</Typography>
                    <Button onClick={handleClear} variant="text" color="primary" style={{ marginLeft: 'auto' }}>Clear</Button>
                </Grid>




                <Grid item xs={12} className={classes.filterTitle}>
                    <Typography variant="body2">Class</Typography>
                </Grid>

                <Grid item container spacing={1}>
                    {
                        CLASSES.map(
                            faction =>
                                <FilterCheckBox classes={classes} value={petClasses[faction.id]} id={faction.id}
                                    handleChangeState={handleChangeClasses} icon={faction.icon} label={faction.label} key={faction.id} />
                        )
                    }
                </Grid>

                <Grid item xs={12} className={classes.filterTitle}>
                    <Typography variant="body2">Type</Typography>
                </Grid>

                <Grid item container spacing={1}>
                    {
                        Object.keys(GEM_NAME).map(
                            (index) => {
                                return <FilterCheckBox classes={classes} value={factions[index]} id={index}
                                    handleChangeState={handleChangeFactions}
                                    icon={() => <Tooltip title={GEM_NAME[index]} placement="top">
                                        <img src={"/images/gems/icon/" + index + ".png"} width={60} alt={GEM_NAME[index]} />
                                    </Tooltip>
                                    }
                                    //label={GEM_NAME[index]} 
                                    label={null}
                                    key={index} />
                            }
                        )
                    }
                </Grid>

                {/* <Grid item xs={12} className={classes.filterTitle}>
                    <Typography variant="body2">Attribute</Typography>
                </Grid>

                <Grid item container spacing={1}>
                    {
                        Object.keys(GEM_TYPE_NAME).map(
                            (index) => {
                                return <FilterCheckBox classes={classes} value={attributes[index]} id={index}
                                    handleChangeState={handleChangeAttributes}
                                    icon={null}
                                    label={GEM_TYPE_NAME[index]}
                                    small={true}
                                    key={index} />
                            }
                        )
                    }
                </Grid> */}


                <Grid item xs={12} className={classes.filterTitle}>
                    <Autocomplete
                        id="search-pet-parts"
                        freeSolo
                        renderTags={() => null}
                        sx={{}}
                        size='small'
                        options={GEM_TYPE_SEARCH}
                        autoHighlight
                        getOptionLabel={(option) => option.name}
                        // value={valSearchParts}
                        renderOption={(props, option) => (
                            <Box component="li"  {...props}>
                                {option.name}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                className={classes.textSearch}
                                {...params}
                                label="Attributes"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'off', // disable autocomplete and autofill
                                    style: { fontSize: 16, paddingTop: 10, paddingBottom: 7 }
                                }}
                                InputLabelProps={{ style: { fontSize: 16, paddingTop: 6, paddingBottom: 9 } }}
                            />
                        )}

                        onChange={(event, value) => {
                            //console.log(value)
                            // setValSearchParts(value)
                            if (value) handleAddGenParts(value.id)
                            // setValSearchPartString("")
                        }}
                    />
                </Grid>

                {genPartArray.length >= 4 && <Typography variant='caption' style={{ marginTop: 8}} >Max number of attributes is 4</Typography>}

                {genPartArray.length > 0 &&
                    <Grid item xs={12} className={classes.filterTitle} style={{ marginTop: 15, }}>
                        {
                            genPartArray.map(
                                partName => <Grid item xs={12} key={partName}>
                                    <ItemSearch 
                                        partName={partName}
                                        handleRemoveGenParts={handleRemoveGenParts} />
                                </Grid>
                            )
                        }
                    </Grid>
                }

                <div style={{ marginBottom: 200 }} ></div>


            </Grid>
        </Fragment>

    )
}