import { HowToVote, LogoutOutlined, Pentagon, PentagonOutlined, ShoppingBagOutlined } from "@mui/icons-material";
import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined';
import DashboardIcon from '@mui/icons-material/DashboardOutlined';
//import DiamondIcon from '@mui/icons-material/Diamon';

import ClaimIcon from '@mui/icons-material/GetAppOutlined';
import InventoryIcon from '@mui/icons-material/Inventory2Outlined';
import ActivitiesIcon from '@mui/icons-material/LocalActivity';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import ShareIcon from '@mui/icons-material/Share';
import MarketIcon from '@mui/icons-material/StorefrontOutlined';
import { Badge } from "@mui/material";
import AccountDashboardView from "../components/views/AccountDashboardView";
import ActivitiesView from "../components/views/ActivitiesView";
import ClaimView from "../components/views/ClaimView";
import { EventFriendRefBuyerList } from "../components/views/EventFriendRefBuyerList";
import FriendReferenceView from "../components/views/FriendReferenceView";
import SettingsView from "../components/views/SettingsView";
import InventoryLayout from "../layouts/InventoryLayout";
import sessionData from "../sessionData";
import { linkToAccount, linkToGem, linkToHome, linkToItems, linkToLogOut, linkToNft, linkToVote } from "./link";
import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined';


export const logInRoutes = () => [

    sessionData.isLoggedIn() &&
    {

        sidebarName: "Disconnect",
        icon: LogoutOutlined,
        link: linkToLogOut
    }
    // {
    //     sidebarName: "Connect Wallet",
    //     icon: LoginOutlined,
    //     link: linkToLogin
    // },

]


export const mainRoutes = (mobile = false) => [


    {
        sidebarName: "Ninnekos",
        icon: MarketIcon,
        link: linkToHome
    },
    // {
    //     sidebarName: "Getting Started",
    //     icon: LaunchOutlined ,
    //     link: linkToStart
    // },
    {
        sidebarName: "Items",
        icon: ShoppingBagOutlined,
        link: linkToItems
    },
    {
        sidebarName: "Gems",
        icon: DiamondOutlinedIcon,
        link: linkToGem
    },
    // {
    //     sidebarName: "NFT items",
    //     icon: PentagonOutlined,
    //     link: linkToNft
    // },
    {

        sidebarName: "Account",
        icon: DashboardIcon,
        link: linkToAccount,
        disabled: !sessionData.isLoggedIn() || mobile
    },
    {

        sidebarName: "Vote",
        icon: HowToVote,
        link: linkToVote,
        disabled: !sessionData.isLoggedIn() 
    },

]


function BadgeIconDashBoard() {
    return sessionData.email ?
        <DashboardIcon /> :
        <Badge color="warning" badgeContent="!"><DashboardIcon /></Badge>
}

export const accountRoutes = () => [

    {
        //path: "",
        sidebarName: "Dashboard",
        icon: BadgeIconDashBoard,
        component: AccountDashboardView,
        link: "/account/dashboard"
    },
    {
        //path: "",
        sidebarName: "Inventory",
        icon: InventoryIcon,
        component: InventoryLayout,
        link: "/account/inventory"
    },
    {
        //path: "",
        sidebarName: "Claim",
        icon: ClaimIcon,
        component: ClaimView,
        link: "/account/claim"
    },
    {
        //path: "",
        sidebarName: "Activities",
        icon: ActivitiesIcon,
        component: ActivitiesView,
        link: "/account/activities"
    },
    {
        //path: "",
        sidebarName: "Settings",
        icon: SettingsIcon,
        component: SettingsView,
        link: "/account/settings",
        disabled: !sessionData.email
    },
    {
        //path: "",
        sidebarName: "Invite Friends",
        icon: ShareIcon,
        component: FriendReferenceView,
        link: "/account/friendRef/",
        // disabled: true
    },
    // {
    //     //path: "",
    //     sidebarName: "Event Friend Ref",
    //     icon: CardGiftcardOutlinedIcon,
    //     component: EventFrienRefView,
    //     link: "/account/eventFriendRef/",
    //     // disabled: true
    // },
    {
        // path: "eventRefBuyerList",
        sidebarName: "Event Buyer List",
        icon: CardGiftcardOutlinedIcon,
        component: EventFriendRefBuyerList,
        link: "/account/friendList",
        disabled: true
    },
];