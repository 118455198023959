import { useQuery } from "@apollo/client";
import { useState } from "react";
import { QUERY_SERVER_TIME } from "../graphql/User/marketQuery";



export function useServerTime() {

  const { data } = useQuery(QUERY_SERVER_TIME)

  if (data) return data.serverTime.message

}