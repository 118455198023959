
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MetaMaskOnboarding from '@metamask/onboarding';
import React, { Fragment, useEffect } from 'react';
import { NETWORK_VERSION, METAMASK_PROVIDER } from '../../utils/constants';
import { getJWT, getNetworkVersion, requestConnectMetaMask, switchToBSCNetwork, updateWeb3Provider } from '../../utils/cryptoWallet';
import { isMobileBrowser } from '../../utils/utils';
import { useSnackbar } from 'notistack';





const useStyles = makeStyles((theme) => ({
    icon: {
        width: 44,
        height: 40
    },
    caption: {
        marginTop: theme.spacing(1)
    }

}));

export default function LoginMetaMaskButton(friendRef) {

    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = React.useState(false)

    const handleClose = () => {
        setOpen(false);
    };

    const handleChangeNetwork = () => {
        setOpen(false);
        switchToBSCNetwork()

    };




    let isMobile = isMobileBrowser()

    const classes = useStyles();


    // const handleClickConnect = async () => {
    //     console.log("sao day")
    //     activateBrowserWallet()
    // }
    const handleClickConnect = () => {

        //neu la mobile, thi mo luon ung dung metamask, ben trong co browser
        //https://metamask.app.link/dapp/market.ninneko.com

        // if (isMobile) {
        //     window.location = "https://metamask.app.link/dapp/market.ninneko.com"
        //     return
        // }


        // if (!MetaMaskOnboarding.isMetaMaskInstalled()) {
        //     //huong dan install
        //     const onboarding = new MetaMaskOnboarding();
        //     onboarding.startOnboarding();

        // } else 

        //{//da cai metamask
        //Kiểm tra xem MetaMask đang set đúng Chain chưa

        // localStorage.removeItem("wallet_address")

        updateWeb3Provider(METAMASK_PROVIDER)

        const networkVersion = getNetworkVersion()

        if (networkVersion == -1000000) {
            enqueueSnackbar("MetaMask not found! Please try to connect wallet on PC/Mac", { variant: 'warning', persist: true })
            return
        }
        // console.log(networkVersion,NETWORK_VERSION )
        if (networkVersion != NETWORK_VERSION) {
            //Chưa đúng hiện thông báo hướng dẫn
            setOpen(true);
            return
        }

        //Đúng chain, kết nối
        getJWT(friendRef)
        //}


    }




    return (

        <Fragment>

            {
                // (isMobile && !window.ethereum) ?//tren mobile va ko phai metamask
                //     <Fragment>
                //         <Link href="https://metamask.app.link/dapp/market.ninneko.com/login">
                //             <Button variant="outlined" color="primary" fullWidth
                //                 startIcon={<img src="/images/metamask.png" className={classes.icon} />}
                //             >
                //                 Use MetaMask Browser
                //             </Button>
                //         </Link>
                //         <Typography variant="body2" color="textSecondary" className={classes.caption}>
                //             On mobile device you need to use MetaMask Browser to Login with MetaMask
                //         </Typography>

                //     </Fragment>
                //     : <Button variant="contained" color="primary" fullWidth
                //         startIcon={<img src="/images/metamask.png" className={classes.icon} />}
                //         onClick={handleClickConnect}>
                //         MetaMask
                //     </Button>

            }

            <Button variant="outlined" color="primary" fullWidth
                startIcon={<img src="/images/metamask.png" className={classes.icon} />}
                onClick={handleClickConnect}>
                MetaMask
            </Button>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Use Binance Smart Chain!"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You need to change your MetaMask network to &nbsp;
                        <Link href="https://docs.binance.org/smart-chain/wallet/metamask.html" target="_blank">
                            Binance Smart Chain
                        </Link> to Connect!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleChangeNetwork} color="primary" autoFocus>
                        Change to BSC
                    </Button>

                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment >
    );
}




