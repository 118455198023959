import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField, Typography, useTheme } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { CardGiftcard } from '@mui/icons-material';
import { useSnackbar } from "notistack";
import React, { Fragment } from "react";
import { giftNFTToAddress } from "../../utils/cryptoWallet";

const useStyles = makeStyles((theme) => ({
    field: {
        //margin: theme.spacing(2),
        //width: '20ch'
        
    },

    price: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: theme.spacing(3)
        //flexShrink: 0
    },
    note: {
        marginTop: theme.spacing(5)
    }

}));


export default function GiftGemBox({ component, petId, giftNinneko }) {



    const [address, setAddress] = React.useState("")

    const [open, setOpen] = React.useState(false)

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const theme = useTheme()

    const giftPet = () => {
        setOpen(false);
        giftNinneko(petId, address)
            
    }

    const handleChaneAddress = (event) => {
        setAddress(event.target.value)
    }

    const classes = useStyles()

    return (
        <Fragment>

            {
                React.cloneElement(
                    component,
                    { onClick: handleOpen }
                )
            }

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Send Gem as a Gift"}
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Typography style={{ marginTop: theme.spacing(1) }}>You are sending your Gem to a friend. Please enter your friend's wallet address:</Typography>
                    <div id="price" className={classes.price} >
                        <TextField value={address} onChange={handleChaneAddress} className={classes.field} placeholder="Receiver's wallet address" fullWidth/>
                    </div>
                    {/* <div className={classes.note}>
                        <Typography variant="body2" color="textSecondary">
                             *Except newborn Ninneko, once the transaction is complete: <br />
                             - Ninneko's level will reset to 1, 90% resources used to upgrade ninneko will be refunded to your in-game mailbox <br />
                             - Ninneko will be locked for 7 days before can be used in game   
                        </Typography>
                    </div> */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={giftPet} variant="contained" color="primary" style={{ margin: theme.spacing(2) }} startIcon={<CardGiftcard />}>
                        Send
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );


}
