import { ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { React, useEffect, useState } from 'react';

import { getMATABalance, getNFTBalance, getNINOBalance, getWalletBalance } from '../../utils/cryptoWallet';

const useStyles = makeStyles((theme) => ({

}));


export default function Balance({ balanceIcon, balanceFunction, balanceTicker }) {

    const classes = useStyles();

    const [balance, updateBalance] = useState("");

    useEffect(
        () => balanceFunction()?.then((balanceData) => {
            const balanceStr = parseFloat(parseFloat(balanceData).toFixed(2))
            updateBalance(balanceStr+ " "+balanceTicker)
            //console.log("balance BNB " + balanceStr)
        })
        , []);


    return (
        <ListItem style={{ display: 'flex', justifyContent: 'center', padding: 0 }}>
            <ListItemIcon style={{ minWidth: 0, marginRight: 8 }}>
                {balanceIcon}
            </ListItemIcon>
            <Typography>
            {balance}
            </Typography>
        </ListItem>

    )
}


export function BalanceBNB() {

    return <Balance balanceIcon={<img src="/images/icon_binance.png" style={{width: 30, height: 30}} />} 
        balanceFunction={getWalletBalance} balanceTicker="" />
}


export function BalanceNFT() {

    return <Balance balanceIcon={<img src="/images/cat.png" style={{width: 30, height: 30}} />} 
        balanceFunction={getNFTBalance} balanceTicker="NEKO" />
}


export function BalanceMATA() {

    return <Balance balanceIcon={<img src="/images/svg/coin_mata.svg"  style={{width: 30, height: 30}} />} 
        balanceFunction={getMATABalance} balanceTicker="" />
}

export function BalanceNINO() {

    return <Balance balanceIcon={<img src="/images/svg/coin_nino.svg" style={{width: 30, height: 30}}/>} 
        balanceFunction={getNINOBalance} balanceTicker="" />
}
