
import React from 'react';
import { Button, Card, Chip, Container, Divider, FormControlLabel, Grid, Hidden, Icon, Paper, SvgIcon, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { BREED_AGE, CLASSES, FACTIONS, FireIcon, GENERATION_ALPHA, GENERATION_BETA, GuardIcon, MAX_BREED_COUNT } from '../../utils/constants';
import { getAge, getTimeToBreed } from '../../utils/utils';
import { useServerTime } from '../../utils/hookUtils';





const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        margin: theme.spacing(1),
        //height: '100%',
        width: 200,
        //marginBottom: theme.spacing(10)
    },
    centerGrid: {
        display: 'flex',
        justifyContent: 'center',
    },
    petAvatar: {
        //width: 160,
        //height: 160,
        // marginTop: theme.spacing(2),
        // marginBottom: theme.spacing(2)

    },
    textBlock: {
        display: 'block'
    },
    
    
    

}));





const  isParent = (child, parent) => {

    return child.parent1.id == parent.id || child.parent2.id == parent.id 
}


const isSameParent = (child1, child2) => {

    //1 trong 2 con chưa có bố mẹ
    if (
        !child1.parent1.id || !child1.parent2.id
        || !child2.parent1.id || !child2.parent2.id
        
        )
    return false    

    return child1.parent1.id == child2.parent1.id
        || child1.parent1.id == child2.parent2.id
        || child1.parent2.id == child2.parent1.id
        || child1.parent2.id == child2.parent2.id

}




const getStatus = (parent1, parent2,serverTime) => {


    if (!parent1 || !parent2) return ""


    if (parent2.id == parent1.id) return "Same Ninneko"


    //const timeToBreed = parent2.generation == GENERATION_BETA?getTimeToBreed(serverTime, parent2.createdAt):0
    const timeToBreed = getTimeToBreed(serverTime, parent2.createdAt)
    
    
    
    
    //let timeToBreed = getTimeToBreed(serverTime,parent2.createdAt)

    //if (timeToBreed != "0") return "breedable in "+ timeToBreed 
    if (timeToBreed != "0") return "newborn: "+ timeToBreed 

    //2 con alpha VẪN BỊ giới hạn MAX_BREED nhé
    //if (parent1.generation == 1 || parent2.generation == 1) return ""

    //còn đẻ được không
    if (parent2.breedCount >= MAX_BREED_COUNT) return "Breed "+MAX_BREED_COUNT+"/"+MAX_BREED_COUNT

    

    //kiểm tra cùng bố hoặc mẹ
    if (isSameParent(parent1, parent2)) return "Same family"

    //kiểm tra có phải bố mẹ và con cái    
    if (isParent(parent1, parent2) || isParent(parent2, parent1)) return "Same family"

    if (parent1.id == parent2.id) return "Same Ninneko"

    return ""
}


export default function SelectPetItem({ parent1, ninneko, onSelect, serverTime }) {

    const classes = useStyles();

    

    const status = getStatus(parent1,ninneko,serverTime)

    const breedStatus = status == ""?"Breed "+ninneko.breedCount+"/"+MAX_BREED_COUNT:""

    

    //console.log("ninneko ========== "+ ninneko.name + " = "+ninneko.breedCount)

    const handleSelect = () => {
        if (status) return
        onSelect(ninneko)
    }

    return (

        
            <Card className={classes.root} onClick={handleSelect}>
                <Grid container style={{ height: 'auto' }}>
                    <Grid item xs={12}>
                                <Typography variant="body2" color={status?'textSecondary':'textPrimary'}
                                style={{lineHeight: 1}} noWrap>{ninneko.name}</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.centerGrid}>
                        <img src={ninneko.avatarURL} className={classes.petAvatar} />
                    </Grid>
                    <Grid item xs={12} className={classes.centerGrid} style={{minHeight: 24}}>
                            {status && <Chip label ={status} variant='outlined' color='error' size='small' /> }
                            {!status && ninneko.generation == GENERATION_ALPHA && <Chip label ={"alpha"} variant='outlined' color='primary' size='small' style={{marginRight: 4}} /> }
                            {breedStatus && <Chip label ={breedStatus} variant='outlined' size='small' /> }
                    </Grid>
                </Grid>
            </Card>


    );
}