import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import {
    Backup,
    CardGiftcard,
    EditOutlined,
    Favorite, OpenInNew, RemoveCircleOutlined,
    RestartAlt, SellRounded,
    ShoppingBag
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, ListItem, Slider, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import { useSnackbar } from 'notistack';
import React, { Fragment, useEffect, useState } from 'react';
import { QUERY_PET } from "../../graphql/User/marketQuery";
import { OPEN_CAT_BOX, QUERY_MINT_GEM_DATA } from '../../graphql/User/userQuery';
import sessionData from '../../sessionData';
import { MAX_BREED_COUNT, PET_STAGE_PET, TIMES_RESET } from '../../utils/constants';
import {
    auctionNFT,
    bidNFT,
    breedNFT,
    buyGemNFT,
    buyNFT,
    cancelAuctionNFT,
    cancelSaleGemNFT,
    cancelSaleNFT,
    giftGemNFTToAddress,
    giftNFTToAddress,
    mintGem,
    putGemOnSale,
    putNFTOnSale
} from '../../utils/cryptoWallet';
import {
    getPriceETHFromWei, getPriceUSDFromETH, getPriceUSDFromWei
} from '../../utils/utils';
import GiftGemBox from './GiftGemBox';
import MintGemBox from './MintGemBox';
import SellGemBox from './SellGemBox';
//import ConnectWalletBox from '../../Login/ConnectWalletBox';
//import BreedPetBox from '../BreedPetBox';
//import GiftPetBox from '../GiftPetBox';
//import RebornBox from '../RebornBox';
//import SellPetBox2 from '../SellPetBox2';


const ConnectWalletBox = React.lazy(() => import('../Login/ConnectWalletBox'))


const OPEN_REBORN_PET = true

const useStyles = makeStyles((theme) => ({

    command: {
        marginBottom: theme.spacing(4),
        flex: 'auto'
    },
    commandButton: {
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    priceBlock: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',


    },
    buyBlock: {
        marginLeft: theme.spacing(2),
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        },
    },
    buyButton: {
        marginLeft: theme.spacing(1),
    },
    editButton: {
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(1),
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1),
        }
    },


}));




function BuyNow({ classes, onClick, loading }) {
    return (

        sessionData.userId ?
            <LoadingButton loading={loading} onClick={onClick} variant="contained" color="primary"
                className={classes.buyButton} startIcon={<ShoppingBag />}> Buy now</LoadingButton>
            :
            <ConnectWalletBox component={<Button variant="contained" color="primary" className={classes.buyButton}
                startIcon={<ShoppingBag />}> Buy now</Button>} />

    )
}


export function GemCommand({
    ninneko,
    isMyPet,
    refetchQuery,
    refetchQueryPet,
    timeToBreed,
    loadingAction,
    setLoadingAction
}) {
    const { enqueueSnackbar } = useSnackbar();
    const [cacheIsMyPet, setCacheIsMyPet] = useState(null)
    const [cacheIsForSale, setCacheIsForSale] = useState(null)
    const [cachePriceSell, setCachePriceSell] = useState(null)

    const variables = {
        id: ninneko.id
    }

    const onError = () => {
        setLoadingAction(false)
    }

    const isAuction = () => {
        return false
    }

    const stopSaleNinneko = () => {
        setLoadingAction(true)

        cancelSaleGemNFT(ninneko.nftId, enqueueSnackbar, () => {
            setCacheIsForSale(false)
            setCacheIsMyPet(true)
            setLoadingAction(false)
        }, onError)
    }

    const buyNinneko = () => {
        setLoadingAction(true)
        buyGemNFT(ninneko.nftId, ninneko.price, enqueueSnackbar, () => {
            setCacheIsMyPet(true)
            setCacheIsForSale(false)
            setLoadingAction(false)

            //Appsflyer tracking
            // var priceBNB = getPriceETHFromWei(cachePriceSell == null ? ninneko.price : cachePriceSell)
            // var usdPrice = parseFloat((parseFloat(priceBNB) * sessionData.exchangeRate).toFixed(2))
            // window.afBuyNFT(ninneko.id, usdPrice)
        }, onError)
    }
    const sellNinneko = (price) => {
        setLoadingAction(true)
        putGemOnSale(ninneko.nftId, price, enqueueSnackbar, () => {
            setCacheIsForSale(true)
            setCacheIsMyPet(true)
            setCachePriceSell(price * 1e18)
            setLoadingAction(false)
        }, onError)
    }

    const giftNinneko = (petId, address) => {
        setLoadingAction(true)
        giftGemNFTToAddress(ninneko.nftId, address, enqueueSnackbar, () => {
            setCacheIsMyPet(false)
            setCacheIsForSale(null)
            setLoadingAction(false)
        }, onError)
    }

    const classes = useStyles()

    //console.log(ninneko)

    if (!ninneko.nftId && sessionData.isLoggedIn() && ninneko.owner.id == sessionData.userId) return <div className={classes.command}>

        <MintGemBox
            setLoadingAction={setLoadingAction}
            refetchQuery={refetchQuery}
            refetchQueryPet={refetchQueryPet}
            ninneko={ninneko}
            component={
                <LoadingButton loading={loadingAction} variant='contained' color='primary'
                    startIcon={<Backup />} className={classes.commandButton}>
                    Mint NFT
                </LoadingButton>
            }
        />

    </div>
    else return (
        <Fragment>
            <div className={classes.command}>
                {((cacheIsMyPet != null && cacheIsMyPet == true) || (cacheIsMyPet == null && isMyPet))
                    && ((cacheIsForSale != null && cacheIsForSale == false) || (cacheIsForSale == null && ninneko.forSale == 0))
                    && (!isAuction())
                    &&
                    <Fragment>

                        <SellGemBox
                            sellNinneko={sellNinneko}
                            component={
                                <LoadingButton loading={loadingAction} disableElevation variant="contained"
                                    color="primary"
                                    className={classes.commandButton} startIcon={<SellRounded />}>
                                    Sell
                                </LoadingButton>
                            }
                        />
                        <GiftGemBox
                            giftNinneko={giftNinneko}
                            petId={ninneko.id}
                            component={
                                <LoadingButton loading={loadingAction} variant="outlined" color="primary"
                                    className={classes.commandButton} startIcon={<CardGiftcard />}>
                                    Gift
                                </LoadingButton>
                            }
                        />

                    </Fragment>}

                {((cacheIsForSale != null && cacheIsForSale == true) || (cacheIsForSale == null && ninneko.forSale == 1) || isAuction()) &&
                    <Fragment>
                        <div id="price" className={classes.priceBlock}>
                            <div>
                                <ListItem style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    padding: 0,
                                    maxWidth: 300
                                }}>
                                    {/* <Typography variant="h5" noWrap>&nbsp;{getPriceETHFromWei(cachePriceSell == null ? ninneko.price : cachePriceSell)} BNB</Typography> */}
                                    <>
                                        <img src="/images/icon_binance.png" style={{ width: 24, height: 24, top: 0 }} />
                                        <Typography variant="h5"
                                            noWrap>&nbsp;{getPriceETHFromWei(cachePriceSell == null ? ninneko.price : cachePriceSell)} BNB</Typography>

                                    </>
                                </ListItem>
                                <div style={{ display: 'flex', justifyContent: 'center', width: "100%" }}>
                                    <Typography
                                        variant="body1">{getPriceUSDFromWei(cachePriceSell == null ? ninneko.price : cachePriceSell)}</Typography>
                                </div>
                            </div>
                            {
                                <div className={classes.buyBlock}>
                                    {((cacheIsMyPet != null && cacheIsMyPet == true) || (cacheIsMyPet == null && isMyPet)) ?
                                        <Fragment>
                                            {/* {!isStopSale && */}
                                            <LoadingButton loading={loadingAction} onClick={stopSaleNinneko}
                                                disableElevation variant="contained"
                                                color="secondary"
                                                startIcon={<RemoveCircleOutlined />}>Stop
                                                Sale</LoadingButton>

                                                <SellGemBox
                                                        sellNinneko={sellNinneko}
                                                        component={
                                                            <LoadingButton loading={loadingAction} disableElevation
                                                                variant="contained" color="primary"
                                                                className={classes.editButton}
                                                                startIcon={<EditOutlined />}>
                                                                Edit Price
                                                            </LoadingButton>
                                                        } />
                                        </Fragment>
                                        :
                                        <BuyNow classes={classes}
                                            onClick={buyNinneko}
                                            loading={loadingAction} />
                                        // <BuyNow classes={classes} onClick={buyNinneko} loading={loadingAction} />
                                    }
                                </div>
                            }
                        </div>
                    </Fragment>}
            </div>
        </Fragment>
    )


}