import { TryRounded } from "@mui/icons-material";

const MySvg1 = '/images/svg/h1.svg';
const MySvg2 = '/images/svg/h2.svg';
const MySvg3 = '/images/svg/h3.svg';
const MySvg4 = '/images/svg/h4.svg';
const MySvg5 = '/images/svg/h5.svg';
const MySvg6 = '/images/svg/h6.svg';


export const MySvgClass1 = '/images/svg/n1.svg';
export const MySvgClass2 = '/images/svg/n2.svg';
export const MySvgClass3 = '/images/svg/n3.svg';
export const MySvgClass4 = '/images/svg/n4.svg';
export const MySvgClass5 = '/images/svg/n5.svg';




export const DRAWER_WIDTH = 280

export function LightningIcon({ width = 25, height = 25, className }) {
    //return <img src="/imagess/pet/bolt.png"} width={width} height={height} className={className}/>
    return <img src={MySvg5} width={width} height={height} className={className} />
}

export function EarthIcon({ width = 25, height = 25, className }) {
    return <img src={MySvg3} width={width} height={height} className={className} />
}


export function FireIcon({ width = 25, height = 25, className }) {
    return <img src={MySvg1} width={width} height={height} className={className} />
}

export function WindIcon({ width = 25, height = 25, className }) {
    return <img src={MySvg2} width={width} height={height} className={className} />
}

export function WaterIcon({ width = 25, height = 25, className }) {
    return <img src={MySvg4} width={width} height={height} className={className} />
}

export function YinYangIcon({ width = 25, height = 25, className }) {
    return <img src={MySvg6} width={width} height={height} className={className} />
}


export function CaptainIcon({ width = 25, height = 25, className }) {
    return <img src={MySvgClass4} width={width} height={height} className={className} />
}

export function GuardIcon({ width = 25, height = 25, className }) {
    return <img src={MySvgClass1} width={width} height={height} className={className} />
}

export function SupportIcon({ width = 25, height = 25, className }) {
    return <img src={MySvgClass5} width={width} height={height} className={className} />
}

export function ScoutIcon({ width = 25, height = 25, className }) {
    return <img src={MySvgClass2} width={width} height={height} className={className} />
}

export function AssassinIcon({ width = 25, height = 25, className }) {
    return <img src={MySvgClass3} width={width} height={height} className={className} />
}




export const FACTIONS = [
    {
        id: 1,
        icon: LightningIcon,
        label: "Thunder"
    },
    {
        id: 2,
        icon: FireIcon,
        label: "Fire"
    },
    {
        id: 3,
        icon: EarthIcon,
        label: "Earth"
    },
    {
        id: 4,
        icon: WindIcon,
        label: "Wind"
    },
    {
        id: 5,
        icon: WaterIcon,
        label: "Water"
    },
    {
        id: 6,
        icon: YinYangIcon,
        label: "YinYang"
    },
]


export const CLASSES = [
    {
        id: 1,
        icon: CaptainIcon,
        label: "Mage"
    },
    {
        id: 2,
        icon: GuardIcon,
        label: "Warrior"
    },
    {
        id: 3,
        icon: SupportIcon,
        label: "Support"
    },
    {
        id: 4,
        icon: ScoutIcon,
        label: "Ranger"
    },
    {
        id: 5,
        icon: AssassinIcon,
        label: "Assassin"
    },
]

export const CLASSES_ICON = [MySvgClass4, MySvgClass1, MySvgClass5, MySvgClass2, MySvgClass3]



export const GENERATION_ALPHA = 1
export const GENERATION_BETA = 2

export const GENERATIONS = [
    {
        id: GENERATION_ALPHA,
        label: "Alpha"
    },

]

export const LIFE_STAGE_NEW_BORN = 1
export const LIFE_STAGE_ADULT = 2

export const LIFE_STAGES = [
    {
        id: LIFE_STAGE_NEW_BORN,
        label: "Newborn"
    },
    {
        id: LIFE_STAGE_ADULT,
        label: "Adult"
    },
]

export const SALE_STATS = [
    {
        id: 1,
        label: "For sale"
    },
    {
        id: 2,
        label: "Not sale"
    },

]

export const PETS_PER_PAGE = 17
export const PET_AVATAR_SIZE = 215
export const PET_BOX_SIZE = 160
export const PET_STAGE_COTTON_BOX = 1
export const PET_STAGE_SPECIAL_BOX = 2
export const PET_STAGE_PET = 0

////---------------------------Snack
export const SNACK_ERROR_TYPE_TRANSACTION = 1
export const SNACK_ERROR_TYPE_UNKOWN = 0
export const SNACK_INFO = 2


//////------------------------------------Game Config Cần đồng bộ với server------------------------

export const BREED_AGE = 6 //days
export const MAX_BREED_COUNT = 6
export const TIMES_RESET = 3
export const BREED_GAS_MODIFIER = 2
// export const BREED_COST_MATA = [
//     100,
//     200,
//     300,
//     600,
//     1000,
//     2000,
//     3500,
//     7200,
//     10000
// ]

//trên chain thì thêm 000000000000000000
export const BREED_COST_MATA = [
    // 3600,
    // 5500,
    // 9400,
    // 15000,
    // 24000,
    // 38000,
    //
    //     5400,
    //     8000,
    //     12000,
    //     18000,
    //     27000,
    //     41000,
    //
    18000,
    25000,
    32000,
    38000,
    45000,
    65000,
]
//trên chain thì thêm 000000000000000000
export const BREED_COST_NINO = 450
// export const BREED_COST_NINO = 750
//export const BREED_COST_NINO = 800

export const REBORN_COST_NINO = 150
export const REBORN_COST_MATA = 21000


export const MINT_COST_NINO = 150


export const BASE_STATS = {
    //atk, arm, hp, spd
    1: { 'atk': 5500, 'arm': 188, 'hp': 64000, 'spd': 78 },
    2: { 'atk': 2750, 'arm': 314, 'hp': 80000, 'spd': 65 },
    3: { 'atk': 2750, 'arm': 188, 'hp': 72000, 'spd': 130 },
    4: { 'atk': 4400, 'arm': 188, 'hp': 56000, 'spd': 117 },
    5: { 'atk': 4950, 'arm': 220, 'hp': 48000, 'spd': 104 },
}

export const GEN_TYPE_WILD = 1



export const BONUS_STATS_CONST = 0.05

export const BONUS_FROM_CLASS_OF_PART = {
    1: { 'hp': 1, 'atk': 3 },//mage
    2: { 'hp': 2, 'arm': 2 },//warrior
    3: { 'spd': 3, 'hp': 1 },//support
    4: { 'atk': 2, 'spd': 2 },//ranger
    5: { 'atk': 2, 'arm': 1, 'spd': 1 },//asssasin
}

export const CLAIM_PERIOD = 15

//-------------------------------------------------------------------------


export const TEST_NET = false
export const TEST_ALWAYS_BREED = false

export const MIN_NINO_CLAIM = 500

export const METAMASK_PROVIDER = 'METAMASK'

export const WALLETCONNECT_PROVIDER = 'WALLETCONNECT'

export const NETWORK_VERSION = TEST_NET ? 97 : 56

export const NETWORK_VERSION_HEX = TEST_NET ? '0x61' : '0x38'

export const NETWORK_CONFIG = TEST_NET ?
    [{
        chainId: NETWORK_VERSION_HEX,
        chainName: 'Binance Smart Chain Testnet',
        nativeCurrency:
        {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18
        },
        rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
        blockExplorerUrls: ['https://testnet.bscscan.com/'],
    }]
    :
    [{
        chainId: NETWORK_VERSION_HEX,
        chainName: 'Binance Smart Chain',
        nativeCurrency:
        {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18
        },
        rpcUrls: ['https://bsc-dataseed.binance.org/'],
        blockExplorerUrls: ['https://bscscan.com/'],
    }]

export const STAGE_WIDTH = 430 * 0.8

export const STAGE_HEIGHT = 430 * 0.8


export const GEM_TYPE = {
    100: 'Attack (%)',
    102: 'HP (%)',
    11: 'Control Immune (%)',
    7: 'Block (%)',
    6: 'Accuracy (%)',
    3: 'Speed',
    4: 'Energy',
    5: 'Skill Damage (%)',
    8: 'Crit (%)',
    9: 'Crit Damage (%)',
    10: 'Armor Break (%)',
    13: 'True Damage (%)'
}


export const GEM_TYPE_SEARCH = [
    {
        id: 100,
        name: 'Attack'
    },
    {
        id: 102,
        name: 'HP'
    },
    {
        id: 11,
        name: 'Control Immune'
    },
    {
        id: 7,
        name: 'Block'
    },
    {
        id: 6,
        name: 'Accuracy'
    },
    {
        id: 3,
        name: 'Speed'
    },
    {
        id: 4,
        name: 'Energy'
    },
    {
        id: 5,
        name: 'Skill Damage'
    },
    {
        id: 8,
        name: 'Crit'
    },
    {
        id: 9,
        name: 'Crit Damage'
    },
    {
        id: 10,
        name: 'Armor Break'
    },

    {
        id: 13,
        name: 'True Damage'
    },



]

export const GEM_TYPE_NAME = {
    100: 'Attack',
    102: 'HP',
    11: 'Control Immune',
    7: 'Block',
    6: 'Accuracy',
    3: 'Speed',
    4: 'Energy',
    5: 'Skill Damage',
    8: 'Crit',
    9: 'Crit Damage',
    10: 'Armor Break',
    13: 'True Damage'
}


export const GEM_MULTIPLIER = {
    100: 0.1,
    102: 0.1,
    11: 0.1,
    7: 0.1,
    6: 0.1,
    3: 1,
    4: 1,
    5: 0.1,
    8: 0.1,
    9: 0.1,
    10: 0.1,
    13: 0.1
}

export const GEM_NAME = {

    1: 'Ruby',
    2: 'Sapphire',
    3: 'Emeralda ',
    4: 'Amethyst',
    5: 'Amber',
    6: 'Citrine',
    7: 'Berserker',
    8: 'Mage Hunter',
    9: 'Image of Sin',
    10: 'Living Fortress',
    11: 'Armor piercing ',
    12: 'Mighty Sorcerer',
    13: 'Eyes Blessing',
    14: 'Stun immunity',
    15: 'Burning Rage',

}



export const GEM_EFFECT = {

    1: "15% Attack",
    2: "15% Armor",
    3: "15% HP",
    4: "30% Accuracy",
    5: "30% Control Immune",
    6: "30% Block",
    7: "50% Damage to Assasin",
    8: "50% Damage to Mage",
    9: "50% Damage to Support",
    10: "50% Damage to Ranger",
    11: "50% Damage to Warrior",
    12: "100% Silence Immune",
    13: "100% Petrify Immune",
    14: "100% Stun Immune",
    15: "100% Freeze Immune	",

}

export const GEM_QUALITY = {
    4: 'Epic',
    5: 'Mythic'
}