import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import {
    Box,
    Button,
    Divider,
    Grid,
    List,
    ListItemButton,
    ListItem,
    ListItemText,
    TextField,
    Typography,
    Collapse,
    Alert,
} from '@mui/material';

import {
    Timeline,
    TimelineItem,
    TimelineSeparator,
    TimelineDot,
    TimelineConnector,
    TimelineContent,
    TimelineOppositeContent
} from '@mui/lab';

import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import React, { Fragment } from 'react';
import { CHANGE_EMAIL, QUERY_ME, UPDATE_NAME, MY_ACTIVITIES, OTP } from '../../graphql/User/userQuery';
import ChangePassBox from '../User/ChangePassBox';
import sessionData from '../../sessionData';
import { ReCaptcha } from 'react-recaptcha-v3'
import { copyTextToClipboard } from '../../utils/utils';
import LoadingSpinner from '../Misc/LoadingSpinner';

const useStyles = makeStyles((theme) => ({
    autoView: {
        margin: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(2),
        },
    },
    field: {
        margin: theme.spacing(2),
        marginLeft: 0,
        // width: '100%',
        display: 'flex',
        flex: 1,
        maxWidth: 400,
        // width: '30ch',
        // [theme.breakpoints.up('sm')]: {
        //     width: '40ch'
        // },

    },
    title: {
        flex: 1,
        margin: theme.spacing(1),
    },
    newMail: {
        flex: 1,
        alignItems: 'center',
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),

    },
    paper: {
        padding: theme.spacing(2),

    },
    button: {
        minWidth: 90
    },
    oppositeContent: {
        // TODO: adjust this value accordingly
        flex: 30
    }

}));

const TimelineActivity = ({ activity }) => {
    const classes = useStyles()
    return (
        <TimelineItem>
            <TimelineSeparator >
                <TimelineDot variant="outlined" />
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent className={classes.oppositeContent}>
                <Typography variant="h6">{new Date(activity.createdAt).toLocaleString()}</Typography>
                <Typography variant="body2">{activity.message}</Typography>
            </TimelineContent>
        </TimelineItem>
    )
}

const makeActivityData = (activities) => {
    const now = new Date()
    const y = now.getFullYear();
    const m = now.getMonth();
    const d = now.getDate();
    const today = new Date(y, m, d, 0, 0, 0);
    const day = now.getDay()
    const fw = new Date(today - day + 1);
    const thisWeek = new Date(fw.getFullYear(), fw.getMonth(), fw.getDate(), 0, 0, 0);
    const thisMonth = new Date(y, m, 1, 0, 0, 0);
    var todayLst = activities.filter(r => {
        const at = Date.parse(r.createdAt)
        return at >= today
    });
    var thisWeekLst = activities.filter(r => {
        const at = Date.parse(r.createdAt)
        return at >= thisWeek && at < today
    });
    var thisMonthLst = activities.filter(r => {
        const at = Date.parse(r.createdAt)
        return at >= thisMonth && at < thisWeek
    });
    var ret = {
        today: todayLst,
        week: thisWeekLst,
        month: thisMonthLst
    }
    console.log(ret)
    return ret
}


export default function SettingsView() {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles()
    const [name, setName] = React.useState("")
    const [email, setEmail] = React.useState("")
    const [otp, setOTP] = React.useState("")
    
    const [captchaToken, setCapchaToken] = React.useState(null)

    const onQueryCompleted = (retsult) => {
        setName(retsult.me.name)
        setEmail(retsult.me.email)
        
    }


    const onError = (message) => enqueueSnackbar("" + message, { variant: 'error' });

    const onUpdateCompleted = () => enqueueSnackbar('Update completed!', { variant: 'success' });

    const onUpdateEmailCompleted = () =>
        enqueueSnackbar('We have sent an email with confirmation link to your email address. Please click on the link to confirm!', { variant: 'warning', persist: true });

    const handleChangeName = (event) => setName(event.target.value)

    const handleChangeEmail = (event) => setEmail(event.target.value)



    const [updateName] = useMutation(
        UPDATE_NAME,
        {
            onError: onError,
            onCompleted: onUpdateCompleted,
            refetchQueries: [
                { query: QUERY_ME }
            ]
        });
    const [changeEmail] = useMutation(
        CHANGE_EMAIL,
        {
            onError: onError,
            onCompleted: onUpdateEmailCompleted,
            refetchQueries: [
                { query: QUERY_ME }
            ],
            context: {
                headers: {
                    "captcha-token": captchaToken
                }
            }
        });


    const { loading, error } = useQuery(QUERY_ME, {
        onCompleted: onQueryCompleted
    });

    const client = useApolloClient()

    

    if (loading) return <LoadingSpinner />
    if (error) return `Error! ${error.message}`;


    const getOTP = () => {

        client.query(
            {
                query: OTP,
                fetchPolicy: 'no-cache'
            })
            .then(result => {
                
                const otpCode = result.data.otp.message
                //console.log(otpCode)
                if (otpCode) setOTP(otpCode)

            })
            .catch(
                error => {
                    console.log(error)
                    enqueueSnackbar(error.toString(), { variant: 'warning', persist: true });
                    
                }
            );

    }

    const handleUpdateName = () => {
        updateName({
            variables: {
                name: name,
            }
        })
    };

    const handleUpdateEmail = () => {
        changeEmail({
            variables: {
                email: email,
            }
        })
    };

    return (

        <div className={classes.autoView}>
            {
                !email ?
                    <Alert severity="warning">You have to create Account before accessing this Page</Alert>
                    :
                    <Grid container spacing={2} >
                        <Grid item xs={12} sm={12} md={6} >
                            <Box component="form" >
                                <Typography variant="h6">General</Typography>

                                <ListItem style={{ padding: 0 }}>

                                    <TextField size="small" label="Your BSC address" className={classes.field}
                                        inputProps={{
                                            style: {
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                width: '100%',
                                            }
                                        }}
                                        variant="outlined" value={sessionData.myAddress} />

                                    <Button className={classes.button} variant="contained" color="primary" onClick={() => copyTextToClipboard(sessionData.myAddress)}>Copy</Button>

                                </ListItem>

                                <ListItem style={{ padding: 0 }}>
                                    <TextField size="small" label="Name" className={classes.field} variant="outlined"
                                        value={name}
                                        onChange={handleChangeName}
                                    />
                                    <Button className={classes.button} variant="contained" color="primary" onClick={handleUpdateName}>Update</Button>
                                </ListItem>

                                <ListItem style={{ padding: 0 }}>
                                    <TextField size="small" label="User name" className={classes.field} variant="outlined"
                                        value={email}
                                        onChange={handleChangeEmail}
                                    />

                                    <ReCaptcha
                                        sitekey="6LdvLeobAAAAAGgaVdDQx-nSXJZ_bdih1G-4h6L7"
                                        action='VerifyEmail'
                                        verifyCallback={(capchaToken) => {
                                            setCapchaToken(capchaToken)
                                            console.log(`captcha-token: ${capchaToken}`)
                                        }}
                                    />
                                    {/* <Button className={classes.button} variant="contained" color="primary" onClick={handleUpdateEmail}>Update</Button> */}
                                      
                                </ListItem>

                                <Divider className={classes.divider} />
                                <Typography variant="h6">Security</Typography>

                                <ListItem style={{ padding: 0 }}>
                                    <TextField size="small" label="Password" className={classes.field} variant="outlined" value="*********" disabled />
                                    <ChangePassBox
                                        component={<Button className={classes.button} variant="contained" color="primary" onClick={handleUpdateName}>Change</Button>} />

                                </ListItem>

                                
                                

                                <ListItem style={{ padding: 0 }}>
                                    <TextField size="small" label="OTP" className={classes.field} variant="outlined"
                                        value={otp}
                                        disabled
                                    />
                                    <Button className={classes.button} variant="contained" color="primary" onClick={getOTP}>Get OTP</Button>
                                </ListItem>
                                <Typography variant='body2'>If you are playing Guest Account, you can link it to this account by using OTP. Becareful, this can't be undone</Typography>
                                <Divider className={classes.divider} />

                                <ListItem style={{ padding: 0, paddingTop: 32 }}>
                                    <Typography color="textSecondary" variant="body2">This site is protected by reCAPTCHA and the Google
                                        <a href="https://policies.google.com/privacy"> Privacy Policy</a> and
                                        <a href="https://policies.google.com/terms"> Terms of Service</a> apply.
                                    </Typography>
                                </ListItem>
                            </Box >
                        </Grid>
                    </Grid>
            }
        </div >

    );
}
