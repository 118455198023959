import gql from 'graphql-tag';

export const QUERY_ME = gql`
query {
    me {
      id
      createdAt
      name
      email
      lastLogin
      avatar
      ethAddr
      lastClaim
      nextClaim
      claimable
      claimAmount
      mataCollect
      refId
    }
  }`;

export const OTP = gql`
query {
    otp {
      code
      message
    }
  }`;

export const CLAIM_DATA = gql`
query {
  claimData {
    amount
    nonce
    sign
    }
  }`;


//myPets (filter: {forSale:true,class:1}){

export const VERIFY_CHANGE_EMAIL = gql`
  mutation changeEmailVerify ($token: String!){
    changeEmailVerify(token: $token) {
      code
      message
    }
  }`;



export const VERIFY_EMAIL = gql`
    mutation setupEmailVerify ($token: String!){
      setupEmailVerify(token: $token) {
        code
        message
      }
    }`;

export const VERIFY_EMAIL_IN_GAME = gql`
    mutation setupEmailInGameVerify ($token: String!){
      setupEmailInGameVerify(token: $token) {
        code
        message
      }
    }`;

export const SETUP_EMAIL = gql`
  mutation setupEmail ($email: String!, $password: String!){
    setupEmail(input: {email: $email, password: $password}) {
      code
      message
    }
  }`;

export const SETUP_ACCOUNT = gql`
  mutation setUserPass ($username: String!, $pass: String!){
    setUserPass(username: $username, pass: $pass) {
      code
      message
    }
  }`;

export const UPDATE_NAME = gql`
  mutation updateUserName ($name: String!){
    updateUserName(name: $name) {
      name
    }
  }`;


export const CHANGE_EMAIL = gql`
  mutation changeEmail ($email: String!){
    changeEmail(email: $email) {
      message
    }
  }`;


export const CHANGE_PASSWORD = gql`
  mutation changePassword ($oldPassword: String!,$newPassword: String!){
    changePassword(input: {oldPassword: $oldPassword, newPassword: $newPassword}) {
      message
    }
  }`;

export const SET_PASSWORD = gql`
  mutation setPass ($newPass: String!){
    setPass(newPass: $newPass) {
      message
    }
  }`;



export const UPDATE_PET_NAME = gql`
  mutation updatePetName ($petId: Int!,$name: String!){
    updatePetName(petId: $petId, name: $name) {
    id
    }
  }`;

export const MY_ACTIVITIES = gql`
query myActivities {
  myActivities  {
   	id,
    createdAt,
    message,
  }
}`;

export const COLLECT_TO_CLAIM = gql`
mutation collectToClaim($amount:  Float!){
  collectToClaim(amount: $amount) {
    code
    message
  }
}`;


export const OPEN_CAT_BOX = gql`
mutation openBox($petId:  Int!){
  openBox(petId: $petId) {
    code
    message
  }
}`;

export const UPDATE_REF_ID = gql`
mutation updateRefId($refId:  Int!){
  updateRefId(refId: $refId) {
    refId
  }
}`;

export const UPDATE_REF_ID_2 = gql`
mutation updateRefId2($refId:  Int!){
  updateRefId2(refId: $refId) {
    refId
  }
}`;

export const QUERY_EVENT_REF = gql`
query {
    event_Ref {
      id
      name
      fromTime
      toTime
      image 
      title
      desc
      sumDesc
      claimable
      nofBuys
      accumulatedRewardList {
        id
        sum
        sumDesc
        threshold
        thresholdDesc
        reward
      }
    }
}`;

export const QUERY_EVENT_REF_PET_BUYERS = gql`
query {
  event_Ref_PetBuyers {
    id
    name
    ethAddr
    refTime
  }
}`;

export const QUERY_EVENT_REF_BUY_PETS = gql`
query ($buyerId: Int!){
  event_Ref_BuyPets (buyerId: $buyerId) {
      createdAt
      price
      pet{
        id
        name
        avatarURL
      }
    }
}`;

export const QUERY_EVENT_REF_CLAIM_DATA = gql`
query {
  event_Ref_ClaimData {
    id
    amount
    nonce
    sign
  }
}`;


export const QUERY_ONCHAIN_ITEMS = gql`
query onchainableMaterials {
  onchainableMaterials {
    id
    number
    state
    pendingNumber
  }
}`;

export const INIT_CLAIM_ITEM = gql`
mutation claimMaterialInit ($materialId: Int!, $amount: Int!){
  claimMaterialInit (materialId: $materialId, amount: $amount) {
    code
    message
  }
}`;

export const QUERY_CLAIM_ITEM_DATA = gql`
query claimMaterialData ($materialId: Int!){
  claimMaterialData (materialId: $materialId) {
    id
    amount
    nonce
    sign
  }
}`;


export const QUERY_MINT_GEM_DATA = gql`
query userGemMintData ($id: Int!){
  userGemMintData (id: $id) {
    inGameId
    quality
    nonce
    sign
  }
}`;