import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import React, { Fragment } from "react";

const useStyles = makeStyles((theme) => ({
    paper: {
        //padding: theme.spacing(2)
    },


}));



export default function HelpBox({component,title,content}) {

    const [open, setOpen] = React.useState(false)
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const classes = useStyles()
    return (
        <Fragment>

            {
                React.cloneElement(
                    component,
                    { onClick: handleOpen }
                )
            }

            <Dialog
                classes={{
                    paper: classes.paper
                }}
                
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <Divider />
                <DialogContent>
                <Typography variant="body1" style={{ lineHeight: 2 }} style={{whiteSpace: 'pre-line'}}>
                    {content}
                </Typography>
                    <DialogActions>

                    </DialogActions>

                </DialogContent>

            </Dialog>
        </Fragment>
    );


}
