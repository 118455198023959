import { Chip, Grid, IconButton, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AccountCircleOutlined, HelpOutlineOutlined, HourglassEmpty, HourglassFull } from '@mui/icons-material';
import React from 'react';
import { Link } from 'react-router-dom';
import { BREED_AGE, CLASSES, FACTIONS, GEM_QUALITY, MAX_BREED_COUNT, TIMES_RESET } from '../../utils/constants';
import { linkToUser } from '../../utils/link';
import { getAge, getTimeToBreed, getOwnerLink } from '../../utils/utils';
import { useQuery } from '@apollo/client';
import { QUERY_SERVER_TIME } from '../../graphql/User/marketQuery';
import HelpBox from '../Misc/HelpBox';
import RebornHistory from '../Pet/RebornHistory';











const useStyles = makeStyles((theme) => ({

    paper: {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(1),

        padding: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(2),
        },
    },
    icon: {
        marginRight: theme.spacing(0.5)
    },

    owner: {
        marginTop: theme.spacing(1)
    }



}));




export function GemAbout({ ninneko, timeToBreed }) {





    const classes = useStyles()

    // const petAge = getAge(ninneko.createdAt)

    // const isNewBorn = petAge < BREED_AGE


    // console.log(ninneko)

    const ClassIcon = CLASSES[ninneko.clazz - 1].icon
    return (
        <Paper className={classes.paper} variant="outlined"
        //elevation={4}
        >
            <Grid item container spacing={2}>

                <Grid item xs={4} >
                    <Typography color="textSecondary">quality</Typography>
                    <Grid container>
                        <Typography>{GEM_QUALITY[ninneko.quality]}</Typography>
                    </Grid>
                </Grid>


                <Grid item xs={4} >
                    <Typography color="textSecondary">class</Typography>
                    <Grid container>
                        <ClassIcon color="secondary" className={classes.icon} />
                        <Typography>{CLASSES[ninneko.clazz - 1].label}</Typography>
                    </Grid>
                </Grid>

                {ninneko.owner?.id > 0 && <Grid item xs={4}>
                    <Typography color="textSecondary">owner</Typography>
                    <div>

                        <Link to={linkToUser + "/?" + getOwnerLink(ninneko.owner)} style={{ textDecoration: 'none' }}>
                            <Typography color="textPrimary" noWrap >{ninneko.owner?.name}</Typography>
                        </Link>
                    </div>
                </Grid>}



            </Grid>
        </Paper>
    )

}

