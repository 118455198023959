import { Dialog, DialogActions, DialogContent, Divider, ListItem, ListItemIcon, ListItemText, Typography, useTheme } from "@mui/material";
import React, { Fragment } from "react";
import { makeStyles } from '@mui/styles';
import { TBL_SKILL } from "../../db/tblSkill";
import { PET_GEN_MAP } from "../../utils/genMap";
const useStyles = makeStyles((theme) => ({
    skillIcon: {
        width: 60,
        height: 60,
        margin: theme.spacing(1),
        marginTop: theme.spacing(2)
    }

}));


export default function PetSKillBox({ component, id = 45, active = false }) {

    const [open, setOpen] = React.useState(false)
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        //setError(null)
    };

    const theme = useTheme()

    //console.log(id)
    //console.log(TBL_SKILL.id)

    const classes = useStyles()

    return (
        <Fragment>

            {
                React.cloneElement(
                    component,
                    { onClick: handleOpen }
                )
            }

            <Dialog

                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {/* <DialogTitle id="alert-dialog-title">  */}
                <ListItem>
                    <ListItemIcon>
                        <img src={"/images/skills/Skill_" + id + ".png"} className={classes.skillIcon} />
                    </ListItemIcon>

                    <ListItemText>
                        <Typography variant="h6">
                            
                            {PET_GEN_MAP[id].name}
                        </Typography>
                        <Typography color="textSecondary">
                            {active?"Active Skill":"Passive Skill"}
                        </Typography>
                    </ListItemText>
                </ListItem>
                {/* </DialogTitle> */}
                <Divider />
                <DialogContent>
                    <Typography style={{ marginTop: theme.spacing(1) }}>
                        {TBL_SKILL[id]}
                    </Typography>
                </DialogContent>
                <DialogActions />
            </Dialog>
        </Fragment>
    );


}
