import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { AppConsumer } from '@inlet/react-pixi';
//import loadable from '@loadable/component';
import { Button, Grid, Paper, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ArrowBack, ConstructionOutlined, TryRounded } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { QUERY_PET, PET_HISTORY, QUERY_SERVER_TIME } from '../../graphql/User/marketQuery';
import { UPDATE_PET_NAME } from '../../graphql/User/userQuery';
import sessionData from '../../sessionData';
import LoadingSpinner from '../Misc/LoadingSpinner';
import { BodyParts } from '../Pet/Info/BodyParts';
import { PetAbout } from '../Pet/Info/PetAbout';
import { PetCommand } from '../Pet/Info/PetCommand';
import { PetStats } from '../Pet/Info/PetStats';
import { SaleHistory } from '../Pet/Info/SaleHistory';
//import PetAnim, { STAGE_WIDTH } from '../Pet/PetAnim';
import PetSKillList from '../Pet/PetSkillList';
import SimplePetItem from '../Pet/SimplePetItem';
import { getTimeToBreed } from '../../utils/utils';
import { useServerTime } from '../../utils/hookUtils';
import { GENERATION_BETA, PET_STAGE_COTTON_BOX, PET_STAGE_PET, PET_STAGE_SPECIAL_BOX, STAGE_WIDTH, TEST_NET } from '../../utils/constants';
import { linkToHome } from '../../utils/link';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import PetAmulet from '../Pet/PetAmulet';
import PetBigImage from '../Pet/PetBigImage';
import { testPoint } from '../../utils/petStatsUtil';



//import PetAnim from '../Pet/PetAnim';
const PetAnim = React.lazy(() => import('../Pet/PetAnim'))

const useStyles = makeStyles((theme) => ({
    root: {
        justifyContent: 'center',
    },

    petBox: {
        alignSelf: 'flex-start',
        paddingTop: theme.spacing(3),
        maxWidth: 344,

        [theme.breakpoints.up('md')]: {
            position: 'sticky',
            top: 80,
            //marginRight: theme.spacing(3)
        },
    },
    stats: {
        marginTop: theme.spacing(4),
        flex: 'auto',
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(3)
        },

    },
    paper: {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(1),

        padding: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1),
        },
    },
    nonPaper: {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(1),
    },
    name: {
        marginTop: theme.spacing(1),
        //marginBottom: theme.spacing(1),
        //textAlign: 'center'
        //padding: theme.spacing(3)

    },
    icon: {
        marginRight: theme.spacing(0.5),
        width: 26,
        height: 26
    },
    centerGrid: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        //flexShrink: 0
    },
    generation: {
        display: 'flex',
        marginBottom: theme.spacing(2)
    },
    chip: {
        marginRight: theme.spacing(1)
    },
    catBox: {
        width: 260,
        height: 260,
        padding: theme.spacing(2),
        paddingTop: 0
    }

}));

const CssTextField = withStyles({
    root: {
        '& .MuiInput-underline:before': {
            borderBottom: 'none',
        },

    },
})(TextField);


function PetName({ ninneko, classes, isMyPet }) {

    const [name, changeName] = useState(ninneko.name)
    const [error, setError] = useState(null)

    useEffect(() => {
        const initName = () => {
            changeName(ninneko.name)
        };
        initName();
        //return () => setSpineData(null)
    }, [ninneko.id]);


    const handleChangePetName = (event) => {
        changeName(event.target.value)
    }

    const [updatePetName] = useMutation(
        UPDATE_PET_NAME,
        {
            onError: (err) => setError(err.toString()),
            onCompleted: () => setError(null),
            refetchQueries: [
                { query: QUERY_PET, variables: { id: ninneko.id } }
            ]

        });

    const editPetName = () => {

        if (name == ninneko.name) return

        updatePetName({
            variables: {
                petId: ninneko.id,
                name: name,
            }
        }).catch(console.log)

    }

    return (
        <Fragment>
            {isMyPet ?
                <CssTextField
                    fullWidth
                    className={classes.name}
                    value={name}
                    onChange={handleChangePetName}
                    inputProps={{
                        style: {
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            width: '100%',

                        },
                        maxLength: 12
                    }}
                    variant="standard"
                    onBlur={editPetName}
                // onInput = {(e) =>{
                //     e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,12)
                // }}
                />
                :
                <Typography className={classes.name} noWrap>{ninneko.name}</Typography>
            }
            <Typography color="secondary">{error}</Typography>
        </Fragment>
    )

}


function PetBox({ ninneko, isMyPet }) {

    const history = useHistory()

    const goBack = () => {
        history.goBack()
    }
    const classes = useStyles()
    return (
        <Grid container>
            <Grid item xs={12}>
                {/* <Link to={linkToHome} style={{textDecoration: 'none'}}> */}
                <Button startIcon={<ArrowBack />} onClick={goBack} variant="outlined" >Back</Button>
                {/* </Link> */}
                <PetName ninneko={ninneko} classes={classes} isMyPet={isMyPet} />
                <Typography color="textSecondary">{"# " + ninneko.id}</Typography>
            </Grid>
            <Grid item xs={12} style={{ width: STAGE_WIDTH }}>
                {
                    ninneko.stage == PET_STAGE_PET ?
                        <Fragment>
                            <AppConsumer>
                                {app => <PetAnim ninneko={ninneko} app={app} />}
                            </AppConsumer>


                            <PetSKillList ninneko={ninneko} />
                            <PetAmulet ninneko={ninneko} />

                        </Fragment>
                        :
                        <Fragment>
                            <img src={ninneko.stage == PET_STAGE_COTTON_BOX ? "/images/fodder.png" : "/images/fodder.png"}
                                alt="Cat Box"
                                className={classes.catBox} />
                        </Fragment>
                }
            </Grid>

        </Grid>
    )
}


// const PetCommand = loadable(() => import( '../Pet/Info/PetCommand'/* webpackChunkName: "PetCommand" */))



export default function PetView({ id }) {
    const client = useApolloClient()
    const classes = useStyles()
    const variables = {
        id: id
    }
    const { loading, error, data, refetch } = useQuery(QUERY_PET, { variables })
    const historyQuery = useQuery(PET_HISTORY, { variables })

    const serverTime = useServerTime()

    const [loadingAction, setLoadingAction] = useState(false)

    //const [timeToBreed, setTimeToBreed] = React.useState(null)



    //----------------------------------------------------------------//

    if (loading) return <LoadingSpinner />
    if (error) {
        setLoadingAction(false)
        return `Error! ${error.message}`;
    }

    const refetchQuery = async () => {
        refetch(variables)
        await client.refetchQueries({
            //include: [QUERY_PETS,QUERY_MY_PETS],
            include: 'all'
        });
        setLoadingAction(false)
        // console.log("----refetchQuerry-------")
    }
    const refetchQueryPet = async () => {
        console.log("refetchQueryPet")
        refetch(variables)
        await client.refetchQueries({
            include: [QUERY_PET],
            // include: 'all'
        });
        setLoadingAction(false)

        // console.log("----refetchQuerryRebornData-------")
    }
    const ninneko = data.pet
    let isMypet = false
    if (ninneko.owner) {
        isMypet = (ninneko.owner.id == sessionData.userId)
    }

    //console.log("ninneko.createdAt " + ninneko.createdAt)

    //const timeToBreed = ninneko.generation == GENERATION_BETA?getTimeToBreed(serverTime, ninneko.createdAt):0
    const timeToBreed = getTimeToBreed(serverTime, ninneko.createdAt)



    // client.query({query: QUERY_SERVER_TIME}).then(
    //     result => {
    //         // console.log(result.data.serverTime.message)
    //         const serverTime =  result.data.serverTime.message
    //         //console.log(serverTime)
    //         //console.log(ninneko.createdAt)
    //         setTimeToBreed(getTimeToBreed(serverTime,ninneko.createdAt))
    //     }
    // );

    //console.log(timeToBreed)
    //----------------------------------------------------------------//

    if (historyQuery.loading) return <LoadingSpinner />
    if (historyQuery.error) return `Error! ${historyQuery.error.message}`;

    const histories = historyQuery.data.saleHistory
    //console.log(ninneko)
    //testPoint()

    return (

        <Grid container className={classes.root} >
            <Grid item className={classes.petBox} >
                <PetBox ninneko={ninneko} isMyPet={isMypet} id={ninneko.id} />
            </Grid>
            <Grid item className={classes.stats} xs={12} sm={6}>
                <PetCommand ninneko={ninneko} isMyPet={isMypet} refetchQuery={refetchQuery} refetchQueryReborn={refetchQueryPet} timeToBreed={timeToBreed}
                    loadingAction={loadingAction} setLoadingAction={setLoadingAction}
                //  isBought = {isBought} setIsBought = {setIsBought} 
                //  isStopSale = {isStopSale} setIsStopSale = {setIsStopSale}
                />
                <PetAbout ninneko={ninneko} timeToBreed={timeToBreed} />
                {
                    ninneko.stage == PET_STAGE_PET &&
                    <Fragment>
                        <PetStats ninneko={ninneko} />
                        <BodyParts ninneko={ninneko} showDetails={true} />
                    </Fragment>
                }
                {ninneko.stage == PET_STAGE_PET && ninneko.parent1.id != 0 && ninneko.parent2.id != 0 &&
                    <Fragment>
                        <Typography variant="h6">Parents</Typography>
                        < Grid container spacing={1} className={classes.nonPaper} >
                            <Grid item xs={6} >
                                <SimplePetItem ninneko={ninneko.parent1} />
                            </Grid>

                            <Grid item xs={6}>
                                <SimplePetItem ninneko={ninneko.parent2} />
                            </Grid>
                        </Grid>
                    </Fragment>
                }
                {(histories.length > 0) && <SaleHistory histories={histories} />}

                {
                //     TEST_NET && <Grid item xs={12}>
                //     <AppConsumer>
                //         {app => <PetBigImage ninneko={ninneko} app={app} />}
                //     </AppConsumer>

                // </Grid>
                }
            </Grid>



        </Grid >


    );


}
