import Box from '@mui/material/Box';
import { makeStyles, styled } from '@mui/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import { CLASSES } from '../../utils/constants';


export function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ paddingTop: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export const AntTabs = styled(Tabs)({
    borderBottom: '1px solid #e8e8e8',
    '& .MuiTabs-indicator': {
        backgroundColor: '#1890ff',
    },
    "& .MuiTab-wrapper": {
        flexDirection: "row",
        // justifyContent: "flex-start"
    },
    '& .MuiTab-wrapper > :first-of-type': {
        marginRight: 6,
      },
      "& .MuiTab-labelIcon": {
          minHeight: 0
        //flexDirection: "row",
        // justifyContent: "flex-start"
    },

});

export const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({

    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    // flexDirection: 'row',
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.85)',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        color: '#40a9ff',
        opacity: 1,
    },
    '&.Mui-selected': {
        color: '#1890ff',
        fontWeight: theme.typography.fontWeightMedium,
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#d1eaff',
    },







}));
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};


const useStyles = makeStyles((theme) => ({
    list: {
        padding: theme.spacing(5)
    },
    paper: {
        display: 'flex',
        //padding: theme.spacing(5)
    },
    wrapper: {
        flexDirection: 'row',
        color: '#40a9ff',
    }

}));
