import { Chip, FormControlLabel, Grid, ListItem,Card, Paper, Switch, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { HelpOutlineOutlined } from '@mui/icons-material';
import { width } from 'dom-helpers';
import React, { Fragment, useState } from 'react';
import { CLASSES, CLASSES_ICON } from '../../../utils/constants';
import { PET_GEN_MAP } from '../../../utils/genMap';
import BodyPartHelpBox from '../BodyPartHelpBox';


export const Tail = '/images/svg/duoi.svg';
export const Eyes = '/images/svg/mat.svg';
export const Mouth = '/images/svg/mom.svg';
export const Ears = '/images/svg/tai.svg';
export const Hand = '/images/svg/tay.svg';
export const Hair = '/images/svg/toc.svg';

// const MySvgClass1 = '/images/svg/n1.svg';

const useStyles = makeStyles((theme) => ({

    paper: {
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(1),
        // backgroundColor: 'Highlight',
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1),
        },
    },
    partBox: {
        display: "inline-flex",
        marginBottom: theme.spacing(1.5)
    },
    part: {
        width: 45,
        height: 45,
        marginRight: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            width: 30,
            height: 30,
            marginRight: theme.spacing(0.5)
        },

    },
    simplePart: {
        width: 36,
        height: 36,
        marginRight: theme.spacing(1),
    },
    genes: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },

    },
    filterGreen: {
        //https://codepen.io/sosuke/pen/Pjoqqp

        filter: 'invert(46%) sepia(0%) saturate(5273%) hue-rotate(68deg) brightness(99%) contrast(94%)'
    },

    classIcon: {
        // position: 'absolute',
        // top: 28,
        // left: 40
    }

}));

const PET_PARTS = [
    { name: "hand", icon: Hand },
    { name: "tail", icon: Tail },
    { name: "eyes", icon: Eyes },
    { name: "hair", icon: Hair },
    { name: "ears", icon: Ears },
    { name: "mouth", icon: Mouth }

]

function PartDetails({ classes, ninneko }) {
    return (
        <Paper className={classes.paper} variant="outlined">
            <Grid container spacing={1}>
                <Grid item sm={2} className={classes.genes} >
                    Genes
                </Grid>
                <Grid item xs={4} sm={3} >
                    Primary
                </Grid>
                <Grid item xs={4} sm={3} >
                    Hidden 1
                </Grid>
                <Grid item xs={4} sm={3} >
                    Hidden 2
                </Grid>

                {PET_PARTS.map( (part, key) =>
                    <Fragment key={key}>
                        <Grid item sm={2} className={classes.genes} >
                            <img src={part.icon} color="action" className={classes.simplePart} />
                        </Grid>
                        {
                            ["D", "R", "R1"].map(
                                gen => {
                                    return <Grid item xs={4} sm={3} key={gen} style={{ display: 'flex', justifyContent: 'center' }}>
                                        <SimplePart genId={ninneko[part.name + gen]} genType={gen} />
                                    </Grid>
                                }
                            )
                        }
                    </Fragment>

                )}
            </Grid>
        </Paper>
    )
}



function SimplePart({ genId, genType }) {



    const classes = useStyles()
    const isWild = PET_GEN_MAP[genId].type == 1
    const classId = PET_GEN_MAP[genId].class
    const ClassIcon = CLASSES_ICON[classId - 1]

    let color = "textSecondary"
    if (genType == "D") color = "textPrimary"
    if (isWild) color = "primary"

    return (
        <ListItem style={{ margin: 0, padding: 0, }}>
            <img src={ClassIcon}
                style={{ width: 25, height: 25 }}
                className={genType != "D" ? classes.filterGreen : undefined}
            />
            <Typography color={color} >
                {PET_GEN_MAP[genId].name}
            </Typography>
        </ListItem>
    )
}


export function Part({ icon, genId }) {


    const classes = useStyles()
    const classId = PET_GEN_MAP[genId].class
    // console.log("genId = " + genId + " classId " + classId)
    const ClassIcon = CLASSES[classId - 1].icon
    const isWild = PET_GEN_MAP[genId].type == 1
    return (
        <div className={classes.partBox}>
            <div >
                {icon}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <ListItem style={{ margin: 0, padding: 0, flex: 1 }}>
                    <ClassIcon color="primary" className={classes.classIcon} />
                    <Typography variant="subtitle1">
                        {PET_GEN_MAP[genId].name}
                        {/* {PET_GEN_MAP[genId].name + "-" +PET_GEN_MAP[genId].id} */}
                    </Typography>
                </ListItem>
                {isWild && <div><Chip label="WILD" size="small" color="primary" variant="outlined" style={{ marginLeft: 5 }} /></div>}
            </div>
        </div>
    )
}

export function BodyParts({ ninneko, showDetails }) {

    const [details, setDetails] = useState(false)

    // console.log(ninneko)
    const handleChangeDetails = () => setDetails(!details)

    const classes = useStyles()
    return (
        <Fragment>
            <ListItem style={{ padding: 0, margin: 0}}>
                <Typography variant="h6">Cattributes &nbsp; </Typography>
                <BodyPartHelpBox component={<HelpOutlineOutlined color="action" />} />
                {showDetails &&<FormControlLabel style={{ marginLeft: 'auto' }}
                    control={<Switch
                        color="primary"
                        checked={details}
                        onChange={handleChangeDetails}
                        name="checkedA" />}
                    label="Details"
                />}
            </ListItem>

            {details ?
                <PartDetails classes={classes} ninneko={ninneko} />
                :
                <Paper className={classes.paper} variant="outlined" /*elevation={4}*/>
                    <Grid container spacing={2}>
                        {PET_PARTS.map(
                            part => <Grid item xs={6} key={part.name}>
                                <Part icon={<img src={part.icon} color="action" className={classes.part} />} genId={ninneko[part.name + "D"]} />
                            </Grid>
                        )}

                    </Grid>
                </Paper>
            }

        </Fragment>
    )
}