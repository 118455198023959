import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

import Claim from '../User/Claim';
import ClaimFromGameToChain from '../User/ClaimFromGameToChain';
import ClaimItem from '../User/ClaimItem';



const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(2),
        },
    },


}));


export default function ClaimView() {

    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Typography variant="h6">Claim items In-Game to your Wallet</Typography>
            <div style={{ marginTop: 24 }} />
            <Grid container spacing={2}>
                <Grid item>
                    <Claim />
                </Grid>
                <Grid item>
                    <Grid container spacing={2} >
                        <ClaimFromGameToChain />
                    </Grid>
                </Grid>


            </Grid>


        </div>


    );


}
