import {
    Button,
    Card,
    Divider,
    Grid, Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import sessionData from "../../sessionData";
import { GEM_EFFECT, GEM_NAME } from "../../utils/constants";

import { approveBuyGemNft, buyGemNft, buyGemNftBundle, checkAllowanceBuyGem } from "../../utils/nftGem";
import { MHidden } from "../@material-extend";
import { BuyGemBoxDialog } from "../Gem/BuyGemBoxDialog";


export const BUNDLE_PRICE_NINO = {
    1: 1667,
    2: 2500,
    5: 5000,
    10: 8333,
    20: 13333
}


export const BUNDLE_PRICE_BNB = {
    1: 0.04,
    2: 0.06,
    5: 0.12,
    10: 0.2,
    20: 0.32
}

const useStyles = makeStyles((theme) => ({

    grid: {
        padding: theme.spacing(2),
        display: 'flex',
        //alignItems: 'stretch',
        justifyContent: 'center'
    },
    centerGrid: {
        display: 'flex',
        //alignItems: 'stretch',
        justifyContent: 'center'
    },
    bannerImg: {
        display: "flex",
        borderRadius: "10px",
        backgroundImage: 'url("/images/gem_banner.png")',
        backgroundRepeat: 'no-repeat',
        height: 350,
        backgroundPosition: 'center',
        //background: "linear-gradient(268.58deg,#0c2a54 .69%,#1a3175)",
        //border: "1px solid #000",
        //boxSizing: "border-box",
        //boxShadow: "0 4px 4px rgba(0,0,0,.25)",
        padding: "35px 26px 28px",
        margin: "24px 0 43px",
        flexWrap: "wrap",
        justifyContent: "space-between",
        color: "white",
    },

}));

function GemItem({ id, buyBox, approveBuyItemNINO, loading }) {


    const [openBuyBox, setOpenBuyBox] = useState(false);

    const classes = useStyles()
    return (
        <Card style={{ width: 220, margin: 8, padding: 16 }}>
            <Grid item xs={12} >


                <Typography color="textPrimary" style={{ paddingBottom: 6 }}>
                    {GEM_NAME[id]}
                </Typography>
                <Divider />

                <div className={classes.centerGrid}>
                    <img src={"/images/gems/5/" + id + ".png"} width={100} height={100} style={{ margin: 16 }} />
                </div>

                <div className={classes.centerGrid}>
                    <Typography color="textPrimary" variant="body2" >
                        Set effect:

                    </Typography>

                </div>

                <div className={classes.centerGrid}>
                    <Typography color="textPrimary" variant="body2" >
                        <strong>{GEM_EFFECT[id]}</strong>
                    </Typography>

                </div>

                {sessionData.isLoggedIn() && approveBuyItemNINO &&
                    <div className={classes.centerGrid}>

                        <Button
                            onClick={() => setOpenBuyBox(true)}
                            variant="contained">Buy now</Button>
                    </div>
                }
            </Grid>

            <BuyGemBoxDialog
                loading={loading}
                buyBox={buyBox}
                open={openBuyBox} onClose={() => setOpenBuyBox(false)} id={id}
            />

        </Card>

    )
}

export function NftGemBox({ approveBuyItemNINO }) {
    const classes = useStyles()

    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false);




    //console.log("sao goi nhieu vay "+approved)
    //getInfo ()




    const onError = () => {
        console.log("clean loading")
        setLoading(false)
    }



    const buyBox = (id, numberOfBox, byBNB = true) => {
        // setOpenBuyBox(false);
        setLoading(true);
        buyGemNftBundle(id, numberOfBox, enqueueSnackbar,
            () => {
                setLoading(false);
                enqueueSnackbar("Buy " + parseInt(numberOfBox) + " gems successfully! Your gems will be in to your inventory in a few minutes", { variant: 'success', autoHideDuration: 10000 });
            },
            onError,
            byBNB? BUNDLE_PRICE_BNB[numberOfBox]:BUNDLE_PRICE_NINO[numberOfBox],
            byBNB
        );
    }



    return (

        <Grid container
        //style={{ maxWidth: "800px" }}
        >
            <MHidden width='mdDown'>
                <Grid item xs={12}>
                    <div className={classes.bannerImg}>

                    </div>
                </Grid>
            </MHidden>
            <Grid item xs={12}>
                <Grid container className={classes.grid} spacing={2}>
                    {
                        Object.keys(GEM_NAME).map(
                            index => <GemItem id={index} key={index}
                                approveBuyItemNINO={approveBuyItemNINO}
                                buyBox={buyBox}

                                loading={loading} />
                        )
                    }
                </Grid>
            </Grid>

        </Grid>


    );
}


