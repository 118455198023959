import { makeStyles } from '@mui/styles';
import React from "react";
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import HeaderBar from "../components/Header/HeaderBar";
import PetFilterHandler from '../components/Pet/PetFilterHandler';
import InventoryView2 from '../components/views/InventoryView2';
import MarketView from "../components/views/MarketView";
import MarketView2 from '../components/views/MarketView2';
//import {animation} from "../utils/pixiApp"








export default function InventoryLayout() {

    const queryParams = new URLSearchParams(window.location.search)

    console.log(queryParams)

    return (
        
            <PetFilterHandler queryParams={queryParams} inventory="iv_">
                <InventoryView2 />
            </PetFilterHandler>
        

    );

}


