import { useMutation } from '@apollo/client';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle,  TextField, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import React, { Fragment, useRef } from "react";
import { CHANGE_PASSWORD, QUERY_ME, SET_PASSWORD } from "../../graphql/User/userQuery";
import { linkToForgotPass } from '../../utils/link';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    field: {
        margin: theme.spacing(2),
        width: "100%",
        [theme.breakpoints.up('sm')]: {
            
            width: '40ch'
        },
        marginLeft: 0,
        
    },
    paper: {
        minWidth: 320
    }

}));


export default function ChangePassBox({ component }) {

    const [open, setOpen] = React.useState(false)

    const [error, setError] = React.useState(null)
    
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setError(null)
    };
    

    const [changePassword] = useMutation(
        SET_PASSWORD,
        {
            onError: setError,
            onCompleted: handleClose,
            refetchQueries: [
                { query: QUERY_ME }
            ]
        });

    
    
    
    
    //const oldPasswordRef = useRef()
    const passwordRef = useRef()
    const retypePasswordRef = useRef()



    

    const handleUpdate = () => {

        if (
            //!oldPasswordRef || !oldPasswordRef.current || 
            !passwordRef || !passwordRef.current ||
            !retypePasswordRef || !retypePasswordRef.current
            ) return

        //const oldPassword = oldPasswordRef.current
        const password = passwordRef.current
        const retypePassword = retypePasswordRef.current

        

        if (password.value !== retypePassword.value) {
            setError({message: "Passwords do not match!"})
            return
        }

        

        changePassword({
            variables: {
                //oldPassword: oldPassword.value,
                newPass: password.value,
            }
        })
            .catch(console.log)

    };


    const classes = useStyles()

    return (
        <Fragment>
            {
                React.cloneElement(
                    component,
                    { onClick: handleOpen }
                )
            }

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                // style={{minWidth: 500}}
                classes={{
                    paper: classes.paper
                }}
            >
                <DialogTitle id="alert-dialog-title">{"Change Password"}</DialogTitle>
                <DialogContent>
                        {/* <TextField className={classes.field} inputRef={oldPasswordRef} placeholder="Curent Password" type="password" /> */}
                        {/* <div>
                            <Link to={linkToForgotPass}><Typography>Forgot password?</Typography></Link>
                        </div> */}
                        <div><TextField className={classes.field} inputRef={passwordRef} placeholder="Password" type="password" /></div>
                        <div><TextField className={classes.field} inputRef={retypePasswordRef} placeholder="Retype password" type="password" /> </div>
                        <div className={classes.field}><Typography color="error" variant="subtitle2">{error ? error.message : ""}</Typography> </div>

                </DialogContent>
                <DialogActions>

                    <Button onClick={handleUpdate} variant="contained" color="primary">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );


}
