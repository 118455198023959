import {
    Card,
    Grid,
    ListItem,
    ListItemIcon,
    Paper,
    Typography,
    Alert,
    InputBase,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Tooltip
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { AccountCircleOutlined } from '@mui/icons-material';
import React from 'react';
import { makeStyles } from '@mui/styles';
import { TokenBalance } from './InventoryView';
import { Button } from '@mui/material';
import { useState } from 'react';
import { approveBuyItemByNINO, approveBuyItemByMATA, buyItemByMATA, buyItemByNINO, checkApproveBuyItemNINO, checkApproveBuyItemMATA, getArrayNumberItemBoughtByNINOToday } from '../../utils/cryptoWallet';
import sessionData from '../../sessionData';
import { useEffect } from 'react';
import { useSnackbar } from "notistack";
import Countdown from 'react-countdown';



const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(3),
        },
        //padding: theme.spacing(2),
        //margin: theme.spacing(2),
        //justifyContent: 'center',
        //flex: 'auto',
        //display: 'flex'
    },
    stakingButtonWrapper: {
        marginTop: "5px",
        boxShadow: "inset 1px 2px 3px rgba(0, 0, 0, 0.075)",
        background: "rgba(244, 244, 244)"
    },
    stakingButton: {
        marginTop: "4px",
        width: "85px"
    }
}));

const TYPE_GREEN_FISH = 1
const TYPE_PURPLE_FISH = 2


const fishItems = [
    {
        id: 1,
        price: 5,
        limit: 10,
        disabled: false,
        type: TYPE_GREEN_FISH
    },
    {
        id: 2,
        price: 13,
        limit: 10,
        disabled: false,
        type: TYPE_GREEN_FISH
    },
    {
        id: 3,
        price: 20,
        limit: 10,
        disabled: false,
        type: TYPE_GREEN_FISH
    },
    {
        id: 4,
        price: 45,
        limit: 10,
        disabled: false,
        type: TYPE_GREEN_FISH
    },
    {
        id: 5,
        price: 90,
        limit: 10,
        disabled: false,
        type: TYPE_GREEN_FISH
    },
    {
        id: 6,
        price: 135,
        limit: 10,
        disabled: false,
        type: TYPE_GREEN_FISH
    },
    {
        id: 7,
        price: 600,
        limit: 10,
        disabled: false,
        type: TYPE_PURPLE_FISH
    },
    {
        id: 8,
        price: 1800,
        limit: 10,
        disabled: false,
        type: TYPE_PURPLE_FISH
    }
]

const stakeNINOitem = {
    id: 1,
    price: 1000,
    limit: -1,
    desc: 'Staking NINO',
    disabled: false,
}

//-------------
const betToken = {
    id: 1,
    price: 1000,
    limit: -1,
    avatar: 600693,
    desc: 'NINO Bet Token',
    disabled: false,
}


const betTokenA = {
    id: 2,
    price: 1000,
    limit: -1,
    avatar: 600695,
    desc: 'Bet Team A',
    disabled: false,
}


const betTokenB = {
    id: 3,
    price: 1000,
    limit: -1,
    avatar: 600696,
    desc: 'Bet Team B',
    disabled: false,
}

const chest5star = {
    id: 4,
    price: 3000,
    limit: -1,
    avatar: 600697,
    desc: '5-star red equipment chest',
    disabled: false,
}


const allArt = {
    id: 5,
    price: 30000,
    limit: -1,
    avatar: 600698,
    desc: 'All 6 star red Artifacts',
    disabled: false,
}

const ninoItems = [betToken, /*betTokenA, betTokenB,*/ chest5star, allArt]

const shopMataItems = [
    {
        id: 1,
        price: 5000,
        limit: -1,
        desc: 'Instantly accquire 72 hours worth of Ninneko EXP',
        disabled: false,
    },
    {
        id: 2,
        price: 2500,
        limit: -1,
        desc: 'Used to upgrade Ninneko tier or Guild skill',
        disabled: false,
    },
    // {
    //     id: 3,
    //     price: 1500,
    //     limit: -1,
    //     desc: 'Yellow Artifact Special Fragment',
    //     disabled: false,
    // },
    // {
    //     id: 4,
    //     price: 4000,
    //     limit: -1,
    //     desc: 'Item equipment',
    //     disabled: false,
    // },
    // {
    //     id: 5,
    //     price: 4000,
    //     limit: -1,
    //     desc: 'Item equipment',
    //     disabled: false,
    // },
    // {
    //     id: 6,
    //     price: 4000,
    //     limit: -1,
    //     desc: 'Item equipment',
    //     disabled: false,
    // },
    // {
    //     id: 7,
    //     price: 4000,
    //     limit: -1,
    //     desc: 'Item equipment',
    //     disabled: false,
    // },
    // // {
    // //     id: 8,
    // //     price: 1000,
    // //     limit: -1,
    // //desc:'Pick a Yellow Artifact',
    // //     disabled: false,
    // // },
    // {
    //     id: 9,
    //     price: 40000,
    //     limit: -1,
    //     desc: 'Pick a Yellow Artifact',
    //     disabled: false,
    // },
    {
        id: 10,
        price: 30000,
        limit: -1,
        desc: 'Used to receive 30k MATA ingame',
        disabled: false,
    },
    {
        id: 11,
        price: 8000,
        limit: -1,
        desc: 'Reset Neko',
        disabled: false,
    },
    /*  {
          id: 12,
          price: 15000,
          limit: 1,
          desc:'Open to claim: 3.000.000 GreenFish, 3.000 PurpleFish, 1.000.000 Exp acc',
          disabled: false,
      },*/
]

const arrItemId = [1, 2, 3, 4, 5, 6, 7, 8]

const QUANTITY_ARR = Array.from(Array(10).keys())

// const QUANTITY_ARR_STAKE = [1,2,3,4,5,6,7,8,9,10,15,20,25,30,40,50,60,70,80,90,100]
const QUANTITY_ARR_STAKE = [1,2,3,4,5,6,7,8,9,10,15,20,100,1000]



function FishItemNINO({ item, canBuy, availableQuantity, setAvailableQuantity, enqueueSnackbar }) {
    const classes = useStyles()
    const [loading, setLoading] = useState(false)

    const handleBuyItem = (packageId) => {
        buyItemByNINO(packageId, enqueueSnackbar, () => {
            setLoading(false)
            let arr = { ...availableQuantity }
            arr[item.id - 1] = arr[item.id - 1] >= 1 ? arr[item.id - 1] - 1 : 0
            setAvailableQuantity(arr)
        }, () => { setLoading(false) })
    }

    return < Grid item style={{ display: 'flex', justifyContent: 'center' }} >
        <Card style={{ width: 257, height: 329, padding: 16 }}>
            <ListItem style={{ height: 179, justifyContent: 'center' }}>
                <img src={`/images/shop/fish${item.id}.png`} alt="fish" />
            </ListItem>

            <ListItem style={{ justifyContent: 'center', paddingBottom: 2 }}>
                <img src="/images/svg/coin_nino.svg" style={{ width: 30, height: 30 }} alt="nino" />
                <Typography variant="h6" noWrap>&nbsp;{item.price}&nbsp;NINO</Typography>
            </ListItem>

            <ListItem style={{ justifyContent: 'center', padding: 0, paddingBottom: 0 }}>
                <Typography variant="h7" noWrap>Available:&nbsp;{availableQuantity[item.id - 1]}</Typography>
            </ListItem>

            <ListItem style={{ justifyContent: 'center' }}>
                <LoadingButton className={classes.stakingButton}
                    loading={loading}
                    disabled={!canBuy || availableQuantity[item.id - 1] <= 0}
                    onClick={() => {
                        setLoading(true)
                        handleBuyItem(item.id)
                    }}
                    variant="contained">Buy</LoadingButton>
            </ListItem>

        </Card>
    </Grid >
}


function ItemNINO({ item, canBuy, enqueueSnackbar }) {
    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const [quantity, setQuantity] = useState(1)

    const handleChangeQuantity = (event) => {
        setQuantity(event.target.value)

    }

    const handleBuyItem = (packageId, quantity) => {
        console.log("MUA NINO "+packageId)
        buyItemByNINO(packageId, quantity, enqueueSnackbar, () => setLoading(false), () => setLoading(false))
    }
    const quantityView = () => {
        const limitQuantity = QUANTITY_ARR_STAKE.map(
            (item) => <MenuItem key={item} value={item}>Limit: {item} </MenuItem>
        )
        let lst = [];
        if (item.limit !== -1) {
            for (var i = 0; i < item.limit; i++) {
                lst.push(limitQuantity[i])
            }
            return lst;
        }
        else {
            return QUANTITY_ARR_STAKE.map(
                (item) => <MenuItem key={item}  value={item }>Quantity: {item } </MenuItem>)
        }

    }

    return < Grid item style={{ display: 'flex', justifyContent: 'center' }} >
        <Card style={{ width: 257, height: 350, padding: 16 }}>
            <ListItem style={{ height: 179, justifyContent: 'center' }}>
                <Tooltip title={item.desc}>
                    <img src={`/images/shop/${item.avatar}.png`} alt="" />
                    {/* <img src="/images/svg/coin_nino.svg" style={{ width: 100, height: 100 }} alt="nino" /> */}
                </Tooltip>
            </ListItem>
            <ListItem style={{ justifyContent: 'center', paddingBottom: 2 }}>
                <Select
                    style={{ width: 150 }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={quantity}
                    //label="Quantity"
                    size='small'
                    onChange={handleChangeQuantity}
                >
                    {
                        quantityView()
                    }

                </Select>
            </ListItem>

            <ListItem style={{ justifyContent: 'center', paddingBottom: 2 }}>
                <img src="/images/svg/coin_nino.svg" style={{ width: 30, height: 30 }} alt="nino" />
                <Typography variant="h6" noWrap>&nbsp;{quantity * item.price}&nbsp;NINO</Typography>
            </ListItem>

            <ListItem style={{ justifyContent: 'center' }}>
                <LoadingButton className={classes.stakingButton}
                    loading={loading}
                    disabled={!canBuy}
                    onClick={() => {
                        setLoading(true)
                        handleBuyItem(item.id, quantity)
                    }}
                    variant="contained">Buy</LoadingButton>
            </ListItem>

        </Card>
    </Grid >
}

function ItemMATA({ item, canBuy, enqueueSnackbar }) {
    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const [quantity, setQuantity] = useState(1)

    const handleChangeQuantity = (event) => {
        setQuantity(event.target.value)

    }

    const handleBuyItem = (packageId, quantity) => {
        buyItemByMATA(packageId, quantity, enqueueSnackbar, () => setLoading(false), () => setLoading(false))
    }
    const quantityView = () => {
        const limitQuantity = QUANTITY_ARR.map(
            (item) => <MenuItem key={item + 1} value={item + 1}>Limit: {item + 1} </MenuItem>
        )
        let lst = [];
        if (item.limit !== -1) {
            for (var i = 0; i < item.limit; i++) {
                lst.push(limitQuantity[i])
            }
            return lst;
        }
        else {
            return QUANTITY_ARR.map(
                (item) => <MenuItem key={item + 1} value={item + 1}>Quantity: {item + 1} </MenuItem>)
        }

    }

    return < Grid item style={{ display: 'flex', justifyContent: 'center' }} >
        <Card style={{ width: 257, height: 350, padding: 16 }}>
            <ListItem style={{ height: 179, justifyContent: 'center' }}>
                <Tooltip title={item.desc}>
                    <img src={`/images/shop/shop_mata_${item.id}.png`} alt="fish" />
                </Tooltip>
            </ListItem>
            <ListItem style={{ justifyContent: 'center', paddingBottom: 2 }}>
                <Select
                    style={{ width: 150 }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={quantity}
                    //label="Quantity"
                    size='small'
                    onChange={handleChangeQuantity}
                >
                    {
                        quantityView()
                    }

                </Select>
            </ListItem>

            <ListItem style={{ justifyContent: 'center', paddingBottom: 2 }}>
                <img src="/images/svg/coin_mata.svg" style={{ width: 30, height: 30 }} alt="nino" />
                <Typography variant="h6" noWrap>&nbsp;{quantity * item.price}&nbsp;MATA</Typography>
            </ListItem>

            <ListItem style={{ justifyContent: 'center' }}>
                <LoadingButton className={classes.stakingButton}
                    loading={loading}
                    disabled={!canBuy}
                    onClick={() => {
                        setLoading(true)
                        handleBuyItem(item.id, quantity)
                    }}
                    variant="contained">Buy</LoadingButton>
            </ListItem>

        </Card>
    </Grid >
}

export default function ItemView() {
    const classes = useStyles()

    const [walletAddress, setWalletAddress] = useState(null);
    const [approveBuyItemNINO, setApprovedBuyItemNINO] = useState(null)
    const [approveBuyItemMATA, setApprovedBuyItemMATA] = useState(null)
    const [availableQuantity, setAvailableQuantity] = useState([0, 0, 0, 0, 0, 0, 0, 0])

    const { enqueueSnackbar } = useSnackbar();

    const getInfo = async () => {
        setWalletAddress(sessionData.myAddress);
        setApprovedBuyItemNINO(await checkApproveBuyItemNINO())
        setApprovedBuyItemMATA(await checkApproveBuyItemMATA())
        let arrBought = await getArrayNumberItemBoughtByNINOToday(arrItemId)

        if (arrBought) {
            let arrAvailable = []
            for (let i = 0; i < fishItems.length; i++) {
                arrAvailable.push(fishItems[i].limit - arrBought[i])
            }
            setAvailableQuantity(arrAvailable)
        }
    };

    useEffect(() => {
        //muc dich de load info khi user vua vao trang, 1 chay sau 500s cho may nhanh, 1 chay 2000s cho may cham
        setTimeout(() => {
            getInfo();
        }, 500)
        setTimeout(() => {
            getInfo();
        }, 2000)
    }, []);

    const handleApproveBuyItemNINO = () => {
        approveBuyItemByNINO(enqueueSnackbar, () => { setApprovedBuyItemNINO(true) }, () => { })
    }

    const handleApproveBuyItemMATA = () => {
        approveBuyItemByMATA(enqueueSnackbar, () => { setApprovedBuyItemMATA(true) }, () => { })
    }

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <span></span>;
        } else {

            return <span>Event ends in: {days}d {hours}h {minutes}min {seconds}</span>;
        }

    };


    return (

        <div className={classes.root}>

            <TokenBalance />

            <div style={{ marginTop: 16 }} />

            {walletAddress == null &&
                <Alert severity="info">
                    Connect your wallet first
                </Alert>
            }

            {walletAddress && approveBuyItemMATA == false &&
                <Alert severity="info"
                    action={
                        <Button color="inherit" size="small" variant='outlined' onClick={handleApproveBuyItemMATA}>
                            Approve
                        </Button>
                    }>
                    You need to approve MATA to buy items
                </Alert>
            }

            {walletAddress && approveBuyItemNINO == false &&
                <Alert severity="info"
                    action={
                        <Button color="inherit" size="small" variant='outlined' onClick={handleApproveBuyItemNINO}>
                            Approve
                        </Button>
                    }>
                    You need to approve NINO to buy items
                </Alert>
            }

            {/* <Alert severity="info">
                <Typography color="#3990f8" variant="body2">
                    <Countdown
                        date={"Sat Mar 12 2022 15:00:00 GMT+0700"}
                        renderer={renderer}
                    />
                </Typography>
            </Alert> */}



            {/* {walletAddress && approveBuyItem == true &&
                <Alert severity="info">
                    Your available quantity will be refreshed next day
                </Alert>
            } */}

            <div style={{ marginTop: 27 }} />

            <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'center' }}>

                {/* {fishItems.filter((item) => !item.disabled && item.type == TYPE_PURPLE_FISH).map(
                    (item, index) =>
                        <FishItemNINO item={item}
                            key={index}
                            canBuy={walletAddress && approveBuyItem}
                            availableQuantity={availableQuantity}
                            setAvailableQuantity={setAvailableQuantity}
                            enqueueSnackbar={enqueueSnackbar} />
                )*/}
                

                {/* // {fishItems.filter((item) => !item.disabled && item.type == TYPE_GREEN_FISH).map(
                //     (item, index) =>
                //         <FishItemNINO item={item}
                //             key={index}
                //             canBuy={walletAddress && approveBuyItem}
                //             availableQuantity={availableQuantity}
                //             setAvailableQuantity={setAvailableQuantity}
                //             enqueueSnackbar={enqueueSnackbar} />
                // )}  */}

                {
                ninoItems.map(
                    (item, index) => <ItemNINO item={item}
                    key={index}
                    canBuy={walletAddress && approveBuyItemNINO}
                    enqueueSnackbar={enqueueSnackbar} />   
                )
                }

                {/* <ItemNINO item={stakeNINOitem}
                    // key={index}
                    canBuy={walletAddress && approveBuyItemNINO}
                    enqueueSnackbar={enqueueSnackbar} />      */}

                  

                {shopMataItems.sort((a, b) => a.id < b.id ? 1 : -1).map((item, index) =>
                    <ItemMATA item={item}
                        key={index}
                        canBuy={walletAddress && approveBuyItemMATA}
                        enqueueSnackbar={enqueueSnackbar} />
                )}
            </Grid>
        </div >
    );


}
