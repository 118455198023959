import { Badge, Button, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
// import NotifyIcon from '@mui/icons-material/LensRounded';
import withStyles from "@mui/styles/withStyles";
import { BorderColor } from '@mui/icons-material';

import React from 'react';
import { NavLink } from "react-router-dom";
//import myHistory from 'utils/myHistory';




const useStyles = makeStyles((theme) => ({

  icon: {
    color: 'inherit',
  },

  nav: {
    //fontWeight: 900,
    //color: theme.palette.text.secondary,
    color: 'inherit',
    textDecoration: 'none'
  },
  //so anh current route cua react router cung duoc
  activeNav: {
    color: theme.palette.primary.main,
    //backgroundColor: theme.palette.primary.light,

  },

}));

export default function SideMenu({ routes, activeNav = false, customStyle = null }) {
  const classes = useStyles()
  return (

     <List
       style={customStyle?customStyle:{}}
     >
      
      {
        routes && routes.map((prop, key) => {
          if (!prop || prop.disabled) return null
          if (prop.redirect /* || key === 0 */) return null;
          return (
            <NavLink
              to={prop.link}
              activeClassName={activeNav ? classes.activeNav : classes.nav}
              className={classes.nav}
              key={key}>
              <ListItem button
                style={customStyle?
                  { color: "inherit", paddingLeft: 0  }:{ color: "inherit" }
                }
              >
                <ListItemIcon style={{ color: "inherit" }}>
                  <prop.icon className={classes.icon} /></ListItemIcon>
                <ListItemText primary={prop.sidebarName} />
              </ListItem>
            </NavLink>
          );

        })
      }
    </List>

  )

}
