import { AccountBalanceWalletOutlined } from "@mui/icons-material";
import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import HeaderBar from "../components/Header/HeaderBar";
import LoginMetaMaskButton from "../components/Login/LoginMetaMaskButton";
import LoginWithEmailButton from "../components/Login/LoginWithEmailButton";
import LoginView from "../components/views/LoginView";




export default function StartLayout() {
    return (
        <div >
            <HeaderBar inLoginPage={true} />
            <Container maxWidth="lg" >
                <Grid container spacing={2}>
                    <Grid item >
                        <div>
                            <AccountBalanceWalletOutlined sx={{ fontSize: 140 }} />
                        </div>
                        <div>
                            <Typography>
                                1. Connect your Wallet
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item >
                        2. Create Account
                    </Grid>
                    <Grid item >
                        3. Download
                    </Grid>
                    <Grid item >
                        4. Play
                    </Grid>
                </Grid>
            </Container>


        </div >
    );

}


