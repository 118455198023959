import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    List,
    ListItem,
    Paper,
    Typography,
    useTheme
} from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import { Favorite, LocalFireDepartment, RestartAlt } from '@mui/icons-material';
import React, { Fragment, useEffect, useState } from "react";
import {
    BREED_COST_MATA,
    BREED_COST_NINO,
    CLASSES,
    FACTIONS,
    GENERATION_BETA,
    PET_AVATAR_SIZE,
    REBORN_COST_MATA,
    REBORN_COST_NINO, TIMES_RESET
} from "../../utils/constants";
import {
    approveNINO,
    approveMATA,
    checkApproveNINO,
    checkApproveMATA,
    getMATABalance,
    getNINOBalance,
    requestAdressAndApproveToken,
    rebornNFT,
    burnNFT
} from "../../utils/cryptoWallet";
import SelectPetBox from "./SelectPetBox";

import { useSnackbar } from "notistack";
import { typography } from "@mui/system";
import { QUERY_REBORN_PET_DATA } from "../../graphql/User/marketQuery";
import { useLazyQuery, useQuery } from '@apollo/client';
import LoadingSpinner from "../Misc/LoadingSpinner";
import PetView from "../views/PetView";
import { linkToInventory } from "../../utils/link";

//const AccountLayout = loadable(() => import( './layouts/AccountLayout'/* webpackChunkName: "account" */))

const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            margin: theme.spacing(2)
        }
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(1),
        width: '50%',
        minHeight: 231,

        [theme.breakpoints.up('sm')]: {
            minWidth: 270,
            minHeight: 284,
        },
    },
    cardBody: {
        height: '100%',
        display: 'block'
    },
    petAvatar: {
        // width: PET_AVATAR_SIZE,
        // height: PET_AVATAR_SIZE,
        margin: "0 auto"
    },
    price: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(1)
    },
    priceBox: {
        marginTop: theme.spacing(1),
        padding: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 0,
    },
    btnApproved: {
        backgroundColor: "white !important",
        border: '1px solid rgba(145, 158, 171, 0.8)',
        color: 'rgb(33, 43, 54) !important'
    },
    btnNotApproved: {
        //cái màu nền  này làm cho cái loading bị ẩn ko nhìn đc
        //backgroundColor: "rgb(0, 171, 85) !important",
        // backgroundColor: "white !important",
        // '&:hover': {
        //     backgroundColor: 'rgb(0, 125, 85) !important'
        // },

    },
    factionIcon: {
        position: 'absolute',
        top: 0,
        left: '88%',
        [theme.breakpoints.up('sm')]: {
            width: 30,
            height: 30
        },


    },
    name: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.93rem'
        },
    }


}));

function BurnResult({ classes, ninneko }) {

    let necoin = 150

    if (ninneko.beastCount == 1) {
        necoin = 540
    } else if (ninneko.beastCount == 2) {
        necoin = 2100
    } else if (ninneko.beastCount >= 3) {
        necoin = 2200
    }
    

    return (
        <Paper className={classes.card} variant="outlined" >
            <div style={{ display: 'block', position: 'relative' }}>
                <Typography component="div" className={classes.name} noWrap>Your Ninneko will be lost</Typography>
            </div>
            <div>
                <Typography variant="caption">*Upgrade resources will be refunded</Typography>
            </div>

            <div className={classes.cardBody}>
                <img style={{ marginTop: 24, marginBottom: 16 }} src={"/images/necoin1.png"} className={classes.petAvatar} />
            </div>

            <Typography variant="h6" component="div" className={classes.name} style={{ margin: '0 auto' }} noWrap>{necoin} Necoin</Typography>

        </Paper>
    )
}

function Neko({ ninneko, classes, bgColor, isFodder = false }) {
    const FactionIcon = ninneko.faction > 0 ? FACTIONS[ninneko.faction - 1].icon : null
    const born = new Date(ninneko.createdAt).toISOString().split('T')[0]

    return (
        <Paper className={classes.card} variant="outlined" style={{ background: bgColor }}>
            <div style={{ display: 'block', position: 'relative' }}>
                <Typography component="div" className={classes.name} noWrap>{ninneko.name}</Typography>
                {FactionIcon && <FactionIcon className={classes.factionIcon} />}
                <div>
                    <Chip label={ninneko.breedCount + " breed"} size="small" variant="outlined"
                        className={classes.chip} />
                    {ninneko.generation == 1 && <Chip label={"alpha"} size="small" variant="outlined" color="primary"
                        className={classes.chip} />}
                </div>
            </div>

            <div className={classes.cardBody}>
                {
                    isFodder ?
                        <img style={{}} src={"/images/fodder.png"} className={classes.petAvatar} />
                        :
                        <img style={{}} src={ninneko.avatarURL} className={classes.petAvatar} />
                }

            </div>

        </Paper>
    )
}


export default function BurnBox({ component, ninneko, setLoadingAction, refetchQuery, refetchQueryPet }) {



    const burnNinneko = () => {
        setLoadingAction(true)
        burnNFT(String(ninneko.id), enqueueSnackbar, () => {
            setLoadingAction(false)
            //refetchQuery()
            window.location = linkToInventory
        }, onError)
    }
    let rebornData;


    const { enqueueSnackbar } = useSnackbar();
    // const [loading, setLoading] = useState(false)

    const [open, setOpen] = useState(false)
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    // console.log(rebornData)

    const theme = useTheme()

    const classes = useStyles()





    const handleBurn = () => {
        setOpen(false)
        burnNinneko()
    }

    const onError = () => {
        // setLoadingApproveNINO(false)
        // setLoadingApproveMATA(false)
    }


    return (
        <Fragment>

            {
                React.cloneElement(
                    component,
                    { onClick: handleOpen }
                )
            }


            <Dialog

                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.dialog}
            >
                <DialogTitle id="alert-dialog-title">
                    {"Burn Ninneko to get Necoin"}
                </DialogTitle>

                <DialogContent style={{ paddingBottom: 0 }}>
                    <ListItem style={{ padding: 0 }}>
                        <Neko classes={classes} ninneko={ninneko} bgColor='rgb(237, 247, 237)' />
                        <div style={{ width: 16, zIndex: 100, display: 'flex', justifyContent: 'center' }}>
                            <LocalFireDepartment style={{ width: 50, height: 50 }} color='error' />
                        </div>
                        <BurnResult classes={classes} ninneko={ninneko} />
                    </ListItem>

                </DialogContent>



                <DialogActions style={{ justifyContent: 'center', paddingTop: 0 }}>
                    <Button onClick={handleBurn} variant="contained" color="primary"
                        //disabled={loadingApproveNINO && loadingApproveMATA || !boolApproveNINO || !boolApproveMATA}
                        style={{ margin: theme.spacing(2) }} startIcon={<LocalFireDepartment />}>
                        Burn
                    </Button>
                </DialogActions>

            </Dialog>
        </Fragment>
    );


}
